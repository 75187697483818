import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ActionMeta } from 'react-select'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import BaseSelect, { OptionType } from '../Shared/BaseSelect'
import {
  IDeleteUserFeatureData,
  IDeleteUserFeatureVars,
  DELETE_USER_FEATURE,
  IAddUserFeatureData,
  IAddUserFeatureVars,
  ADD_USER_FEATURE,
  IQueryFeaturesData,
  QUERY_FEATURES,
} from '../../graphql/admin/queryFeatures'
import { toast } from 'react-toastify'
import { Title } from '../Styles/TextCustom'
import { themeColors } from '../../const/colors'
import { Grid } from '@mui/material'

const FeatureSelect: FC<{
  login: string
  features: { id: string; name: string }[]
  refetch: () => void
}> = ({ login, features, refetch }) => {
  const { t } = useTranslation(['user_details', 'feature', 'code'])
  const [selectedFeatures, setSelectedFeatures] = useState<OptionType[]>([])
  const successNotify = (message: string) => toast.dark(message)

  const { data: existingFeatures, loading: loadingFeatures } =
    useQuery<IQueryFeaturesData>(QUERY_FEATURES, {
      fetchPolicy: 'network-only',
    })

  useEffect(() => {
    const initialFeatures = features.map(({ name }) => ({
      label: name,
      value: `${login}-${name}`,
    }))
    setSelectedFeatures(initialFeatures)
  }, [features, login])

  const [addUserFeature, { loading: addFeatureLoading }] = useMutation<
    IAddUserFeatureData,
    IAddUserFeatureVars
  >(ADD_USER_FEATURE)

  const [deleteUserFeature, { loading: deleteFeatureLoading }] = useMutation<
    IDeleteUserFeatureData,
    IDeleteUserFeatureVars
  >(DELETE_USER_FEATURE)

  const handleSelectChange = (
    selectedValue: any,
    actionMeta: ActionMeta<OptionType>
  ) => {
    if (actionMeta.action === 'select-option' && actionMeta.option?.value) {
      const { value, label } = actionMeta.option
      addUserFeature({
        variables: { login, name: label, id: value },
      })
        .then(({ data }) => {
          if (data?.addUserFeature.userFeature) {
            successNotify(`${t('user_details:add_feature')}`)
            setSelectedFeatures(selectedValue)
            refetch()
          }
        })
        .catch((error) => console.error('Feature Change Error:', error))
    }

    if (actionMeta.action === 'remove-value') {
      deleteUserFeature({
        variables: { id: actionMeta.removedValue.value },
      })
        .then(({ data }) => {
          if (data?.deleteUserFeature.userFeature) {
            successNotify(`${t('user_details:delete_feature')}`)
            setSelectedFeatures(selectedValue)
            refetch()
          }
        })
        .catch((error) => console.error('Feature Change Error:', error))
    }
  }
  const options = existingFeatures?.queryFeature.map(({ name }) => ({
    value: `${login}-${name}`,
    label: name,
  }))
  const isSelectLoading =
    addFeatureLoading || deleteFeatureLoading || loadingFeatures

  return (
    <BlueContainer>
      <Title>{t('user_features')}</Title>
      <Container>
        <BaseSelect<OptionType, true>
          value={selectedFeatures}
          defaultValue={selectedFeatures}
          onChange={handleSelectChange}
          options={options}
          isLoading={isSelectLoading}
          isDisabled={isSelectLoading}
          isClearable={false}
          isMulti
          closeMenuOnSelect={false}
          noOptionsMessage={() => t('no_features')}
          placeholder={t('choose_feature')}
        />
      </Container>
    </BlueContainer>
  )
}

export default FeatureSelect

const BlueContainer = styled(Grid)`
  background-color: ${themeColors.lightBlue};
  border-radius: 20px;
  padding: 20px;
  margin-top: 16px;
`
const Container = styled(Grid)`
  max-width: 500px;
`
