import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  DOMAIN_CREATE,
  IDomainCreateVars,
  IDomainCreateData,
} from '../../graphql/tenant/createDomain'
import {
  DOMAIN_DELETE,
  IDomainDeleteVars,
  IDomainDeleteData,
} from '../../graphql/tenant/deleteDomain'
import { useMutation } from '@apollo/client'
import { TextField, InputAdornment, IconButton, Chip } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import ErrorContainer from '../Shared/ErrorContainer'

const DomainsContainer = ({
  domains,
  setDomains,
}: {
  domains: string[]
  setDomains: (v: string[]) => void
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [domain, setDomain] = useState('')
  const { t } = useTranslation(['form', 'btn', 'tenant'])

  const successNotify = (message: string) => toast.dark(message)
  const addDomain = () => {
    domain && domainCreate({ variables: { name: domain } })
  }

  const handleDeleteDomain = (domainToDelete: string) => {
    domainDelete({ variables: { name: domainToDelete } })
    setDomains(domains.filter((chip) => chip !== domainToDelete))
  }
  const onDomainCreateCompleted = (data: IDomainCreateData) => {
    if (data.domainCreate?.status) {
      domain && setDomains([...domains, domain])
      successNotify(`${t('tenant:add_domain_success')}`)
      setDomain('')
      setErrorMessage('')
    } else setErrorMessage(t(`code:${data.domainCreate.code}`))
  }

  const [domainCreate] = useMutation<IDomainCreateData, IDomainCreateVars>(
    DOMAIN_CREATE,
    {
      onCompleted: onDomainCreateCompleted,
    }
  )
  const [domainDelete] = useMutation<IDomainDeleteData, IDomainDeleteVars>(
    DOMAIN_DELETE
  )

  return (
    <>
      <TextField
        label={t('domain_name')}
        name="domain"
        variant="outlined"
        value={domain}
        onChange={(e) => setDomain(e.target.value)}
        fullWidth={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={addDomain}
                edge="end"
                color="primary"
                size="large"
                data-e2e="add-domain-button"
              >
                <FontAwesomeIcon icon={faPlusCircle as IconProp} size="lg" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <ChipsContainer>
        {domains.map((item) => {
          let icon
          return (
            <CustomChip
              key={item}
              color="primary"
              variant="outlined"
              icon={icon}
              label={item}
              onDelete={handleDeleteDomain}
              data-e2e="domain-chip"
            />
          )
        })}
      </ChipsContainer>
      {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
    </>
  )
}

export default DomainsContainer
const ChipsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 16px 0 0;
  box-shadow: none;
`
const CustomChip = styled(Chip)`
  margin: 4px 8px;
`
