import gql from 'graphql-tag'

export interface IUpdateParkingVars {
  id: string
  capacity: number
  sellerId: string
  remarks: string
}

export interface IUpdateParkingData {
  updateParking: {
    id: string
    capacity: number
    remarks: string
    seller: {
      name: string
      id: string
    }
  }
}

export const UPDATE_PARKING = gql`
  mutation updateParking(
    $id: [ID!]
    $capacity: Int
    $sellerId: ID
    $remarks: String
  ) {
    updateParking(
      input: {
        filter: { id: $id }
        set: {
          capacity: $capacity
          seller: { id: $sellerId }
          remarks: $remarks
        }
      }
    ) {
      parking {
        id
        capacity
        remarks
        seller {
          name
          id
        }
      }
    }
  }
`
