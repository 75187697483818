import gql from 'graphql-tag'
import { LockerType } from './queryLockers'
import { IPayment } from '../shared/sharedTypes'

export interface ILockerOrder {
  id: string
  product: {
    name: string
    nameEn: string
    seller: {
      name: string
      nip: string
    }
  }
  locker: {
    name: string
    type: LockerType
    building: {
      name: string
    }
  }
  validFrom: string
  validTo: string
  invoiceNeeded: boolean
  user: {
    login: string
    firstName: string
    lastName: string
    tenant?: {
      tenantName: string
    }
  }
  buyer?: {
    city: string
    buyerName: string
    country: string
    nip: string
    street: string
    zipcode: string
  }
  payment: IPayment
}

export interface IQueryLockerOrdersData {
  orders: ILockerOrder[]
}

export const QUERY_LOCKER_ORDERS = gql`
  query queryLockerOrders {
    orders: queryLockerOrders {
      id
      product {
        name
      }
      locker {
        building {
          name
        }
      }
      validFrom: valid_from
      validTo: valid_to
      invoiceNeeded: invoice_needed
      user {
        login
        firstName: first_name
        lastName: last_name
        tenant {
          tenantName: name
        }
      }
      buyer {
        city
        buyerName: name
        country
        nip
        street
        zipcode
      }
      payment {
        paymentId: id
        sessionId: session_id
        createdAt: created_at
        status
        amount
        sandbox
      }
    }
  }
`
