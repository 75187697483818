import { Grid, Chip, Stack, Button } from '@mui/material'
import styled from 'styled-components'
import SelectBuildingColumnFilter from '../Table/SelectBuildingColumnFilter'
import { LockerType } from '../../graphql/lockers/queryLockers'
import SelectLockerTypeColumnFilter from '../Lockers/SelectLockerTypeColumnFilter'
import { useTranslation } from 'react-i18next'
import { themeColors } from '../../const/colors'
import { ReservationStatus } from '../../graphql/shared/sharedTypes'
import ReservationStatusSelect from './ReservationStatusSelect'

const LockerFilters = ({
  building,
  setBuilding,
  lockerTypeFilter,
  setLockerTypeFilter,
  reservationStatus,
  setReservationStatus,
}: {
  building: string
  setBuilding: (v: string) => void
  lockerTypeFilter: LockerType | ''
  setLockerTypeFilter: (v: LockerType | '') => void
  reservationStatus: ReservationStatus | ''
  setReservationStatus: (v: ReservationStatus | '') => void
}) => {
  const { t } = useTranslation(['lockers'])

  const handleClearFilters = () => {
    setBuilding('')
    setLockerTypeFilter('')
    setReservationStatus('')
  }

  return (
    <>
      <GridStyled container spacing={2}>
        <Grid item>
          <SelectBuildingColumnFilter value={building} setValue={setBuilding} />
        </Grid>
        <Grid item>
          <SelectLockerTypeColumnFilter
            value={lockerTypeFilter}
            setValue={setLockerTypeFilter}
          />
        </Grid>
        <Grid item>
          <ReservationStatusSelect
            value={reservationStatus}
            setValue={setReservationStatus}
          />
        </Grid>
      </GridStyled>
      <Stack direction="row" spacing={1}>
        {building && (
          <ChipStyled label={building} onDelete={() => setBuilding('')} />
        )}
        {lockerTypeFilter && (
          <ChipStyled
            label={t(lockerTypeFilter)}
            onDelete={() => setLockerTypeFilter('')}
          />
        )}
        {reservationStatus && (
          <ChipStyled
            label={t(`locker_reservation_status:${reservationStatus}`)}
            onDelete={() => setReservationStatus('')}
          />
        )}
        {(building || lockerTypeFilter || reservationStatus) && (
          <ButtonStyled variant="text" onClick={handleClearFilters}>
            {t('btn:clear_filters')}
          </ButtonStyled>
        )}
      </Stack>
    </>
  )
}

export default LockerFilters

const GridStyled = styled(Grid)`
  padding: 16px 0;
  flex-direction: row;
`
const ChipStyled = styled(Chip)`
  color: ${themeColors.primary};
  background-color: ${themeColors.lightBlue};
  font-size: 14px;
  font-weight: 500;
`
const ButtonStyled = styled(Button)`
  text-transform: none;
  font-weight: 700;
`
