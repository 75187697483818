import gql from 'graphql-tag'
import { IssueFilter } from './queryIssue'

export interface IAggregateIssuesVars {
  filter?: IssueFilter
}

export interface IAggregateIssuesData {
  aggregateIssue: { count: number }
}

export const AGGREGATE_ISSUES = gql`
  query aggregateIssues($filter: IssueFilter) {
    aggregateIssue(filter: $filter) {
      count
    }
  }
`
export interface IAggregateIssuesAndEmailVerificationRequestsVars {
  filter?: IssueFilter
  filter2?: IssueFilter
}

export interface IAggregateIssuesAndEmailVerificationRequestsData {
  aggregateIssue: { count: number }
  aggregateMessages: { messagesCount: number }
  aggregateEmailVerificationRequest: { count: number }
}

export const AGGREGATE_ISSUES_AND_EMAIL_VERIFICATION_REQUESTS = gql`
  query aggregateIssuesAndEmailVerificationRequests(
    $filter: IssueFilter
    $filter2: IssueFilter
  ) {
    aggregateIssue(filter: $filter) {
      count
    }
    aggregateMessages: aggregateIssue(filter: $filter2) {
      messagesCount: count
    }
    aggregateEmailVerificationRequest {
      count
    }
  }
`
