import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BaseDrawer from '../Shared/BaseDrawer'
import EditParking from './EditParking'
import {
  GET_PARKING_LIST,
  IParkingListData,
} from '../../graphql/parking/getParkingList'
import { useQuery } from '@apollo/client'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { ParkingPlaceStatus } from '../../graphql/parking/queryParkingPlace'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface Row {
  buildings?: string
  capacity?: number
  dedicatedPlaces?: number
  id: string
  name: string
  placesNumber: number
  places: {
    name: string
    id: string
  }[]
  remarks?: string
  parkingBuildings: { name: string; value: boolean }[]
  sellerName: string
  sellerEmail: string
  sellerId: string
}

const ParkingsListTable = () => {
  const { t } = useTranslation(['parking_list'])
  const [openedDetails, setOpenedDetails] = useState<Row>()

  const invisibleColumns = {
    id: false,
  }

  const csvHeaders = [
    {
      label: t('parking_subscriptions_products_list:id'),
      key: 'id',
    },
    {
      label: 'Parking',
      key: 'name',
    },
    {
      label: t('limit_place'),
      key: 'capacity',
    },
    {
      label: t('is_dedicate_places'),
      key: 'dedicatedPlaces',
    },
    {
      label: t('dedicate_places_number'),
      key: 'placesNumber',
    },
    {
      label: t('parking_building'),
      key: 'buildings',
    },
    {
      label: t('company'),
      key: 'sellerName',
    },
    {
      label: t('company_email'),
      key: 'sellerEmail',
    },
    {
      label: t('remarks'),
      key: 'remarks',
    },
  ]

  const { data, refetch, loading } = useQuery<IParkingListData>(
    GET_PARKING_LIST,
    {
      fetchPolicy: 'network-only',
    }
  )

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: t('parking_subscriptions_products_list:id'),
      },
      {
        accessorKey: 'name',
        header: t('name'),
      },
      {
        accessorKey: 'capacity',
        header: t('limit_place'),
      },
      {
        accessorKey: 'buildings',
        header: t('parking_building'),
      },
      {
        accessorKey: 'sellerName',
        header: t('company'),
      },
      {
        accessorKey: 'sellerEmail',
        header: t('company_email'),
      },
      {
        accessorKey: 'remarks',
        header: t('remarks'),
      },
    ],
    [t]
  )

  const tableData: Row[] = useMemo(
    () =>
      data?.queryParking.map(
        ({
          buildings,
          capacity,
          dedicatedPlaces,
          id,
          name,
          places,
          remarks,
          seller,
        }) => {
          const responseData: any = {
            buildings: buildings.map(({ name }) => name).join(', '),
            capacity: places.filter(
              ({ status }) => status === ParkingPlaceStatus.available
            ).length,
            dedicatedPlaces,
            placesNumber: places.length,
            places,
            id,
            name,
            sellerName: seller?.sellerName,
            sellerEmail: seller?.sellerEmail,
            sellerId: seller?.sellerId,
            remarks,
          }

          return responseData
        }
      ) || [],

    [data]
  )

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <Table
        columns={columns}
        data={tableData}
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvExport
        csvHeaders={csvHeaders}
        onRowClick={(rowData) => {
          rowData.name && setOpenedDetails(rowData)
        }}
      />
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        <EditParking
          closeDetails={() => setOpenedDetails(undefined)}
          updateList={refetch}
          parking={openedDetails}
        />
      </BaseDrawer>
    </>
  )
}

export default ParkingsListTable
