import React, { FC } from 'react'
import { useAppSelector } from '../../redux/store'
import { ListItemIcon } from '@mui/material'
import { faPercent } from '@fortawesome/free-solid-svg-icons'
import FAIcon from '../Shared/FAIcon'
import { ROUTES } from '../../const/routes'
import { useTranslation } from 'react-i18next'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useIsRouteSelected } from '../../hooks/useIsRouteSelected'
import { EXTRAS_ROLES } from '../../const/permissions'
import { checkRole } from '../../utils/checkRole'
import {
  MainListItem,
  CustomListItemText,
  LinkStyled,
} from '../Styles/NavListStyle'

interface IExtrasListItemProps {
  isMenuOpen?: boolean
}

const ExtrasListItem: FC<IExtrasListItemProps> = ({ isMenuOpen }) => {
  const { t } = useTranslation(['extras'])
  const { roles } = useAppSelector((state) => state.user)
  const { isSelected } = useIsRouteSelected(ROUTES.PROMOS, !!isMenuOpen)

  if (!checkRole(EXTRAS_ROLES, roles)) {
    return null
  }

  return (
    <MainListItem selected={isSelected} data-e2e="navigation-extras">
      <LinkStyled to={ROUTES.PROMOS}>
        <ListItemIcon>
          <FAIcon icon={faPercent as IconProp} />
        </ListItemIcon>
        <CustomListItemText primary={t('promos')} />
      </LinkStyled>
    </MainListItem>
  )
}

export default ExtrasListItem
