import { useTranslation } from 'react-i18next'
import BasicSelect, { OptionType } from '../Shared/BasicSearchableSelect'
import { LockerStatus } from '../../graphql/lockers/queryLockers'

const LockerStatusSelect = ({
  control,
  errors,
  defaultValue,
}: {
  control: Object
  errors: Object
  defaultValue?: LockerStatus
}) => {
  const { t } = useTranslation(['lockers'])

  const options: OptionType[] = [
    {
      value: LockerStatus.available,
      label: `${t('locker_status:available')}`,
    },
    {
      value: LockerStatus.unavailable,
      label: `${t('locker_status:unavailable')}`,
    },
    {
      value: LockerStatus.ready_to_rent,
      label: `${t('locker_status:ready_to_rent')}`,
    },
    {
      value: LockerStatus.rented,
      label: `${t('locker_status:rented')}`,
    },
    {
      value: LockerStatus.should_be_returned,
      label: `${t('locker_status:should_be_returned')}`,
    },
    {
      value: LockerStatus.service,
      label: `${t('locker_status:service')}`,
    },
    {
      value: LockerStatus.broken,
      label: `${t('locker_status:broken')}`,
    },
  ]

  return (
    <div data-e2e="locker-status-select">
      <BasicSelect
        options={options}
        control={control}
        errors={errors}
        name={'status'}
        rules={{ required: `${t('form:field_required')}` }}
        label={'Status'}
        defaultValue={defaultValue}
      />
    </div>
  )
}

export default LockerStatusSelect
