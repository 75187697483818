import gql from 'graphql-tag'
import { ParkingPlaceStatus } from './queryParkingPlace'

export interface IParkingListData {
  queryParking: {
    buildings: {
      name: string
    }[]
    capacity: number
    dedicatedPlaces: boolean
    id: string
    name: string
    places: {
      id: string
      name: string
      status: ParkingPlaceStatus
    }[]
    remarks: string
    seller?: {
      sellerEmail: string
      sellerName: string
      sellerId: string
    }
  }[]
}

export const GET_PARKING_LIST = gql`
  query queryParking {
    queryParking(order: { asc: name }) {
      buildings {
        name
      }
      places {
        id
        name
        status
      }
      capacity
      dedicatedPlaces: dedicated_places
      id
      name
      remarks
      seller {
        sellerEmail: email
        sellerName: name
        sellerId: id
      }
    }
  }
`
