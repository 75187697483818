import { useState } from 'react'
import { Grid, Chip, Stack, Button, Typography } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { themeColors } from '../../const/colors'
import { date2localeString } from '../../utils/formatDate'
import DatePicker from '../../components/Shared/DatePicker'
import ParkingPoolSelect from './ParkingPoolSelect'

const ParkingPoolReservationsFilter = ({
  minValidTo,
  setMinValidTo,
  tenant,
  setTenant,
}: {
  minValidTo: Date | null
  setMinValidTo: (value: Date | null) => void
  tenant: string | null
  setTenant: (value: string | null) => void
}) => {
  const { t, i18n } = useTranslation(['sold_parking_items'])
  const lang = i18n.language
  const [minDate, setMinDate] = useState<Date | null>(minValidTo)
  const [selectedTenant, setSelectedTenant] = useState<string | null>(tenant)

  const formatDate = (date: Date) =>
    date2localeString(date, lang, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })

  const handleClearFilters = () => {
    setMinDate(null)
    setMinValidTo(null)
    setSelectedTenant(null)
    setTenant(null)
  }

  const handleDeleteMinDate = () => {
    setMinDate(null)
    setMinValidTo(null)
  }
  const handleDeleteSelectedTenant = () => {
    setSelectedTenant(null)
    setTenant(null)
  }

  const onSubmitRange = () => {
    setMinValidTo(minDate)
    selectedTenant && setTenant(selectedTenant)
  }

  return (
    <Wrapper>
      <Subtitle>{t('valid_to_info')}</Subtitle>
      <GridStyled container spacing={2}>
        <Grid item>
          <DatePicker
            selectedDate={minDate}
            setSelectedDate={setMinDate}
            label={t('valid_to')}
          />
        </Grid>
        <Grid item>
          <ParkingPoolSelect
            value={selectedTenant || ''}
            setValue={setSelectedTenant}
          />
        </Grid>
        <ButtonWrapper item>
          {(minDate || selectedTenant) && (
            <ButtonStyled variant="text" onClick={onSubmitRange}>
              {t('btn:submit_filters')}
            </ButtonStyled>
          )}
        </ButtonWrapper>
      </GridStyled>
      <Stack direction="row" spacing={1}>
        {minDate && (
          <ChipStyled
            label={`${t('sold_parking_items:valid_to')} ${formatDate(
              new Date(minDate)
            )}`}
            onDelete={handleDeleteMinDate}
          />
        )}
        {selectedTenant && (
          <ChipStyled label={'Firma'} onDelete={handleDeleteSelectedTenant} />
        )}
        {(minDate || selectedTenant) && (
          <ButtonStyled variant="text" onClick={handleClearFilters}>
            {t('btn:clear_filters')}
          </ButtonStyled>
        )}
      </Stack>
    </Wrapper>
  )
}

export default ParkingPoolReservationsFilter
const Wrapper = styled.div`
  padding-bottom: 16px;
`
const GridStyled = styled(Grid)`
  padding: 16px 0;
  flex-direction: row;
`
const ChipStyled = styled(Chip)`
  color: ${themeColors.primary};
  background-color: ${themeColors.lightBlue};
  font-size: 14px;
  font-weight: 500;
`
const ButtonStyled = styled(Button)`
  text-transform: none;
  font-weight: 700;
`
const ButtonWrapper = styled(Grid)`
  display: flex;
  align-items: center;
`
const Subtitle = styled(Typography)`
  font-weight: 500;
`
