import { useEffect } from 'react'
import Input from '../Shared/Input'
import { useTranslation } from 'react-i18next'
import { IFormError } from '../../types/FormTypes'
import styled from 'styled-components'
import { IParkingPoolProfile } from '../../graphql/tenant/queryTenantWithDomains'
import AvailabilitySwitch from '../Shared/AvailabilitySwitch'

const ParkingPoolProfileInputs = ({
  profile,
  control,
  errors,
  isDisabled,
  setIsDisabled,
}: {
  profile?: IParkingPoolProfile
  control: any
  errors: IFormError | any
  isDisabled: boolean
  setIsDisabled: (v: boolean) => void
}) => {
  const { t } = useTranslation(['form'])

  const {
    name,
    maxAdvanceDays,
    maxDurationDays,
    maxReservationCountPerUser,
    disabled,
    customEmailMessage,
  } = profile || {}

  useEffect(() => {
    !!profile && setIsDisabled(!!disabled)
  }, [disabled, profile])

  return (
    <>
      <Input
        label={t('profile_name')}
        name="name"
        control={control}
        errors={errors}
        rules={{ required: `${t('field_required')}` }}
        autoCapitalize="none"
        defaultValue={String(name || '')}
        data-e2e="profile-name"
      />
      <Input
        label={t('max_advanced_days')}
        name="maxAdvanceDays"
        control={control}
        errors={errors}
        rules={{ required: `${t('field_required')}` }}
        defaultValue={String(maxAdvanceDays || '')}
        data-e2e="profile-max-advanced-days"
      />
      <Input
        label={t('max_continuous_days')}
        name="maxDurationDays"
        control={control}
        errors={errors}
        rules={{ required: `${t('field_required')}` }}
        autoCapitalize="none"
        defaultValue={String(maxDurationDays || '')}
        data-e2e="profile-max-duration-days"
      />
      <Input
        label={t('max_simultaneous_reservations')}
        name="maxReservationCountPerUser"
        control={control}
        errors={errors}
        rules={{ required: `${t('field_required')}` }}
        autoCapitalize="none"
        defaultValue={String(maxReservationCountPerUser || '')}
        data-e2e="profile-max-reservation-count-per-user"
      />
      <Input
        label={t('parking_pool:custom_email_message')}
        name="customEmailMessage"
        control={control}
        errors={errors}
        defaultValue={String(customEmailMessage || '')}
        data-e2e="profile-custom-email-message"
        multiline
        rows={2}
      />
      <SwitchWrapper>
        <AvailabilitySwitch
          checked={!isDisabled}
          onChange={() => setIsDisabled(!isDisabled)}
          checkedText={`${t('btn:on')}`}
          uncheckedText={`${t('btn:off')}`}
        />
      </SwitchWrapper>
    </>
  )
}

export default ParkingPoolProfileInputs

const SwitchWrapper = styled.div`
  padding: 16px;
`
