import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface ICancelUserLockerOrderVars {
  login: string
}

export interface ICancelUserLockerOrderData {
  cancelUserLockerOrder: IQueryBasicData & {
    parameters: {
      name: string
      value: string
    }
  }
}

export const CANCEL_USER_LOCKER_ORDER = gql`
  mutation cancelUserLockerOrder($login: String!) {
    cancelUserLockerOrder(login: $login) {
      status
      code
      error
      message
      parameters {
        name
        value
      }
    }
  }
`
