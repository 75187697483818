import gql from 'graphql-tag'

export interface IUpdateSurveyQuestionVars {
  id: string
  name: string
  nameEn: string
}

export const UPDATE_SURVEY_QUESTION = gql`
  mutation updateSurveyQuestion($id: ID!, $name: String, $nameEn: String) {
    updateSurveyQuestion(
      input: { filter: { id: [$id] }, set: { name: $name, name_en: $nameEn } }
    ) {
      surveyQuestion {
        id
        name
      }
    }
  }
`
