import gql from 'graphql-tag'

export interface IDeleteBannerVars {
  id: string
}

export const DELETE_BANNER = gql`
  mutation deleteBanner($id: [ID!]) {
    deleteBanner(filter: { id: $id }) {
      msg
    }
  }
`
