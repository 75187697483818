import gql from 'graphql-tag'
export const SET_MENU_ITEM_AVAILABILITY = gql`
  mutation UpdateMenuItem($input: UpdateMenuItemInput!) {
    updateMenuItem(input: $input) {
      menuItem {
        visible
      }
    }
  }
`
