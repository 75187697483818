import gql from 'graphql-tag'
import { QuestionType } from './getSurvey'

export interface IAddSurveyVars {
  name: string
  nameEn: string
  description?: string
  descriptionEn?: string
  bannerUrl?: string
  bannerUrlEn?: string
  thanks?: string
  thanksEn?: string
  residentOnly: boolean
  questions: {
    name: string
    name_en: string
    type: QuestionType
    sort: number
    options: {
      name: string
      name_en: string
      sort: number
    }[]
  }[]
}

export const ADD_SURVEY = gql`
  mutation addSurvey(
    $name: String!
    $nameEn: String!
    $description: String
    $descriptionEn: String
    $bannerUrl: String
    $bannerUrlEn: String
    $createdAt: DateTime!
    $thanks: String
    $thanksEn: String
    $residentOnly: Boolean
    $questions: [SurveyQuestionRef]!
  ) {
    addSurvey(
      input: [
        {
          name: $name
          name_en: $nameEn
          description: $description
          description_en: $descriptionEn
          banner_url: $bannerUrl
          banner_url_en: $bannerUrlEn
          created_at: $createdAt
          thanks: $thanks
          thanks_en: $thanksEn
          resident_only: $residentOnly
          questions: $questions
          active: false
        }
      ]
    ) {
      survey {
        id
        name
      }
    }
  }
`
