import React from 'react'
import Input from '../Shared/Input'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IFormError } from '../../types/FormTypes'

const SurveyOption = ({
  control,
  errors,
  survey,
}: {
  control: any
  errors: any
  survey?: any
}) => {
  const { t } = useTranslation(['survey_form'])

  return (
    <NameContainer>
      <Input
        label={t('name')}
        name={'name'}
        control={control}
        errors={errors}
        defaultValue={survey?.surveyName || ''}
        rules={{ required: `${t('form:field_required')}` }}
        data-e2e="survey-input-name"
      />
      <Input
        label={t('name_en')}
        name={'nameEn'}
        control={control}
        errors={errors}
        defaultValue={survey?.surveyNameEn || ''}
        rules={{ required: `${t('form:field_required')}` }}
        data-e2e="survey-input-name-en"
      />
      <Input
        label={t('welcome_text')}
        name={'description'}
        control={control}
        errors={errors}
        defaultValue={survey?.description || ''}
        multiline
        rows={2}
        data-e2e="survey-input-welcome"
      />
      <Input
        label={t('welcome_text_en')}
        name={'descriptionEn'}
        control={control}
        errors={errors}
        defaultValue={survey?.descriptionEn || ''}
        multiline
        rows={2}
        data-e2e="survey-input-welcome-en"
      />
      <Input
        label={t('banner')}
        name={'banner'}
        control={control}
        errors={errors}
        defaultValue={survey?.bannerUrl || ''}
        multiline
        data-e2e="survey-input-banner"
      />
      <Input
        label={t('banner_en')}
        name={'bannerEn'}
        control={control}
        errors={errors}
        defaultValue={survey?.bannerUrlEn || ''}
        multiline
        data-e2e="survey-input-banner-en"
      />
    </NameContainer>
  )
}

export default SurveyOption
const NameContainer = styled.div`
  border-bottom: 1px solid #bdbdbd;
  padding: 24px 0 10px;
`
