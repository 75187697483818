import React, { useState } from 'react'
import { Typography, Tab } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ExtrasTable from '../../components/Extras/ExtrasTable'
import { TabsStyled } from '../../components/Styles/CustomElementsStyled'

const ExtrasList = () => {
  const [value, setValue] = useState(0)

  const { t } = useTranslation(['extras'])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Wrapper>
      <TypographyStyled variant="h6">{t('promos')}</TypographyStyled>

      <TabsStyled value={value} onChange={handleChange} textColor="primary">
        <Tab label={t('active')} data-e2e="active-promos" />
        <Tab label={t('inactive')} data-e2e="inactive-promos" />
      </TabsStyled>
      <ExtrasTable visible={!value} />
    </Wrapper>
  )
}

export default ExtrasList
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
const Wrapper = styled.div`
  position: relative;
`
