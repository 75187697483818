import { Button } from '@mui/material'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Integration = () => {
  const { t } = useTranslation()
  return (
    <Container>
      <TypographyStyled variant="h6">
        {t('admin_menu:integrations')}
      </TypographyStyled>
      <Link
        href={
          'https://grafana.intranet.vivia.dev/d/Gn0In0jMC/okr?orgId=2&refresh=1h'
        }
        target="_blank"
      >
        <ButtonStyled color="primary" variant="outlined" size="large">
          {'Grafana'}
        </ButtonStyled>
      </Link>
      <Link href={'http://kibana.stat.dev.oliviacentre.com'} target="_blank">
        <ButtonStyled color="primary" variant="outlined" size="large">
          {'Kibana'}
        </ButtonStyled>
      </Link>
    </Container>
  )
}

export default Integration

const Link = styled.a`
  text-decoration: none;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const ButtonStyled = styled(Button)`
  width: 200px;
  margin-bottom: 16px;
`
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
