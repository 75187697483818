import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IAssignUserRoleVars {
  login: string
  role: string
}

export interface IAssignUserRoleData {
  assignRole: IQueryBasicData
}

export const ASSIGN_USER_ROLE = gql`
  mutation assignUserRole($login: String!, $role: String!) {
    assignRole(login: $login, role: $role) {
      code
      error
      message
      status
    }
  }
`

export interface IUnassignUserRoleVars {
  login: string
  role: string
}

export interface IUnassignUserRoleData {
  unassignRole: IQueryBasicData
}

export const UNASSIGN_USER_ROLE = gql`
  mutation unassignUserRole($login: String!, $role: String!) {
    unassignRole(login: $login, role: $role) {
      code
      error
      message
      status
    }
  }
`
