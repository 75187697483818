import gql from 'graphql-tag'

export const TENANT_DOMAIN_UNASSIGN = gql`
  mutation TenantDomainUnassignAndDelete($tenant: String!, $domain: String!) {
    tenantDomainUnassign(tenant: $tenant, domain: $domain) {
      status
      code
      message
      error
    }
  }
`
