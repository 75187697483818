import React, { useCallback, useEffect, useState } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import {
  SET_SURVEY_PREVIEW,
  ISetSurveyPreviewVars,
} from '../../graphql/survey/setSurveyPreview'
import { ISurvey } from '../../graphql/survey/querySurvey'
import { Grid } from '@mui/material'
import AvailabilitySwitch from '../Shared/AvailabilitySwitch'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { toast } from 'react-toastify'

const SurveyPreview = ({
  surveyId,
  surveys,
  refetch,
}: {
  surveyId: string
  surveys: ISurvey[]
  refetch: () => void
}) => {
  const [preview, setPreview] = useState<string[]>([])
  const errorNotify = (message: string) => toast.error(message)

  const [setSurveyPreview, { loading }] = useMutation<ISetSurveyPreviewVars>(
    SET_SURVEY_PREVIEW,
    {
      onCompleted: () => refetch(),
      onError: (error: ApolloError) => errorNotify(error.message),
    }
  )

  const handlePreviewChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, name } = event.target
      const variables = {
        id: name,
        preview: checked,
      }

      if (checked && !preview.length) {
        checked && setPreview((items) => items.concat([name]))
        setSurveyPreview({ variables })
      } else if (!checked && preview.length) {
        setPreview((items) => items.filter((item) => item !== name))
        setSurveyPreview({ variables })
      }
    },
    [preview, setSurveyPreview]
  )

  useEffect(() => {
    if (!!surveys) {
      setPreview(
        surveys
          .filter((item: any) => item.preview)
          .map((item: any) => item.surveyId)
      )
    }
  }, [surveys])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <Grid>
      <AvailabilitySwitch
        checked={preview.includes(surveyId)}
        name={surveyId}
        onChange={handlePreviewChange}
        checkedText={'yes'}
        uncheckedText={'no'}
      />
    </Grid>
  )
}

export default SurveyPreview
