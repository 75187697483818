import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import {
  QUERY_TENANT,
  IQueryTenantData,
} from '../../graphql/shared/queryTenant'
import BaseDrawer from '../Shared/BaseDrawer'
import ResidentRequestDetails from './ResidentRequestDetails'
import FullScreenLoader from '../Shared/FullScreenLoader'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import { themeColors } from '../../const/colors'
import {
  QUERY_ISSUE,
  IQueryIssueVars,
  IQueryIssueData,
  newTenantIssuesFilter,
  resolvedTenantIssuesFilter,
} from '../../graphql/userIssues/queryIssue'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

interface Row {
  id: string
  fullName: string
  login: string
  email: string
  workEmail: string
  company: string
  createdAt: string
  createdAt_csv: string
  closeAt: string
  closeAt_csv: string
  status: string
  statusTranslated: string
  description: string
}

const getStatusColor = (status: string) => {
  return status === 'accepted' ? themeColors.accept : themeColors.error
}

const ResidentIssuesTable: FC<{
  tab: number
  updateAggregatedCount: () => void
}> = ({ tab, updateAggregatedCount }) => {
  const { t, i18n } = useTranslation([
    'columns',
    'admin_menu',
    'tenant_request_details',
  ])

  const [openedIssue, setOpenedIssue] = useState<Row>()

  const {
    data: issues,
    loading: loadingIssues,
    refetch,
  } = useQuery<IQueryIssueData, IQueryIssueVars>(QUERY_ISSUE, {
    variables: {
      filter: tab ? resolvedTenantIssuesFilter : newTenantIssuesFilter,
    },
    fetchPolicy: 'no-cache',
  })

  const {
    data: tenants,
    loading: loadingTenants,
    refetch: refetchTenants,
  } = useQuery<IQueryTenantData>(QUERY_TENANT, {
    fetchPolicy: 'no-cache',
  })

  const invisibleColumns = {
    createdAtCSV: false,
    company: Boolean(tab),
    closeAt: Boolean(tab),
    closeAtCSV: false,
    statusTranslated: Boolean(tab),
  }

  const csvHeadersTab0 = [
    {
      label: t('full_name'),
      key: 'fullName',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: t('work_email'),
      key: 'workEmail',
    },
    {
      label: t('created_at'),
      key: 'createdAtCSV',
    },
    {
      label: 'Status',
      key: 'statusTranslated',
    },
  ]

  const csvHeadersTab1 = [
    {
      label: t('full_name'),
      key: 'fullName',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: t('work_email'),
      key: 'workEmail',
    },
    {
      label: t('close_date'),
      key: 'closeAtCSV',
    },
    {
      label: 'Status',
      key: 'statusTranslated',
    },
  ]

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        header: t('full_name'),
        accessorKey: 'fullName',
      },
      {
        header: t('login'),
        accessorKey: 'login',
      },
      {
        header: t('main_email'),
        accessorKey: 'email',
      },
      {
        header: t('work_email'),
        accessorKey: 'workEmail',
      },
      {
        header: t('company'),
        accessorKey: 'company',
      },
      {
        header: t('created_at'),
        accessorKey: 'createdAt',
        cell: (value) => dateIso2localeString(value.getValue(), i18n.language),
        enableColumnFilter: false,
      },
      {
        header: 'createdAt_csv',
        accessorKey: 'createdAtCSV',
      },
      {
        header: t('close_date'),
        accessorKey: 'closeAt',
        cell: (value) => dateIso2localeString(value.getValue(), i18n.language),
        enableColumnFilter: false,
      },
      {
        header: 'closeAt_csv',
        accessorKey: 'closeAtCSV',
      },
      {
        header: t('status'),
        accessorKey: 'statusTranslated',
      },
    ],
    [t, i18n]
  )

  const tableData: Row[] = useMemo(
    () =>
      issues?.queryIssue?.map(
        ({
          id,
          description,
          comment,
          status,
          createdAt,
          modifiedAt,
          user: { firstName, lastName, login, email, workEmail, tenant },
          tenantRequest: { code },
          resolvedBy,
        }) => ({
          id,
          fullName: `${firstName} ${lastName}`,
          login,
          email: email.email,
          workEmail: workEmail?.email || '',
          company: tenant?.name || '',
          createdAt,
          createdAt_csv: dateIso2ExcelFormat(createdAt),
          closeAt: modifiedAt,
          closeAt_csv: dateIso2ExcelFormat(modifiedAt),
          status,
          statusTranslated: t(`tenant_request_details:${status}`),
          description,
          code,
          comment,
          resolvedBy,
        })
      ) || [],
    [issues?.queryIssue, t]
  )

  if (loadingIssues || loadingTenants) {
    return <FullScreenLoader />
  }

  return (
    <>
      <Table
        columns={columns}
        data={tableData}
        csvExport
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvHeaders={tab ? csvHeadersTab1 : csvHeadersTab0}
        onRowClick={(rowData) => setOpenedIssue(rowData)}
        getCellProps={(cellInfo) =>
          cellInfo.column.id === 'statusTranslated'
            ? {
                style: {
                  color: getStatusColor(cellInfo.row.original.status),
                  fontWeight: 600,
                },
              }
            : {}
        }
        sortByKey={tab ? 'closeAt' : 'createdAt'}
        sortDesc
      />
      <BaseDrawer open={!!openedIssue} variant={'temporary'}>
        <ResidentRequestDetails
          options={tenants?.queryTenant.map(({ id, name }) => ({
            value: id,
            label: name,
          }))}
          issue={openedIssue}
          closeIssue={() => setOpenedIssue(undefined)}
          updateList={refetch}
          updateTenants={refetchTenants}
          updateAggregatedCount={updateAggregatedCount}
          tab={tab}
        />
      </BaseDrawer>
    </>
  )
}

export default ResidentIssuesTable
