import React, { useCallback, useEffect, useState } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import {
  SET_BANNER_PREVIEW,
  ISetBannerPreviewVars,
} from '../../graphql/banner/setBannerPreview'
import { IBanner } from '../../graphql/banner/queryBanner'
import { Grid } from '@mui/material'
import AvailabilitySwitch from '../Shared/AvailabilitySwitch'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { toast } from 'react-toastify'

const BannerPreview = ({
  bannerId,
  banners,
  refetch,
}: {
  bannerId: string
  banners: IBanner[]
  refetch: () => void
}) => {
  const [preview, setPreview] = useState<string[]>([])
  const errorNotify = (message: string) => toast.error(message)

  const [setBannerPreview, { loading }] = useMutation<ISetBannerPreviewVars>(
    SET_BANNER_PREVIEW,
    {
      onCompleted: () => refetch(),
      onError: (error: ApolloError) => errorNotify(error.message),
    }
  )

  const handlePreviewChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, name } = event.target
      const variables = {
        id: name,
        preview: checked,
      }

      if (checked && !preview.length) {
        checked && setPreview((items) => items.concat([name]))
        setBannerPreview({ variables })
      } else if (!checked && preview.length) {
        setPreview((items) => items.filter((item) => item !== name))
        setBannerPreview({ variables })
      }
    },
    [preview, setBannerPreview]
  )

  useEffect(() => {
    if (!!banners) {
      setPreview(
        banners.filter((item: any) => item.preview).map((item: any) => item.id)
      )
    }
  }, [banners])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <Grid>
      <AvailabilitySwitch
        checked={preview.includes(bannerId)}
        name={bannerId}
        onChange={handlePreviewChange}
        checkedText={'yes'}
        uncheckedText={'no'}
      />
    </Grid>
  )
}

export default BannerPreview
