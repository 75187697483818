import { useTranslation } from 'react-i18next'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import styled from 'styled-components'
import { dateIso2localeString } from '../../utils/formatDate'
import { LockerReservationRow } from '../LockerReservation/LockerReservationTable'
import { LockerRow } from '../Lockers/LockerTable'
import LockerNumbersContainer from '../LockerReservation/LockerNumbersContainer'

const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}

const ReservationSummaryDetails = ({
  reservations,
}: {
  reservations: LockerReservationRow[] | LockerRow[]
}) => {
  const { t, i18n } = useTranslation(['lockers'])
  const lang = i18n.language
  const { building, user } = reservations[0]
  const oneReservation = reservations.length === 1 && reservations[0]

  return (
    <>
      <LockerNumbersContainer building={building.name} lockers={reservations} />
      {oneReservation && oneReservation.validFrom && oneReservation.validTo && (
        <Container>
          <TextBold>{t('lease_term')}:</TextBold>
          <TextBody2Medium>{`${dateIso2localeString(
            oneReservation.validFrom,
            lang,
            options
          )} - ${dateIso2localeString(
            oneReservation.validTo,
            lang,
            options
          )}`}</TextBody2Medium>
        </Container>
      )}
      {oneReservation && (
        <Container>
          <TextBody2Bold>{t('tenant')}: </TextBody2Bold>
          {oneReservation.tenant && (
            <TextBody2Medium>{oneReservation.tenant}</TextBody2Medium>
          )}
          {user && <TextBody2Medium>{user}</TextBody2Medium>}
          <TextBody2Medium>{oneReservation.email}</TextBody2Medium>
          {oneReservation.notes && (
            <>
              <TextBody2Bold>{t('notes')}: </TextBody2Bold>
              <TextBody2Medium>{oneReservation.notes}</TextBody2Medium>
            </>
          )}
        </Container>
      )}
    </>
  )
}

export default ReservationSummaryDetails

const TextBold = styled(TextBody2Bold)`
  display: inline;
`
const Container = styled.div`
  padding: 4px 8px;
`
