import gql from 'graphql-tag'

export enum GetResponseJobStatus {
  pending = 'pending',
  processing = 'processing',
  completed = 'completed',
  canceled = 'canceled',
  error = 'error',
}

export interface IGetResponseSyncData {
  sync: {
    errors: {
      message: string
      code: number
    }
    job: {
      id: string
      status: GetResponseJobStatus
    }
  }
}

export const GETRESPONSE_SYNC = gql`
  mutation createNewMailingListSync {
    sync: createNewMailingListSync {
      errors {
        message
        code
      }
      job {
        id
        status
      }
    }
  }
`
