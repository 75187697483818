import { Grid } from '@mui/material'
import styled from 'styled-components'
import { themeColors } from '../../const/colors'
import PageContentContainer from '../PageContentContainer'
import { TextH6 } from './TextCustom'

export const DrawerContent = styled(PageContentContainer).attrs(() => ({
  bgColor: themeColors.lightBlue,
}))`
  padding: 15px 30px 30px;
  width: 50vw;
  min-width: 600px;
`

export const CustomHeader = styled(TextH6)`
  display: inline;
  padding-left: 16px;
`

export const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
`
export const TextBold = styled.span`
  font-weight: 700;
`
export const SelectWrapper = styled(Grid)`
  padding: 0 16px 0 0;
`
export const TenantButtonWrapper = styled(Grid)`
  padding: 5px 0 0 0;
`
