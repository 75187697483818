import gql from 'graphql-tag'

export interface IUpdateSurveyVars {
  id: string
  name?: string
  nameEn?: string
  description?: string
  descriptionEn?: string
  bannerUrl?: string
  bannerUrlEn?: string
  active: boolean
  thanks: string
  thanksEn: string
  questions?: {
    id: string
    name?: string
    nameEn?: string
    options?: {
      id: string
      name?: string
      nameEn?: string
    }
  }[]
}
export interface IUpdateSurveyData {
  updateSurvey: {
    survey: {
      id: string
      name: string
      nameEn: string
      questions: {
        name: string
        nameEn: string
      }[]
    }
  }
}

export const UPDATE_SURVEY = gql`
  mutation updateSurvey(
    $id: ID!
    $name: String
    $nameEn: String
    $description: String
    $descriptionEn: String
    $bannerUrl: String
    $bannerUrlEn: String
    $thanks: String
    $thanksEn: String
    $residentOnly: Boolean
  ) {
    updateSurvey(
      input: {
        filter: { id: [$id] }
        set: {
          name: $name
          name_en: $nameEn
          description: $description
          description_en: $descriptionEn
          banner_url: $bannerUrl
          banner_url_en: $bannerUrlEn
          thanks: $thanks
          thanks_en: $thanksEn
          resident_only: $residentOnly
        }
      }
    ) {
      survey {
        id
        active
        name
        nameEn: name_en
        description
        description_en
        banner_url
      }
    }
  }
`
