import gql from 'graphql-tag'
import { ReservationStatus } from '../shared/sharedTypes'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IGroupReserveLockersVars {
  validFrom: string
  validTo?: string
  requestedRoomLockers: number
  requestedGarageLockers: number
  requestedStatus: ReservationStatus
  buildingName?: string
  tenantId?: string
  tenantName: string
  tenantEmail: string
  comment: string
  suggestedIds?: string
}

export interface IGroupReserveLockersData {
  groupReserveLockers: IQueryBasicData & {
    parameters: {
      name: string
      value: string
    }[]
  }
}

export const GROUP_RESERVE_LOCKERS = gql`
  mutation groupReserveLockers(
    $validFrom: DateTime!
    $validTo: DateTime
    $requestedRoomLockers: Int!
    $requestedGarageLockers: Int!
    $requestedStatus: ReservationStatus!
    $buildingName: String
    $tenantId: ID
    $tenantName: String
    $tenantEmail: String
    $comment: String
    $suggestedIds: String
  ) {
    groupReserveLockers(
      valid_from: $validFrom
      valid_to: $validTo
      requested_room_lockers: $requestedRoomLockers
      requested_garage_lockers: $requestedGarageLockers
      requested_status: $requestedStatus
      building_name: $buildingName
      tenant_id: $tenantId
      tenant_name: $tenantName
      tenant_email: $tenantEmail
      comment: $comment
      suggested_locker_ids: $suggestedIds
    ) {
      status
      code
      error
      message
      parameters {
        name
        value
      }
    }
  }
`
