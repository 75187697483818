import { FC } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Input from '../Shared/Input'
import { Grid } from '@mui/material'
import { IFormError } from '../../types/FormTypes'
import { NIP_REGEX } from '../../const/regex'
import { IBuyer } from '../../graphql/lockers/updateLockerGroupReservation'

const SummaryTenantDataForm: FC<{
  control: any
  errors: IFormError | any
  tenant?: string
  buyer?: IBuyer
  representative?: string
  pickUp?: boolean
}> = ({ control, errors, tenant, buyer, representative, pickUp }) => {
  const { t } = useTranslation(['lockers'])

  return (
    <InputsWrapper>
      <InputStyled
        label={t('company_name')}
        data-e2e="tenant-name-input"
        name={'name'}
        control={control}
        errors={errors}
        defaultValue={tenant || ''}
        rules={{ required: `${t('form:field_required')}` }}
      />
      <InputStyled
        label={t('company_address')}
        data-e2e="street-input"
        name={'street'}
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        defaultValue={buyer?.street || ''}
      />
      <InputStyled
        label={'NIP'}
        data-e2e="nip-input"
        name={'nip'}
        control={control}
        errors={errors}
        rules={{
          required: `${t('form:field_required')}`,
          pattern: {
            value: NIP_REGEX,
            message: t('form:valid_nip'),
          },
        }}
        defaultValue={buyer?.nip || ''}
      />
      <Container container spacing={1}>
        <Grid xs={6} item>
          <InputStyled
            label={t('zip_code')}
            data-e2e="zipcode-input"
            name={'zipcode'}
            control={control}
            errors={errors}
            rules={{ required: `${t('form:field_required')}` }}
            defaultValue={buyer?.zipcode || ''}
          />
        </Grid>
        <Grid xs={6} item>
          <InputStyled
            label={t('city')}
            data-e2e="city-input"
            name={'city'}
            control={control}
            errors={errors}
            rules={{ required: `${t('form:field_required')}` }}
            defaultValue={buyer?.city || ''}
          />
        </Grid>
      </Container>
      <InputStyled
        label={t('representative')}
        data-e2e="representative-input"
        name={'representative'}
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        defaultValue={representative || ''}
      />
      {pickUp && (
        <InputStyled
          label={t('title_annotation')}
          data-e2e="annotation-input"
          name={'annotation'}
          control={control}
          errors={errors}
        />
      )}
    </InputsWrapper>
  )
}

export default SummaryTenantDataForm

const InputStyled = styled(Input)`
  max-width: 500px;
`
const InputsWrapper = styled(Grid)`
  padding: 16px 0;
`
const Container = styled(Grid)`
  max-width: 500px;
`
