import React from 'react'
import { useTranslation } from 'react-i18next'
import { IFormError } from '../../types/FormTypes'
import Input from '../Shared/Input'

const SignInForm: React.FC<{
  control: any
  errors: IFormError | any
  email: string
}> = ({ control, errors, email }) => {
  const { t } = useTranslation(['form'])

  return (
    <>
      <Input
        label={'Email'}
        name="login"
        control={control}
        errors={errors}
        defaultValue={email}
        disabled
      />
      <Input
        label={t('password')}
        name="password"
        autoCompleteType="password"
        autoCapitalize={'none'}
        control={control}
        errors={errors}
        rules={{ required: `${t('field_required')}` }}
      />
    </>
  )
}

export default SignInForm
