import gql from 'graphql-tag'
import { ID, IQueryBasicData } from '../shared/IQueryData'

export interface IUnassignPlacesFromParkingPoolVars {
  places: ID[]
  pool: ID
}

export interface IUnassignPlacesFromParkingPoolData {
  unassignPlacesFromParkingPool: IQueryBasicData
}

export const UNASSIGN_PLACES_FROM_PARKING_POOL = gql`
  mutation unassignPlacesFromParkingPool($places: [ID!]!, $pool: ID!) {
    unassignPlacesFromParkingPool(places: $places, pool: $pool) {
      status
      error
      message
      code
    }
  }
`
