import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, Tooltip } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { themeColors } from '../../const/colors'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const EditTableRow = ({ onClick }: { onClick: (v: any) => void }) => {
  const { t } = useTranslation(['btn'])

  return (
    <Tooltip title={`${t('edit')}`}>
      <IconButton data-e2e="edit-row" size="small" onClick={onClick}>
        <FontAwesomeIcon
          icon={faEdit as IconProp}
          size="1x"
          color={themeColors.primary}
        />
      </IconButton>
    </Tooltip>
  )
}

export default EditTableRow
