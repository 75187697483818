import React, { useEffect } from 'react'
import Input from '../Shared/Input'
import { useTranslation } from 'react-i18next'
import { IFormError } from '../../types/FormTypes'
import Checkbox from '../../components/Shared/Checkbox'
import styled from 'styled-components'
import { ITenant } from '../../graphql/tenant/getTenant'

const ParkingPoolInputs = ({
  tenant,
  control,
  errors,
  watch,
}: {
  tenant: ITenant
  control: any
  errors: IFormError | any
  watch: any
}) => {
  const { t } = useTranslation(['form', 'btn', 'tenant', 'generic'])
  const {
    parkingPass,
    placesCountContract = '',
    maxDurationDays = '',
    maxAdvanceDays = '',
    maxActiveUserCount = '',
    maxReservationCountPerUser = '',
    placesAggregate,
    notifyUserNearingReservation,
    customEmailMessage,
    reservationNearingInHours,
    notifyOfficeManagersNewReservation,
  } = tenant?.parkingPool || {}

  const notify = watch('notifyUserNearingReservation')

  return (
    <>
      <Input
        label={t('amano_product_id')}
        name="productId"
        control={control}
        errors={errors}
        rules={{ required: `${t('field_required')}` }}
        autoCapitalize="none"
        defaultValue={String(parkingPass?.productId || '')}
        // disabled={!!parkingPass?.productId}
        data-e2e="parking-pool-product-id"
      />
      <Input
        label={t('places_amount')}
        name="placesCountContract"
        control={control}
        errors={errors}
        rules={{ required: `${t('field_required')}` }}
        autoCapitalize="none"
        defaultValue={String(placesCountContract)}
        data-e2e="parking-pool-places-count-contract"
      />
      <Input
        label={t('places_count')}
        name="placesCount"
        control={control}
        errors={errors}
        autoCapitalize="none"
        defaultValue={String(placesAggregate?.count || 0)}
        disabled
        data-e2e="parking-pool-places-count"
      />
      <Input
        label={t('max_simultaneous_users')}
        name="maxActiveUserCount"
        control={control}
        errors={errors}
        rules={{ required: `${t('field_required')}` }}
        autoCapitalize="none"
        defaultValue={String(maxActiveUserCount)}
        data-e2e="parking-pool-max-active-user-count"
      />
      <Input
        label={t('max_continuous_days')}
        name="maxDurationDays"
        control={control}
        errors={errors}
        rules={{ required: `${t('field_required')}` }}
        autoCapitalize="none"
        defaultValue={String(maxDurationDays)}
        data-e2e="parking-pool-max-duration-days"
      />
      <Input
        label={t('max_advanced_days')}
        name="maxAdvancedDays"
        control={control}
        errors={errors}
        rules={{ required: `${t('field_required')}` }}
        defaultValue={String(maxAdvanceDays)}
        data-e2e="parking-pool-max-advanced-days"
      />
      <Input
        label={t('max_simultaneous_reservations')}
        name="maxReservationCountPerUser"
        control={control}
        errors={errors}
        rules={{ required: `${t('field_required')}` }}
        autoCapitalize="none"
        defaultValue={String(maxReservationCountPerUser)}
        data-e2e="parking-pool-max-reservation-count-per-user"
      />
      <CheckboxStyled
        control={control}
        errors={errors}
        name="notifyUserNearingReservation"
        defaultValue={notifyUserNearingReservation}
        data-e2e="user-notify"
      >
        {t('parking_pool:reservation_notify')}
      </CheckboxStyled>
      {!!notify && (
        <>
          <Input
            label={t('parking_pool:reservation_nearing_in_hours')}
            name="reservationNearingInHours"
            control={control}
            errors={errors}
            rules={{ required: `${t('field_required')}` }}
            autoCapitalize="none"
            defaultValue={String(reservationNearingInHours || 0)}
            data-e2e="parking-pool-reservation-nearing-in-hours"
          />
          <Input
            label={t('parking_pool:custom_email_message')}
            name="customEmailMessage"
            control={control}
            errors={errors}
            defaultValue={String(customEmailMessage || '')}
            data-e2e="parking-pool-custom-email-message"
            multiline
            rows={2}
          />
        </>
      )}
      <CheckboxStyled
        control={control}
        errors={errors}
        name="notifyOfficeManagersNewReservation"
        defaultValue={notifyOfficeManagersNewReservation}
        data-e2e="office-manager-notify"
      >
        {t('parking_pool:notify_office_manager')}
      </CheckboxStyled>
    </>
  )
}

export default ParkingPoolInputs

const CheckboxStyled = styled(Checkbox)`
  margin-bottom: 16px;
`
