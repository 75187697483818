import gql from 'graphql-tag'
import {ID, IQueryBasicData} from '../shared/IQueryData'

export interface IAssignPlacesToParkingPoolVars {
  places: ID[]
  pool: ID
}

export interface IAssignPlacesToParkingPoolData {
  assignPlacesToParkingPool: IQueryBasicData
}

export const ASSIGN_PLACES_TO_PARKING_POOL = gql`
    mutation assignPlacesToParkingPool(
        $places: [ID!]!
        $pool: ID!
    ) {
        assignPlacesToParkingPool(places: $places, pool: $pool) {
            status
            error
            message
            code
        }
    }
`
