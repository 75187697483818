import { gql } from '@apollo/client'
import { IQueryBasicData } from '../shared/IQueryData'

export enum AccessCardType {
  prox40 = 'prox40',
  prox816 = 'prox816',
  seos = 'seos',
}

export interface ISetAccessCardForUserData {
  setAccessCardForUser: IQueryBasicData
}

export interface ISetAccessCardForUserVars {
  login: string
  cardType: AccessCardType
  cardNumber: string
}

export const SET_ACCESS_CARD_FOR_USER = gql`
  mutation setAccessCardForUser(
    $login: String!
    $cardType: AccessCardType!
    $cardNumber: String!
  ) {
    setAccessCardForUser(
      login: $login
      card_type: $cardType
      card_number: $cardNumber
    ) {
      status
      code
      message
      error
    }
  }
`
