import gql from 'graphql-tag'

export interface IGetUserAcceptancesVars {
  login: string
}

export interface IGetUserAcceptancesEvents {
  getUser: {
    acceptances: {
      decision: boolean
      created_at: string
      modified_at: string
      regulation: {
        title: string
        content: string
        required: boolean
        sort: number
        service: string
        current: boolean
      }
    }[]
    events: {
      name: string
      created_at: string
      comment: string
      regulation: {
        title: string
        service: string
        content: string
        current: boolean
        required: boolean
      }
    }[]
  }
}

export const GET_USER_ACCEPTANCES = gql`
  query GetUserAcceptances($login: String!) {
    getUser(login: $login) {
      acceptances {
        decision
        created_at
        modified_at
        regulation {
          title
          content
          required
          sort
          service
          current
        }
      }
      events(
        filter: { name: { eq: "acceptance" } }
        order: { desc: created_at }
      ) {
        name
        created_at
        comment
        regulation {
          title
          service
          content
          current
          required
        }
      }
    }
  }
`
