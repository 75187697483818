import React, { useEffect, useState } from 'react'
import { Typography, Tab, Tabs } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import LockerList from '../../components/Lockers/LockerList'
import { ReservationStatus } from '../../graphql/shared/sharedTypes'
import { LockerStatus } from '../../graphql/lockers/queryLockers'
import { useParams } from 'react-router-dom'

const tabs = [
  'all',
  LockerStatus.available,
  LockerStatus.ready_to_rent,
  LockerStatus.rented,
  LockerStatus.should_be_returned,
  LockerStatus.service,
  LockerStatus.broken,
  LockerStatus.unavailable,
  LockerStatus.error,
]

const LockersList = () => {
  const [value, setValue] = useState<LockerStatus | string>('all')
  const { t } = useTranslation(['lockers'])
  const params = useParams() as { status: LockerStatus | string }

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: ReservationStatus | string
  ) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (!!params.status && tabs.includes(params.status)) {
      setValue(params.status)
    }
  }, [])

  return (
    <>
      <TypographyStyled variant="h6">{t('title')}</TypographyStyled>
      <TabsStyled
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
      >
        {tabs.map((tab: string, i) => (
          <Tab label={t(`locker_status:${tab}`)} value={tab} key={i} />
        ))}
      </TabsStyled>
      <LockerList lockerStatus={value} />
    </>
  )
}

export default LockersList

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
const TabsStyled = styled(Tabs)`
  margin-bottom: 20px;
`
