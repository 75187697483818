import { gql } from '@apollo/client'

export interface IUserWithAcceptancesData {
  queryUser: {
    login: string
    firstName: string
    lastName: string
    email: {
      email: string
    }
    isTenant: boolean
    tenant?: {
      name: string
    }
    language: string
    ticketsOrdered: {
      count: number
    }
    ticketsOrders: {
      count: number
    }
    ticketsUsed: {
      count: number
    }
  }[]
}

export const USERS_WITH_ACCEPTANCES = gql`
  query UsersVisitedGardenWithAcceptances {
    queryUser(filter: { not: { disabled: true } })
      @cascade(fields: ["acceptances", "email"]) {
      login
      firstName: first_name
      lastName: last_name
      email {
        email
      }
      isTenant: is_tenant
      tenant {
        name
      }
      language
      acceptances(filter: { decision: true }) @cascade(fields: "regulation") {
        regulation(
          filter: {
            title: {
              in: [
                "Informacje handlowe drogą elektroniczną"
                "Przetwarzanie danych"
              ]
            }
          }
        ) {
          title
        }
      }
      ticketsUsed: garden_ticketsAggregate(
        filter: {
          status: { in: [active, active_outside, past, past_overridden] }
        }
      ) {
        count
      }
      ticketsOrdered: garden_ticketsAggregate {
        count
      }
      ticketsOrders: garden_ticket_ordersAggregate(
        filter: { status: { in: completed } }
      ) {
        count
      }
    }
  }
`
