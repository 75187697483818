import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormError } from '../../types/FormTypes'
import { Grid, InputAdornment } from '@mui/material'
import styled from 'styled-components'
import Input from '../Shared/Input'
import { Row } from '../../screens/Lockers/GroupReservationList'
import TenantSelect from '../Shared/TenantSelect'

const EditReservationInputs: FC<{
  control: any
  errors: IFormError | any
  reservation: Row
  disabled?: boolean
}> = ({ control, errors, reservation, disabled }) => {
  const { t } = useTranslation(['locker_group_reservation'])
  const { tenantId, tenantName, tenantEmail, price, comment, tenant } =
    reservation

  return (
    <InputsWrapper>
      <SelectWrapper>
        <TenantSelect
          control={control}
          errors={errors}
          defaultValue={
            tenant && tenantId ? { value: tenantId, label: tenant } : undefined
          }
          disabled={disabled}
        />
      </SelectWrapper>
      <InputStyled
        label={t('tenant')}
        name={'tenantName'}
        control={control}
        errors={errors}
        defaultValue={tenantName}
        disabled={disabled}
      />
      <InputStyled
        label={'Email'}
        name={'tenantEmail'}
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        defaultValue={tenantEmail}
        disabled={disabled}
      />
      <ShortInput
        label={t('price')}
        name={'price'}
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
        }}
        defaultValue={((price as number) * 0.01).toFixed(2)}
        disabled={disabled}
      />
      <InputStyled
        label={t('reservation_comment')}
        name={'comment'}
        control={control}
        errors={errors}
        multiline
        rows={2}
        defaultValue={comment}
        disabled={disabled}
      />
    </InputsWrapper>
  )
}

export default EditReservationInputs

const InputStyled = styled(Input)`
  max-width: 500px;
`
const InputsWrapper = styled(Grid)`
  padding: 16px 0;
`
const ShortInput = styled(Input)`
  max-width: 170px;
  display: inline-block;
`
const SelectWrapper = styled(Grid)`
  padding: 8px 0 16px;
`
