import gql from 'graphql-tag'

export interface IGetSurveyAnswersData {
  getSurvey: {
    surveyName: string
    surveyNameEn: string
    surveyId: string
    answersAggregate: {
      count: number
    }
    questions: {
      id: string
      name: string
      nameEn: string
    }[]
    answers: {
      createdAt: string
      survey: {
        questions: {
          id: string
          name: string
          nameEn: string
        }[]
      }
      user: {
        login: string
        firstName: string
        lastName: string
        email: {
          email: string
        }
        tenant: {
          name: string
        }
      }
      question: {
        id: string
      }
      responseText: string
      responseOptions: {
        id: string
        name: string
      }[]
    }[]
  }
}

export const GET_SURVEY_ANSWERS = gql`
  query getSurvey($id: ID!) {
    getSurvey(id: $id) {
      surveyName: name
      surveyNameEn: name_en
      surveyId: id
      answersAggregate {
        count
      }
      questions {
        id
        name
        nameEn: name_en
      }
      answers(order: { desc: created_at }) {
        createdAt: created_at
        survey {
          questions {
            id
            name
            nameEn: name_en
          }
        }
        user {
          login
          firstName: first_name
          lastName: last_name
          email {
            email
          }
          tenant {
            name
          }
        }
        question {
          id
        }
        responseText
        responseOptions {
          id
          name
        }
      }
    }
  }
`
