import gql from 'graphql-tag'
import { VehicleType } from './getParkingPasses'
export interface IUpdateParkingPassData {
  parkingPass: {
    name: string
  }
}
export interface IUpdateParkingPassVars {
  parkingPassId: string
  name: string
  nameEn: string
  parkingId: string
  disabled: boolean
  regulationId: string
  durationDays: number
  minAdvanceDays: number
  maxAdvanceDays: number
  overflowReservationDays: number
  price: number
  sort: number
  amanoProductNo?: number
  amanoCurrency?: string
  amanoSellCard?: boolean
  minAdvanceWorkDays: number
  vehicleType: VehicleType
}

export const UPDATE_PARKING_PASS = gql`
  mutation updateParkingPass(
    $parkingPassId: [ID!]
    $name: String!
    $nameEn: String
    $parkingId: ID
    $disabled: Boolean
    $regulationId: ID
    $durationDays: Int!
    $minAdvanceDays: Int!
    $maxAdvanceDays: Int!
    $overflowReservationDays: Int!
    $price: Int!
    $sort: Int
    $amanoProductNo: Int
    $amanoCurrency: String
    $amanoSellCard: Boolean
    $minAdvanceWorkDays: Int
    $vehicleType: VehicleType!
  ) {
    updateParkingPass(
      input: {
        filter: { id: $parkingPassId }
        set: {
          name: $name
          name_en: $nameEn
          parking: { id: $parkingId }
          disabled: $disabled
          regulation: { id: $regulationId }
          duration_days: $durationDays
          min_advance_days: $minAdvanceDays
          max_advance_days: $maxAdvanceDays
          overflow_reservation_days: $overflowReservationDays
          price: $price
          sort: $sort
          amano_productno: $amanoProductNo
          amano_currency: $amanoCurrency
          amano_sell_card: $amanoSellCard
          min_advance_work_days: $minAdvanceWorkDays
          vehicle_type: $vehicleType
        }
      }
    ) {
      parkingPass {
        name
      }
    }
  }
`
