import gql from 'graphql-tag'
import { ILog } from '../shared/sharedTypes'

export interface IHIDConfig {
  id: string
  enabled: boolean
  userCanBuy: boolean
  maxNumberOfMobileCards: number
}

export interface IParkingPoolProfile {
  id: string
  name: string
  disabled: boolean
  maxReservationCountPerUser: number
  maxAdvanceDays: number
  maxDurationDays: number
  customEmailMessage: string
  logs: ILog[]
}
export interface IParkingPool {
  id: string
  parkingPass: {
    productId: number
    parking: {
      name: string
    }
  }
  maxActiveUserCount: number
  maxAdvanceDays: number
  maxDurationDays: number
  maxReservationCountPerUser: number
  placesCountContract: number
  disabled: boolean
  isValid: boolean
  placesAggregate: { count: number }
  notifyUserNearingReservation: boolean
  reservationNearingInHours: number
  customEmailMessage: string
  notifyOfficeManagersNewReservation: boolean
  profiles: IParkingPoolProfile[]
}

export interface ISeos {
  id: string
  prefix: string
  disabled: boolean
  logs: ILog[]
}
export interface IGetTenantsWithDomainsData {
  queryTenant: {
    id: string
    name: string
    slug: string
    domains: {
      domain: string
    }[]
    logs: ILog[]
    createdAt: string
    parkingPool?: IParkingPool
    seos?: ISeos
    hidConfig: IHIDConfig
    mySolvyTenant: {
      id: string
      name: string
    }
  }[]
}

export const GET_TENANTS_WITH_DOMAINS = gql`
  query queryTenant {
    queryTenant {
      id
      name
      slug
      domains {
        domain: name
      }
      logs {
        createdAt: created_at
        message
        parameters {
          name
          value
          type
        }
      }
      createdAt: created_at
      parkingPool: parking_pool {
        id
        disabled
        isValid: is_valid
        maxActiveUserCount: max_active_user_count
        maxAdvanceDays: max_advance_days
        maxDurationDays: max_duration_days
        maxReservationCountPerUser: max_reservation_count_per_user
        placesCountContract: places_count_contract
        parkingPass: parking_pass {
          productId: amano_productno
          parking {
            name
          }
        }
        placesAggregate {
          count
        }
        notifyUserNearingReservation: notify_user_nearing_reservation
        reservationNearingInHours: reservation_nearing_in_hours
        customEmailMessage: custom_email_message
        notifyOfficeManagersNewReservation: notify_office_managers_new_reservation
        profiles {
          id
          disabled
          name
          maxReservationCountPerUser: max_reservation_count_per_user
          maxAdvanceDays: max_advance_days
          maxDurationDays: max_duration_days
          customEmailMessage: custom_email_message
          logs {
            createdAt: created_at
            message
            parameters {
              name
              value
              type
            }
          }
        }
      }
      seos {
        id
        prefix
        disabled
        logs(order: { desc: created_at }) {
          createdAt: created_at
          message
          parameters {
            name
            value
            type
          }
        }
      }
      hidConfig: hid_config {
        id
        enabled
        userCanBuy: user_can_buy
        maxNumberOfMobileCards: max_number_of_mobile_cards
      }
      mySolvyTenant: task_tenant {
        id: Id
        name: Name
      }
    }
  }
`
