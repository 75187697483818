import { useState, useEffect } from 'react'
import { ClickAwayListener } from '@mui/material'
import { TextH6, DrawerSubTitle, TextError } from '../Styles/TextCustom'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import DietTypeRadio from './DietTypeRadio'
import MenuItemAvailability from './MenuItemAvailability'
import { UPDATE_MENU_ITEM } from '../../graphql/gastro/updateMenuItem'
import { useMutation } from '@apollo/client'
import MenuItemInputs from './MenuItemInputs'
import { toast } from 'react-toastify'
import { date2isoString } from '../../utils/formatDate'
import DrawerHeader from '../Shared/DrawerHeader'
import DrawerButtons from '../Shared/DrawerButtons'
import FullScreenLoader from '../Shared/FullScreenLoader'

const EditMenuItem = ({
  menuItem,
  closeDetails,
  updateList,
}: {
  menuItem: any
  closeDetails: () => void
  updateList: () => void
}) => {
  const { t } = useTranslation([
    'menu_item_form',
    'form',
    'btn',
    'code',
    'generic',
  ])

  const [openClickAwayModal, setOpenClickAwayModal] = useState(false)
  const [selectedDietType, setSelectedDietType] = useState<string>()
  const [selectedAvailability, setSelectedAvailability] = useState<boolean>()
  const [availabilityDetails, setAvailabilityDetails] = useState<any[]>([])
  const [itemsToDeleteFromDatabase, setItemsToDeleteFromDatabase] = useState<
    any[]
  >([])
  const successNotify = (message: string) => toast.dark(message)

  const lowerCaseFirstLetter = (text: string) =>
    text.charAt(0).toLowerCase() + text.slice(1)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const watchFormData = useWatch({ control })
  const onSubmit = (formData: any) => {
    const {
      name,
      nameEn,
      descriptionPl,
      descriptionEn,
      amount,
      category,
      unit,
      price,
    } = formData
    !!Object.keys(watchFormData).length ||
    !!selectedDietType ||
    !Object.is(menuItem.visible, selectedAvailability) ||
    availabilityDetails.length
      ? updateMenuItem({
          variables: {
            id: menuItem.id,
            removeAvailability: itemsToDeleteFromDatabase,
            visible: selectedAvailability,
            name: name.trim(),
            nameEn: nameEn.trim(),
            description: descriptionPl.trim(),
            descriptionEn: descriptionEn.trim(),
            amount: amount * 1,
            categoryId: category.value,
            unit: unit.value,
            price: Math.floor(parseFloat(price) * 100),
            dietType: selectedDietType,
            availability: availabilityDetails,
          },
        })
      : closeDetails()
  }

  const updateAndClose = () => {
    successNotify(`${t('success_edit')}`)
    updateList()
    closeDetails()
  }

  const handleAvailabilityDetails = (value: any, type: string) => {
    setAvailabilityDetails([])
    if (type === 'date') {
      return setAvailabilityDetails([
        { dateAvailabilityRef: { date: date2isoString(value) } },
      ])
    } else if (type === 'weekdays') {
      return setAvailabilityDetails(
        value.map((item: string) => ({
          weekDayAvailabilityRef: { week_day: item },
        }))
      )
    }
  }

  const [updateMenuItem, { loading }] = useMutation(UPDATE_MENU_ITEM, {
    onCompleted: updateAndClose,
    fetchPolicy: 'no-cache',
  })

  const handleClickAway = () => {
    !!Object.keys(watchFormData).length ||
    !Object.is(menuItem?.visible, selectedAvailability)
      ? setOpenClickAwayModal(true)
      : closeDetails()
  }

  const handleCloseModal = (e: any) => {
    e.stopPropagation()
    setOpenClickAwayModal(false)
  }

  useEffect(() => {
    setSelectedAvailability(menuItem?.visible)
    setSelectedDietType(menuItem?.diet)
    if (menuItem?.rawAvailability.length) {
      const formatted = menuItem.rawAvailability.map((item: any) => ({
        [`${lowerCaseFirstLetter(item.__typename)}Ref`]: {
          id: item.id,
        },
      }))
      setItemsToDeleteFromDatabase(formatted)
    }
  }, [menuItem])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <>
          <DrawerHeader title={t('edit_title')} handleClose={handleClickAway} />
          <TextH6>{t('name_and_price')}</TextH6>

          <DrawerSubTitle>{t('polish_version')}</DrawerSubTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <MenuItemInputs
              control={control}
              errors={errors}
              menuItem={menuItem}
            />
            <TextH6>{t('diet')}</TextH6>
            <DietTypeRadio
              selectedDietType={menuItem?.diet}
              handleChangeValue={(dietType: string) =>
                setSelectedDietType(dietType)
              }
            />
            <TextH6>{t('availability')}</TextH6>
            <MenuItemAvailability
              availability={menuItem?.visible}
              availabilityDate={menuItem?.rawAvailability}
              handleChangeAvailability={(availability: boolean) =>
                setSelectedAvailability(availability)
              }
              handleChangeAvailabilityDate={(value, type) =>
                handleAvailabilityDetails(value, type)
              }
            />
            <DrawerButtons
              handleAccept={handleSubmit(onSubmit)}
              handleCancel={handleClickAway}
            />
            {!!Object.keys(errors).length && (
              <TextError>{t('form:form_error')}</TextError>
            )}
          </form>
        </>
      </ClickAwayListener>
      {/* <BaseModal
        confirmText={'btn:confirm'}
        open={openConfirmModal}
        handleClose={(e) => handleCloseModal(e)}
        handleAccept={handleSubmit(onSubmit)}
        handleClick={(e) => e.stopPropagation()}
      >
        <ModalContent>{t('confirm_title')}</ModalContent>
      </BaseModal> */}
      <BaseModal
        confirmText={'btn:exit'}
        open={openClickAwayModal}
        handleClose={(e) => handleCloseModal(e)}
        handleAccept={() => closeDetails()}
      >
        <ModalContent>{t('generic:exit_without_saving')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default EditMenuItem
