import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IRefreshTokenVars {
  refreshToken: string
  app: string
  apiVersion: number
}

export interface IRefreshTokenData {
  refresh: IQueryBasicData & {
    accessToken: string
    refreshToken: string
  }
}

export const REFRESH_TOKEN = gql`
  query RefreshToken($refreshToken: String!, $app: String!, $apiVersion: Int!) {
    refresh(refresh_token: $refreshToken, app: $app, api_version: $apiVersion) {
      status
      code
      error
      accessToken
      refreshToken
    }
  }
`
