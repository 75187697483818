import React from 'react'
import styled from 'styled-components'
import { themeColors } from '../const/colors'
import { CenterContainer } from '../components/Styles/CustomElementsStyled'

const Home = () => {
  return (
    <CenterContainer>
      <h1>
        My <Color>Olivia</Color>
      </h1>
    </CenterContainer>
  )
}

export default Home

const Color = styled.span`
  color: ${themeColors.primary};
`
