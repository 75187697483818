import { IQueryBasicData } from '../shared/IQueryData'
import { gql } from '@apollo/client'

export interface IRegisterVars {
  login: string
  email: string
  password: string
  firstName: string
  lastName: string
  acceptances: string
  language: string
  app: string
}

export interface IRegisterData {
  registerUser: IQueryBasicData & { user: { login: string; language: string } }
}

export const REGISTER_USER_MUTATION = gql`
  mutation registerUser(
    $login: String!
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $acceptances: String
    $language: String
    $app: String
  ) {
    registerUser(
      login: $login
      email: $email
      password: $password
      first_name: $firstName
      last_name: $lastName
      acceptances: $acceptances
      language: $language
      service: "olivia"
      app: $app
    ) {
      status
      code
      message
      error
      user {
        login
        language
      }
    }
  }
`
