import { useCallback, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useIsRouteSelected = (
  route: string,
  isMenuOpen: boolean,
  isNestedListExpanded: boolean = true
): { isSelected: boolean } => {
  const [isSelected, setIsSelected] = useState(false)
  let { pathname } = useLocation()

  const checkIsSelected = useCallback(() => {
    const selected =
      !isMenuOpen || !isNestedListExpanded
        ? pathname.includes(route)
        : `/admin/${route}` === pathname
    setIsSelected(selected)
  }, [route, isMenuOpen, isNestedListExpanded, pathname])

  useEffect(() => {
    checkIsSelected()
  }, [pathname])

  return {
    isSelected,
  }
}
