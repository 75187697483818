import { useState, useEffect } from 'react'
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Grid,
  Checkbox,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import WeekdayChip from './WeekdayChip'
import styled from 'styled-components'
import AvailabilitySwitch from '../Shared/AvailabilitySwitch'
import DatePicker from '../Shared/DatePicker'

const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

const MenuItemAvailability = ({
  availability = true,
  handleChangeAvailability,
  availabilityDate,
  handleChangeAvailabilityDate,
}: {
  availability?: boolean
  handleChangeAvailability: (value: boolean) => void
  availabilityDate?: any
  handleChangeAvailabilityDate: (value: any, type: string) => void
}) => {
  const [availabilitySwitch, setAvailabilitySwitch] = useState(availability)
  const [radioValue, setRadioValue] = useState('fixedMenu')
  const [allWeekdays, setAllWeekdays] = useState(true)
  const [weekdays, setWeekdays] = useState<string[]>(days)
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const { t } = useTranslation(['menu_item_form', 'days'])

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllWeekdays(event.target.checked)
    event.target.checked ? setWeekdays(days) : setWeekdays([])
  }
  const handleDateChange = (date: Date | null) => {
    if (!!date) {
      setSelectedDate(date)
      handleChangeAvailabilityDate(date, 'date')
    }
  }

  const handleClickChip = (day: string) => {
    weekdays.includes(day)
      ? setWeekdays(weekdays.filter((item) => item !== day))
      : setWeekdays(weekdays.concat([day]))
  }
  const handleAvailabilitySwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAvailabilitySwitch(event.target.checked)
    handleChangeAvailability(event.target.checked as boolean)
  }

  useEffect(() => {
    days.every((item: any) => weekdays.includes(item))
      ? setAllWeekdays(true)
      : setAllWeekdays(false)
    handleChangeAvailabilityDate(weekdays, 'weekdays')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekdays])

  useEffect(() => {
    const date = availabilityDate?.find(
      (item: any) => Object.keys(item)[0] === 'date'
    )
    if (date) {
      setRadioValue('specificDate')
      setSelectedDate(date.date)
    } else {
      const chosenWeekdays = availabilityDate?.filter(
        (item: any) => Object.keys(item)[0] === 'week_day'
      )
      if (!!chosenWeekdays && chosenWeekdays.length) {
        setRadioValue('fixedMenu')
        setWeekdays(chosenWeekdays.map((item: any) => item.week_day))
      }
    }
  }, [availabilityDate])

  return (
    <>
      <FormContainer>
        <AvailabilitySwitch
          checked={availabilitySwitch}
          onChange={handleAvailabilitySwitch}
        />
        {availabilitySwitch && (
          <RadioGroup
            data-e2e="availability-radio"
            value={radioValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setRadioValue((event.target as HTMLInputElement).value)
            }
          >
            <FormControlLabel
              value="fixedMenu"
              control={<Radio color="primary" />}
              label={`${t('fixed_menu')}`}
              data-e2e="Menu-button-fixed-days"
            />
            {radioValue === 'fixedMenu' && (
              <RadioContent>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allWeekdays}
                      onChange={handleCheckAll}
                      color="primary"
                      data-e2e="Menu-available-fixed-all-days"
                    />
                  }
                  label={`${t('all_weekdays')}`}
                />
                <Grid container data-e2e="Menu-available-day">
                  {days.map((item) => (
                    <WeekdayChip
                      label={t(`days:${item}`)}
                      active={weekdays.includes(item)}
                      onClick={() => handleClickChip(item)}
                      key={item}
                    />
                  ))}
                </Grid>
              </RadioContent>
            )}

            <FormControlLabel
              value="specificDate"
              control={<Radio color="primary" />}
              label={`${t('select_date')}`}
            />
            {radioValue === 'specificDate' && (
              <RadioContent data-e2e="Menu-available-calendar">
                <DatePicker
                  disablePast={true}
                  selectedDate={selectedDate}
                  setSelectedDate={handleDateChange}
                  label={t('select_date')}
                />
              </RadioContent>
            )}
          </RadioGroup>
        )}
      </FormContainer>
    </>
  )
}

export default MenuItemAvailability

const FormContainer = styled(FormControl)`
  width: 100%;
  padding-bottom: 40px;
`
const RadioContent = styled(Grid)`
  width: 100%;
  padding: 16px 40px 16px 0;
`
