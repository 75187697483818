import styled from 'styled-components'
import { Grid } from '@mui/material'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import { useTranslation } from 'react-i18next'
import { Row } from '../../screens/Parking/PaymentHistory'
import { dateIso2localeString } from '../../utils/formatDate'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { getPaymentStatusColor } from '../../utils/getStatusColor'
import DrawerHeader from '../Shared/DrawerHeader'
import LogsContainer from '../Shared/LogsContainer'
import AddLogForm from '../Shared/AddLogForm'
import ResendInvoice from '../Shared/ResendInvoice'
import { useQuery } from '@apollo/client'
import { themeColors } from '../../const/colors'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'
import {
  IGetParkingPaymentVars,
  IGetParkingPaymentData,
  GET_PARKING_TICKET_PAYMENT,
  GET_ONE_TIME_PARKING_TICKET_PAYMENT,
} from '../../graphql/parking/getParkingPayment'

export const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
}

const PaymentDetails = ({
  data,
  closeDetails,
  refetch,
}: {
  data: Row
  closeDetails: () => void
  refetch: () => void
}) => {
  const { t, i18n } = useTranslation(['payment_history'])
  const lang = i18n.language

  const {
    ticketId,
    paymentId,
    productName,
    validFrom,
    validTo,
    status,
    login,
    tenantName,
    carPlate,
    createdAt,
    price,
    buyerName,
    nip,
    street,
    zipcode,
    city,
    country,
  } = data

  const {
    data: paymentData,
    refetch: refetchPaymentData,
    loading,
  } = useQuery<IGetParkingPaymentData, IGetParkingPaymentVars>(
    productName === t('oneTimeTicket')
      ? GET_ONE_TIME_PARKING_TICKET_PAYMENT
      : GET_PARKING_TICKET_PAYMENT,
    {
      variables: {
        id: ticketId,
      },
    }
  )

  if (loading || !paymentData) {
    return <FullScreenLoader />
  }

  const { payment, productSeller, invoiceNeeded, parkingPass } =
    paymentData.parkingTicket

  const seller = payment?.seller
    ? payment.seller
    : productSeller
    ? productSeller
    : parkingPass?.seller
    ? parkingPass?.seller
    : parkingPass?.parking.seller

  const details = [
    { label: t('payment_id'), value: payment.sessionId },
    { label: t('product_name'), value: productName },
    { label: t('seller_company'), value: seller?.name },
    { label: t('seller_nip'), value: seller?.nip },
    {
      label: t('dates'),
      value: `${dateIso2localeString(
        validFrom,
        lang,
        options
      )} - ${dateIso2localeString(validTo, lang, options)}`,
    },
    { label: 'Login', value: login },
    { label: t('tenant_name'), value: tenantName },
    { label: t('car_plates'), value: carPlate },
    {
      label: t('created_at'),
      value: !!createdAt && dateIso2localeString(createdAt, lang, options),
    },
    { label: t('price'), value: price },
  ]

  const invoiceData = [
    { value: buyerName },
    { value: nip },
    { value: street },
    { value: zipcode },
    { value: city },
    { value: country },
    { value: payment.invoiceEmail },
  ]

  return (
    <>
      <DrawerHeader title={t('details_title')} handleClose={closeDetails} />
      <DetailsWrapper>
        {details.map(
          ({ label, value }) =>
            value && (
              <RowContainer key={label}>
                <Text>{`${label}: `}</Text>
                <TextBold>{value}</TextBold>
              </RowContainer>
            )
        )}
        <RowContainer>
          <Text>{'Status: '}</Text>
          <Status
            color={status ? getPaymentStatusColor(status) : themeColors.black}
          >
            {t(`payment_status:${status}`)}
          </Status>
        </RowContainer>
        <RowContainer>
          <Text>{`${t('invoice')}: `}</Text>
          <TextBold>
            {invoiceNeeded ? t('generic:yes') : t('generic:no')}
          </TextBold>
        </RowContainer>
        {invoiceNeeded && (
          <Container>
            <Text>{`${t('invoice_data')}: `}</Text>
            {invoiceData.map(
              ({ value }, i) =>
                value && <TextBody2Bold key={i}>{value}</TextBody2Bold>
            )}
          </Container>
        )}
      </DetailsWrapper>
      <DetailsWrapper>
        {!!payment.logs && <LogsContainer logs={payment.logs} />}
      </DetailsWrapper>
      {!!payment.sessionId && !paymentId && (
        <ResendInvoice
          paymentId={payment.sessionId}
          refetch={refetch}
          getPaymentLogs={refetchPaymentData}
          invoice={invoiceNeeded}
        />
      )}
      <DetailsWrapper>
        {!!paymentId && (
          <AddLogForm
            id={paymentId}
            refetch={refetch}
            closeDetails={closeDetails}
            getLogs={refetchPaymentData}
          >
            {!!payment.sessionId && (
              <ResendInvoice
                paymentId={payment.sessionId}
                refetch={refetch}
                getPaymentLogs={refetchPaymentData}
                invoice={invoiceNeeded}
              />
            )}
          </AddLogForm>
        )}
      </DetailsWrapper>
    </>
  )
}

export default PaymentDetails

const RowContainer = styled(Grid)`
  padding: 2px;
  max-width: 500px;
`
const Status = styled(TextBody2Bold)`
  text-transform: uppercase;
  display: inline;
`
const Text = styled(TextBody2Medium)`
  display: inline;
`
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
const Container = styled(Grid)`
  padding: 2px;
`
