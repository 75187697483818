import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import BaseSelect, { OptionType } from '../Shared/BaseSelect'
import {
  QUERY_CATEGORY,
  IQueryCategoryData,
} from '../../graphql/gastro/queryCategory'
import { IFormError, InputRules } from '../../types/FormTypes'

const CategorySelect: FC<{
  category?: OptionType
  control: any
  errors: IFormError | any
  name: string
  rules?: InputRules
  isValid?: boolean
}> = ({ category, control, errors, name, rules, isValid }) => {
  const { t } = useTranslation(['menu_item_form'])

  const { data, loading } = useQuery<IQueryCategoryData>(QUERY_CATEGORY)

  const isSelectLoading = loading

  return (
    <BaseSelect
      isValid={isValid}
      defaultValue={category}
      options={data?.queryCategory.map(({ id, titlePl }) => ({
        label: titlePl,
        value: id,
      }))}
      name={name}
      control={control}
      errors={errors}
      rules={rules}
      isLoading={isSelectLoading}
      isDisabled={isSelectLoading}
      isClearable={false}
      noOptionsMessage={() => t('no_category')}
      placeholder={t('dish_category')}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  )
}

export default CategorySelect
