import gql from 'graphql-tag'

export interface ISetMySolvyTenantVars {
  id: string
  mySolvyTenantId: string
}

export interface ISetMySolvyTenantData {
  updateTenant: {
    tenant: {
      name: string
      mySolvyTenant: {
        id: string
        name: string
      }
    }
  }
}

export const SET_MYSOLVY_TENANT = gql`
  mutation setMySolvyTenant($id: [ID!], $mySolvyTenantId: ID) {
    updateTenant(
      input: {
        filter: { id: $id }
        set: { task_tenant: { Id: $mySolvyTenantId } }
      }
    ) {
      tenant {
        name
        mySolvyTenant: task_tenant {
          id: Id
          name: Name
        }
      }
    }
  }
`
