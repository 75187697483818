import gql from 'graphql-tag'
import { IParkingPool } from './queryTenantWithDomains'

export interface IQueryTenantParkingPoolData {
  queryTenant: {
    id: string
    name: string
    parkingPool?: IParkingPool
  }[]
}

export const QUERY_TENANT_PARKING_POOL = gql`
  query queryTenant {
    queryTenant(filter: { has: parking_pool }) {
      id
      name
      parkingPool: parking_pool {
        id
        maxReservationCountPerUser: max_reservation_count_per_user
        placesCountContract: places_count_contract
        maxActiveUserCount: max_active_user_count
        placesAggregate {
          count
        }
      }
    }
  }
`
