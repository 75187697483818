import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IChangeParkingSubscriptionCarPlatesVars {
  id: string
  carPlate: string
  carPlate2?: string
  comment?: string
}

export interface IChangeParkingSubscriptionCarPlatesData {
  changeParkingSubscriptionCarPlates: IQueryBasicData
}

export const CHANGE_PARKING_SUBSCRIPTION_CAR_PLATES = gql`
  mutation changeParkingSubscriptionCarPlates(
    $id: ID!
    $carPlate: String!
    $carPlate2: String
    $comment: String
  ) {
    changeParkingSubscriptionCarPlates(
      subscription: $id
      car_plate: $carPlate
      car_plate2: $carPlate2
      comment: $comment
    ) {
      status
      code
      error
      message
    }
  }
`
