import React from 'react'
import { TextH4Bold, TextInfo } from '../Styles/TextCustom'
import { Img } from '../Styles/CustomElementsStyled'
import image from '../../assets/images/new-message.svg'
import { useTranslation } from 'react-i18next'

const ChangedEmailVerification = () => {
  const { t } = useTranslation(['email_verification_page'])
  return (
    <>
      <TextH4Bold>{t('new_email_title')}</TextH4Bold>
      <TextInfo>{t('new_email')}</TextInfo>
      <Img src={image} alt="img" width="515" />
    </>
  )
}

export default ChangedEmailVerification
