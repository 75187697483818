import gql from 'graphql-tag'

export type QuestionType = 'radiobutton' | 'checkbox' | 'input' | 'textarea'

export interface IGetSurveyData {
  getSurvey: {
    surveyId: string
    surveyName: string
    surveyNameEn: string
    bannerUrl: string
    bannerUrlEn: string
    residentOnly: boolean
    active: boolean
    description: string
    descriptionEn: string
    createdDate: string
    thanks: string
    thanksEn: string
    answersAggregate: {
      count: number
    }
    answers: {
      user: {
        login: string
      }
    }[]
    questions: {
      questionName: string
      questionNameEn: string
      questionId: string
      type: QuestionType
      answers: {
        responseText: string
        responseOptionsAggregate: {
          count: number
        }
      }[]
      options: {
        name: string
        nameEn: string
        id: string
      }[]
    }[]
  }
}

export const GET_SURVEY = gql`
  query getSurvey($id: ID!) {
    getSurvey(id: $id) {
      surveyId: id
      surveyName: name
      surveyNameEn: name_en
      bannerUrl: banner_url
      bannerUrlEn: banner_url_en
      active
      residentOnly: resident_only
      description
      descriptionEn: description_en
      createdDate: created_at
      thanks
      thanksEn: thanks_en
      answersAggregate {
        count
      }
      answers {
        user {
          login
        }
      }
      questions(order: { asc: sort }) {
        questionName: name
        questionNameEn: name_en
        questionId: id
        type
        answers {
          responseText
          responseOptionsAggregate {
            count
          }
        }
        options(order: { asc: sort }) {
          name
          nameEn: name_en
          id
        }
      }
    }
  }
`
