import { LockerRow } from '../components/Lockers/LockerTable'
import { LockerType } from '../graphql/lockers/queryLockers'

export const handleProtocolTitle = (reservations: LockerRow[] | any[]) => {
  const garage = reservations.filter(
    ({ type }) => type === LockerType.garage
  ).length

  const room = reservations.filter(
    ({ type }) => type === LockerType.locker_room
  ).length

  if (garage === 1 && !room) {
    return 'klucza do szafki rowerowej w hali garażowej budynku'
  } else if (garage && garage !== 1 && !room) {
    return 'kluczy do szafek rowerowych w hali garażowej budynku'
  } else if (garage && room) {
    return 'kluczy do szafek rowerowych w budynku'
  } else if (room && room !== 1 && !garage) {
    return 'kluczy do szafek w szatni rowerowej budynku'
  } else if (room === 1 && !garage) {
    return 'klucza do szafki w szatni rowerowej budynku'
  }
}
