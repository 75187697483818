import gql from 'graphql-tag'

export interface IRegulationsVars {
  service: 'olivia' | 'garden' | 'parking'
}

export interface IRegulationData {
  id: string
  content: string
  contentEn: string
  title: string
  titleEn: string
  service: string
  sort: number
  topLevel: boolean
  validUntil: string
  required: boolean
  createdAt: string
}

export interface IQueryRegulationData {
  queryRegulation: IRegulationData[]
}

export const QUERY_REGULATION = gql`
  query queryRegulation($service: String) {
    queryRegulation(
      filter: { service: { eq: $service } }
      order: { asc: title }
    ) {
      id
      content
      contentEn: content_en
      title
      titleEn: title_en
      service
      sort
      topLevel: top_level
      validUntil: valid_until
      required
      createdAt: created_at
    }
  }
`
