import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import { useQuery, ApolloError } from '@apollo/client'
import {
  IQueryLockerProductData,
  QUERY_LOCKER_PRODUCT,
} from '../../graphql/lockers/queryLockerProduct'
import { ILog } from '../../graphql/shared/sharedTypes'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { themeColors } from '../../const/colors'
import { Typography } from '@mui/material'
import { formatPrice, formatToExcelPrice } from '../../utils/formatPrice'
import EditLockerProduct from '../../components/Lockers/EditLockerProduct'
import ErrorContainer from '../../components/Shared/ErrorContainer'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface LockerProductRow {
  id: string
  name: string
  namePl: string
  nameEn: string
  buildingName: string
  building: {
    name: string
    id: string
  }
  periodName: string
  periodNamePl: string
  periodNameEn: string
  durationDays: number
  minAdvanceDays: number
  maxAdvanceDays: number
  availability: boolean
  priceMessage: string
  priceMessageEn: string
  price: string
  priceCSV: string
  rawPrice: number
  seller: string
  regulation: string
  logs?: ILog[]
  disabled: boolean
  withShower: boolean
}

const LockerProductList = () => {
  const { t, i18n } = useTranslation(['locker_products'])
  const langPl = i18n.language === 'pl'
  const [openedDetails, setOpenedDetails] = useState<LockerProductRow>()
  const [error, setError] = useState('')

  const invisibleColumns = {
    id: false,
    priceCSV: false,
  }

  const csvHeaders = [
    {
      label: 'ID',
      key: 'id',
    },
    {
      label: t('product_name'),
      key: 'name',
    },
    {
      label: t('building'),
      key: 'buildingName',
    },
    {
      label: t('period'),
      key: 'periodName',
    },
    {
      label: t('days_number'),
      key: 'durationDays',
    },
    {
      label: t('availability'),
      key: 'availability',
    },
    {
      label: `${t('price')} (PLN)`,
      key: 'priceCSV',
    },
  ]

  const { data, refetch, loading } = useQuery<IQueryLockerProductData>(
    QUERY_LOCKER_PRODUCT,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => setError(error.message),
    }
  )

  const columns: ColumnDef<LockerProductRow, any>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'name',
        header: t('product_name'),
      },
      {
        accessorKey: 'buildingName',
        header: t('building'),
      },
      {
        accessorKey: 'periodName',
        header: t('period'),
      },
      {
        accessorKey: 'durationDays',
        header: t('days_number'),
      },
      {
        accessorKey: 'availability',
        header: t('availability'),
        cell: (value) => (value.getValue() ? t('unavailable') : t('available')),
      },
      {
        accessorKey: 'priceMessage',
        header: t('price_name'),
      },
      {
        accessorKey: 'price',
        header: t('price'),
      },
      {
        accessorKey: 'priceCSV',
        header: t('price'),
      },
    ],
    [t]
  )

  const tableData: LockerProductRow[] = useMemo(() => {
    return (
      data?.lockerProducts.map(
        ({
          id,
          name,
          nameEn,
          periodName,
          periodNameEn,
          disabled,
          regulation,
          type,
          building,
          durationDays,
          minAdvanceDays,
          maxAdvanceDays,
          price,
          priceMessage,
          priceMessageEn,
          seller,
          logs,
          withShower,
        }) => ({
          id,
          name: langPl ? name : nameEn,
          namePl: name,
          nameEn,
          buildingName: building.name,
          building,
          periodName: langPl ? periodName : periodNameEn,
          periodNamePl: periodName,
          periodNameEn,
          durationDays,
          minAdvanceDays,
          maxAdvanceDays,
          availability: disabled,
          priceMessage,
          priceMessageEn,
          price: formatPrice(price),
          priceCSV: formatToExcelPrice(price),
          seller: seller.name,
          regulation: regulation.id,
          rawPrice: price,
          type,
          logs,
          disabled,
          withShower,
        })
      ) || []
    )
  }, [data, langPl])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">{t('title')}</TypographyStyled>
      <Table
        columns={columns}
        data={tableData}
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvExport
        csvHeaders={csvHeaders}
        onRowClick={(rowData: LockerProductRow) => {
          rowData && setOpenedDetails(rowData)
        }}
        getCellProps={({ column, row }) =>
          column.id === 'availability'
            ? {
                style: {
                  color: !row.original.availability && themeColors.accept,
                  fontWeight: 600,
                },
              }
            : {}
        }
        sortByKey="name"
      />
      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        <EditLockerProduct
          closeDetails={() => setOpenedDetails(undefined)}
          lockerProduct={openedDetails}
          refetch={refetch}
        />
      </BaseDrawer>
    </>
  )
}

export default LockerProductList
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
