const pl = {
  generic: {
    error: 'Błąd',
    ups: 'Ups!',
    understand: 'Rozumiem',
    no_options: 'Brak opcji',
    network_error: 'Błąd sieci',
    qraphql_error: 'Błąd GraphQL',
    no: 'Nie',
    maybe_later: 'Może później',
    exit_without_saving: 'Czy na pewno chcesz porzucić zmiany?',
    free: 'Darmowy',
    yes: 'Tak',
    active: 'Aktywna',
    inactive: 'Nieaktywna',
    visible: 'Widoczne',
    invisible: 'Niewidoczne',
    available: 'Dostępne',
    unavailable: 'Niedostępne',
    success_message: 'Zmiany zostały zapisane',
    selected: 'zaznaczonych',
    no_results: 'Brak wyników',
    reservation_created: 'Rezerwacja została utworzona',
    pl: 'Polski',
    en: 'Angielski',
    all: 'Wszyscy',
    none: 'Brak',
    no_parameters: 'Brak wymaganych parametrów wymaganych do logowania.',
  },
  btn: {
    log_in: 'Zaloguj się',
    go_to_app: 'Przejdź do aplikacji',
    create_new_password: 'Utwórz nowe hasło',
    confirm: 'Potwierdź',
    save: 'Zapisz',
    cancel: 'Anuluj',
    accept: 'Przyjmij',
    reject: 'Odrzuć',
    add_tenant: 'Dodaj najemcę',
    save_tenant: 'Zapisz najemcę',
    add_domain: 'Dodaj domenę',
    delete_domain: 'Usuń domenę',
    send_again: 'Wyślij ponownie zaznaczone',
    delete: 'Usuń',
    delete_selected: 'Usuń zaznaczone',
    send_message: 'Wyślij wiadomość',
    exit: 'Wyjdź',
    add_menu_item: 'Dodaj nowe danie',
    more_data: 'Więcej Danych',
    edit: 'Edytuj',
    add_dish: 'Dodaj danie',
    edit_dish: 'Edytuj danie',
    add_subscriptions_parking: 'Dodaj abonament',
    search: 'szukaj',
    filter_clear: 'Wyczyść filtr',
    clear_filters: 'Wyczyść filtry',
    expand: 'Rozwiń',
    collapse: 'Zwiń',
    back_to_user: 'Wróć do użytkownika',
    add_survey: 'Dodaj Ankietę',
    show_regulation: 'Pokaż treść regulaminu',
    hide_regulation: 'Ukryj treść regulaminu',
    add_comment: 'Dodaj komentarz',
    submit_range: 'Zatwierdź zakres dat',
    save_changes: 'Zapisz zmiany',
    logout: 'Wyloguj',
    download_csv: 'Pobierz CSV',
    show: 'Pokaż',
    send: 'Wyślij',
    close_without_message: 'Zamknij bez odpowiedzi',
    sign_up: 'Zarejestruj się',
    send_link_again: 'Wyślij link ponownie',
    go_to_mysolvy: 'Idź do MySolvy',
    send_link: 'Wyślij link',
    refresh: 'Odśwież',
    on: 'Włączony',
    off: 'Wyłączony',
    submit_filters: 'Zatwierdź filtry',
    assign: 'Przypisz',
  },
  form: {
    password: 'Hasło',
    new_password: 'Nowe hasło',
    email_login: 'Email lub Login',
    field_required: 'Pole wymagane',
    select_tenant: 'Wybierz najemcę...',
    tenant_required: 'Musisz wybrać najemcę aby zaakceptować',
    no_tenants: 'Brak najemców',
    comment: 'Opcjonalny komentarz dla użytkownika',
    company_name: 'Nazwa firmy',
    domain_name: 'Nazwa domeny mailowej',
    login: 'Login',
    contact_email: 'Email kontaktowy',
    work_email: 'Email służbowy',
    first_name: 'Imię',
    last_name: 'Nazwisko',
    search: 'Szukaj',
    message: 'Napisz odpowiedź',
    form_error: 'Wypełnij wymagane pola',
    log_comment: 'Komentarz',
    valid_nip: 'Wprowadź poprawny NIP',
    feature_enabled: 'Funkcja włączona',
    feature_disabled: 'Funkcja wyłączona',
    amano_product_id: 'ID produktu amano',
    places_amount: 'Ilość miejsc',
    max_simultaneous_users: 'Max. ilość użytkowników',
    max_continuous_days: 'Max. ilość dni jednej rezerwacji',
    max_advanced_days: 'Max. ilość dni z wyprzedzeniem dla rezerwacji',
    max_simultaneous_reservations: 'Max. ilość jednoczesnych rezerwacji',
    shared_parking_feature: 'Współdzielenie miejsc',
    places_count: 'Liczba miejsc do zarezerwowania',
    from: 'Od',
    to: 'Do',
    correct_form: 'Popraw błędnie wypełnione pola',
    email_msg:
      'Na podany mail wysłany zostanie link aktywacyjny. Jeśli nie dotrze - napisz do nas: aplikacja@oliviacentre.com Jeśli podasz e-mail służbowy, szybciej uzyskasz status Rezydenta potrzebny do rezerwacji biletu do Olivia Garden.',
    login_msg:
      'Po co login? By - bez względu na zmianę pracy, nazwiska czy maila - zawsze mieć dostęp do wszystkich usług aplikacji',
    password_msg: 'Minimum 6 znaków',
    private_or_work_email: 'Email prywatny lub służbowy',
    check_all: 'Zaznacz wszystkie',
    required_fields: 'Pola wymagane',
    phone: 'Numer telefonu',
    valid_phone: 'Wpisz poprawny numer telefonu',
    email_or_login: 'Email lub login',
    profile_name: 'Nazwa profilu',
  },
  days: {
    Monday: 'Pn',
    Tuesday: 'Wt',
    Wednesday: 'Śr',
    Thursday: 'Czw',
    Friday: 'Pt',
    Saturday: 'Sob',
    Sunday: 'Nie',
  },
  admin_menu: {
    garden: 'Garden',
    garden_tickets: 'Bilety do ogrodu',
    gastro: 'Restauracja',
    gastro_menu: 'Menu',
    orders: 'Zamówienia',
    orders_history: 'Historia zamówień',
    parking_areas: 'Parkingi',
    parking_sold_subscription_list: 'Lista sprzedanych abonamentów',
    payment_history: 'Historia płatności',
    parking_subscription_product_list: 'Lista produktów abonamentowych',
    parking_sold_one_time_tickets: 'Lista sprzedanych biletów jednorazowych',
    parking_places: 'Statusy miejsc parkingowych',
    parking_place_reservations: 'Rezerwacje miejsc parkingowych',
    parking_list: 'Lista parkingów',
    users: 'Użytkownicy',
    tenant_issues: 'Prośby o status rezydenta',
    users_list: 'Wszyscy użytkownicy',
    unconfirmed_emails: 'Niepotwierdzone maile',
    companies_list: 'Lista firm',
    messages: 'Wiadomości',
    minimize_menu: 'Zwiń Menu',
    surveys: 'Ankiety',
    surveys_list: 'Lista Ankiet',
    surveys_results_list: 'Ankiety wyniki',
    blocking_olivka_products: 'Blokowanie ofert Olivki',
    bike_lockers: 'Szafki rowerowe',
    lockers_list: 'Lista szafek',
    product_list: 'Lista produktów',
    group_reservation_list: 'Lista rezerwacji grupowych',
    reservation_list: 'Lista rezerwacji',
    locker_statistics: 'Statystyka',
    parking_occupancy_report: 'Raport zajętości parkingów',
    parking_pool_occupancy_report: 'Raport zajętości parkingów najemców',
    parking_management_report: 'Raport zarządczy',
    tenant_parking_places: 'Miejsca parkingowe najemcy',
    getresponse_sync: 'Synchronizacja',
    email_list: 'Lista użytkowników',
    integrations: 'Integracje',
    events: 'Eventy',
    event_tickets: 'Lista biletów',
    event_sales_system: 'System sprzedaży',
    delete_account_requests: 'Lista rezygnacji z konta',
    event_reports: 'Raporty',
    tenant_parking: 'Rezerwacje parkingów najemców',
    current_version: 'Aktualna wersja aplikacji',
    new_push: 'Nowy push',
    push_list: 'Lista pushy',
    payout: 'Wypłata',
    banner_in_app: 'Baner w aplikacji',
    user_roles_list: 'Lista ról użytkowników',
    car_plate_verification_report: 'Raport kontroli',
  },
  menu_item_form: {
    none: 'Brak',
    vege: 'Wegetariańska',
    vegan: 'Wegańska',
    fixed_menu: 'Menu stałe',
    select_date: 'Wybierz datę',
    name_and_price: 'Nazwa i cena',
    polish_version: 'Polska wersja',
    english_version: 'Angielska wersja',
    dish_category: 'Kategoria dania',
    diet: 'Dieta',
    availability: 'Dostępność',
    edit_title: 'Edytuj danie',
    add_title: 'Dodaj danie',
    dish_name: 'Nazwa dania',
    description: 'Opis (opcjonalnie)',
    price: 'Cena',
    amount: 'Ilość',
    unit: 'Jednostka',
    no_units: 'Brak jednostek',
    no_category: 'Brak kategorii',
    confirm_title: 'Czy na pewno chcesz zapisać zmiany?',
    success_edit: 'Zmiany zostały zapisane',
    success_add: 'Zmiany zostały zapisane',
    dish_deleted: 'Zmiany zostały zapisane',
    delete_confirm_title: 'Czy na pewno chcesz usunąć?',
    all_weekdays: 'Wszystkie dni tygodnia',
  },
  login_screen: {
    my: 'Moja',
    remind_password: 'Nie pamiętam hasła',
  },
  email_verification_page: {
    success: 'Twoje konto jest już aktywne',
    tip: 'Teraz możesz zalogować się w aplikacji',
    new_email_title: 'Twój nowy adres email został potwierdzony',
    new_email: 'Możesz teraz logować się za pomocą nowego maila',
  },
  recover_password_page: {
    title: 'Stwórz nowe hasło do Twojego konta',
    success:
      ' Twoje hasło zostało zmienione poprawnie, teraz możesz zalogować się w aplikacji.',
  },
  remind_password_screen: {
    info: 'Wpisz swój login lub adres email, na który wyślemy Ci link do zalogowania w aplikacji.',
  },
  remind_password_email_sent_screen: {
    header: 'Wysłaliśmy link na Twój adres email',
    info: 'Otwórz link, który wysłaliśmy Ci na Twój adres mailowy, aby zmienić hasło do swojego konta.',
    info2:
      'Mail nie przyszedł? Sprawdź folder SPAM. W razie czego możesz wysłać link ponownie.',
    success: 'Link został wysłany ponownie',
  },
  tenant_request_details: {
    title: 'Prośba o status rezydenta',
    full_name: 'Imię i nazwisko:',
    message: 'Wiadomość:',
    login: 'Login:',
    contact_email: 'Email kontaktowy:',
    company_email: 'Email służbowy:',
    accepted: 'Zaakceptowana',
    rejected: 'Odrzucona',
    reply: 'Odpowiedź:',
    close_at: 'Data zamknięcia:',
    resolved_by: 'Zweryfikowana przez:',
  },
  user_message_details: {
    title: 'Szczegóły wiadomości',
    from: 'Od:',
    email: 'Email:',
    company: 'Firma:',
    date: 'Data zgłoszenia:',
    message: 'Wiadomość:',
    success: 'Wiadomość została wysłana poprawnie',
    reply: 'Odpowiedź:',
    closeAt: 'Data zamknięcia:',
    resolved_by: 'Odpowiedział(a):',
    delete_account: 'Prośba o usunięcie konta',
    os: 'System:',
    app: 'Wersja aplikacji:',
    api: 'Wersja API:',
    backend: 'Wersja backendu:',
    application_problem: 'Problem z aplikacją',
    account_delete_request: 'Prośba o usunięcie konta',
    issue_type: 'Typ wiadomości',
    change_issue_type: 'Zmień typ wiadomości:',
    user_details: 'Szczegóły użytkownika',
  },
  tenant: {
    add_new_tenant: 'Dodaj nowego najemcę',
    edit_tenant: 'Edytuj najemcę',
    tenant_domains: 'Domeny najemcy:',
    add_domain: 'Dodaj domenę:',
    confirm_title: 'Czy na pewno chcesz edytować tego najemcę?',
    add_tenant_success: 'Najemca został utworzony',
    add_domain_success: 'Domena została utworzona',
    seos_card: 'Karta SEOS',
    add_seos_card: 'Dodaj kartę seos',
    parking_pool_profiles: 'Profile puli parkingowej:',
    add_profile: 'Dodaj profil',
    edit_profile: 'Edytuj profil',
    active: 'Aktywny',
    short_name: 'Nazwa skrócona',
    name: 'Nazwa',
    mysolvy_companies: 'Lista firm MySolvy',
    assigned_mysolvy_company: 'Powiązana firma MySolvy',
    ms_company_assigned: 'Firma MySolvy została powiązana',
    ms_company_unassigned: 'Powiązanie z firmą MySolvy zostało usunięte',
    myolivia_company: 'Firma MyOlivia',
  },
  email_verification_requests: {
    send_again: 'Wyślij ponownie',
    success: 'Email z kodem weryfikacyjnym został wysłany ponownie',
    delete_request: 'Czy na pewno chcesz usunąć wybrany kod weryfikacyjny?',
    delete_selected_requests:
      'Czy na pewno chcesz usunąć wybrane kody weryfikacyjne?',
  },
  error: {
    no_app: 'Nie znaleziono aplikacji.',
  },
  code: {
    100: 'OK',
    101: 'E-mail zweryfikowany',
    102: 'Profil zmieniony',
    103: 'Hasło zmienione',
    104: 'Status rezydenta zaktualizowany',
    105: 'E-mail potwierdzony',
    106: 'Użytkownik zalogowany',
    107: 'Użytkownik zarejestrowany',
    108: 'Zmiana adresu e-mail zaakceptowana. Podążaj za instrukcjami wysłanymi na adres e-mail.',
    109: 'Status rezydenta zaakceptowany',
    110: 'Status rezydenta odrzucony',
    111: 'Status zgody został zmieniony',
    200: 'Bilet zamówiony',
    201: 'Bilet znaleziony',
    202: 'Bilet został anulowany',
    300: 'Wiadomość wysłana',
    301: 'Zgłoszenie rozwiązane',
    400: 'Kliknij link weryfikacyjny wysłany na adres email',
    998: 'Zarezerwowane - nie używać',

    999: 'Wystąpił błąd 999, spróbuj ponownie później',
    1000: 'Niepoprawny adres email',
    1001: 'Wystąpił błąd 1001, spróbuj ponownie później',
    1002: 'Niepoprawny login lub hasło',
    1003: 'Email nie jest zweryfikowany',
    1004: 'Wystąpił błąd 1004, spróbuj ponownie później',
    1005: 'Błąd weryfikacji email',
    1006: 'Niepoprawny token, zaloguj się ponownie',
    1007: 'Wystąpił błąd 1007, spróbuj ponownie później',
    1008: 'Wystąpił błąd 1008, spróbuj ponownie później',
    1009: 'Nie można dodać użytkownika',
    1010: 'Problem z weryfikacją użytkownika',
    1011: 'Data w nieodpowiednim formacie',
    1012: 'Błąd wewnętrzny - nie można pobrać danych użytkownika',
    1013: 'Nie można potwierdzić statusu rezydenta',
    1014: 'Nie można zaktualizować użytkownika',
    1015: 'Nie można zaktualizować statusu rezydenta',
    1016: 'Nie można potwierdzić maila',
    1017: 'Podany login jest już zajęty.',
    1018: 'Podany e-mail jest już zarejestrowany.',
    1019: 'Brakuje parametru: email',
    1020: 'Brakuje parametru: kod weryfikacyjny',
    1021: 'Brakuje parametru: hasło.',
    1022: 'Nie można zmienić hasła.',
    1023: 'Niepoprawny typ email.',
    1024: 'Błąd wewnętrzny - nie można przetworzyć szablonu.',
    1025: 'Nieważny lub wygasły kod weryfikacyjny.',
    1026: 'Błędny parametr: action.',
    1027: 'Niestety podany adres e-mail nie został znaleziony na naszej liście rezydentów - wybierz opcję poniżej.',
    1028: 'Nie posiadasz wystarczających uprawnień',
    1029: 'Nie można przypisać roli',
    1030: 'Nie można usunąć przypisania roli',
    1031: 'Nie można dodać najemcy',
    1032: 'Nie można zmodyfikować najemcy',
    1033: 'Nie można usunąć najemcy',
    1034: 'Nie można dodać domeny',
    1035: 'Nie można edytować domeny',
    1036: 'Nie można usunąć domeny',
    1037: 'Nie można przypisać domeny',
    1038: 'Nie można cofnąć przypisania domeny',
    1039: 'Niepoprawna nazwa serwisu',
    1040: 'Nie można wysłać wiadomości email',
    1041: 'Nie posiadamy takiego konta w naszej bazie.',
    1042: 'Login jest za krótki',
    1043: 'Login zawiera niedozwolony znak',
    1044: 'Nie można wysłać ponownie - email jest nieznany lub kod weryfikacyjny nieważny',
    1045: 'Wartość parametru nieobsługiwana',
    1046: 'Nie można usunąć użytkownika',
    1047: 'Użytkownik nie ma przypisanego budynku',
    1048: 'Użytkownik nie ma przypisanej karty dostępowej',
    1049: 'Nieprawidłowy typ karty dostępu',
    1050: 'Nie znaleziono użytkownika',
    1051: 'Nieprawidłowy numer karty dostępowej',
    1100: 'Wymagane zgody nie zostały zaakceptowane',
    2000: 'Brak aktywnego biletu',
    2001: 'Brak aktywnego biletu',
    2002: 'Błąd wewnętrzny serwera biletowego',
    2003: 'Nie możesz kupić biletu wstecz',
    2004: 'Masz już aktywny bilet',
    2005: 'Błąd wewnętrzny - nieznany parametr dla serwera biletowego',
    2006: 'Błąd wewnętrzny serwera biletów',
    2007: 'Byłeś blisko. Brak wolnego slotu na wybraną przez Ciebie datę.',
    2008: 'Nie można zamówić biletu',
    2009: 'Data niedostępna',
    2010: 'Ups... Nie możesz zarezerwować biletu z wyprzedzeniem większym niż 31 dni.',
    2011: 'Bilet został już wykorzystany',
    3000: 'Nieznany temat problemu',
    3001: 'Nie można wysłać zgłoszenia, spróbuj ponownie później',
    3002: 'Nie można pobrać zgłoszenia',
    7001: 'Nieobsługiwany status',
    7003: 'Użytkownik ma już zamówioną szafkę',
    7004: 'Już anulowano',
    7005: 'Nie znaleziono aktywnego zamówienia na szafki',
    10025: 'Nie znaleziono zamówienia',
    10026: 'Błąd przetwarzania treści',
    10027: 'Brak faktury',
    10028: 'Nie znaleziono płatności',
    12001: 'Użytkownik zalogowany',
    12002: 'Użytkownik nie ma wystarczających uprawnień/ról',
  },
  user_details: {
    title: 'Szczegóły użytkownika',
    confirm_modal_title: 'Potwierdź zmiany',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    company: 'Firma',
    user_roles: 'Role użytkownika',
    no_roles: 'Brak ról użytkownika',
    choose_role: 'Wybierz role użytkownika',
    unconfirmed_user: '* Niepotwierdzony użytkownik',
    tenant_change_success: 'Najemca został przypisany',
    tenant_deleted_success: 'Przypisany najemca został usunięty',
    add_role: 'Rola została przypisana',
    delete_role: 'Rola została usunięta',
    assigned_restaurants: 'Przypisane restauracje',
    no_restaurants: 'Brak restauracji',
    choose_restaurant: 'Wybierz restaurację',
    add_restaurant: 'Restauracja została przypisana',
    delete_restaurant: 'Restauracja została usunięta',
    switch_lock_enable: 'Użytkownik odblokowany',
    switch_lock_disabled: 'Użytkownik zablokowany',
    lock: 'Blokada użytkownika',
    lock_success: 'Użytkownik został zablokowany',
    unlock_success: 'Użytkownik został odblokowany',
    parking_no_plate: 'Brak numerów rejestracyjnych',
    parking_placeHolder: 'Numery rejestracyjne',
    user_parking: 'Numery rejestracyjne samochodów użytkownika',
    acceptances: 'Zgody użytkownika:',
    global_searcher: 'Wyszukiwarka informacji:',
    add_feature: 'Moduł został przypisany',
    delete_feature: 'Moduł został usunięty',
    user_features: 'Moduły użytkownika',
    no_features: 'Brak modułów',
    choose_feature: 'Wybierz moduł',
    access_card: 'Karta dostępowa',
    access_card_change_success: 'Karta dostępowa została przypisana',
    add_role_confirmation1: 'Czy na pewno chcesz przypisać rolę ',
    add_role_confirmation2: ' do użytkownika?',
    remove_role_confirmation1: 'Czy na pewno chcesz usunąć rolę ',
    remove_role_confirmation2: '?',
    change_role_confirmation: 'Czy na pewno chcesz ',
    remove_role: 'usunąć role: ',
    give_role: 'przypisać role: ',
    anonymize: 'Anonimizuj',
    anonymized: 'Użytkownik zanonimizowany',
    anonymize_success: 'Użytkownik został zanonimizowany',
    anonymize_info: 'Zanonimizować można tylko zablokowanego użytkownika.',
    anonymize_status: 'Status anonimizacji: ',
    anonymized_date: 'Data anonimizacji: ',
    anonymize_confirmation: 'Czy na pewno chcesz anonimiziować użytkownika ',
    block_user_confirmation: 'Czy na pewno chcesz zablokować użytkownika ',
    restore_user: 'Przywróć użytkownika',
    role: 'Rola',
    remove_user_role: 'Usuń rolę wybranym użytkownikom',
    remove_role_hint: 'Wybierz rolę a następnie użytkowników z listy',
    remove_role_success: 'Rola została usunięta poprawnie',
    block_user: 'Zablokuj użytkownika',
    unblock_user: 'Odblokuj użytkownika',
    expand_role_legend: 'Rozwiń opisy ról',
    collapse_role_legend: 'Zwiń opisy ról',
    expand_acceptances: 'Rozwiń zgody',
    collapse_acceptances: 'Zwiń zgody',
    change_history: 'Historia zmian',
  },
  user_search: {
    garden_tickets: 'Bilety do Gardenu',
    user_acceptance_history: 'Historia zgód użytkownika',
    parking_subcriptions: 'Abonamenty/bilety parkingowe',
    locker_reservations: 'Rezerwacje szafek',
    user_messages: 'Wiadomości',
    user_messages_closed: 'Zamknięte wiadomości',
    no_questions: 'Brak zapytań',
    get_query: 'Wybierz zapytanie',
  },

  table_component: {
    download_csv: 'Pobierz CSV',
    title: 'Filtrowanie zaawansowane po kolumnie',
    choose_column: 'Wybierz kolumnę',
    choose_column_no: 'Brak kolumn',
    value: 'Wpisz fraze',
    enter_value: 'Wpisz wyszukiwany wyraz',
    no_items: 'Brak danych',
    search: 'Szukaj...',
  },
  columns: {
    full_name: 'Imię i Nazwisko',
    login: 'Login',
    main_email: 'Email główny',
    work_email: 'Email służbowy',
    unconfirmed_email: 'Niepotwierdzony email',
    created_at: 'Data wysłania',
    company: 'Firma',
    tenant: 'Najemca',
    domains: 'Domeny mailowe',
    roles: 'Role',
    message: 'Treść wiadomości',
    message_date: 'Data zgłoszenia',
    close_date: 'Data zamknięcia',
    status: 'Status',
    visit_date: 'Data odwiedzin',
    qr_code: 'Kod QR',
    reply: 'Odpowiedź',
    creation_date: 'Data utworzenia',
    dish_name: 'Nazwa dania',
    price: 'Cena',
    availability: 'Dostępne',
    visible: 'Widoczne',
    diet_type: 'Rodzaj dania',
    category: 'Kategoria',
    last_mobile_app: 'Ostatnie logowanie z aplikacji',
    last_seen: 'Data ostatniego logowania',
    api_version: 'Wersja API',
    car_plate: 'Numery rejestracyjne samochodów',
    features: 'Moduły',
    ticket_type: 'Typ biletu',
    order_id: 'Id zamówienia',
    payment_status: 'Status płatności',
    parking_pool: 'Parking pool',
    resident: 'Rezydent',
    account_status: 'Status konta',
    card_type: 'Typ karty',
    title: 'Tytuł',
    account_confirmed: 'Konto potwierdzone',
    account_created: 'Data utworzenia konta',
    mysolvy_company: 'Firma MySolvy',
  },
  account_status: {
    active: 'Aktywne',
    disabled: 'Zablokowane',
    anonimized: 'Zanonimizowane',
  },
  tabs: {
    new: 'Nowe',
    closed: 'Zamknięte',
    pending: 'Oczekujące',
    verified: 'Zweryfikowane',
    app: 'Z aplikacji',
    other: 'Inne',
  },
  diet_types: {
    vege: 'Wegetariańskie',
    vegan: 'Wegańskie',
    none: '',
  },
  categories: {
    breakfest: 'Śniadania',
    drinks: 'Napoje',
    dinners: 'Zestawy obiadowe',
    soups: 'Zupy',
    extras: 'Dodatki',
    dumplings: 'Pierogi',
    pizzas: 'Pizza',
    sandwiches: 'Kanapki',
    salads: 'Sałatki',
    desserts: 'Desery',
    market: 'Ryneczek',
    self_made: 'Zrób to sam',
    sushi: 'Sushi',
    pastas: 'Makarony',
    cakes: 'Ciasta',
    cofee: 'Kawa',
    buns: 'Drożdżówki',
  },

  gastro: {
    invisible_restaurant: 'Restaturacja nie jest widoczna w aplikacji',
    available_tip:
      'Danie jest widoczne w aplikacji zgodnie z ustawieniami dostępności',
  },

  garden_ticket_status: {
    new: 'Oczekujemy na płatność',
    reserved: 'Zarezerwowany',
    active: 'Aktywny',
    active_outside: 'Aktywny (wyszedł)',
    cancelled: 'Anulowany',
    past: 'Wykorzystany',
    past_overridden: 'Wykorzystany (brak wyjścia)',
    unused: 'Niewykorzystany',
    unavailable: 'Niedostępny',
    error: 'Błąd',
    company: '[Brak]',
  },
  garden_order_status: {
    new: 'Oczekujemy na płatność',
    received: 'Oczekujemy na płatność',
    processing: 'Oczekujemy na płatność',
    confirmed: 'Oczekujemy na płatność',
    completed: 'Opłacone',
    failed: 'Nieopłacone',
    error: 'Błąd',
  },
  non_data: {
    non_data: '[Brak]',
  },
  user_details_table: {
    createdAt: 'Data zmiany',
    service: 'Jaki to serwis?',
    title: 'Tytuł zgody',
    required: 'Czy zgoda jest wymagana?',
    current: 'Czy uzytkownik wyraził zgode?',
    content: 'Treść zgody',
    buttonBack: 'Zamknij i wróć do Listy Użytkowników',
    topic: 'Historia zmian użytkownika:',
  },
  user_acceptances: {
    required: 'Czy zgoda jest wymagana?:',
    selected: 'Czy uzytkownik zaznaczył zgode?:',
    service: 'Którego modułu dotyczy zgoda:',
    current: 'Czy zgoda/regulamin aktualnie obowiązuje:',
    createdAt: 'Data pierwszej decyzji:',
    modifiedAt: 'Data ostatniej decyzji:',
  },
  bool: {
    yes: 'Tak',
    no: 'Nie',
  },
  payment_history: {
    details_title: 'Szczegóły płatności',
    payment_date: 'Data płatności',
    ticket_id: 'Id biletu',
    ticket_type: 'Typ biletu',
    ticket_start: 'Bilet od',
    ticket_stop: 'Bilet do',
    product_name: 'Nazwa produktu',
    seller_company: 'Sprzedawca',
    seller_nip: 'NIP sprzedawcy',
    invoice: 'Faktura',
    invoice_data: 'Dane do faktury',
    parking_name: 'Nazwa parkingu',
    fv: 'Faktura Vat',
    payment_id: 'Id płatności',
    account_firstname: 'Imie',
    account_lastname: 'Nazwisko',
    email: 'Email',
    nip: 'Nip',
    name: 'Nazwa firmy',
    zipcode: 'Kod pocztowy',
    street: 'Ulica',
    city: 'Miasto',
    country: 'Kraj',
    car_plate_1: 'Numer Rejestracyjny',
    car_plate_2: 'Numer Rejestracyjny',
    access_card_id: 'Numer karty OBC',
    tenant_name: 'Najemca',
    payment_value_gross: 'Cena zakupu',
    payment_history: 'Historia płatności',
    payment_status: 'Status płatności',
    subscription: 'Abonament',
    oneTimeTicket: 'Bilet jednorazowy',
    ticket_number: 'Numer biletu',
    dates: 'Daty',
    car_plates: 'Numery rejestracyjne',
    price: 'Kwota',
    created_at: 'Data utworzenia',
    payment: 'Płatność',
    lease_from: 'Najem od',
    lease_to: 'Najem do',
    building: 'Budynek',
    locker_number: 'Numer szafki',
    locker_payment_history: 'Historia płatności za szafki',
    parking_payment_history: 'Historia płatności za parking',
    resend_invoice: 'Wyślij fakturę ponownie',
    resend_receipt: 'Wyślij paragon ponownie',
    resend_invoice_success: 'Faktura została wysłana',
  },
  parking_subscriptions_products_list: {
    parking_id: 'ID Parkingu',
    parking_name: 'Nazwa Parkingu',
    product_duration: 'Czas trwania abonamentu',
    product_name: 'Nazwa produktu',
    product_id: 'Id produktu',
    product_price: 'Cena produktu',
    product_valid_from: 'Oferta dostępna od',
    product_valid_to: 'Oferta wana do',
    active: 'Czy oferta jest aktywna?',
    max_spaces_to_sell: 'Miejsc do sprzedaży',
    seller_company_name: 'Firma sprzedająca',
    product_regulations: 'Link do regulaminu i produktu',
    subscriptions_list: 'Lista produktów abonamentowych',
  },
  parking_subscription_form: {
    add_title: 'Dodaj Abonament',
    edit_title: 'Edytuj Abonament',
    name_and_price: 'Name and price',
    valid_from: 'Produkt dostępny od:',
    duration: 'Czas trwania abonamentu:',
    price: 'Cena abonamentu:',
    price_label: 'Cena',
    limit: 'Liczba miejsc parkingowych do sprzedaży:',
    max_advance:
      'Maksymalna liczba dni, w której przed użyciem biletu można dokonać zakupu:',
    min_advance:
      'Minimalna liczba dni, w której przed użyciem biletu można dokonać zakupu:',
    after_end:
      'Ile dni po wygaśnieciu biletu miejsce parkingowe będzie zarezerwowane:',
    valid_to: 'Produkt dostępny do:',
    active: 'Czy produkt jest aktywny:',
    select_parking: 'Wybierz Parking',
    select_parking_no: 'Brak Parkingów',
    rules: 'Regulamin',
    select_no_rules: 'Brak Regulaminu',
    select_rules: 'Wybierz regulamin',
    company: 'Firma sprzedająca abonament',
    select_company: 'Wybierz firmę',
    select_company_no: 'Brak firm',
    add: 'Dodaj',
    active_yes: 'Tak, produkt jest aktywny',
    active_no: 'Nie, produkt nie jest aktywny',
    date: 'Wybierz date',
    subscription: 'Nazwa Abonamentu:',
    subscription_pl: 'Polska nazwa abonamentu',
    subscription_en: 'Angielska nazwa abonamentu',
    days: 'Dni',
    places: 'Miejsc',
    show_regulation: 'Pokaż treść regulaminu',
    min_advance_others:
      'Minimalna liczba dni roboczych, w której przed użyciem biletu można dokonać zakupu, dla budynków innych niż mój:',
    sort: 'Sortowanie:',
    amano_product_no: 'Numer produktu Amano:',
    value: 'Wartość',
    number: 'Numer',
    currency: 'Waluta',
    amano_sell_card: 'Karta sprzedaży Amano:',
    car: 'Samochód',
    motorcycle: 'Motocykl',
    bike: 'Rower',
    vehicle_type: 'Rodzaj pojazdu:',
    select_vehicle_type: 'Wybierz rodzaj pojazdu',
  },
  parking_list: {
    parking_list: 'Lista parkingów',
    edition_parking: 'Edycja parkingu',
    name: 'Nazwa parkingu',
    limit_place: 'Limit miejsc parkigowych',
    places: 'Miejsc',
    building: 'Lista przypisanych budynków do parkingu:',
    building_list: 'Rozwiń liste budynków',
    building_list_no: 'Brak przypisanych budynków',
    parking_places: 'Lista dedykowanych miejsc parkingowych',
    parking_places_select: 'Rozwiń liste miejsc dedykowanych',
    parking_places_select_no: 'Brak miejsc dedykowanych',
    remarks: 'Uwagi',
    is_dedicate_places: 'Czy są miejsca dedykowane?',
    dedicate_places_number: 'Liczba miejsc dedykowanych',
    parking_building: 'Budynki przypisane do parkingu',
    company: 'Firma obsługująca parking',
    company_email: 'Email do firmy obsługującej parking',
    go_to_places: 'Przejdź do miejsc parkingowych',
    date: 'Data',
    capacity: 'Pojemność',
    sold: 'Sprzedane',
    used: 'Użyte',
    parking_occupancy_report: 'Raport zajętości parkingów',
    management_report: 'Raport zarządczy',
    rented: 'Wynajęte',
    available_for_rent: 'Dostępne do wynajmu w aplikacji',
  },
  sold_parking_items: {
    valid_subscriptions_list: 'Lista aktualnych abonamentów',
    sold_one_time_tickets: 'Lista sprzedanych biletów jednorazowych',
    valid_from: 'Ważny od',
    valid_to: 'Ważny do',
    product_name: 'Nazwa produktu',
    product_id: 'Id produktu',
    car_plates: 'Numery rejestracyjne',
    car_plate: 'Numer rejestracyjny',
    status: 'Status abonamentu',
    ticket_status: 'Status biletu',
    purchase_date: 'Data zakupu',
    payment_amount: 'Kwota płatności',
    payment_status: 'Status płatności',
    payment_id: 'ID płatności OBC',
    payment_external_id: 'ID płatności PayU',
    payment_created_at: 'Data płatności',
    logs: 'Logi:',
    no_logs: 'Brak logów',
    log_message: 'Wiadomość',
    created_at: 'Data utworzenia',
    valid_to_info: 'z datą ważności równą lub wyższą:',
    details_title: 'Szczegóły abonamentu',
    user: 'Dane kupującego',
    dates: 'Daty',
    related_tickets_and_payments: 'Powiązane bilety i płatności:',
    ticket: 'Bilet',
    place: 'Miejsce',
    parameters: 'Parametry:',
    name: 'nazwa',
    type: 'typ',
    value: 'wartość',
    sync_with_amano: 'Synchronizuj z Amano',
    cancel_subscription: 'Anuluj abonament',
    sync_date_and_time: 'Data i godzina synchronizacji',
    subscription_cancelled: 'Abonament został anulowany',
    cancel_subscription_info: 'Czy na pewno chcesz anulować ten abonament?',
    ticket_number: 'Numer biletu',
    ticket_details: 'Sczegóły biletu',
    comment: 'Komentarz',
    action_comment: 'Komentarz anulowania/resynchronizacji',
    reschedule_success: 'Abonament został zsynchronizowany',
    sale_id: 'ID sprzedaży',
    tenant: 'Najemca',
    payment_value: 'Cena zakupu',
    duration: 'Liczba dni',
    current_reservations_list: 'Parkingi najemców - aktualne rezerwacje',
    sync_hint: 'Możesz synchronizować tylko abonamenty zintegrowane z Amano',
    assigned_car_plates: 'Przypisane numery rejestracyjne',
  },
  parking_subscription_status: {
    active: 'Aktywny',
    cancelled: 'Anulowany',
    error: 'Błąd',
    new: 'Nowy',
    past: 'Przeszły',
    pending: 'W toku',
  },
  parking: {
    controller: 'Kontroler',
    car_plate: 'Numer rejestracyjny',
    ticket: 'Bilet',
  },
  survey: {
    edit_survey: 'Edytuj ankietę',
    add_survey: 'Dodaj ankietę',
    status: 'Status ankiety',
    resident_only_title: 'Czy ankieta jest widoczna dla Rezydentów?',
    resident_only: 'Ankieta jest widoczna tylko dla rezydentów',
    for_all: 'Ankieta jest widoczna dla wszystkich',
    survey_name: 'Nazwa ankiety',
    survey_id: 'Id ankiety',
    created_at: 'Data utworzenia',
    responses: 'Odpowiedzi',
    no_answers: 'Brak odpowiedzi',
    full_name: 'Imię i nazwisko',
    company: 'Firma',
    results_chart: 'Wyniki wykres',
    no_options: 'Brak opcji',
    show_answers_to_question: 'Pokaż odpowiedzi na pytanie:',
    preview: 'Podgląd',
  },
  survey_form: {
    name: 'Nazwa',
    name_en: 'Nazwa EN',
    welcome_text: 'Tekst powitalny',
    welcome_text_en: 'Tekst powitalny EN',
    banner: 'Url banera',
    banner_en: 'Url banera EN',
    question_content: 'Treść pytania',
    question_content_en: 'Treść pytania EN',
    question_type: 'Typ pytania',
    radiobutton: 'Jednokrotnego wyboru (radio buttony)',
    checkbox: 'Wielokrotnego wyboru (checkboxy)',
    input: 'Krótka odpowiedź (input)',
    textarea: 'Długa odpowiedź (textarea)',
    add_reply: 'Dodaj odpowiedź',
    other: 'Inne',
    add_question: 'Dodaj pytanie',
    thanks_text: 'Tekst z podziękowaniem*',
    thanks_text_en: 'Tekst z podziękowaniem* EN',
    active: 'Aktywna',
    inactive: 'Nieaktywna',
    survey_duration: 'Czas trwania ankiety',
    no_options: 'Brak opcji',
    option: 'Odpowiedź',
    option_en: 'Odpowiedź EN',
    valid_from: 'Aktywna od',
    valid_to: 'Aktywna do',
    success: 'Ankieta została zapisana',
  },
  feature: {
    gastro_mobile: 'Restauracja mobile',
    gastro_admin: 'Restauracja admin',
    parking_mobile: 'Parking mobile',
    parking_admin: 'Parking admin',
    olivia_news: 'Olivia news',
    survey_with_upsert: 'Ankiety z upsert',
  },
  olivka: {
    blocking_olivka_offers: 'Blokowanie ofert Olivki',
    keyword: 'Słowo kluczowe',
    add_line: 'Dodaj linię',
    offers: 'Oferty:',
    restaurants: 'Restauracje:',
    promo: 'Promocje:',
    regex_saved: 'Regex został zapisany',
    regex_removed: 'Regex został usunięty',
    regex_updated: 'Regex został edytowany',
  },
  lockers: {
    title: 'Szafki rowerowe',
    all: 'Wszystkie',
    ready_to_rent: 'Do wydania',
    rented_overdue: 'Do zdania',
    rented: 'Wynajęta',
    available: 'Wolna',
    broken: 'Uszkodzona',
    reserved: 'Zarezerwowana',
    cancelled: 'Anulowano',
    error: 'Error',
    rent_locker_manually: 'Wynajmij szafkę ręcznie',
    rent_lockers_manually: 'Wynajmij szafki ręcznie',
    building: 'Budynek',
    locker_type: 'Rodzaj szafki',
    locker_number: 'Numer szafki',
    key_number: 'Numer kluczyka',
    transponder_number: 'Numer transpondera',
    lease_term: 'Okres najmu',
    tenant: 'Najemca',
    rent_manually: 'Wynajmij ręcznie',
    print_protocol: 'Wydrukuj protokół',
    save_and_print: 'Zapisz i wydrukuj',
    print_transfer_protocol: 'Wydrukuj protokół przekazania',
    print_pickup_protocol: 'Wydrukuj protokół odebrania',
    bike_locker_details: 'Szczegóły szafki rowerowej',
    company: 'Firma',
    notes: 'Komentarz',
    logs: 'Historia szafki (logi)',
    garage: 'Szafka w garażu',
    locker_room: 'Szafka w szatni',
    locker_room_with_shower: 'Szafka w szatni z prysznicami',
    from: 'Od',
    to: 'Do',
    small_from: 'od',
    small_to: 'do',
    rented_a: 'Wynajęta',
    available_a: 'Wolna',
    broken_a: 'Uszkodzona',
    reserved_a: 'Zarezerwowana',
    prereserved: 'Wstępnie zarezerwowana',
    rental: 'Wynajem',
    reservation: 'Rezerwacja',
    available_lockers: 'Dostępne szafki',
    type: 'Rodzaj',
    total: 'Szafek łącznie:',
    rent_confirmation: 'Potwierdzenie wynajęcia',
    reservation_confirmation: 'Potwierdzenie rezerwacji',
    company_name: 'Nazwa firmy',
    company_address: 'Adres firmy',
    city: 'Miasto',
    zip_code: 'Kod pocztowy',
    representative: 'Reprezentowana przez',
    netto_amount: 'Kwota netto',
    submit_range: 'Zatwierdź zakres dat',
    no_results: 'Brak wyników',
    rent_protocol: 'Protokół wynajęcia szafki',
    transfer_protocol: 'Protokół przekazania szafki',
    pickup_protocol: 'Protokół odebrania szafki',
    return_protocol: 'Protokół odbioru',
    no_lockers_requested: 'Wybierz ilość szafek',
    numbers: 'numery',
    tenant_tip: 'Możesz wybrać z listy lub wpisać niżej',
    edit_reservation: 'Edytuj rezerwację',
    close_reservation: 'Zamknij rezerwację',
    title_annotation: 'Dopisek do tytułu',
    locker_status: 'Status szafki',
    current_lease: 'Aktualny najem',
    edit_locker: 'Edytuj szafkę',
    reservation_status: 'Status rezerwacji',
    bike_locker_reservations: 'Rezerwacje szafek rowerowych',
    user: 'Użytkownik',
    valid_valid_from: 'Data nie może być wcześniejsza',
    valid_valid_to: 'Data nie może być późniejsza',
    reservation_details: 'Szczegóły rezerwacji',
    protocol_button_hint: 'Wybierz budynek oraz szafki z listy',
    go_to_transfer_protocol: 'Przejdź do protokołu przekazania',
    go_to_return_protocol: 'Przejdź do protokołu odbioru',
    go_to_edit_locker: 'Przejdź do edycji szafki',
    selected_lockers: 'Wybrane szafki',
    rent_selected_lockers_tip:
      'Jeżeli chcesz wynająć wybrane szafki, wybierz budynek z filtra nad tabelą następnie szafki z listy',
    rental_statistics: 'Statystyka wynajmu',
    app_rental: 'Wynajęte w aplikacji',
    admin_rental: 'Wynajęte poza aplikacją',
    service: 'Serwis',
    total_table: 'Razem',
    change_status: 'Zmień status',
    change_status_button_hint: 'Wybierz zakładkę ze statusem i szafki z listy',
    comment: 'Komentarz',
    new_status: 'Nowy status',
    valid_status: 'Nowy status musi różnić się od poprzedniego',
    change_status_info:
      'Ze statusu DOSTĘPNA możesz zmieić tylko na SERWIS lub USZKODZONA.',
    extension: 'Przedłużenie',
    cancel_reservation: 'Anuluj rezerwację',
    cancel_reservation_confirmation:
      'Czy na pewno chcesz zanulować rezerwację użytkownika: ',
  },
  locker_status: {
    all: 'Wszystkie',
    available: 'Dostępna',
    unavailable: 'Niedostępna',
    broken: 'Uszkodzona',
    error: 'Błąd',
    rented: 'Wynajęta',
    ready_to_rent: 'Do wydania',
    should_be_returned: 'Do zdania',
    service: 'Serwis',
  },
  locker_reservation_status: {
    reservation_status: 'Status rezerwacji',
    rented: 'Wynajęta',
    available: 'Brak rezerwacji',
    broken: 'Uszkodzona',
    reserved: 'Zarezerwowana',
    ready_to_rent: 'Do wydania',
    should_be_returned: 'Do zdania',
    service: 'Serwis',
  },
  locker_products: {
    title: 'Lista produktów',
    product_name: 'Nazwa produktu',
    building: 'Budynek',
    period: 'Okres',
    days_number: 'Liczba dni',
    availability: 'Dostępność',
    price_name: 'Nazwa ceny',
    price: 'Cena',
    garage: 'Szafka w hali garażowej bez dostępu do pryszniców',
    locker_room: 'Szafka w szatni z prysznicami',
    locker_room_with_shower: 'Szafka w hali garażowej z dostępem do pryszniców',
    available: 'Dostępny',
    unavailable: 'Niedostępny',
    product_details: 'Szczegóły produktu',
    product_name_en: 'Angielska nazwa produktu',
    min_advance_days:
      'Minimalna liczba dni, w której przed użyciem szafki można dokonać zakupu:',
    max_advance_days:
      'Maksymalna liczba dni, w której przed użyciem szafki można dokonać zakupu:',
    days: 'Dni',
    price_name_en: 'Angielska nazwa ceny',
    seller: 'Firma sprzedająca szafkę',
    logs: 'Logi',
    with_shower: 'Z prysznicem',
  },
  locker_group_reservation: {
    group_reservations: 'Rezerwacje grupowe',
    building: 'Budynek',
    garage_lockers: 'Szafki w garażu',
    room_lockers: 'Szafki w szatni',
    period: 'Okres',
    tenant: 'Najemca',
    price: 'Cena',
    created_at: 'Data utworzenia',
    reservation_details: 'Szczegóły rezerwacji',
    edit_reservation: 'Edytuj rezerwację',
    cancel_reservation: 'Anuluj rezerwację',
    cancel_reservation_info: 'Czy na pewno chcesz anulować tę rezerwację?',
    reservation_cancelled: 'Rezerwacja anulowana',
    email: 'Email najemcy',
    cancellation_comment: 'Komentarz do anulowania rezerwacji',
    comment: 'Komentarz',
    reservation_comment: 'Komentarz do rezerwacji',
    cancelled: 'Anulowana',
  },
  logs: {
    logs: 'Logi:',
    no_logs: 'Brak logów',
    parameters: 'Parametry:',
    name: 'nazwa',
    type: 'typ',
    value: 'wartość',
    show_logs: 'Pokaż logi',
    hide_logs: 'Ukryj logi',
    created_by: 'autor',
    comment: 'komentarz',
  },
  parking_places: {
    parking_place_statuses: 'Statusy miejsc parkingowych',
    parking_place_reservations: 'Rezerwacje miejsc parkingowych',
    level: 'Poziom',
    place: 'Miejsce',
    shared: 'Współdzielone',
    lease_term: 'Okres najmu',
    company: 'Firma',
    tenant: 'Najemca',
    from: 'Od',
    to: 'Do',
    show_place_numbers: 'Pokaż numery miejsc',
    available: 'Dostępne',
    unavailable: 'Niedostępne',
    reserved: 'Zarezerwowane',
    occupied: 'Zajęte',
    to_rent: 'Do wynajęcia',
    yes: 'Tak',
    no: 'Nie',
    hide_numbers: 'Ukryj numery miejsc',
    show_numbers: 'Pokaż numery miejsc',
    unlimited: 'bezterminowo',
    change_places_status: 'Zmień status miejsc parkingowych',
    change_status: 'Zmień status',
    new_status: 'Nowy status',
    comment: 'Komentarz',
    change_status_button_hint: 'Wybierz status oraz miejsca z listy',
    assign_place_to_parking_pool:
      'Przypisz miejsca do puli parkingowej najemcy',
    taken_places: 'Zajęte miejsca',
    tenant_parking_places: 'Miejsca parkingowe najemcy',
    select_places_hint: 'Wybierz najemcę oraz miejsca z listy',
    unassign_places: 'Usuń przypisanie miejsc do najemcy',
    confirm_modal_content:
      'Czy na pewno chcesz usunąć przypisanie do najemcy wybranych miejsc?',
    select_tenant: 'Wybierz najemcę, do którego chcesz przypisać miejsca',
    selected_places: 'Wybrane miejsca',
  },
  parking_pool: {
    add_parking_pool_success: 'Parking pool został przypisany do firmy',
    no_valid: 'Brak przypisanych miejsc parkingowych',
    reservation_notify:
      'Powiadomienie użytkownika o zbliżającej się rezerwacji',
    notify_office_manager:
      'Powiadomienie Office Managerów o wykonanej rezerwacji',
    custom_email_message: 'Niestandardowa treść powiadomienia',
    reservation_nearing_in_hours:
      'Liczba godzin wyprzedzających rezerwację, z jaką ma być wysłane powiadomienie',
    edit_profile: 'Edytuj profil',
    do_it_now: 'Zrobię to teraz',
    do_it_later: 'Zrobię to później',
    add_places_hint:
      'Aby dokończyć tworzenie produktu współdzielnia miejsc parkingowych najemcy {{tenant}}, musisz przypisać do niego miejsca w wybranym parkingu {{parking}}, w sekcji miejsc parkingowych',
    go_to_places: 'Idź do miejsc',
    assign_places_hint:
      'Wybierz miejsca z listy, a następnie przypisz je do puli parkingowej najemcy',
  },
  reservation_status: {
    ready_to_rent: 'Do wydania',
    rented_overdue: 'Do zdania',
    rented: 'Wynajęte',
    available: 'Wolne',
    unavailable: 'Niedostępne',
    broken: 'Uszkodzone',
    reserved: 'Zarezerwowane',
    prereserved: 'Wstępna rezerwacja',
    error: 'Error',
    cancelled: 'Anulowano',
    finished: 'Zakończona',
  },
  payment_status: {
    completed: 'Zakończona',
    confirmed: 'Potwierdzona',
    error: 'Błąd',
    failed: 'Nieudana',
    new: 'Nowa',
    processing: 'W toku',
    received: 'Otrzymana',
    cancelled: 'Anulowana',
    timedout: 'Czas upłynął',
    partially_refunded: 'Częściowo zwrócona',
    fully_refunded: 'Zwrócona',
  },
  parking_ticket_status: {
    new: 'Nowy',
    reserved: 'Zarezerwowany',
    processing: 'W toku',
    paid: 'Opłacony',
    cancelled: 'Anulowany',
    unavailable: 'Niedostępny',
    error: 'Błąd',
  },
  protocol: {
    date: 'Data',
    locker_no: 'Nr szafki',
    key_no: 'Nr klucza',
    transponder_no: 'Nr transpondera',
    tenant: 'Najemca',
    key_number: 'Liczba kluczy',
    point_1:
      'Odbierający niniejszym oświadcza, że nie wnosi uwag co do stanu udostępnionych szafek rowerowych.',
    point_2:
      'Protokół został sporządzony w dwóch egzemplarzach, z których jeden otrzymuje Odbierający, zaś drugi Przekazujący.',
    providing: 'Przekazujący:',
    receiver: 'Odbierający:',
    key_to_garrage_locker:
      'klucza do szafki rowerowej w hali garażowej budynku',
    keys_to_garrage_lockers:
      'kluczy do szafek rowerowych w hali garażowej budynku',
    keys_to_building_lockers: 'kluczy do szafek rowerowych w budynku',
    key_to_locker_room_locker: 'kluczy do szafek w szatni rowerowej budynku',
    keys_to_locker_room_locker: 'klucza do szafki w szatni rowerowej budynku',
    receiving_protocol: 'Protokół odebrania',
  },
  getresponse: {
    title: 'Synchronizacja z GetResponse',
    sync_date: 'Data synchronizacji',
    processed_records: 'Przetworzone rekordy',
    added_records: 'Dodane rekordy',
    deleted_records: 'Usunięte rekordy',
    modified_records: 'Zmodyfikowane rekordy',
    errors: 'Błędy',
    sync: 'Synchronizuj',
    sync_progress: 'Postęp synchronizacji:',
    get_user_list: 'Pobierz liste użytkowników:',
    permission_to_email: 'którzy wyrazili zgodę na kontakt mailowy:',
    with_garden_tickets: 'z biletami Olivia Garden:',
    pending: 'W toku',
    processing: 'Przetwarzanie',
    completed: 'Zakończona',
    canceled: 'Anulowana',
    error: 'Błąd',
    cancel_sync: 'Anuluj synchronizację',
  },
  events: {
    title: 'Lista biletów w aplikacji',
    payment_date: 'Data płatności',
    ticket_number: 'Numer biletu',
    resident_tickets: 'Bilety dla rezydenta',
    non_resident_tickets: 'Bilety dla nierezydenta',
    event_date: 'Data eventu',
    event_name: 'Event',
    user: 'Użytkownik',
    purchase_location: 'Miejsce zakupu',
    price: 'Cena zakupu',
    payment_status: 'Status płatności',
    sales_system: 'System sprzedaży',
    order_id: 'ID zamówienia',
    created_at: 'Data utworzenia',
    reports: 'Raporty',
    event_order_report: 'Raport zamówień',
    tenant: 'Firma',
  },
  push: {
    push_notification: 'Powiadomienie PUSH',
    title: 'Tytuł',
    message: 'Wiadomość',
    target: 'Wybierz do kogo ma być skierowana wiadomość:',
    all: 'Wszyscy',
    residents: 'Rezydenci',
    non_residents: 'Nierezydenci',
    logins: 'Lista loginów',
    tokens: 'Lista FCM tokenów',
    send_push: 'Wyślij push',
    push_success: 'Powiadomienie push zostało wysłane',
    push_confirmation: 'Czy na pewno chcesz wysłać powiadomienie push?',
    developers: 'Programiści',
    testers: 'Testerzy',
    employees: 'Pracownicy firmy',
    marketing_message: 'Wiadomość marketingowa',
    createdAt: 'Data utworzenia',
    body: 'Treść',
    author: 'Autor',
    push_list: 'Lista pushy',
    to_me: 'Do mnie',
    login: 'Do mnie',
    main_screen: 'Ekran główny',
    my_account: 'Moje konto',
    my_account_contact_us: 'Skontaktuj się z nami',
    garden: 'Olivia Garden',
    gastro: 'Restauracje',
    parking: 'Parking',
    surveys: 'Ankiety',
    news: 'News',
    observation_deck: 'Piętro widokowe',
    events: 'Eventy',
    lockers: 'Szafki',
    hid: 'Karta dostępu',
    mobile_view_target_info:
      '(funkcjonalność dla wersji aplikacji 2.0.2 lub nowszej)',
    mobile_view_target_title: 'Widok aplikacji, do którego ma kierować push:',
    mobile_view_target: 'Widok w aplikacji',
    select_language: 'Wybierz zadeklarowany język odbiorcy',
    select_language_tooltip:
      'Jeśli wybrano język, wiadomość zostanie wysłana tylko do użytkowników, którzy zadeklarowali wybrany język. Gdy nie zadeklarowali, zakładamy język polski.',
  },
  payu: {
    payu_payout: 'Wypłata PayU',
    select_company: 'Wybierz spółkę',
    amount: 'Kwota wypłaty',
    description: 'Komentarz',
    payout: 'Wypłać',
    balance: 'Stan konta',
    amount_validation: 'Kwota wyższa niż stan konta',
    payout_success: 'Wypłata się powiodła',
  },
  tenant_access_account: {
    hid_account: 'Konto HID',
    card_limit: 'Limit kart',
    edit_limit: 'Edytuj limit',
    set_limit: 'Ustaw limit',
    delete_limit: 'Usuń limit',
    delete_confirmation: 'Czy na pewno chcesz usunąć limit kart tego najemcy?',
  },
  parking_pool_occupancy_report: {
    date: 'Data',
    tenant: 'Najemca',
    capacity: 'Liczba aktywnych użytkowników',
    sold: 'Zarezerwowane',
    used: 'Użyte',
    max_user_count: 'Maksymalna liczba użytkowników',
    max_places_count: 'Liczba miejsc wg umowy',
    places_count: 'Liczba miejsc',
  },
  banner: {
    title: 'Baner w aplikacji',
    add_banner: 'Dodaj baner',
    name: 'Nazwa',
    target: 'Użytkownicy',
    residents: 'Rezydenci',
    all: 'Wszyscy',
    only_residents: 'Baner widoczny tylko dla rezydentów:',
    src: 'Url grafiki banera',
    src_en: 'Url grafiki banera EN',
    url: 'Link',
    url_en: 'Link EN',
    internal: 'Link wewnątrz aplikacji',
    external: 'Link zewnętrzny',
    mobile_view_target: 'Wybierz ekran w aplikacji do którego ma kierować link',
    external_link: 'Link zewnętrzny, do którego ma kierować baner',
    active: 'Aktywny',
    inactive: 'Nieaktywny',
    edit_banner: 'Edytuj baner',
    preview: 'Podgląd',
    preview_hint:
      'Podgląd banera dla roli "banner_preview" będzie dostepny gdy żaden baner nie jest obecnie aktywny.',
  },
  skd: {
    access_confirmation_requests: 'Prośby o potwierdzenie dostępu',
    access_confirmation_request: 'Prośba o potwierdzenie dostępu',
    new: 'Nowe',
    closed: 'Zamknięte',
    message: 'Wiadomość:',
    date: 'Data',
    comment: 'Komentarz',
    accepted: 'Potwierdzono',
    rejected: 'Odrzucono',
    access_card_list: 'Lista kart dostępowych',
    card_number: 'Numer karty',
    access_granted: 'Przyznany dostęp',
    access_account_list: 'Lista kont dostępowych',
    access_cards: 'Karty dostępowe',
    device: 'Urządzenie',
    delete_confirmation: 'Czy na pewno chcesz usunąć kartę o numerze ',
    access_account_details: 'Szczegóły konta dostepowego',
    company: 'Firma',
    privilege_requested: 'Wniosek o uprawnienia',
    privilege_granted: 'Uprawnienia przyznane',
    no_cards: 'Brak kart',
    delete_account: 'Usuń konto dostępowe ',
    delete_account_confirmation:
      'Czy na pewno chcesz usunąć mobilne konto dostępowe uzytkownika ',
  },
  extras: {
    event_promo: 'Extras eventowy',
    promos: 'Olivia Extras',
    active: 'Aktywne',
    inactive: 'Nieaktywne',
    order: 'Kolejność',
    valid_from: 'Aktywny od',
    valid_to: 'Aktywny do',
    dates: 'Daty',
    visibility: 'Aktywny',
    edit_promo: 'Edytuj extras',
    company_name: 'Firma/Dostawca extrasu',
    company_logo: 'Logo firmy - url',
    title: 'Tytuł',
    title_en: 'Tytuł EN',
    description: 'Opis',
    description_en: 'Opis EN',
    instruction: 'Jak skorzystać?',
    instruction_en: 'Jak skorzystać? EN',
    location: 'Lokalizacja',
    location_en: 'Lokalizacja EN',
    choose_event: 'Wybierz event',
    choose_related_event: 'Wybierz event związany z extrasem',
    fixed_discount: 'Stała zniżka',
    choose_dates: 'Wybierz daty dostępności extrasu:',
    choose_logo: 'Wybierz grafikę z listy',
    choose_promotion_logo: 'Wybierz logo/grafikę z listy lub podaj URL:',
    add_promo: 'Dodaj extras',
    sort: 'Pozycja',
    set_order: 'Ustaw kolejność ',
    discount: 'Zniżka (np. 10% lub 5 zł)',
    fixed_type: 'Extras stały',
    temporary_type: 'Extras czasowy',
    event_type: 'Extras czasowy - event',
    select_extras_type: 'Wybierz rodzaj extrasu:',
    time_validation: 'Musisz wybrać datę',
    image_url: 'URL grafiki',
    custom_url: 'Inny URL',
    chosen_url: 'Obecny URL grafiki:',
  },
  confirm_email_sent_screen: {
    header: 'Wysłaliśmy link aktywacyjny na adres:',
    info: 'Otwórz link, który wysłaliśmy Ci na Twój adres mailowy, aby się zalogować do aplikacji.',
    info2:
      'Mail nie przyszedł? Sprawdź folder SPAM. W razie czego możesz wysłać link ponownie.',
  },
}

export default pl
