import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextBody1Bold } from '../../components/Styles/TextCustom'
import { useQuery, ApolloError } from '@apollo/client'
import {
  IQueryLockersData,
  QUERY_LOCKERS,
  LockerType,
  ILocker,
  LockerStatus,
} from '../../graphql/lockers/queryLockers'
import {
  QUERY_BUILDING,
  IQueryBuildingData,
  IBuilding,
} from '../../graphql/shared/queryBuilding'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { themeColors } from '../../const/colors'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import { Row } from '../../components/Lockers/LockerStatisticsTable'
import LockerStatisticsTable from '../../components/Lockers/LockerStatisticsTable'
import { getCurrentActiveReservation } from '../../utils/getCurrentReservation'
import ErrorContainer from '../../components/Shared/ErrorContainer'

const LockerStatistics = () => {
  const { t } = useTranslation(['lockers'])

  const [error, setError] = useState('')
  const [buildingLockers, setBuildingLockers] = useState<Row[]>([])
  const [loading, setLoading] = useState(true)

  const { data: buildings, loading: buildingLoading } =
    useQuery<IQueryBuildingData>(QUERY_BUILDING, {
      fetchPolicy: 'no-cache',
    })

  const { data } = useQuery<IQueryLockersData>(QUERY_LOCKERS, {
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => setError(error.message),
  })

  const filteredByBuildingAndType = (
    buildings: IBuilding[],
    lockers: ILocker[],
    lockerType: LockerType
  ) => {
    let filteredLockers = lockers.filter(({ type }) => type === lockerType)
    return buildings.map(({ name }) => {
      const filteredByBuilding = filteredLockers.filter(
        ({ building }) => building.name === name
      )
      return {
        building: name,
        type: lockerType,
        appRental: filteredByBuilding.filter(({ currentUser }) => !!currentUser)
          .length,
        adminRental: filteredByBuilding.filter(
          ({ currentTenant, reservations }) =>
            !!currentTenant ||
            !!getCurrentActiveReservation(reservations)?.groupReservation
        ).length,
        service: filteredByBuilding.filter(
          ({ status }) => status === LockerStatus.service
        ).length,
        available: filteredByBuilding.filter(
          ({ status }) => status === LockerStatus.available
        ).length,
      }
    })
  }

  useEffect(() => {
    if (buildings && data) {
      setBuildingLockers([
        ...filteredByBuildingAndType(
          buildings.queryBuilding,
          data.lockers,
          LockerType.garage
        ),
        ...filteredByBuildingAndType(
          buildings.queryBuilding,
          data.lockers,
          LockerType.locker_room
        ),
      ])
      setLoading(false)
    }
  }, [buildings, data])

  if (loading || buildingLoading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">{t('rental_statistics')}</TypographyStyled>

      <LockerStatisticsTable lockers={buildingLockers} />
      {!buildingLockers.length && (
        <TextBody1Bold color={themeColors.primary}>
          {t('no_results')}
        </TextBody1Bold>
      )}
      {!!error && <ErrorContainer errorMessage={error} />}
    </>
  )
}

export default LockerStatistics
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
