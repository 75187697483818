import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { ApolloError, useMutation } from '@apollo/client'
import { Grid } from '@mui/material'
import { BaseButton } from '../Shared/BaseButton'
import {
  UPDATE_PARKING_POOL_PROFILE,
  IUpdateParkingPoolProfileData,
  IUpdateParkingPoolProfileVariables,
} from '../../graphql/tenant/updateParkingPoolProfile'
import { toast } from 'react-toastify'
import ParkingPoolProfileInputs from './ParkingPoolProfileInputs'
import { IParkingPoolProfile } from '../../graphql/tenant/queryTenantWithDomains'
import ErrorContainer from '../Shared/ErrorContainer'

type FormData = {
  name: string
  maxAdvanceDays: number
  maxDurationDays: number
  maxReservationCountPerUser: number
  customEmailMessage?: string
}

const EdiProfileForm = ({
  handleClose,
  handleUpdateTenants,
  profile,
}: {
  handleClose: () => void
  handleUpdateTenants: () => void
  profile: IParkingPoolProfile
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const { t } = useTranslation(['form'])
  const successNotify = (message: string) => toast.dark(message)
  const { id } = profile

  const {
    control,
    handleSubmit: handleParkingSubmit,
    formState: { errors },
  } = useForm<FormData>()

  const onCompleted = (data: IUpdateParkingPoolProfileData) => {
    if (data.updateParkingPoolProfile.parkingPoolProfile[0].name) {
      setErrorMessage('')
      handleClose()
      handleUpdateTenants()
      successNotify(`${t('generic:success_message')}`)
    } else {
      setErrorMessage(`${t('generic:error')}`)
    }
  }

  const [updateParkingPoolProfile] = useMutation<
    IUpdateParkingPoolProfileData,
    IUpdateParkingPoolProfileVariables
  >(UPDATE_PARKING_POOL_PROFILE, {
    onCompleted,
    onError: (error: ApolloError) => {
      setErrorMessage(error.message)
    },
  })

  const onSubmit = async (data: FormData) => {
    const {
      name,
      maxAdvanceDays,
      maxDurationDays,
      maxReservationCountPerUser,
      customEmailMessage,
    } = data
    await updateParkingPoolProfile({
      variables: {
        profileId: id,
        name,
        maxAdvanceDays: Number(maxAdvanceDays),
        maxDurationDays: Number(maxDurationDays),
        maxReservationCountPerUser: Number(maxReservationCountPerUser),
        customEmailMessage: customEmailMessage || '',
        disabled: isDisabled,
      },
    })
  }

  return (
    <>
      <DetailsWrapper>
        <Form onSubmit={handleParkingSubmit(onSubmit)}>
          <ParkingPoolProfileInputs
            profile={profile}
            control={control}
            errors={errors}
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
          />
          <ButtonWrapper>
            <BaseButton type="submit">{t('btn:save')}</BaseButton>
          </ButtonWrapper>
        </Form>
      </DetailsWrapper>
      {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
    </>
  )
}

export default EdiProfileForm

const DetailsWrapper = styled(Grid)`
  padding: 16px;
  width: 600px;
`
const Form = styled.form`
  max-width: 550px;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
`
