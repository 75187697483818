import gql from 'graphql-tag'

export interface IAggregateAccessCardIssuesData {
  aggregateIssue: { count: number }
}

export const AGGREGATE_ACCESS_CARD_ISSUES = gql`
  query aggregateAccessCardIssues {
    aggregateIssue(
      filter: {
        type: { eq: card_access_request }
        and: { status: { eq: new } }
      }
    ) {
      count
    }
  }
`
