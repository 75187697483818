import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { IFormError } from '../../types/FormTypes'
import { TextBody1 } from '../Styles/TextCustom'
import TimePickerController from '../Shared/TimePickerController'
import DatePickerController from '../Shared/DatePickerController'
import styled from 'styled-components'

const ExtrasSlotPickers = ({
  errors,
  control,
  defaultFrom = null,
  defaultTo = null,
  watch,
}: {
  control: any
  errors: IFormError | any
  defaultFrom?: Date | null
  defaultTo?: Date | null
  watch: any
}) => {
  const { t } = useTranslation(['extras'])

  const defaultTime = (date: Date) =>
    new Date(date).getHours() !== 0 || new Date(date).getMinutes() !== 0
      ? date
      : null

  return (
    <>
      <TextBody1>{t('choose_dates')}</TextBody1>
      <Grid container spacing={1}>
        <Grid xs={6} item>
          <div data-e2e="start-date-picker">
            <DatePickerController
              name={'dateFrom'}
              label={t('form:from')}
              control={control}
              errors={errors}
              defaultValue={defaultFrom}
            />
          </div>
          <Wrapper>
            <div data-e2e="start-time-picker">
              <TimePickerController
                name={'timeFrom'}
                label={t('form:from')}
                control={control}
                errors={errors}
                defaultValue={defaultFrom && defaultTime(defaultFrom)}
                rules={{
                  validate: (val: Date) =>
                    val
                      ? (val && !!watch('dateFrom')) || t('time_validation')
                      : true,
                }}
              />
            </div>
          </Wrapper>
        </Grid>
        <Grid xs={6} item>
          <div data-e2e="end-date-picker">
            <DatePickerController
              name={'dateTo'}
              label={t('form:to')}
              control={control}
              errors={errors}
              defaultValue={defaultTo}
            />
          </div>
          <Wrapper>
            <div data-e2e="end-time-picker">
              <TimePickerController
                name={'timeTo'}
                label={t('form:to')}
                control={control}
                errors={errors}
                defaultValue={defaultTo && defaultTime(defaultTo)}
                rules={{
                  validate: (val: Date) =>
                    val
                      ? (val && !!watch('dateTo')) || t('time_validation')
                      : true,
                }}
              />
            </div>
          </Wrapper>
        </Grid>
      </Grid>
    </>
  )
}

export default ExtrasSlotPickers

const Wrapper = styled.div`
  margin: 16px 0 0 0;
`
