import { LockerStatus } from '../graphql/lockers/queryLockers'
import { IReservation, ReservationStatus } from '../graphql/shared/sharedTypes'

const now = new Date(new Date().setHours(0, 0, 0, 0))

export const activeReservations = [
  ReservationStatus.rented,
  ReservationStatus.reserved,
]

const unavailableLockerStatus = [
  LockerStatus.broken,
  LockerStatus.service,
  LockerStatus.unavailable,
  LockerStatus.rented,
  LockerStatus.should_be_returned,
]

export const getCurrentReservation = (reservations: IReservation[]) =>
  reservations.find(
    ({ validFrom, validTo, status }) =>
      (activeReservations.includes(status) &&
        new Date(new Date(validFrom).setHours(0, 0, 0, 0)) <= now &&
        new Date(new Date(validTo).setHours(0, 0, 0, 0)) >= now) ||
      new Date(new Date(validFrom).setHours(0, 0, 0, 0)) > now
  )

export const getCurrentActiveReservation = (reservations: IReservation[]) =>
  reservations.find(
    ({ validFrom, validTo, status }) =>
      (new Date(new Date(validFrom).setHours(0, 0, 0, 0)) <= now &&
        new Date(new Date(validTo).setHours(0, 0, 0, 0)) >= now &&
        activeReservations.includes(status)) ||
      (new Date(new Date(validFrom).setHours(0, 0, 0, 0)) > now &&
        activeReservations.includes(status))
  )

export const getCurrentActiveReservations = (reservations: IReservation[]) =>
  reservations
    .filter(({ status }) => activeReservations.includes(status))
    .sort(
      (a, b) =>
        new Date(a.validFrom).getTime() - new Date(b.validFrom).getTime()
    )

export const handleLockerAvailability = (
  status: LockerStatus,
  reservations: IReservation[]
) =>
  !unavailableLockerStatus.includes(status) &&
  !getCurrentActiveReservation(reservations)
