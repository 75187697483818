import { gql } from '@apollo/client'
import { IFormRegulation } from '../../types/FormTypes'

export interface IRegulationsByServiceVars {
  service: string
  login: string
}

export interface IRegulationsByServiceData {
  queryRegulation: IFormRegulation[]
}

export const GET_REGULATIONS_BY_SERVICE_PL = gql`
  query getRegulations($service: String!, $login: String) {
    queryRegulation(
      order: { asc: sort }
      filter: { current: true, service: { eq: $service } }
    ) {
      id
      required
      content
      topLevel: top_level
      acceptances @cascade(fields: "user") {
        decision
        modifiedAt: modified_at
        user(filter: { login: { eq: $login } }) {
          login
        }
      }
    }
  }
`
export const GET_REGULATIONS_BY_SERVICE_EN = gql`
  query getRegulations($service: String!, $login: String) {
    queryRegulation(
      order: { asc: sort }
      filter: { current: true, service: { eq: $service } }
    ) {
      id
      required
      content: content_en
      topLevel: top_level
      acceptances @cascade(fields: "user") {
        decision
        modifiedAt: modified_at
        user(filter: { login: { eq: $login } }) {
          login
        }
      }
    }
  }
`
