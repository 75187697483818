import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IPayuPayoutVars {
  company: string
  amount: number
  description: string
}

export interface IPayuPayoutData {
  payuPayout: IQueryBasicData & {
    payoutId: string
    extPayoutId: string
  }
}

export const PAYU_PAYOUT = gql`
  mutation payuPayout($company: String!, $amount: Int!, $description: String!) {
    payuPayout: PayuPayout(
      company: $company
      amount: $amount
      description: $description
    ) {
      status
      code
      message
      error
      payoutId: payout_id
      extPayoutId: ext_payout_id
    }
  }
`
