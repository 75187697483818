import gql from 'graphql-tag'

export interface IQueryFeaturesData {
  queryFeature: { name: string; description: string; enabled: boolean }[]
}

export const QUERY_FEATURES = gql`
  query queryFeature {
    queryFeature(order: { asc: name }) {
      name
      description
      enabled
    }
  }
`
export interface IAddUserFeatureVars {
  id: string
  name: string
  login: string
}
export interface IAddUserFeatureData {
  addUserFeature: {
    userFeature: {
      name: string
    }[]
  }
}

export const ADD_USER_FEATURE = gql`
  mutation addUserFeature($id: String!, $name: String!, $login: String) {
    addUserFeature(
      input: { name: $name, id: $id, user: { login: $login }, enabled: true }
    ) {
      userFeature {
        id
        name
        enabled
      }
    }
  }
`
export interface IDeleteUserFeatureVars {
  id: string
}
export interface IDeleteUserFeatureData {
  deleteUserFeature: {
    userFeature: {
      name: string
    }[]
  }
}

export const DELETE_USER_FEATURE = gql`
  mutation deleteUserFeature($id: String!) {
    deleteUserFeature(filter: { id: { eq: $id } }) {
      userFeature {
        id
        name
        enabled
      }
    }
  }
`
