import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import {
  QUERY_MYSOLVY_TENANT,
  IQueryMySolvyTenantData,
} from '../../graphql/tenant/queryMySolvyTenant'
import BaseSelect, { OptionType } from '../Shared/BaseSelect'
import { ActionMeta } from 'react-select'

const MySolvyTenantSelect: FC<{
  selectedMySolvyTenant: OptionType | null
  handleSelectChange: (
    value: OptionType | null,
    actionMeta: ActionMeta<OptionType>
  ) => void
}> = ({ selectedMySolvyTenant, handleSelectChange }) => {
  const { t } = useTranslation(['form', 'btn', 'tenant', 'user_details'])

  const { data, loading } = useQuery<IQueryMySolvyTenantData>(
    QUERY_MYSOLVY_TENANT,
    {
      fetchPolicy: 'no-cache',
    }
  )

  return (
    <BaseSelect
      placeholder={t('select_tenant')}
      name="tenant"
      options={data?.queryMySolvyTenant.map(({ shortName, id }) => ({
        value: id,
        label: shortName,
      }))}
      value={selectedMySolvyTenant}
      onChange={handleSelectChange}
      noOptionsMessage={() => t('no_tenants')}
      isLoading={loading}
      isDisabled={loading}
    />
  )
}

export default MySolvyTenantSelect
