import gql from 'graphql-tag'
import { ILockerGroupReservation } from './queryLockerGroupReservation'

export interface IGetLockerGroupReservationVars {
  id: string
}

export interface IGetLockerGroupReservationData {
  groupReservation: ILockerGroupReservation
}

export const GET_LOCKER_GROUP_RESERVATION = gql`
  query getLockerGroupReservation($id: ID!) {
    groupReservation: getLockerGroupReservation(id: $id) {
      id
      createdAt: created_at
      validFrom: valid_from
      validTo: valid_to
      status
      building {
        name
        location
        seller {
          name
          zipcode
          street
          city
        }
      }
      garageLockers: requested_garage_lockers
      roomLockers: requested_room_lockers
      lockers {
        name
        key
        transponder
        type
      }
      tenant {
        name
      }
      tenantName: tenant_name
      tenantEmail: tenant_email
      comment
      buyer {
        name
        nip
        street
        zipcode
        city
      }
      representative: buyer_represented_by
      price
      logs {
        createdAt: created_at
        message
        parameters {
          name
          value
          type
        }
      }
    }
  }
`
