import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import {
  USERS_WITH_ACCEPTANCES,
  IUserWithAcceptancesData,
} from '../../graphql/users/usersWithAcceptances'
import { CSVLink } from 'react-csv'
import { Button } from '@mui/material'
import GetAppIcon from '@mui/icons-material/GetApp'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { TextBody1 } from '../../components/Styles/TextCustom'

interface IUsersData {
  firstName: string
  lastName: string
  email: string
  isTenant: string
  tenant?: string
  language: string
  ticketsOrdered?: number
  ticketsOrders?: number
  ticketsUsed?: number
}
interface IHeader {
  label: string
  key: string
}

const EmailList = () => {
  const [usersWithTickets, setUsersWithTickets] = useState<IUsersData[]>([])
  const [userBasicData, setUserBasicData] = useState<IUsersData[]>([])
  const { t } = useTranslation(['getresponse'])
  const { data } = useQuery<IUserWithAcceptancesData>(USERS_WITH_ACCEPTANCES, {
    fetchPolicy: 'no-cache',
  })

  const renderCSVLink = (
    tableData: IUsersData[],
    headers: IHeader[],
    name: string
  ) => {
    return (
      <CSVLinkStyled
        separator={';'}
        data={tableData}
        filename={name}
        headers={headers}
      >
        <ButtonStyled
          color="primary"
          variant="outlined"
          size="large"
          startIcon={<GetAppIcon />}
        >
          {t('btn:download_csv')}
        </ButtonStyled>
      </CSVLinkStyled>
    )
  }

  useEffect(() => {
    if (data?.queryUser) {
      const usersData = data?.queryUser
      let reducedUserData = usersData.map(
        ({
          firstName,
          lastName,
          email,
          isTenant,
          tenant,
          language,
          ticketsOrdered,
          ticketsOrders,
          ticketsUsed,
        }) => ({
          firstName,
          lastName,
          email: email?.email,
          isTenant: isTenant ? t('bool:yes') : t('bool:no'),
          language,
          tenant: tenant?.name,
          ticketsOrdered: ticketsOrdered.count,
          ticketsOrders: ticketsOrders.count,
          ticketsUsed: ticketsUsed.count,
        })
      )
      setUsersWithTickets(reducedUserData)
    }
  }, [data, t])

  useEffect(() => {
    setUserBasicData(
      usersWithTickets.map(
        ({ firstName, lastName, email, isTenant, tenant, language }) => ({
          firstName,
          lastName,
          email,
          isTenant,
          tenant,
          language,
        })
      )
    )
  }, [usersWithTickets])

  const basicHeaders = [
    { label: 'Imię', key: 'firstName' },
    { label: 'Nazwisko', key: 'lastName' },
    { label: 'Email', key: 'email' },
    { label: 'Rezydent', key: 'isTenant' },
    { label: 'Firma', key: 'tenant' },
    { label: 'Język', key: 'language' },
  ]
  const headersWithTickets = [
    { label: 'Imię', key: 'firstName' },
    { label: 'Nazwisko', key: 'lastName' },
    { label: 'Email', key: 'email' },
    { label: 'Rezydent', key: 'isTenant' },
    { label: 'Firma', key: 'tenant' },
    { label: 'Język', key: 'language' },
    { label: 'Zarezerwowane bilety', key: 'ticketsOrdered' },
    { label: 'Zamówienia biletów', key: 'ticketsOrders' },
    { label: 'Użyte bilety', key: 'ticketsUsed' },
  ]

  return (
    <Container>
      <CSVContainer>
        <TypographyStyled variant="h6">{t('get_user_list')}</TypographyStyled>
        <TextBody1>{t('permission_to_email')}</TextBody1>
        {renderCSVLink(
          userBasicData,
          basicHeaders,
          'users-with-acceptances.csv'
        )}
        <TextBody1>{t('with_garden_tickets')}</TextBody1>
        {renderCSVLink(
          usersWithTickets,
          headersWithTickets,
          'users-with-tickets.csv'
        )}
      </CSVContainer>
    </Container>
  )
}

export default EmailList

const CSVLinkStyled = styled(CSVLink)`
  text-decoration: none;
`
const CSVContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const ButtonStyled = styled(Button)`
  width: 210px;
  margin-bottom: 16px;
`
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
