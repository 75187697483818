import BaseModal from '../Shared/BaseModal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ClickAwayListener } from '@mui/material'
import { ModalContent } from '../Styles/CustomElementsStyled'
import { TextError, TextH6 } from '../Styles/TextCustom'
import { useForm, useFieldArray } from 'react-hook-form'
import Input from '../Shared/Input'
import AddSurveyQuestion from './AddSurveyQuestion'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import FullScreenLoader from '../Shared/FullScreenLoader'
import SurveyBaseInfo from '../SurveyDetails/SurveyBaseInfo'
import DrawerButtons from '../Shared/DrawerButtons'
import DrawerHeader from '../Shared/DrawerHeader'
import { BaseTextButton } from '../Shared/BaseButton'
import { ADD_SURVEY, IAddSurveyVars } from '../../graphql/survey/addSurvey'
import { useMutation, ApolloError } from '@apollo/client'
import { date2isoString } from '../../utils/formatDate'
import { toast } from 'react-toastify'
import AvailabilitySwitch from '../Shared/AvailabilitySwitch'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const AddSurvey = ({
  closeDetails,
  updateList,
}: {
  closeDetails: () => void
  updateList: () => void
}) => {
  const [openClickAwayModal, setOpenClickAwayModal] = useState(false)
  const [residentOnly, setResidentOnly] = useState(false)
  const [error, setError] = useState('')
  const { t } = useTranslation(['survey_form', 'survey'])
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questions',
  })
  const now = new Date()
  const createdAt = date2isoString(now, false)
  const successNotify = (message: string) => toast.dark(message)

  const handleCloseModal = (e: any) => {
    e.stopPropagation()
    setOpenClickAwayModal(false)
  }
  const handleClickAway = () => {}
  const onCompleted = () => {
    updateList()
    successNotify(t('success'))
    closeDetails()
  }
  const [addSurvey, { loading }] = useMutation<IAddSurveyVars>(ADD_SURVEY, {
    onCompleted,
    onError: (error: ApolloError) => setError(error.message),
  })

  const onSubmit = (data: any) => {
    const inputTypes = ['input', 'textarea']
    const {
      name,
      nameEn,
      description,
      descriptionEn,
      banner,
      bannerEn,
      thanks,
      thanksEn,
    } = data
    const questions = data.questions.map(
      (
        {
          name,
          nameEn,
          type,
          options,
          other,
        }: {
          name: string
          nameEn: string
          type: string
          options: { name: string; nameEn: string }[]
          other: boolean
        },
        i: { i: any }
      ) => {
        const modifiedOptions = options.map(({ name, nameEn }, i) => ({
          name: name.trim(),
          name_en: nameEn.trim(),
          sort: i,
        }))
        other &&
          modifiedOptions.push({
            name: 'Inne',
            name_en: 'Other',
            sort: modifiedOptions.length,
          })
        return {
          name: name.trim(),
          name_en: nameEn.trim(),
          type,
          sort: i,
          options: inputTypes.includes(type) ? [] : modifiedOptions,
        }
      }
    )

    const variables = {
      name: name.trim(),
      nameEn: nameEn.trim(),
      description: description.trim(),
      descriptionEn: descriptionEn.trim(),
      bannerUrl: banner.trim(),
      bannerUrlEn: bannerEn.trim(),
      thanks: thanks.trim(),
      thanksEn: thanksEn.trim(),
      questions,
      createdAt,
      residentOnly,
    }
    addSurvey({ variables })
  }

  const renderQuestions = () => {
    return fields.map((field, index) => (
      <AddSurveyQuestion
        errors={errors}
        control={control}
        number={index + 1}
        name={`questions[${index}].name`}
        nameEn={`questions[${index}].nameEn`}
        nameSelect={`questions[${index}].type`}
        questionIndex={index}
        key={field.id}
        watch={watch}
        questionRemove={remove}
        setValue={setValue}
      />
    ))
  }

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <>
          <DrawerHeader
            title={'survey:add_survey'}
            handleClose={closeDetails}
          />
          <SurveyBaseInfo errors={errors} control={control} />
          {renderQuestions()}
          <BaseTextButton
            data-e2e="survey-add-question"
            onClick={() => append({})}
            icon={faPlus as IconProp}
          >
            {t('add_question')}
          </BaseTextButton>
          <Input
            label={t('thanks_text')}
            name={'thanks'}
            control={control}
            errors={errors}
            multiline
            rows={2}
            data-e2e="survey-input-thanks"
          />
          <Input
            label={t('thanks_text_en')}
            name={'thanksEn'}
            control={control}
            errors={errors}
            multiline
            rows={2}
            data-e2e="survey-input-thanks-en"
          />
          <SwitchWrapper data-e2e="survey-resident-only-switch">
            <TextH6>{'Ankieta widoczna tylko dla rezydentów'}</TextH6>
            <AvailabilitySwitch
              checked={residentOnly}
              checkedText={`${t('survey:resident_only')}`}
              uncheckedText={`${t('survey:for_all')}`}
              onChange={() => setResidentOnly(!residentOnly)}
            />
          </SwitchWrapper>
          <DrawerButtons
            handleAccept={handleSubmit(onSubmit)}
            handleCancel={closeDetails}
          />
          {(!!Object.keys(errors).length || error) && (
            <TextError>{!error ? t('form:form_error') : error}</TextError>
          )}
        </>
      </ClickAwayListener>
      <BaseModal
        confirmText={'btn:exit'}
        open={openClickAwayModal}
        handleClose={(e) => handleCloseModal(e)}
        handleAccept={() => closeDetails()}
      >
        <ModalContent>{t('generic:exit_without_saving')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default AddSurvey

const SwitchWrapper = styled.div`
  padding: 0 16px;
`
