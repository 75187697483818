import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import styled from 'styled-components'
import PayuPayoutCompanySelect from '../../components/PayU/PayuPayoutCompanySelect'
import { useForm } from 'react-hook-form'
import {
  PAYU_BALANCE,
  IPayuBalanceData,
  IPayuBalanceVars,
} from '../../graphql/payU/payuBalance'
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import PayoutForm from '../../components/PayU/PayoutForm'
import { BaseButton } from '../../components/Shared/BaseButton'
import { TextBody2Medium, TextError } from '../../components/Styles/TextCustom'
import { formatPrice } from '../../utils/formatPrice'
import {
  PAYU_PAYOUT,
  IPayuPayoutData,
  IPayuPayoutVars,
} from '../../graphql/payU/payuPayout'
import { toast } from 'react-toastify'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'

type FormData = {
  amount: string
  description: string
}

const PayuPayout = () => {
  const { t } = useTranslation(['payu'])
  const [company, setCompany] = useState('')
  const [balanceErrorMessage, setBalanceErrorMessage] = useState('')
  const [payoutErrorMessage, setPayoutErrorMessage] = useState('')
  const successNotify = (message: string) => toast.dark(message)
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>()

  const getBalanceCompleted = (data: IPayuBalanceData) => {
    const { error, code, status } = data.payuBalance
    status && setBalanceErrorMessage('')
    error &&
      setBalanceErrorMessage(
        t(`code:${code}`) === `${code}` ? `${error}` : t(`code:${code}`)
      )
  }

  const onCompleted = (data: IPayuPayoutData) => {
    const { status, error, code } = data.payuPayout
    if (status) {
      setPayoutErrorMessage('')
      successNotify(`${t('payout_success')}`)
      refetch()
      reset()
    } else
      error &&
        setPayoutErrorMessage(
          t(`code:${code}`) === `${code}` ? `${error}` : t(`code:${code}`)
        )
  }

  const [getPayuBalance, { data, loading, refetch }] = useLazyQuery<
    IPayuBalanceData,
    IPayuBalanceVars
  >(PAYU_BALANCE, {
    onCompleted: getBalanceCompleted,
    onError: (error: ApolloError) => setBalanceErrorMessage(error.message),
  })

  const [payuPayout, { loading: payoutLoading }] = useMutation<
    IPayuPayoutData,
    IPayuPayoutVars
  >(PAYU_PAYOUT, {
    onCompleted,
    onError: (error: ApolloError) => setPayoutErrorMessage(error.message),
  })

  useEffect(() => {
    !!company && getPayuBalance({ variables: { company } })
  }, [company])

  const onSubmit = (data: FormData) => {
    const { amount, description } = data
    const formatted = Number(amount) * 100

    payuPayout({
      variables: {
        company,
        amount: Math.round(formatted),
        description,
      },
    })
  }

  if (loading || payoutLoading) {
    return <FullScreenLoader />
  }

  return (
    <Grid>
      <TypographyStyled variant="h6">{t('payu_payout')}</TypographyStyled>
      <FormWrapper>
        <PayuPayoutCompanySelect value={company} setValue={setCompany} />
        {balanceErrorMessage && <TextError>{balanceErrorMessage}</TextError>}
        {company && !!data?.payuBalance.availableAmount && (
          <>
            <BalanceWrapper>
              <TextBody2Medium>{`${t('balance')}: ${formatPrice(
                data.payuBalance.availableAmount
              )}`}</TextBody2Medium>
            </BalanceWrapper>
            <PayoutForm
              control={control}
              errors={errors}
              balance={data.payuBalance.availableAmount}
            />
            <BaseButton onClick={handleSubmit(onSubmit)}>
              {t('payout')}
            </BaseButton>
            {payoutErrorMessage && <TextError>{payoutErrorMessage}</TextError>}
          </>
        )}
      </FormWrapper>
    </Grid>
  )
}

export default PayuPayout

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
const FormWrapper = styled(Grid)`
  max-width: 600px;
  margin-top: 16px;
`
const BalanceWrapper = styled(Grid)`
  padding: 16px;
`
