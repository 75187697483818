import React, { useCallback } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import {
  UPDATE_EXTRAS_VISIBILITY,
  IUpdateExtrasVars,
} from '../../graphql/extras/updateExtrasVisibility'
import { Grid } from '@mui/material'
import AvailabilitySwitch from '../Shared/AvailabilitySwitch'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { toast } from 'react-toastify'

const ExtrasVisibility = ({
  id,
  visible,
  refetch,
}: {
  id: string
  visible: boolean
  refetch: () => void
}) => {
  const errorNotify = (message: string) => toast.error(message)

  const [updateExtrasVisibility, { loading }] = useMutation<IUpdateExtrasVars>(
    UPDATE_EXTRAS_VISIBILITY,
    {
      onCompleted: () => refetch(),
      onError: (error: ApolloError) => errorNotify(error.message),
    }
  )

  const handleVisibilityChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target
      updateExtrasVisibility({
        variables: {
          id,
          visible: checked,
        },
      })
    },
    [id, updateExtrasVisibility]
  )

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <Grid>
      <AvailabilitySwitch
        checked={visible}
        onChange={handleVisibilityChange}
        checkedText={'active'}
        uncheckedText={'inactive'}
      />
    </Grid>
  )
}

export default ExtrasVisibility
