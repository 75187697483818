import React from 'react'
import { useTranslation } from 'react-i18next'
import { IFormError } from '../../types/FormTypes'
import Input from '../Shared/Input'
import { EMAIL_REGEX, LOGIN_REGEX } from '../../const/regex'
import styled from 'styled-components'

const SignUpInputs: React.FC<{ control: any; errors: IFormError | any }> = ({
  control,
  errors,
}) => {
  const { t } = useTranslation(['form'])

  return (
    <Container>
      <Input
        label={t('first_name')}
        name="firstName"
        control={control}
        errors={errors}
        rules={{ required: String(t('field_required')) }}
      />
      <Input
        label={t('last_name')}
        name="lastName"
        control={control}
        errors={errors}
        rules={{ required: String(t('field_required')) }}
      />
      <Input
        label={t('private_or_work_email')}
        name="email"
        control={control}
        errors={errors}
        rules={{
          required: String(t('field_required')),
          pattern: {
            value: EMAIL_REGEX,
            message: t('valid_email'),
          },
        }}
        message={t('email_msg')}
      />
      <Input
        label={t('login')}
        name="login"
        control={control}
        errors={errors}
        rules={{
          required: String(t('field_required')),
          pattern: {
            value: LOGIN_REGEX,
            message: t('valid_login'),
          },
          minLength: {
            value: 2,
            message: t('login_too_short'),
          },
        }}
        message={t('login_msg')}
      />
      <Input
        label={t('password')}
        name="password"
        control={control}
        errors={errors}
        rules={{
          required: String(t('field_required')),
          minLength: {
            value: 6,
            message: t('password_length'),
          },
        }}
        message={t('password_msg')}
      />
    </Container>
  )
}

export default SignUpInputs

const Container = styled.div`
  width: 500px;
`
