import { gql } from '@apollo/client'
import { IFormRegulation } from '../../types/FormTypes'

export interface IAcceptance {
  decision: boolean
  id: string
  regulation: IFormRegulation
}

export interface IGetMyAcceptancesData {
  acceptances: {
    acceptances: IAcceptance[]
  }
  acceptances2: {
    acceptances: IAcceptance[]
  }
}

export const GET_MY_ACCEPTANCES = gql`
  query getMyAcceptances {
    acceptances: getMyAcceptances(servicename: "olivia") {
      status
      code
      errors {
        code
        message
      }
      acceptances {
        decision
        id
        regulation {
          id
          title
        }
      }
    }
    acceptances2: getMyAcceptances(servicename: "mysolvy") {
      status
      code
      errors {
        code
        message
      }
      acceptances {
        decision
        id
        regulation {
          id
          title
        }
      }
    }
  }
`
