import { FC } from 'react'
import styled from 'styled-components'
import { themeColors } from '../../const/colors'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

const NewTabLink: FC<{ path: string; text: string }> = ({ path, text }) => {
  return (
    <LinkStyled data-e2e="link-button" to={path} target="_blank">
      {text}{' '}
      <FontAwesomeIcon
        icon={faExternalLinkAlt as IconProp}
        size="1x"
        color={themeColors.primary}
      />
    </LinkStyled>
  )
}

export default NewTabLink

const LinkStyled = styled(Link)`
  text-decoration: none;
  margin: 4px 0;
  text-transform: uppercase;
  letter-spacing: 1.25;
  font-weight: 600;
  color: ${themeColors.primary};
`
