import Input from '../Shared/Input'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { InputAdornment, IconButton, Tooltip } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import DomainChip from './DomainChip'

type DomainForm = {
  domain: string
}
const EditTenantDomains = ({
  tenantDomains,
  domainsToUnassign,
  domainsToAssign,
  setTenantDomains,
  setDomainsToUnassign,
  setDomainsToAssign,
}: {
  tenantDomains: string[]
  domainsToUnassign: string[]
  domainsToAssign: string[]
  setTenantDomains: (v: string[]) => void
  setDomainsToUnassign: (v: string[]) => void
  setDomainsToAssign: (v: string[]) => void
}) => {
  const { t } = useTranslation(['form', 'btn', 'tenant', 'generic'])

  const {
    control: domainControl,
    handleSubmit: submitDomain,
    formState: { errors: domainErrors },
    setValue,
  } = useForm<DomainForm>()

  const addDomainToAssign = (data: DomainForm) => {
    const { domain } = data
    if (!!domain) {
      setDomainsToAssign([...domainsToAssign, domain])
      setTenantDomains([...tenantDomains, domain])
      setValue('domain', '')
    }
  }

  const handleDeleteDomain = (domainToDelete: string) => {
    if (domainsToAssign.find((domain) => domain === domainToDelete)) {
      setDomainsToAssign(
        domainsToAssign.filter((chip) => chip !== domainToDelete)
      )
    } else {
      setDomainsToUnassign([...domainsToUnassign, domainToDelete])
    }
    setTenantDomains(tenantDomains.filter((chip) => chip !== domainToDelete))
  }
  return (
    <>
      {!!tenantDomains.length && (
        <>
          <Text>{t('tenant:tenant_domains')}</Text>
          <ChipsContainer>
            {tenantDomains.map((item) => (
              <DomainChip
                key={item}
                domain={item}
                handleDeleteDomain={handleDeleteDomain}
              />
            ))}
          </ChipsContainer>
        </>
      )}
      <Text>{t('tenant:add_domain')}</Text>
      <DomainInput
        data-e2e="domain-input"
        label={t('domain_name')}
        name="domain"
        control={domainControl}
        errors={domainErrors}
        defaultValue={''}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={`${t('btn:add_domain')}`}>
                <IconButton
                  onClick={submitDomain(addDomainToAssign)}
                  edge="end"
                  color="primary"
                  size="large"
                  data-e2e="add-domain-button"
                >
                  <FontAwesomeIcon icon={faPlusCircle as IconProp} size="lg" />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </>
  )
}

export default EditTenantDomains

const DomainInput = styled(Input)`
  margin-top: 16px;
`
const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 16px 0 0;
  box-shadow: none;
  background-color: transparent;
`
const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  margin: 16px 0 0;
`
