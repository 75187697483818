import { themeColors } from './colors'
import { createTheme } from '@mui/material/styles'
import { plPL, enUS } from '@mui/material/locale'
import i18n from 'i18next'

const lang = i18n.language === 'pl' ? plPL : enUS

export const theme = createTheme(
  {
    typography: {
      fontFamily: "'Montserrat', sans-serif",
    },
    palette: {
      primary: {
        main: themeColors.primary,
      },
      secondary: {
        main: themeColors.secondary,
      },
      error: {
        main: themeColors.error,
      },
    },
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            color: themeColors.gray,
            fontWeight: 600,
            selected: {
              color: themeColors.primary,
            },
          },
        },
      },
    },
  },
  lang
)
