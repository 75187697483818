import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from '../Styles/TextCustom'
import styled from 'styled-components'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import { Grid } from '@mui/material'
import { themeColors } from '../../const/colors'

const UserParkingData: FC<{ carPlates: { name: string }[] }> = ({
  carPlates,
}) => {
  const { t } = useTranslation(['user_details'])

  return (
    <BlueContainer>
      <Title>Parking:</Title>
      <Text>{`${t('user_parking')}: `}</Text>
      <TextBold>
        {carPlates.length
          ? `${carPlates.map(({ name }) => name).join(', ')}`
          : 'Brak'}
      </TextBold>
    </BlueContainer>
  )
}

export default UserParkingData
const Text = styled(TextBody2Medium)`
  display: inline;
`
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
const BlueContainer = styled(Grid)`
  background-color: ${themeColors.lightBlue};
  border-radius: 20px;
  padding: 20px;
  margin-top: 16px;
`
