import { useState, useEffect } from 'react'
import { Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const DietTypeRadio = ({
  selectedDietType,
  handleChangeValue,
}: {
  selectedDietType?: string
  handleChangeValue: (value: string) => void
}) => {
  const [value, setValue] = useState<string>('none')
  const { t } = useTranslation(['menu_item_form'])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value)
    handleChangeValue((event.target as HTMLInputElement).value)
  }

  useEffect(() => {
    selectedDietType && setValue(selectedDietType)
  }, [selectedDietType])

  return (
    <FormControl component="fieldset">
      <RadioGroup
        value={value}
        onChange={handleChange}
        data-e2e="diet-type-radio"
      >
        <LabelStyled
          value="none"
          control={<Radio color="primary" />}
          label={`${t('none')}`}
        />
        <LabelStyled
          value="vege"
          control={<Radio color="primary" />}
          label={`${t('vege')}`}
        />
        <LabelStyled
          value="vegan"
          control={<Radio color="primary" />}
          label={`${t('vegan')}`}
        />
      </RadioGroup>
    </FormControl>
  )
}

export default DietTypeRadio

const LabelStyled = styled(FormControlLabel)`
  font-size: 16px;
`
