import { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2localeString,
  date2isoString,
  date2isoStringWithTimezone,
} from '../../utils/formatDate'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import { ApolloError, useLazyQuery, useQuery } from '@apollo/client'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import DateRangeParkingOccupancyFilter from '../../components/Parking/DateRangeParkingOccupancyFilter'
import {
  STAT_PARKING_POOL_DAILY_USAGE,
  IStatParkingPoolDailyUsageData,
  IStatParkingPoolDailyUsageVars,
} from '../../graphql/parking/statParkingPoolDailyUsage'
import {
  QUERY_TENANT_PARKING_POOL,
  IQueryTenantParkingPoolData,
} from '../../graphql/tenant/queryTenantParkingPool'
import ErrorContainer from '../../components/Shared/ErrorContainer'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface Row {
  date: string
  tenant: string
  parking: string
  capacity: number
  sold: number
  maxUserCount?: number
  placesCount?: number
  maxPlacesCount?: number
}

const now = new Date(new Date().setHours(0, 0, 0, 0))
const start = new Date(now.setDate(now.getDate() - 30))
const end = new Date()

const ParkingPoolOccupancyReport = () => {
  const { t, i18n } = useTranslation(['parking_pool_occupancy_report'])
  const lang = i18n.language
  const [rangeFrom, setRangeFrom] = useState<Date | null>(start)
  const [rangeTo, setRangeTo] = useState<Date | null>(end)
  const [error, setError] = useState('')

  const { data: tenants, loading: tenantsLoading } =
    useQuery<IQueryTenantParkingPoolData>(QUERY_TENANT_PARKING_POOL, {
      fetchPolicy: 'no-cache',
    })

  const [parkingPoolDailyUsage, { data, loading }] = useLazyQuery<
    IStatParkingPoolDailyUsageData,
    IStatParkingPoolDailyUsageVars
  >(STAT_PARKING_POOL_DAILY_USAGE, {
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => {
      setError(error.message)
    },
  })

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        accessorKey: 'date',
        header: t('date'),
        cell: (value) =>
          !!value &&
          dateIso2localeString(value.getValue(), lang, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'tenant',
        header: t('tenant'),
      },
      {
        accessorKey: 'parking',
        header: 'Parking',
      },
      {
        accessorKey: 'sold',
        header: t('sold'),
      },
      {
        accessorKey: 'placesCount',
        header: t('places_count'),
      },
      {
        accessorKey: 'maxPlacesCount',
        header: t('max_places_count'),
      },
      {
        accessorKey: 'capacity',
        header: t('capacity'),
      },
      {
        accessorKey: 'maxUserCount',
        header: t('max_user_count'),
      },
    ],
    [t, lang]
  )

  const tableData: Row[] = useMemo(() => {
    return (
      data?.statParkingPoolDailyUsage?.stats?.map(
        ({ date, tenant, parkingName, capacity, sold, used }) => ({
          date,
          tenant,
          parking: parkingName,
          capacity,
          sold,
          used,
          maxUserCount: tenants?.queryTenant.find(({ name }) => name === tenant)
            ?.parkingPool?.maxActiveUserCount,
          maxPlacesCount: tenants?.queryTenant.find(
            ({ name }) => name === tenant
          )?.parkingPool?.placesCountContract,
          placesCount: tenants?.queryTenant.find(({ name }) => name === tenant)
            ?.parkingPool?.placesAggregate.count,
        })
      ) || []
    )
  }, [data, tenants])

  useEffect(() => {
    if (rangeFrom && rangeTo) {
      parkingPoolDailyUsage({
        variables: {
          tenant: '*',
          parkingName: '',
          from: date2isoStringWithTimezone(new Date(rangeFrom)),
          to: date2isoString(new Date(rangeTo)),
        },
      })
    }
  }, [rangeFrom, rangeTo])

  if (loading || tenantsLoading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {t('admin_menu:parking_pool_occupancy_report')}
      </TypographyStyled>
      <Table
        columns={columns}
        data={tableData}
        enableRowSelection
        csvExport
        sortByKey="date"
        sortDesc
      >
        <DateRangeParkingOccupancyFilter
          rangeFrom={rangeFrom}
          rangeTo={rangeTo}
          setRangeFrom={setRangeFrom}
          setRangeTo={setRangeTo}
        />
      </Table>
      {!!error && <ErrorContainer errorMessage={error} />}
    </>
  )
}

export default ParkingPoolOccupancyReport

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
