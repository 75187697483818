import gql from 'graphql-tag'

export type BannerTargetType = 'internal' | 'external'

export interface IBanner {
  id: string
  name: string
  url: string
  urlEn: string
  src: string
  srcEn: string
  visible: boolean
  type: BannerTargetType
  residentOnly: boolean
  preview: boolean
}

export interface IQueryBannerData {
  queryBanner: IBanner[]
}

export const QUERY_BANNER = gql`
  query queryBanner {
    queryBanner {
      id
      name
      url
      urlEn: url_en
      src
      srcEn: src_en
      visible
      type
      residentOnly: resident_only
      preview
    }
  }
`
