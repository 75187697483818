import { gql } from '@apollo/client'

interface IFeature {
  name: string
  description?: string
}

export interface IQueryFeaturesData {
  enabledFeatures: IFeature[]
}

export const QUERY_FEATURES = gql`
  query features {
    enabledFeatures {
      name
    }
  }
`
