import { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  RESCHEDULE_PARKING_SUBSCRIPTION,
  IRescheduleParkingSubscriptionVars,
  IRescheduleParkingSubscriptionData,
} from '../../graphql/parking/rescheduleParkingSubscription'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { date2isoString } from '../../utils/formatDate'
import ErrorContainer from '../Shared/ErrorContainer'
import { FormData } from './SubscriptionActions'
import { BaseTextButton } from '../Shared/BaseButton'
import { themeColors } from '../../const/colors'
import { TextBody2 } from '../Styles/TextCustom'

const AmanoSync = ({
  id,
  refetch,
  handleSubmit,
  allowSync,
}: {
  id: string
  refetch: () => void
  handleSubmit: any
  allowSync: boolean
}) => {
  const { t } = useTranslation(['sold_parking_items'])
  const successNotify = (message: string) => toast.dark(message)
  const [error, setError] = useState('')

  const onRescheduleCompleted = (data: IRescheduleParkingSubscriptionData) => {
    const { status, error } = data.rescheduleParkingSubscription
    if (status) {
      successNotify(`${t('reschedule_success')}`)
      refetch()
    } else {
      error && setError(error)
    }
  }

  const [rescheduleSubscription] = useMutation<
    IRescheduleParkingSubscriptionData,
    IRescheduleParkingSubscriptionVars
  >(RESCHEDULE_PARKING_SUBSCRIPTION, {
    onCompleted: onRescheduleCompleted,
    fetchPolicy: 'no-cache',
  })

  const handleRescheduleSubscription = (data: FormData) => {
    const { comment, date } = data
    setError('')
    const convertDate = new Date(date)
    const syncDate = date
      ? new Date(convertDate.setHours(convertDate.getHours() + 1))
      : null

    rescheduleSubscription({
      variables: {
        id,
        comment,
        date: syncDate ? date2isoString(syncDate) : undefined,
      },
    })
  }

  return (
    <>
      <BaseTextButton
        color={!allowSync ? themeColors.gray : themeColors.primary}
        onClick={handleSubmit(handleRescheduleSubscription)}
        disabled={!allowSync}
      >
        {t('sync_with_amano')}
      </BaseTextButton>
      {!allowSync && (
        <HintText color={themeColors.gray}>{t('sync_hint')}</HintText>
      )}

      {!!error && <ErrorContainer errorMessage={error} />}
    </>
  )
}

export default AmanoSync

const HintText = styled(TextBody2)`
  text-align: center;
`
