import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormError } from '../../types/FormTypes'
import UnitSelect from './UnitSelect'
import CategorySelect from './CategorySelect'
import { Grid, InputAdornment } from '@mui/material'
import styled from 'styled-components'
import Input from '../Shared/Input'
import { TextH6, DrawerSubTitle } from '../Styles/TextCustom'

const MenuItemInputs: FC<{
  control: any
  errors: IFormError | any
  menuItem?: any
}> = ({ control, errors, menuItem }) => {
  const { t } = useTranslation(['menu_item_form', 'form'])

  return (
    <>
      <InputStyled
        label={t('dish_name')}
        name={'name'}
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        defaultValue={menuItem?.namePl || ''}
        data-e2e="gastro-add-dish-input-name-pl"
      />
      <InputStyled
        label={t('description')}
        name={'descriptionPl'}
        control={control}
        errors={errors}
        multiline
        defaultValue={menuItem?.descriptionPl || ''}
        data-e2e="gastro-add-dish-input-description-pl"
      />
      <DrawerSubTitle>{t('english_version')}</DrawerSubTitle>
      <InputStyled
        label={t('dish_name')}
        name={'nameEn'}
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        defaultValue={menuItem?.nameEn || ''}
        data-e2e="gastro-add-dish-input-name-en"
      />
      <InputStyled
        label={t('description')}
        name={'descriptionEn'}
        control={control}
        errors={errors}
        multiline
        defaultValue={menuItem?.descriptionEn || ''}
        data-e2e="gastro-add-dish-input-description-en"
      />
      <ShortInput
        label={t('price')}
        name={'price'}
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">zł</InputAdornment>,
        }}
        defaultValue={
          menuItem?.rawPrice
            ? ((menuItem?.rawPrice as number) * 0.01).toFixed(2)
            : ''
        }
        data-e2e="gastro-add-dish-input-price"
      />
      <Row container>
        <Grid xs={5} item>
          <ShortInput
            label={t('amount')}
            name={'amount'}
            control={control}
            errors={errors}
            rules={{ required: `${t('form:field_required')}` }}
            type="number"
            defaultValue={menuItem?.amount || ''}
            data-e2e="gastro-add-dish-input-quantity"
          />
        </Grid>
        <Grid xs={4} item data-e2e="gastro-add-dish-select-unit">
          <ShortSelect
            name={'unit'}
            control={control}
            errors={errors}
            rules={{ required: `${t('form:field_required')}` }}
            isValid={!errors.unit}
            unit={menuItem && { label: menuItem.unit, value: menuItem.unit }}
          />
        </Grid>
      </Row>
      <TextH6>{t('dish_category')}</TextH6>
      <CategorySelectorContainer data-e2e="gastro-add-dish-select-category">
        <CategorySelect
          name={'category'}
          control={control}
          errors={errors}
          rules={{ required: `${t('form:field_required')}` }}
          isValid={!errors.unit}
          category={
            menuItem && {
              label: menuItem.categoryTitle,
              value: menuItem.categoryId,
            }
          }
        />
      </CategorySelectorContainer>
    </>
  )
}

export default MenuItemInputs

const InputStyled = styled(Input)`
  max-width: 500px;
`
const CategorySelectorContainer = styled.div`
  max-width: 500px;
`
const ShortInput = styled(Input)`
  max-width: 170px;
  display: inline-block;
`
const ShortSelect = styled(UnitSelect)`
  max-width: 120px;
`
const Row = styled(Grid)`
  padding: 0;
  max-width: 500px;
  flex-wrap: nowrap;
`
