import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'
export interface IDomainCreateVars {
  name: string
}
export interface IDomainCreateData {
  domainCreate: IQueryBasicData
}

export const DOMAIN_CREATE = gql`
  mutation DomainCreate($name: String!) {
    domainCreate(name: $name) {
      status
      code
      message
      error
    }
  }
`
