import gql from 'graphql-tag'
import { IBanner, BannerTargetType } from './queryBanner'

export interface IAddBannerVars {
  name: string
  url: string
  urlEn: string
  src: string
  srcEn: string
  visible: boolean
  type: BannerTargetType
  residentOnly: boolean
}

export interface IAddBannerData {
  addBanner: { banner: IBanner }
}

export const ADD_BANNER = gql`
  mutation addBanner(
    $name: String!
    $url: String!
    $urlEn: String
    $src: String!
    $srcEn: String
    $visible: Boolean!
    $type: BannerTargetType!
    $residentOnly: Boolean
  ) {
    addBanner(
      input: {
        name: $name
        url: $url
        url_en: $urlEn
        src: $src
        src_en: $srcEn
        visible: $visible
        type: $type
        resident_only: $residentOnly
      }
    ) {
      banner {
        id
        name
        url
        src
        visible
        type
      }
    }
  }
`
