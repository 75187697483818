import React, { useState } from 'react'
import { Typography, Tab } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import MessagesTable from '../../components/UserMessages/MessagesTable'
import {
  BadgeStyled,
  TabsStyled,
} from '../../components/Styles/CustomElementsStyled'

const UsersMessageList = ({
  userMessagesCount,
  updateAggregatedCount,
}: {
  userMessagesCount?: number
  updateAggregatedCount: () => void
}) => {
  const [value, setValue] = useState(0)
  const { t } = useTranslation(['tabs', 'admin_menu'])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <BadgeStyled badgeContent={userMessagesCount} color="error">
        <TypographyStyled variant="h6">
          {t('admin_menu:messages')}
        </TypographyStyled>
      </BadgeStyled>
      <TabsStyled value={value} onChange={handleChange} textColor="primary">
        <Tab label={t('new')} data-e2e="messages-tab-open" />
        <Tab label={t('closed')} data-e2e="messages-tab-close" />
      </TabsStyled>
      <MessagesTable
        tab={value}
        updateAggregatedCount={updateAggregatedCount}
      />
    </>
  )
}

export default UsersMessageList
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
