import gql from 'graphql-tag'

export interface IQueryCompanyData {
  queryCompany: {
    name: string
    id: string
    nip: string
  }[]
}
export const QUERY_COMPANY = gql`
  query queryCompany {
    queryCompany {
      id
      name
      nip
    }
  }
`
