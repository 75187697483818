import { useState, FC, useEffect } from 'react'
import styled from 'styled-components'
import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Column } from '@tanstack/react-table'
import SimpleSelect from '../Shared/SimpleSelect'

const ColumnFilter: FC<{
  column: Column<any, unknown>
}> = ({ column }) => {
  const columnFilterValue = column.getFilterValue()
  const initialValue = (columnFilterValue ?? '') as string
  const [value, setValue] = useState(initialValue)
  //@ts-ignore
  const { filterVariant, options } = column.columnDef.meta ?? {}
  const { t } = useTranslation(['table_component'])

  const onChange = (value: string) => column.setFilterValue(value)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, 500)

    return () => clearTimeout(timeout)
  }, [value])

  if (filterVariant && filterVariant === 'select') {
    return (
      <SimpleSelect
        value={value}
        setValue={setValue}
        options={options}
        variant="standard"
      />
    )
  }

  return (
    <Input
      data-e2e={'column-filter'}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder={t('search')}
      variant="standard"
      size="small"
      color="primary"
    />
  )
}

const Input = styled(TextField)``
export default ColumnFilter
