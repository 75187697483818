import React from 'react'
import { useTranslation } from 'react-i18next'
import Input from '../../components/Shared/Input'
import styled from 'styled-components'
import { Grid } from '@mui/material'

interface IFormProps {
  errors: any
  control: any
  balance: number
}

const PayoutForm = (props: IFormProps) => {
  const { control, errors, balance } = props
  const { t } = useTranslation(['payu'])

  return (
    <Container>
      <Input
        label={t('amount')}
        name="amount"
        control={control}
        errors={errors}
        rules={{
          required: `${t('form:field_required')}`,
          max: {
            value: balance / 100,
            message: t('payu:amount_validation'),
          },
        }}
        type="number"
      />
      <Input
        label={t('description')}
        name="description"
        control={control}
        errors={errors}
        multiline
        rows={3}
        rules={{
          required: `${t('form:field_required')}`,
        }}
      />
    </Container>
  )
}

export default PayoutForm
const Container = styled(Grid)`
  padding: 16px 0;
`
