import { gql } from '@apollo/client'

export const QUERY_USER = gql`
  query queryUser {
    queryUser {
      login
      createdAt: created_at
      firstName: first_name
      lastName: last_name
      lastMobileApp: last_mobile_app
      lastSeen: last_seen
      apiVersion: api_version
      disabled
      email {
        email
      }
      workEmail: work_email {
        email
      }
      tenant {
        name
        id
        seos {
          disabled
          id
          prefix
        }
      }
      accessCard: access_card {
        number
        type
      }
      roles {
        name
      }
      features {
        name
      }
      carPlates: car_plates {
        name
      }
    }
  }
`
