import React, { useState } from 'react'
import styled from 'styled-components'
import { themeColors } from '../../const/colors'
import { useForm } from 'react-hook-form'
import { CenterContainer } from '../../components/Styles/CustomElementsStyled'
import { BaseButton } from '../../components/Shared/BaseButton'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import Input from '../../components/Shared/Input'
import { useDispatch } from 'react-redux'
import { UserActionTypes } from '../../redux/user/types/UserActionTypes'
import { ILoginData, ILoginVars, LOGIN_QUERY } from '../../graphql/auth/login'
import { ApolloError, useLazyQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { checkSignInRoles } from '../../utils/checkSignInRoles'
import ErrorContainer from '../../components/Shared/ErrorContainer'

type SignInFormData = {
  login: string
  password: string
}

const defaultValues = {
  login: '',
  password: '',
}

interface ICustomWindow extends Window {
  TAG?: string
}
declare var window: ICustomWindow

const AdminLogin = ({ prevRoute }: { prevRoute: string }) => {
  const { t } = useTranslation(['form', 'btn', 'login_screen', 'code'])
  const [errorMessage, setErrorMessage] = useState('')
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormData>({
    defaultValues,
  })
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const setRoles = (roles: { name: string }[]) => {
    dispatch({
      type: UserActionTypes.SET_USER_ROLES,
      isAdmin: roles.map((item) => item.name).includes('admin'),
      roles: roles.map((role) => role.name),
    })
  }

  const onLoginCompleted = (data: ILoginData) => {
    if (data?.login.status) {
      const { accessToken, refreshToken } = data.login
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('refreshToken', refreshToken)

      if (checkSignInRoles(data?.login.user.roles.map((item) => item.name))) {
        setRoles(data?.login.user.roles)
        !!prevRoute ? navigate(prevRoute) : navigate('/admin')
      } else setErrorMessage(t('code:1028'))
    } else setErrorMessage(t(`code:${data.login.code}`))
  }

  const [loginUser, { loading }] = useLazyQuery<ILoginData, ILoginVars>(
    LOGIN_QUERY,
    {
      onCompleted: onLoginCompleted,
      onError: (error: ApolloError) => setErrorMessage(error.message),
      fetchPolicy: 'no-cache',
    }
  )

  const handleLogin = (formData: SignInFormData) => {
    const login = formData.login.trim()
    loginUser({
      variables: {
        login,
        password: formData.password,
        app: `${window.TAG || 'local'}`,
        apiVersion: 36,
        service: 'olivia',
      },
    })
  }

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <CenterContainer>
      <BlueContainer>
        <Logo>
          {'My'}&nbsp;
          <Color>Olivia</Color>
        </Logo>
        <FormContainer onSubmit={handleSubmit(handleLogin)}>
          <Input
            label={t('email_login')}
            name="login"
            autoCompleteType="email"
            control={control}
            errors={errors}
            rules={{ required: `${t('field_required')}` }}
            autoCapitalize={'none'}
          />
          <Input
            label={t('password')}
            name="password"
            autoCompleteType="password"
            autoCapitalize={'none'}
            control={control}
            errors={errors}
            rules={{ required: `${t('field_required')}` }}
          />

          <ButtonStyled type="submit">{t('btn:log_in')}</ButtonStyled>
          {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
        </FormContainer>
      </BlueContainer>
    </CenterContainer>
  )
}

export default AdminLogin

const BlueContainer = styled.div`
  background-color: ${themeColors.lightBlue};
  border-radius: 30px;
  width: 566px;
  height: 474px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const FormContainer = styled.form`
  width: 328px;
`
const Logo = styled.h3`
  font-size: 44px;
  margin: 0 0 30px;
  letter-spacing: 0.75px;
  font-weight: 400;
`
const Color = styled.span`
  font-weight: 800;
  color: ${themeColors.secondary};
`
const ButtonStyled = styled(BaseButton)`
  margin-top: 20px;
`
