import React, { useEffect, useState } from 'react'
import { BaseButton } from '../components/Shared/BaseButton'
import { TextH4Bold, TextInfo } from '../components/Styles/TextCustom'
import { Img, CenterContainer } from '../components/Styles/CustomElementsStyled'
import image from '../assets/images/high-five.png'
import bottomWave from '../assets/images/wave-bottom.svg'
import topWave from '../assets/images/wave-top.svg'
import { useMutation, useLazyQuery, ApolloError } from '@apollo/client'
import { VERIFY_EMAIL, IVerifyEmailData } from '../graphql/auth/verifyEmail'
import { CONFIRM_EMAIL, IConfirmEmailData } from '../graphql/auth/confirmEmail'
import FullScreenLoader from '../components/Shared/FullScreenLoader'
import Error from '../components/PagesComponents/Error'
import ChangeEmailVerification from '../components/PagesComponents/ChangedEmailVerification'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const EmailVerificationPage = () => {
  const { t, i18n } = useTranslation([
    'btn',
    'error',
    'email_verification_page',
    'code',
  ])
  const [loading, setLoading] = useState(true)
  const [action, setAction] = useState<any>('')
  const [code, setCode] = useState<any>('')
  const [email, setEmail] = useState<any>('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isMobile, setIsMobile] = useState(false)

  const onVerifyEmailCompleted = (data: IVerifyEmailData) => {
    if (data?.verifyEmail.status) {
      confirmEmail({
        variables: { email, code },
      })
    } else {
      setErrorMessage(t(`code:${data.verifyEmail.code}`))
      setLoading(false)
    }
  }

  const onConfirmEmailCompleted = (data: IConfirmEmailData) => {
    if (data?.confirmEmail.status) {
      setLoading(false)
    } else {
      setErrorMessage(t(`code:${data.confirmEmail.code}`))
      setLoading(false)
    }
  }

  const [verifyEmail] = useLazyQuery(VERIFY_EMAIL, {
    onCompleted: onVerifyEmailCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })
  const [confirmEmail] = useMutation(CONFIRM_EMAIL, {
    onCompleted: onConfirmEmailCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  const checkDevice = () => {
    const isIos =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    const isAndroid = /(android)/i.test(navigator.userAgent)
    if (isIos || isAndroid) {
      setIsMobile(true)
    }
  }

  const openApp = () => {
    const appLinkPrefix = 'myolivia://login/'
    document.location.href = appLinkPrefix
    setTimeout(() => {
      setErrorMessage(t('error:no_app'))
    }, 1500)
  }

  useEffect(() => {
    checkDevice()
    const queryString = window.location.hash
    const params = queryString.split('?')
    const urlParams = new URLSearchParams(params[1])
    const email = urlParams.get('email')
    const code = urlParams.get('verification_code')
    const lang = urlParams.get('lang')
    if (lang !== 'none') {
      i18n.changeLanguage(lang || undefined)
    }
    setAction(urlParams.get('action'))
    setEmail(email)
    setCode(code)

    verifyEmail({
      variables: { email, code },
    })
  }, [i18n, verifyEmail])

  if (loading) {
    return <FullScreenLoader />
  }
  if (errorMessage) {
    return <Error errorMessage={errorMessage} title={true} />
  }

  return (
    <CenterContainerStyled>
      {action === 'update_contact_email' || action === 'update_work_email' ? (
        <ChangeEmailVerification />
      ) : (
        <>
          <TextH4Bold>{t('email_verification_page:success')}</TextH4Bold>
          <Img src={image} alt="img" />
        </>
      )}
      {isMobile ? (
        <BaseButton onClick={openApp}>{t('btn:go_to_app')}</BaseButton>
      ) : (
        <TextInfo>{t('email_verification_page:tip')}</TextInfo>
      )}
    </CenterContainerStyled>
  )
}

export default EmailVerificationPage

const CenterContainerStyled = styled(CenterContainer)`
  @media (max-width: 768px) {
    background-image: url(${bottomWave}), url(${topWave});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom, center top;
  }
`
