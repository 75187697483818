import React, { FC, useState } from 'react'
import { useAppSelector } from '../../redux/store'
import { List, Collapse, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import FAIcon from '../Shared/FAIcon'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../const/routes'
import { useTranslation } from 'react-i18next'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  MainListItem,
  ListItem,
  ListItemTextStyled,
  LinkStyled,
} from '../Styles/NavListStyle'

interface IEventsListItemProps {
  isMenuOpen?: boolean
}

const EventsListItem: FC<IEventsListItemProps> = ({ isMenuOpen }) => {
  const { t } = useTranslation(['admin_menu'])
  let { pathname } = useLocation()
  const [isEventsExpanded, setIsEventsExpanded] = useState(false)
  const { isAdmin } = useAppSelector((state) => state.user)

  const isSelected = (route: string, isNestedListExpanded: boolean = true) =>
    !isMenuOpen || !isNestedListExpanded
      ? pathname.includes(route)
      : `/admin/${route}` === pathname

  if (!isAdmin) {
    return null
  }

  return (
    <>
      <MainListItem
        onClick={() => setIsEventsExpanded(!isEventsExpanded)}
        data-e2e="navigation-events"
      >
        <ListItemIcon>
          <FAIcon icon={faCalendar as IconProp} />
        </ListItemIcon>
        <ListItemTextStyled primary={t('events')} />
        {isEventsExpanded ? <ExpandLess /> : <ExpandMore />}
      </MainListItem>
      <Collapse in={isMenuOpen && isEventsExpanded} timeout="auto">
        <List component="div">
          <LinkStyled to={ROUTES.EVENT_TICKETS}>
            <ListItem
              selected={isSelected(ROUTES.EVENT_TICKETS, isEventsExpanded)}
              data-e2e="event-tickets"
            >
              <ListItemText primary={t('event_tickets')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.EVENTS_SALES_SYSTEM}>
            <ListItem
              selected={isSelected(
                ROUTES.EVENTS_SALES_SYSTEM,
                isEventsExpanded
              )}
              data-e2e="events-sales-system"
            >
              <ListItemText primary={t('event_sales_system')} />
            </ListItem>
          </LinkStyled>
          <LinkStyled to={ROUTES.EVENTS_REPORTS}>
            <ListItem
              selected={isSelected(ROUTES.EVENTS_REPORTS, isEventsExpanded)}
              data-e2e="events-reports"
            >
              <ListItemText primary={t('event_reports')} />
            </ListItem>
          </LinkStyled>
        </List>
      </Collapse>
    </>
  )
}

export default EventsListItem
