import { ApolloError, useMutation } from '@apollo/client'
import {
  UPDATE_BANNER_VISIBILITY,
  IUpdateBannerVisibilityVars,
} from '../../graphql/banner/updateBannerVisibility'
import React, { useCallback, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import AvailabilitySwitch from '../../components/Shared/AvailabilitySwitch'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { IBanner } from '../../graphql/banner/queryBanner'

const BannerVisibility = ({
  banners,
  bannerId,
  refetch,
}: {
  banners?: IBanner[]
  bannerId: string
  refetch: () => void
}) => {
  const [available, setAvailable] = useState<string[]>([])

  const [updateBannerVisibility, { loading: updateLoading }] =
    useMutation<IUpdateBannerVisibilityVars>(UPDATE_BANNER_VISIBILITY, {
      onCompleted: () => refetch(),
      onError: (error: ApolloError) => console.log(error.message),
    })

  const handleAvailabilityChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, name } = event.target
      const variables = {
        id: name,
        visible: checked,
      }
      console.log(variables)
      if (checked && !available.length) {
        checked && setAvailable((items) => items.concat([name]))
        updateBannerVisibility({ variables })
      } else if (!checked && available.length) {
        setAvailable((items) => items.filter((item) => item !== name))
        updateBannerVisibility({ variables })
      }
    },
    [available, updateBannerVisibility]
  )

  useEffect(() => {
    if (!!banners) {
      setAvailable(
        banners.filter((item: any) => item.visible).map((item: any) => item.id)
      )
    }
  }, [banners])

  if (updateLoading) {
    return <FullScreenLoader />
  }

  return (
    <Grid data-e2e="visibility-switch">
      <AvailabilitySwitch
        checked={available.includes(bannerId)}
        name={bannerId}
        onChange={handleAvailabilityChange}
        checkedText={'banner:active'}
        uncheckedText={'banner:inactive'}
      />
    </Grid>
  )
}

export default BannerVisibility
