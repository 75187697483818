import styled from 'styled-components'
import { themeColors } from '../../const/colors'

const TextColor = styled.p<{ color?: string }>`
  ${({ color }) => color && `color: ${color};`};
`

export const TextBody1 = styled(TextColor)`
  font-size: 16px;
  font-weight: 400;
`
export const TextBody1Bold = styled(TextBody1)`
  font-size: 16px;
  font-weight: 700;
`
export const TextBody1Medium = styled(TextBody1)`
  font-size: 16px;
  font-weight: 500;
`
export const TextBody2 = styled(TextColor)`
  font-size: 14px;
  font-weight: 400;
  flex-shrink: 1;
  margin: 0;
`
export const TextBody2Medium = styled(TextBody2)`
  font-weight: 500;
  letter-spacing: 0.25px;
`
export const TextBody2Bold = styled(TextBody2Medium)`
  line-height: 19px;
  font-weight: 700;
`
export const TextH4Bold = styled.h4`
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.75px;
  color: ${themeColors.black};
  text-align: center;
  margin: 0 0 30px;

  @media (min-width: 1025px) {
    font-size: 30px;
    line-height: 52.5px;
  }
`
export const TextH6 = styled(TextColor)`
  font-size: 20px;
  font-weight: 400;
`
export const TextH6Bold = styled(TextH6)`
  font-weight: 700;
`
export const TextButton = styled.p<{ color?: string }>`
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  color: ${(props) => props.color || themeColors.white};
  cursor: pointer;
`
export const TextError = styled.p`
  color: ${themeColors.error};
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.75px;
`
export const TextInfo = styled.p`
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.75px;
  text-align: center;
  @media (min-width: 700px) {
    font-size: 20px;
  }
`
export const Title = styled(TextBody1Bold)`
  margin: 0;
  letter-spacing: 0.5px;
`
export const InputMessage = styled(TextBody2)`
  padding-left: 16px;
  margin-top: 0;
  padding-top: 0;
`
export const LinkText = styled(TextBody1Bold)`
  color: ${themeColors.primary};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.25px;
`
export const CheckboxText = styled(TextBody1)`
  color: ${(props) => props.color || 'black'};
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.25px;
`
export const TextCaption = styled(TextColor)`
  font-size: 9px;
`

// menu item details

export const DrawerSubTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0ch.5px;
`
