import { useEffect, FC } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FullScreenLoader from '../Shared/FullScreenLoader'
import {
  IGetUserVars,
  IGetUsersData,
  GET_USER,
} from '../../graphql/users/getUser'
import BlockUser from '../UserDetails/BlockUser'
import { TextBody1Bold } from '../Styles/TextCustom'
import { themeColors } from '../../const/colors'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const BlockAccountFromMessage: FC<{
  login: string
}> = ({ login }) => {
  const { t } = useTranslation(['user_message_details'])

  const [getUser, { data, loading, refetch }] = useLazyQuery<
    IGetUsersData,
    IGetUserVars
  >(GET_USER, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    login && getUser({ variables: { login } })
  }, [login])

  if (loading || !data) {
    return <FullScreenLoader />
  }

  const user = data.getUser
  const disabled = data.getUser.disabled

  return (
    <>
      {disabled && (
        <Row>
          <TextStyled color={themeColors.negative}>
            {t('user_details:switch_lock_disabled')}
          </TextStyled>
          <FontAwesomeIcon
            icon={faCheck as IconProp}
            size="1x"
            color={themeColors.negative}
          />
        </Row>
      )}
      {!disabled && (
        <TextStyled color={themeColors.negative}>
          {t('delete_account')}
        </TextStyled>
      )}
      <BlockUser userData={user} refetch={refetch} />
    </>
  )
}

export default BlockAccountFromMessage

const TextStyled = styled(TextBody1Bold)`
  padding: 0 8px;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
