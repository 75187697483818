import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import {
  GET_ORDERS_REPORTS,
  IGetOrdersReportsVars,
  IGetOrdersReportsData,
} from '../../graphql/events/getOrdersReports'
import { Button } from '@mui/material'
import GetAppIcon from '@mui/icons-material/GetApp'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { TextBody1 } from '../../components/Styles/TextCustom'
import DateRangeFilter from '../../components/Table/DateRangeFilter'
import { date2isoString } from '../../utils/formatDate'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { useCSVDownloader } from 'react-papaparse'

const now = new Date()
const start = new Date(now.setDate(now.getDate() - 30))
const end = new Date()

const EventReports = () => {
  const { t } = useTranslation(['events'])
  const [fromDate, setFromDate] = useState<Date | null>(start)
  const [toDate, setToDate] = useState<Date | null>(end)
  const { CSVDownloader } = useCSVDownloader()
  const [getOrdersReport, { data, loading }] = useLazyQuery<
    IGetOrdersReportsData,
    IGetOrdersReportsVars
  >(GET_ORDERS_REPORTS, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (fromDate && toDate) {
      getOrdersReport({
        variables: {
          from: date2isoString(fromDate),
          to: date2isoString(toDate),
        },
      })
    }
  }, [fromDate, toDate])

  if (loading || !data) {
    return <FullScreenLoader />
  }
  return (
    <Container>
      <CSVContainer>
        <TypographyStyled variant="h6">{t('reports')}</TypographyStyled>
        <TextBody1>{t('event_order_report')}</TextBody1>
        <DateRangeFilter
          rangeFrom={fromDate}
          rangeTo={toDate}
          setRangeFrom={setFromDate}
          setRangeTo={setToDate}
        />
        <CSVDownloader
          filename={'event-orders'}
          bom={true}
          config={{
            delimiter: ';',
          }}
          data={data.getOrdersReports.csv}
        >
          <ButtonStyled
            color="primary"
            variant="outlined"
            size="large"
            startIcon={<GetAppIcon />}
          >
            {t('btn:download_csv')}
          </ButtonStyled>
        </CSVDownloader>
      </CSVContainer>
    </Container>
  )
}

export default EventReports

const CSVContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const ButtonStyled = styled(Button)`
  width: 210px;
  margin: 16px 0;
`
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
