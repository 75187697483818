import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import styled from 'styled-components'
import { dateIso2localeString } from '../../utils/formatDate'
import { ILockerGroupReservation } from '../../graphql/lockers/queryLockerGroupReservation'
import LockerNumbersContainer from '../LockerReservation/LockerNumbersContainer'

const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}

const GroupReservationProtocolDetails = ({
  reservation,
}: {
  reservation: ILockerGroupReservation
}) => {
  const { t, i18n } = useTranslation(['lockers'])
  const lang = i18n.language
  const {
    building,
    lockers,
    validFrom,
    validTo,
    tenant,
    tenantName,
    tenantEmail,
    comment,
  } = reservation

  const defaultTenant = tenant?.name ? tenant?.name : tenantName

  return (
    <Container>
      <LockerNumbersContainer building={building.name} lockers={lockers} />
      <TextWithPadding>{t('lease_term')}:</TextWithPadding>
      <TextBold>{`${dateIso2localeString(
        validFrom,
        lang,
        options
      )} - ${dateIso2localeString(validTo, lang, options)}`}</TextBold>
      <TextWithPadding>{t('tenant')}:</TextWithPadding>
      <TextBody2Bold>{defaultTenant}</TextBody2Bold>
      <TextBody2Bold>{tenantEmail}</TextBody2Bold>
      {comment && (
        <>
          <TextWithPadding>{t('notes')}:</TextWithPadding>
          <TextBold>{comment}</TextBold>
        </>
      )}
    </Container>
  )
}

export default GroupReservationProtocolDetails
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
const TextWithPadding = styled(TextBody2Medium)`
  padding: 16px 0;
`
const Container = styled(Grid)`
  max-width: 500px;
`
