import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IVerifyEmailVars {
  email: string
  code: string
}

export interface IVerifyEmailData {
  verifyEmail: IQueryBasicData & {
    user: {
      login: string
      email: { email: string }
      work_email: { email: string }
    }
  }
}

export const VERIFY_EMAIL = gql`
  query VerifyEmail($email: String!, $code: String!) {
    verifyEmail(email: $email, code: $code) {
      status
      message
      error
      code
      user {
        login
        email {
          email
        }
        work_email {
          email
        }
      }
    }
  }
`
