import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { themeColors } from '../../const/colors'
import { IconButton } from '@mui/material'
import Input from '../Shared/Input'
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useForm } from 'react-hook-form'
import {
  ADD_OLIVKA_REGEXP,
  IAddOlivkaRegexpVars,
} from '../../graphql/olivka/addOlivkaRegexp'
import { ApolloError, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { styled as styledMaterial } from '@mui/material/styles'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import ErrorContainer from '../Shared/ErrorContainer'

interface IAddRegexInputProps {
  name: string
  remove: () => void
  refetch: () => void
}

interface IFormData {
  value: string
}

const InputStyled = styledMaterial(Input)({
  width: 410,
  margin: '0 8px',
})

const AddRegexInput = (props: IAddRegexInputProps) => {
  const { name, remove, refetch } = props
  const [error, setError] = useState('')
  const { t } = useTranslation(['olivka'])
  const successNotify = (message: string) => toast.dark(message)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>()

  const onAddRegexCompleted = () => {
    successNotify(t('regex_saved'))
    refetch()
    remove()
  }

  const [addRegex] = useMutation<IAddOlivkaRegexpVars>(ADD_OLIVKA_REGEXP, {
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => setError(error.message),
    onCompleted: onAddRegexCompleted,
  })

  const onSubmit = (data: IFormData) => {
    const { value } = data
    addRegex({
      variables: {
        name,
        value,
      },
    })
  }

  return (
    <>
      <Row>
        <div>
          <InputStyled
            label={'Regex'}
            name={'value'}
            control={control}
            errors={errors}
            autoCapitalize={'none'}
            rules={{ required: `${t('form:field_required')}` }}
          />
        </div>
        <IconButtonStyled size="small" onClick={handleSubmit(onSubmit)}>
          <FontAwesomeIcon
            icon={faSave as IconProp}
            size="1x"
            color={themeColors.primary}
          />
        </IconButtonStyled>
        <IconButtonStyled size="small" onClick={remove}>
          <FontAwesomeIcon
            icon={faTrash as IconProp}
            size="1x"
            color={themeColors.error}
          />
        </IconButtonStyled>
      </Row>
      {error && <ErrorContainer errorMessage={error} />}
    </>
  )
}

export default AddRegexInput

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const IconButtonStyled = styled(IconButton)`
  margin: 16px 0 0;
  width: 30px;
  align-self: flex-start;
`
