import { FC, useCallback, useMemo, useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
  IQueryRestaurantMenuItemsData,
  QUERY_RESTAURANT_MENU_ITEMS,
} from '../../graphql/gastro/queryRestaurantMenuItems'
import { DELETE_MENU_ITEM } from '../../graphql/gastro/deleteMenuItem'
import { SET_MENU_ITEM_AVAILABILITY } from '../../graphql/gastro/setMenuItemAvailability'
import { useTranslation } from 'react-i18next'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { Grid, Alert } from '@mui/material'
import { themeColors } from '../../const/colors'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import EditMenuItem from './EditMenuItem'
import AvailabilitySwitch from '../Shared/AvailabilitySwitch'
import styled from 'styled-components'
import AddMenuItem from './AddMenuItem'
import BaseModal from '../Shared/BaseModal'
import { toast } from 'react-toastify'
import { ModalContent } from '../Styles/CustomElementsStyled'
import { dateIso2localeString } from '../../utils/formatDate'
import { formatPrice, formatToExcelPrice } from '../../utils/formatPrice'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'
import MenuItemAvailabilityCell from './MenuItemAvailabilityCell'
import MenuItemActionsCell from './MenuItemActionsCell'
interface Row {
  id: string
  name: string
  price: string
  priceCSV: string
  availability: string
  isAvailable: boolean
  dietType: string
  category: string
  amount: number
  unit: string
  availabilityDate: string
  visible: boolean
}

const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

const MenuTable: FC<{
  restaurant: any
  openedAddForm: boolean
  closeAddForm: () => void
}> = ({ restaurant, openedAddForm, closeAddForm }) => {
  const [available, setAvailable] = useState<string[]>([])
  const [openedDetails, setOpenedDetails] = useState<Row>()
  const [itemToDelete, setItemToDelete] = useState('')
  const successNotify = (message: string) => toast.dark(message)
  const { t, i18n } = useTranslation(['columns'])
  const lang = i18n.language

  const csvHeaders = [
    {
      label: t('dish_name'),
      key: 'name',
    },
    {
      label: `${t('price')} (PLN)`,
      key: 'priceCSV',
    },
    {
      label: t('availability'),
      key: 'isAvailable',
    },
    {
      label: t('diet_type'),
      key: 'dietType',
    },
    {
      label: t('category'),
      key: 'category',
    },
  ]

  const invisibleColumns = {
    priceCSV: false,
  }

  const onDeleteCompleted = () => {
    refetch()
    successNotify(`${t('menu_item_form:dish_deleted')}`)
  }

  const { data, refetch, loading } = useQuery<IQueryRestaurantMenuItemsData>(
    QUERY_RESTAURANT_MENU_ITEMS,
    {
      variables: {
        filter: { id: [restaurant] },
      },
      fetchPolicy: 'no-cache',
    }
  )

  const [updateMenuItem] = useMutation(SET_MENU_ITEM_AVAILABILITY, {
    onCompleted: () => refetch(),
  })

  const [deleteMenuItem] = useMutation(DELETE_MENU_ITEM, {
    onCompleted: onDeleteCompleted,
  })

  const handleDeleteMenuItem = (id: string) => {
    deleteMenuItem({
      variables: {
        id: [id],
      },
    })
    setItemToDelete('')
  }

  const handleAvailabilityChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, name } = event.target
      checked
        ? setAvailable((items) => items.concat([name]))
        : setAvailable((items) => items.filter((item) => item !== name))
      updateMenuItem({
        variables: {
          input: { filter: { id: name }, set: { visible: checked } },
        },
      })
    },
    [updateMenuItem]
  )

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: t('dish_name'),
        cell: (cellInfo) => (
          <NameCell onClick={() => setOpenedDetails(cellInfo.row.original)}>
            <ItemNameText>{cellInfo.row.original.name}</ItemNameText>
            <ItemAmountText>{`${cellInfo.row.original.amount} ${cellInfo.row.original.unit}`}</ItemAmountText>
          </NameCell>
        ),
      },
      {
        accessorKey: 'price',
        header: t('price'),
      },
      {
        accessorKey: 'priceCSV',
        header: t('price'),
      },
      {
        accessorKey: 'availability',
        header: t('visible'),
        cell: (cellInfo) => (
          <Grid>
            <AvailabilitySwitch
              checked={available.includes(cellInfo.row.original.id)}
              name={cellInfo.row.original.id}
              onChange={handleAvailabilityChange}
            />
          </Grid>
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'isAvailable',
        header: t('availability'),
        cell: (cellInfo) => (
          <MenuItemAvailabilityCell
            availabilityDate={cellInfo.row.original.availabilityDate}
            visible={cellInfo.row.original.visible}
            isAvailable={cellInfo.row.original.isAvailable}
          />
        ),
        meta: {
          filterVariant: 'select',
          options: [
            { label: 'Tak', value: true },
            { label: 'Nie', value: false },
          ],
        },
      },
      {
        accessorKey: 'dietType',
        header: t('diet_type'),
      },
      {
        accessorKey: 'category',
        header: t('category'),
      },
      {
        accessorKey: 'actions',
        header: '',
        cell: (cellInfo) => (
          <MenuItemActionsCell
            onClickDetails={() => setOpenedDetails(cellInfo.row.original)}
            onClickDelete={() => setItemToDelete(cellInfo.row.original.id)}
          />
        ),
        enableColumnFilter: false,
      },
    ],
    [t, handleAvailabilityChange, available]
  )

  const tableData: Row[] = useMemo(
    () =>
      data?.queryRestaurant[0]?.menuItems.map(
        ({
          id,
          namePl,
          nameEn,
          descriptionPl,
          descriptionEn,
          price,
          visible,
          dietType,
          category,
          amount,
          unit,
          availability,
          isAvailable,
        }) => {
          const availabilityDataDisplay = (data: any) => {
            const daysForSort: any = {
              Monday: 1,
              Tuesday: 2,
              Wednesday: 3,
              Thursday: 4,
              Friday: 5,
              Saturday: 6,
              Sunday: 7,
            }
            const date = data.find(
              (item: any) => Object.keys(item)[0] === 'date'
            )
            if (date) {
              return dateIso2localeString(date.date, lang, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
            } else {
              const weekdays = data
                .filter((item: any) => Object.keys(item)[0] === 'week_day')
                .map((item: any) => item.week_day)
                .sort((a: any, b: any) => {
                  return daysForSort[a] - daysForSort[b]
                })

              if (!!weekdays && weekdays.length) {
                return days.every((item: any) => weekdays.includes(item))
                  ? 'Menu stałe'
                  : weekdays.map((item: any) => t(`days:${item}`)).join(', ')
              }
            }
          }
          return {
            id,
            name: namePl,
            price: formatPrice(price),
            priceCSV: formatToExcelPrice(price),
            availability: visible
              ? t('menu_item_form:available')
              : t('menu_item_form:unavailable'),
            dietType: dietType ? t(`diet_types:${dietType}`) : '',
            category: lang === 'pl' ? category.titlePl : category.titleEn,
            visible,
            namePl,
            nameEn,
            descriptionPl,
            descriptionEn,
            rawPrice: price,
            unit: unit.name,
            amount,
            categoryId: category.id,
            categoryTitle: category.titlePl,
            diet: dietType,
            availabilityDate: availabilityDataDisplay(availability),
            rawAvailability: availability,
            isAvailable,
          }
        }
      ) || [],

    [data, t, lang]
  )

  useEffect(() => {
    if (!!data?.queryRestaurant[0]) {
      setAvailable(
        data.queryRestaurant[0].menuItems
          .filter((item) => item.visible)
          .map((item) => item.id)
      )
    }
  }, [data])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      {data?.queryRestaurant[0]?.visible !== undefined &&
        !data?.queryRestaurant[0].visible && (
          <AlertStyled severity="error">
            {t('gastro:invisible_restaurant')}
          </AlertStyled>
        )}

      <Table
        columns={columns}
        data={tableData}
        columnVisibility={invisibleColumns}
        enableRowSelection
        sortByKey="name"
        csvHeaders={csvHeaders}
        csvExport
      />
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        <EditMenuItem
          closeDetails={() => setOpenedDetails(undefined)}
          menuItem={openedDetails}
          updateList={refetch}
        />
      </BaseDrawer>
      <BaseDrawer open={openedAddForm} variant={'temporary'}>
        <AddMenuItem
          updateList={refetch}
          closeDrawer={closeAddForm}
          currentRestaurant={restaurant}
        />
      </BaseDrawer>
      <BaseModal
        confirmText={'btn:confirm'}
        open={!!itemToDelete}
        handleClose={() => setItemToDelete('')}
        handleAccept={() => handleDeleteMenuItem(itemToDelete)}
      >
        <ModalContent>{t('menu_item_form:delete_confirm_title')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default MenuTable

const ItemNameText = styled.p<{ color?: string }>`
  font-weight: 500;
  margin: 3px 0;
  ${({ color }) => color && `color: ${color};`};
`
const ItemAmountText = styled.p<{ color?: string }>`
  font-weight: 500;
  color: ${(props) => props.color || themeColors.gray};
  margin: 0 0;
`
const NameCell = styled(Grid)`
  cursor: pointer;
`
const AlertStyled = styled(Alert)`
  margin: 16px 0;
`
