import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import CssBaseline from '@mui/material/CssBaseline'
import { HashRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client/react'
import { apolloClient } from './utils/apollo/apolloClient'
import getEnv from './utils/env'
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles'
import StylesProvider from '@mui/styles/StylesProvider'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import './i18n'
import { theme } from './const/themes'
import * as Sentry from '@sentry/react'
import 'react-toastify/dist/ReactToastify.css'
import '@mui/styles'
import { Toast } from '../src/components/Shared/ToastContainer'
import 'dayjs/locale/pl'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

if (getEnv('ENV') === 'https://dgraph.app.oliviacentre.com/graphql') {
  Sentry.init({
    dsn: 'https://21036292284a44d69118e90c91d48920@o556357.ingest.sentry.io/5811781',
    integrations: [Sentry.browserTracingIntegration()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  // <React.StrictMode>
  <ApolloProvider client={apolloClient}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pl'}>
            <CssBaseline />
            <Provider store={store}>
              <HashRouter>
                <App />
                <Toast />
              </HashRouter>
            </Provider>
          </LocalizationProvider>
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </ApolloProvider>
  // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
