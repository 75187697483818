import styled from 'styled-components'
import {
  QUERY_PARKING,
  IQueryParkingData,
} from '../../graphql/parking/queryParking'
import { useQuery } from '@apollo/client'
import { FormControl, MenuItem, InputLabel } from '@mui/material'
import Select from '@mui/material/Select'

const ParkingSelect = ({
  value,
  setValue,
  disabled = false,
}: {
  value?: string
  setValue: (v: string) => void
  disabled?: boolean
}) => {
  const { data } = useQuery<IQueryParkingData>(QUERY_PARKING)

  const handleChange = (event: any) => {
    setValue(event.target.value as string)
  }

  return (
    <FormControl data-e2e="parking-select">
      <InputLabel id="select-label">{'Parking'}</InputLabel>
      <SelectStyled
        labelId="select-label"
        value={value}
        label="parking"
        onChange={handleChange}
        disabled={disabled}
      >
        {data?.queryParking.map(({ name }, i) => (
          <MenuItem key={i} value={name}>
            {name}
          </MenuItem>
        ))}
      </SelectStyled>
    </FormControl>
  )
}

export default ParkingSelect

const SelectStyled = styled(Select)`
  width: 174px;
`
