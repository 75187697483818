import { Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material'
import styled from 'styled-components'

type RadioOption = {
  label: string
  value: string
}

const RadioGroupForm = ({
  value,
  setValue,
  options,
  ...rest
}: {
  value: string
  setValue: (value: string) => void
  options: RadioOption[]
  [k: string]: any
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue((event.target as HTMLInputElement).value)

  return (
    <FormControl component="fieldset">
      <RadioGroup value={value} onChange={handleChange} {...rest}>
        {options.map(({ label, value }, i) => (
          <LabelStyled
            key={i}
            value={value}
            control={<Radio color="primary" />}
            label={label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default RadioGroupForm

const LabelStyled = styled(FormControlLabel)`
  font-size: 16px;
`
