import React from 'react'
import styled from 'styled-components'
import { CircularProgress } from '@mui/material'
import { themeColors } from '../../const/colors'

const Loader = () => {
  return <CustomProgress size={50} />
}

const CustomProgress = styled(CircularProgress)`
  color: ${themeColors.primary};
  margin: 16px;
`

export default Loader
