import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TextBody1Bold } from '../Styles/TextCustom'
import BuildingSelect from '../Shared/UncontrolledBuildingSelect'
import LockerTypePicker from '../Lockers/LockerTypePicker'
import { Grid } from '@mui/material'
import { IFormError } from '../../types/FormTypes'
import { ILocker, LockerType } from '../../graphql/lockers/queryLockers'
import { handleLockerAvailability } from '../../utils/getCurrentReservation'
import LockerReservationStatusSelect from '../LockerReservation/UncontrolledReservationStatusSelect'
import RentLockerInputs from './RentLockerInputs'
import { LockerReservationRow } from '../LockerReservation/LockerReservationTable'
import LockerNumbersContainer from '../LockerReservation/LockerNumbersContainer'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'

const RentManyLockersForm = ({
  selectedLockers,
  lockers,
  garageLockers,
  lockerroomLockers,
  setGarageLockers,
  setLockerroomLockers,
  control,
  errors,
  watch,
  building,
}: {
  selectedLockers: LockerReservationRow[]
  lockers: ILocker[]
  garageLockers: number
  lockerroomLockers: number
  setGarageLockers: (v: number) => void
  setLockerroomLockers: (v: number) => void
  control: Object
  errors: IFormError | any
  watch: any
  building: string
}) => {
  const { t } = useTranslation(['lockers'])
  const [availableInGarage, setAvailableInGarage] = useState(0)
  const [availableInLockerRoom, setAvailableInLockerRoom] = useState(0)

  const pickedBuilding = watch('building')

  useEffect(() => {
    if (!selectedLockers.length || !building) {
      let available = lockers.filter((item) =>
        handleLockerAvailability(item.status, item.reservations)
      )

      if (pickedBuilding) {
        available = available.filter(
          ({ building }) => building.name === pickedBuilding
        )
      }
      setAvailableInGarage(
        available.filter(({ type }) => type === LockerType.garage).length
      )
      setAvailableInLockerRoom(
        available.filter(({ type }) => type === LockerType.locker_room).length
      )
    }
  }, [pickedBuilding, lockers, selectedLockers, building])

  return (
    <>
      <DetailsWrapper>
        <BuildingSelect
          control={control}
          errors={errors}
          defaultValue={building}
          disabled={!!building}
        />
        <SelectWrapper>
          <LockerReservationStatusSelect control={control} errors={errors} />
        </SelectWrapper>
      </DetailsWrapper>
      {!!selectedLockers.length && !!building ? (
        <DetailsWrapper>
          <TextBody1Bold>{t('selected_lockers')}:</TextBody1Bold>
          <LockerNumbersContainer
            building={building}
            lockers={selectedLockers}
          />
        </DetailsWrapper>
      ) : (
        <DetailsWrapper>
          <TextBody1Bold>{t('available_lockers')}:</TextBody1Bold>
          <LockerTypePicker
            garageLockers={garageLockers}
            setGarageLockers={setGarageLockers}
            lockerroomLockers={lockerroomLockers}
            setLockerroomLockers={setLockerroomLockers}
            availableGarageLockers={availableInGarage}
            availableLockerRoomLockers={availableInLockerRoom}
          />
        </DetailsWrapper>
      )}

      <RentLockerInputs control={control} errors={errors} />
    </>
  )
}

export default RentManyLockersForm

const SelectWrapper = styled(Grid)`
  padding-top: 16px;
`
