import gql from 'graphql-tag'
import { LockerStatus } from './queryLockers'

export interface IChangeLockersStatusVars {
  ids: string
  oldStatus: LockerStatus
  newStatus: string
  comment: string
}

export const CHANGE_LOCKERS_STATUS = gql`
  mutation ChangeLockersStatus(
    $ids: String!
    $oldStatus: LockerStatus!
    $newStatus: LockerStatus!
    $comment: String
  ) {
    changeLockersStatus(
      ids: $ids
      old_status: $oldStatus
      new_status: $newStatus
      comment: $comment
    ) {
      status
      code
      error
      message
    }
  }
`
