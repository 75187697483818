import Select, { Props as SelectProps } from 'react-select'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'
import { themeColors } from '../../const/colors'
import { InputRules, IFormError } from '../../types/FormTypes'

export type OptionType = { value: string; label: string }

interface BaseSelectRHF<OptionType, IsMulti extends boolean>
  extends SelectProps<OptionType, IsMulti> {
  name: string
  control: any
  errors: IFormError | any
  rules?: InputRules
  isValid?: boolean
  [k: string]: any
}

interface BaseSelectControlled<OptionType, IsMulti extends boolean>
  extends SelectProps<OptionType, IsMulti> {
  errorMsg?: string
  isValid?: boolean
  defaultValue?: any
  [k: string]: any
}

const BaseSelect = <OptionType, IsMulti extends boolean = false>({
  name,
  control,
  rules,
  errors,
  errorMsg,
  isValid = true,
  isClearable = true,
  classNamePrefix = 'custom-select',
  defaultValue,
  ...rest
}:
  | BaseSelectRHF<OptionType, IsMulti>
  | BaseSelectControlled<OptionType, IsMulti>) => {
  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      padding: 10,
      fontWeight: 400,
      letterSpacing: 0.75,
      zIndex: 100,
      backgroundColor: state.isSelected
        ? `${themeColors.primary}`
        : state.isFocused
        ? `${themeColors.lightBlue}`
        : 'white',
      ':active': {
        backgroundColor:
          !state.isDisabled &&
          (state.isSelected
            ? `${themeColors.primary}`
            : `${themeColors.lightBlue}`),
      },
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      fontWeight: 400,
      fontSize: 16,
      paddingLeft: 4,
      minHeight: 56,
      letterSpacing: 0.75,
      borderRadius: 5,
      borderColor: `${themeColors.lightGray}`,
      '&:hover': {
        borderColor: `${themeColors.black}`,
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 100,
    }),
  }
  const errorStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      padding: 10,
      fontWeight: 400,
      letterSpacing: 0.75,
      zIndex: 100,
      backgroundColor: state.isSelected
        ? `${themeColors.primary}`
        : state.isFocused
        ? `${themeColors.lightBlue}`
        : 'white',
      ':active': {
        backgroundColor:
          !state.isDisabled &&
          (state.isSelected
            ? `${themeColors.primary}`
            : `${themeColors.lightBlue}`),
      },
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      fontWeight: 400,
      fontSize: 16,
      paddingLeft: 4,
      minHeight: 56,
      letterSpacing: 0.75,
      borderRadius: 5,
      borderColor: 'red',
      '&:hover': {
        borderColor: 'red',
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 100,
    }),
  }

  if (name && control) {
    return (
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        rules={rules}
        render={({ field: { onChange: onControlChange, value: val } }) => (
          <>
            <Select
              isClearable={isClearable}
              onChange={onControlChange}
              value={val}
              styles={isValid ? customStyles : errorStyles}
              classNamePrefix={classNamePrefix}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: `${themeColors.primary}`,
                },
              })}
              {...rest}
            />

            {errors && errors[name]?.message && (
              <InputMessage color={themeColors.error}>
                {errors[name].message}
              </InputMessage>
            )}
          </>
        )}
      />
    )
  } else {
    return (
      <>
        <Select
          isClearable={isClearable}
          styles={customStyles}
          classNamePrefix={classNamePrefix}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: `${themeColors.primary}`,
            },
          })}
          {...rest}
        />
        {errorMsg && (
          <InputMessage color={themeColors.error}>{errorMsg}</InputMessage>
        )}
      </>
    )
  }
}

export default BaseSelect

const InputMessage = styled.p<{ color: string }>`
  color: ${(props) => props.color};
  font-size: 14px;
  margin: 0px;
  padding-left: 16px;
  padding-top: 5px;
  padding-bottom: 9px;
`
