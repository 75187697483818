import gql from 'graphql-tag'

export interface IUpdateTenantSeosCardVars {
  id: string
  prefix: string
  disabled: boolean
}
export interface IUpdateTenantSeosCardData {
  tenant: {
    seos: {
      prefix: string
      disabled: boolean
    }
  }
}

export const UPDATE_TENANT_SEOS_CARD = gql`
  mutation updateTenant($id: ID!, $prefix: String!, $disabled: Boolean) {
    updateTenant(
      input: {
        filter: { id: [$id] }
        set: { seos: { prefix: $prefix, disabled: $disabled } }
      }
    ) {
      tenant {
        seos {
          prefix
          disabled
        }
      }
    }
  }
`
