import { gql } from '@apollo/client'
import { IHIDConfig } from './queryTenantWithDomains'
import { ILog } from '../shared/sharedTypes'
import { IParkingPool } from './queryTenantWithDomains'

export interface ITenant {
  id: string
  name: string
  domains: {
    name: string
  }[]
  hidConfig: IHIDConfig
  logs: ILog[]
  parkingPool: IParkingPool
  seos: {
    prefix: string
    disabled: boolean
    logs: ILog[]
  }
  mySolvyTenant: {
    id: string
    name: string
  }
}

export interface IGetTenantVars {
  name: string
}

export interface IGetTenantData {
  getTenant: ITenant
}

export const GET_TENANT = gql`
  query getTenant($name: String) {
    getTenant(name: $name) {
      id
      name
      domains {
        name
      }
      hidConfig: hid_config {
        id
        enabled
        userCanBuy: user_can_buy
        maxNumberOfMobileCards: max_number_of_mobile_cards
      }
      logs(order: { desc: created_at }) {
        createdAt: created_at
        message
        parameters {
          name
          value
          type
        }
      }
      parkingPool: parking_pool {
        id
        disabled
        isValid: is_valid
        maxActiveUserCount: max_active_user_count
        maxAdvanceDays: max_advance_days
        maxDurationDays: max_duration_days
        maxReservationCountPerUser: max_reservation_count_per_user
        placesCountContract: places_count_contract
        parkingPass: parking_pass {
          productId: amano_productno
          parking {
            name
          }
        }
        placesAggregate {
          count
        }
        notifyUserNearingReservation: notify_user_nearing_reservation
        reservationNearingInHours: reservation_nearing_in_hours
        customEmailMessage: custom_email_message
        notifyOfficeManagersNewReservation: notify_office_managers_new_reservation
        profiles {
          id
          disabled
          name
          maxReservationCountPerUser: max_reservation_count_per_user
          maxAdvanceDays: max_advance_days
          maxDurationDays: max_duration_days
          customEmailMessage: custom_email_message
          logs {
            createdAt: created_at
            message
            parameters {
              name
              value
              type
            }
          }
        }
      }
      seos {
        prefix
        disabled
        logs(order: { desc: created_at }) {
          createdAt: created_at
          message
          parameters {
            name
            value
            type
          }
        }
      }
      mySolvyTenant: task_tenant {
        id: Id
        name: LongName
      }
    }
  }
`
