import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import DrawerHeader from '../Shared/DrawerHeader'
import DrawerButtons from '../Shared/DrawerButtons'
import { LockerRow } from './LockerTable'
import { Grid } from '@mui/material'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import styled from 'styled-components'
import FullScreenLoader from '../Shared/FullScreenLoader'
import Input from '../Shared/Input'
import {
  CHANGE_LOCKERS_STATUS,
  IChangeLockersStatusVars,
} from '../../graphql/lockers/changeLockersStatus'
import { ApolloError, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import LockerStatusSelect from './LockerStatusSelect'
import { LockerStatus } from '../../graphql/lockers/queryLockers'
import { themeColors } from '../../const/colors'
import { getLockerStatusColor } from '../../utils/getStatusColor'
import ErrorContainer from '../Shared/ErrorContainer'

type FormData = {
  comment: string
  status: string
}

const EditManyLockers = ({
  closeDetails,
  lockers,
  refetch,
  lockerStatus,
}: {
  closeDetails: () => void
  lockers?: LockerRow[]
  refetch: () => void
  lockerStatus: LockerStatus | string
}) => {
  const { t } = useTranslation(['lockers'])
  const successNotify = (message: string) => toast.dark(message)
  const [errorMessage, setErrorMessage] = useState('')
  const ids = lockers?.map(({ id }) => id).join(',')

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  const onCompleted = () => {
    successNotify(`${t('generic:success_message')}`)
    refetch()
    closeDetails()
  }

  const [changeLockersStatus, { loading }] =
    useMutation<IChangeLockersStatusVars>(CHANGE_LOCKERS_STATUS, {
      onCompleted,
      onError: (error: ApolloError) => setErrorMessage(error.message),
    })

  if (!lockers?.length || loading) {
    return <FullScreenLoader />
  }

  const onSubmit = (data: FormData) => {
    const { comment, status } = data

    if (status === lockerStatus) {
      setErrorMessage(t('valid_status'))
    } else {
      changeLockersStatus({
        variables: {
          ids,
          oldStatus: lockerStatus,
          newStatus: status,
          comment,
        },
      })
    }
  }

  return (
    <>
      <DrawerHeader title={t('change_status')} handleClose={closeDetails} />
      <RowStyled>
        <Text>{'Status: '}</Text>
        <TextBold color={getLockerStatusColor(lockerStatus as LockerStatus)}>
          {t(`locker_status:${lockerStatus}`)}
        </TextBold>
      </RowStyled>
      <RowStyled>
        <TextBody2Bold color={themeColors.primary}>{`${lockers.length} ${t(
          'generic:selected'
        )}`}</TextBody2Bold>
      </RowStyled>
      <FormStyled onSubmit={handleSubmit(onSubmit)}>
        <TextWithPadding>{t('new_status')}:</TextWithPadding>
        <LockerStatusSelect
          control={control}
          errors={errors}
          defaultValue={undefined}
        />
        <InputStyled
          label={t('comment')}
          name={'comment'}
          control={control}
          errors={errors}
          multiline
          rows={4}
          data-e2e="change-status-comment"
        />
        <DrawerButtons
          handleAccept={handleSubmit(onSubmit)}
          handleCancel={closeDetails}
        />
        {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
      </FormStyled>
    </>
  )
}

export default EditManyLockers

const RowStyled = styled(Grid)`
  padding: 6px;
`
const Text = styled(TextBody2Medium)`
  display: inline;
`
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
const InputStyled = styled(Input)`
  max-width: 500px;
  margin-top: 16px;
`
const TextWithPadding = styled(TextBody2Medium)`
  padding: 16px 0;
`
const FormStyled = styled.form`
  padding: 16px 0 0;
`
