import { useTranslation } from 'react-i18next'
import {
  QUERY_PAYU_PAYOUT_COMPANY,
  IQueryPayuPayoutCompanyData,
} from '../../graphql/payU/queryPayuPayoutCompany'
import { useQuery } from '@apollo/client'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { FormControl, MenuItem, InputLabel, Select } from '@mui/material'
import styled from 'styled-components'

const PayuPayoutCompanySelect = ({
  value,
  setValue,
}: {
  value?: string
  setValue: (v: string) => void
}) => {
  const { t } = useTranslation(['payu'])
  const { data, loading } = useQuery<IQueryPayuPayoutCompanyData>(
    QUERY_PAYU_PAYOUT_COMPANY
  )

  const handleChange = (event: any) => {
    setValue(event.target.value as string)
  }

  if (loading) {
    return <FullScreenLoader />
  }

  if (!data) {
    return null
  }

  return (
    <FormControl data-e2e="payout-company-select">
      <InputLabel id="select-label">{t('select_company')}</InputLabel>
      <SelectStyled
        labelId="select-label"
        value={value}
        label={t('select_company')}
        onChange={handleChange}
      >
        {data.queryPayuPayoutCompany.map(({ name, description }, i) => (
          <MenuItem key={i} value={name}>
            {description}
          </MenuItem>
        ))}
      </SelectStyled>
    </FormControl>
  )
}

export default PayuPayoutCompanySelect

const SelectStyled = styled(Select)`
  width: 500px;
`
