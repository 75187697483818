import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Title } from '../Styles/TextCustom'
import { ApolloError, useMutation } from '@apollo/client'
import { Grid } from '@mui/material'
import { BaseButton } from '../Shared/BaseButton'
import {
  CREATE_PARKING_POOL,
  ICreateParkingPoolData,
  ICreateParkingPoolVariables,
} from '../../graphql/tenant/createParkingPool'
import ParkingSelect from '../Parking/ParkingSelect'
import ParkingPoolInputs from '../Tenant/ParkingPoolInputs'
import { toast } from 'react-toastify'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'
import ErrorContainer from '../Shared/ErrorContainer'
import { ITenant } from '../../graphql/tenant/getTenant'

type SharedParkingFormData = {
  productId: number
  maxActiveUserCount: number
  maxAdvancedDays: number
  maxDurationDays: number
  placesCountContract: number
  maxReservationCountPerUser: number
  notifyUserNearingReservation: boolean
  customEmailMessage: string
  reservationNearingInHours: number
  notifyOfficeManagersNewReservation: boolean
}

const AddParkingPoolForm = ({
  tenantToEdit,
  setParkingPoolFormTouched,
  refetch,
}: {
  tenantToEdit: ITenant
  setParkingPoolFormTouched: (v: boolean) => void
  refetch: () => void
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [parking, setParking] = useState('')

  const { t } = useTranslation(['parking_pool'])
  const successNotify = (message: string) => toast.dark(message)
  const { parkingPass } = tenantToEdit?.parkingPool || {}

  useEffect(() => {
    if (parkingPass) {
      setParking(parkingPass.parking.name)
    }
  }, [parkingPass])

  const {
    control,
    handleSubmit: handleParkingSubmit,
    formState: { errors, isDirty },
    watch,
  } = useForm<SharedParkingFormData>()

  const onAddParkingPoolSuccess = (data: ICreateParkingPoolData) => {
    const { status, code, error } = data.createParkingPool
    if (status) {
      successNotify(`${t('add_parking_pool_success')}`)
      refetch()
    } else {
      setErrorMessage(
        t(`code:${code}`) === `${code}` ? `${error}` : t(`code:${code}`)
      )
    }
  }

  const [addParkingPool] = useMutation<
    ICreateParkingPoolData,
    ICreateParkingPoolVariables
  >(CREATE_PARKING_POOL, {
    onCompleted: onAddParkingPoolSuccess,
    onError: (error: ApolloError) => {
      setErrorMessage(error.message)
    },
  })

  const onParkingSubmit = async (data: SharedParkingFormData) => {
    await addParkingPool({
      variables: {
        productId: Number(data.productId),
        maxActiveUserCount: Number(data.maxActiveUserCount),
        maxAdvanceDays: Number(data.maxAdvancedDays),
        maxDurationDays: Number(data.maxDurationDays),
        maxReservationCountPerUser: Number(data.maxReservationCountPerUser),
        placesCountContract: Number(data.placesCountContract),
        tenant: tenantToEdit.name,
        parkingName: parking,
        notifyUserNearingReservation: data.notifyUserNearingReservation,
        reservationNearingInHours: Number(data.reservationNearingInHours) || 0,
        customEmailMessage: data.customEmailMessage || '',
        notifyOfficeManagersNewReservation:
          data.notifyOfficeManagersNewReservation,
      },
    })
  }

  useEffect(() => {
    isDirty && setParkingPoolFormTouched(true)
  }, [isDirty, setParkingPoolFormTouched])

  return (
    <>
      <DetailsWrapper>
        <Title>{t('form:shared_parking_feature')}</Title>
        <form onSubmit={handleParkingSubmit(onParkingSubmit)}>
          <SelectWrapper>
            <ParkingSelect setValue={setParking} value={parking} />
          </SelectWrapper>
          <ParkingPoolInputs
            tenant={tenantToEdit}
            control={control}
            errors={errors}
            watch={watch}
          />
          <ButtonWrapper
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <BaseButton
              type="submit"
              disabled={!parking}
              data-e2e="parking-pool-submit"
            >
              {t('btn:save')}
            </BaseButton>
          </ButtonWrapper>
        </form>
      </DetailsWrapper>
      {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
    </>
  )
}

export default AddParkingPoolForm

const ButtonWrapper = styled(Grid)`
  padding: 16px;
`
const SelectWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`
