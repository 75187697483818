import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Grid, ClickAwayListener } from '@mui/material'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import {
  RESOLVE_ISSUE,
  IResolveIssueVars,
  IResolveIssueData,
} from '../../graphql/userIssues/resolveIssue'
import { useForm } from 'react-hook-form'
import { ApolloError, useMutation, useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Text, TextBold } from '../Styles/DetailsDrawersStyles'
import Input from '../Shared/Input'
import { toast } from 'react-toastify'
import { dateIso2localeString } from '../../utils/formatDate'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import DrawerHeader from '../Shared/DrawerHeader'
import DrawerButtons from '../Shared/DrawerButtons'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { MessageRow } from './MessagesTable'
import { IssueType } from '../../graphql/userIssues/queryIssue'
import ChangeIssueType from './ChangeIssueType'
import {
  GET_ISSUE,
  IGetIssueData,
  IGetIssueVars,
} from '../../graphql/userIssues/getIssue'
import LogsContainer from '../Shared/LogsContainer'
import BlockAccountFromMessage from './BlockAccountFromMessage'
import CloseIssue from './CloseIssue'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'
import ErrorContainer from '../Shared/ErrorContainer'
import { ROUTES } from '../../const/routes'
import NewTabLink from '../Shared/NewTabLink'

type FormData = {
  message: string
}

const UserMessageDetails = ({
  issue,
  closeIssue,
  updateList,
  tab,
  updateAggregatedCount,
}: {
  issue?: MessageRow
  closeIssue: () => void
  updateList: () => void
  tab: number
  updateAggregatedCount: () => void
}) => {
  const { t, i18n } = useTranslation([
    'user_message_details',
    'form',
    'btn',
    'code',
    'generic',
  ])
  const [errorMessage, setErrorMessage] = useState('')
  const [openClickAwayModal, setOpenClickAwayModal] = useState(false)
  const successNotify = (message: string) => toast.dark(message)

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<FormData>()
  const inputMessage = watch('message')

  const onCompleted = (data: IResolveIssueData) => {
    if (data?.resolveIssue.status) {
      successNotify(`${t('success')}`)
      updateList()
      updateAggregatedCount()
      closeIssue()
    } else setErrorMessage(`${t(`code:${data.resolveIssue.code}`)}`)
  }

  const [resolveIssue, { loading }] = useMutation<
    IResolveIssueData,
    IResolveIssueVars
  >(RESOLVE_ISSUE, {
    onCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })
  const [getIssue, { data }] = useLazyQuery<IGetIssueData, IGetIssueVars>(
    GET_ISSUE,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id: issue?.id || '',
      },
    }
  )
  useEffect(() => {
    issue && getIssue()
  }, [issue])

  useEffect(() => {
    issue && reset({ message: '' })
  }, [issue, reset])

  if (loading || !issue) {
    return <FullScreenLoader />
  }

  const {
    fullName,
    login,
    email,
    company,
    createdAt,
    closeAt,
    resolvedBy,
    app,
    os,
    apiVersion,
    backendVersion,
    type,
    message,
    reply,
  } = issue

  const issueData = [
    { label: t('from'), value: fullName },
    { label: t('email'), value: email },
    { label: t('company'), value: company },
    {
      label: t('date'),
      value: dateIso2localeString(createdAt, i18n.language),
    },
    {
      label: t('closeAt'),
      value: tab && dateIso2localeString(closeAt, i18n.language),
    },
    {
      label: t('resolved_by'),
      value: tab && `${resolvedBy.firstName} ${resolvedBy.lastName}`,
    },
  ]

  const additionalData = [
    { label: t('app'), value: app },
    { label: 'System', value: os },
    { label: t('api'), value: apiVersion },
    { label: t('backend'), value: backendVersion },
  ]

  const handleClickAway = () =>
    inputMessage ? setOpenClickAwayModal(true) : closeIssue()

  const handleCloseModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setOpenClickAwayModal(false)
  }

  const handleSendMessage = (formData: FormData) => {
    resolveIssue({
      variables: {
        id: issue.id,
        comment: formData.message,
      },
    })
  }
  const deleteAccount = type === IssueType.account_delete_request

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <>
          <DrawerHeader title={t('title')} handleClose={handleClickAway}>
            {!tab && (
              <CloseIssue
                id={issue.id}
                updateList={updateList}
                updateAggregatedCount={updateAggregatedCount}
                closeIssue={closeIssue}
              />
            )}
          </DrawerHeader>
          <DetailsWrapper>
            <Grid>
              {issueData.map(
                (item) =>
                  !!item.value && (
                    <Row key={item.label}>
                      <DetailText>{`${item.label} `}</DetailText>
                      <DetailBold>{item.value}</DetailBold>
                    </Row>
                  )
              )}
            </Grid>
            <GridStyled>
              {additionalData.map(
                (item) =>
                  !!item.value && (
                    <Grid key={item.label}>
                      <TextInfo>
                        {item.label}&nbsp;
                        <TextBold>{item.value}</TextBold>
                      </TextInfo>
                    </Grid>
                  )
              )}
            </GridStyled>
            <Wrapper>
              <NewTabLink
                text={t('user_details')}
                path={`/admin/${ROUTES.USER_LIST}/${login}`}
              />
            </Wrapper>
          </DetailsWrapper>
          {deleteAccount && (
            <DetailsWrapper>
              <BlockAccountFromMessage login={login} />
            </DetailsWrapper>
          )}

          {data?.getIssue && (
            <ChangeIssueType
              issue={data.getIssue}
              refetch={getIssue}
              updateList={updateList}
            />
          )}

          <DetailsWrapper>
            <TextTitle>{t('message')}</TextTitle>
            <Text data-e2e="messages-details-messages">{message}</Text>

            {!!tab && (
              <Grid>
                <TextTitle>{t('reply')}</TextTitle>
                <Text data-e2e="messages-details-reply">{reply}</Text>
              </Grid>
            )}
            {!tab && (
              <Input
                label={t('form:message')}
                name="message"
                control={control}
                errors={errors}
                rules={{ required: `${t('form:field_required')}` }}
                multiline
                rows={6}
                data-e2e="messages-details-input"
              />
            )}
          </DetailsWrapper>
          {!tab && (
            <DrawerButtons
              handleAccept={handleSubmit(handleSendMessage)}
              handleCancel={closeIssue}
              acceptText={t('btn:send_message')}
            />
          )}

          {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
          {!!data?.getIssue.logs.length && (
            <Wrapper>
              <LogsContainer logs={data?.getIssue.logs} />
            </Wrapper>
          )}
        </>
      </ClickAwayListener>
      <BaseModal
        confirmText={'btn:exit'}
        open={openClickAwayModal}
        handleClose={(e) => handleCloseModal(e)}
        handleAccept={() => closeIssue()}
      >
        <ModalContent>{t('generic:exit_without_saving')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default UserMessageDetails

const TextTitle = styled(Text)`
  font-weight: 600;
`
const TextInfo = styled(Text)`
  font-size: 14px;
  margin: 0;
`
const GridStyled = styled(Grid)`
  padding: 2px 6px;
  max-width: 500px;
`
const Row = styled(Grid)`
  padding: 4px 6px;
`
const DetailText = styled(TextBody2Medium)`
  display: inline;
`
const DetailBold = styled(TextBody2Bold)`
  display: inline;
`
const Wrapper = styled.div`
  padding: 16px 0;
`
