import gql from 'graphql-tag'

export enum UsersScope {
  all = 'All',
  residents = 'Residents',
  nonResidents = 'NonResidents',
  employees = 'Employees',
  developers = 'Developers',
  testers = 'Testers',
}

export interface IGetTargetUsersVars {
  scope: string
  forMarketing: boolean
  filter?: string
  lang?: string
}

export interface IGetTargetUsersData {
  getTargetUsers: string[]
}
export const GET_TARGET_USERS = gql`
  query getTargetUsers(
    $scope: UsersScope!
    $forMarketing: Boolean!
    $filter: String
    $lang: UserProfileLanguage
  ) {
    getTargetUsers(
      scope: $scope
      listtype: logins
      for_marketing: $forMarketing
      filter: $filter
      lang: $lang
    )
  }
`
