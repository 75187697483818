import styled from 'styled-components'
import {
  QUERY_BUILDING,
  IQueryBuildingData,
} from '../../graphql/shared/queryBuilding'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { FormControl, MenuItem, InputLabel } from '@mui/material'
import Select from '@mui/material/Select'

const SelectBuildingColumnFilter = ({
  value,
  setValue,
}: {
  value?: string
  setValue: (v: string) => void
}) => {
  const { t } = useTranslation(['lockers'])
  const { data } = useQuery<IQueryBuildingData>(QUERY_BUILDING)

  const handleChange = (event: any) => {
    setValue(event.target.value as string)
  }

  return (
    <FormControl data-e2e="building-select-filter">
      <InputLabel id="select-label">{t('building')}</InputLabel>
      <SelectStyled
        labelId="select-label"
        value={value}
        label="budynek"
        onChange={handleChange}
      >
        {data?.queryBuilding.map(({ name }, i) => (
          <MenuItem key={i} value={name}>
            {name}
          </MenuItem>
        ))}
      </SelectStyled>
    </FormControl>
  )
}

export default SelectBuildingColumnFilter

const SelectStyled = styled(Select)`
  width: 174px;
`
