import gql from 'graphql-tag'

export interface IMySolvyTenant {
  id: string
  active: boolean
  createdAt: string
  name: string
  shortName: string
  tenantMyOlivia: {
    name: string
  }[]
}

export interface IQueryMySolvyTenantData {
  queryMySolvyTenant: IMySolvyTenant[]
}

export const QUERY_MYSOLVY_TENANT = gql`
  query queryMySolvyTenant {
    queryMySolvyTenant: queryTaskTenant {
      id: Id
      active: Active
      createdAt: Created
      name: LongName
      shortName: Name
      tenantMyOlivia: Tenant {
        name
      }
    }
  }
`
