import Drawer from '@mui/material/Drawer'
import { themeColors } from '../../const/colors'
import styled from 'styled-components'
import { makeStyles } from '@mui/styles'

type Anchor = 'top' | 'left' | 'bottom' | 'right'
type Variant = 'persistent' | 'permanent' | 'temporary' | undefined

const useStyles = makeStyles({
  drawerPaper: {
    marginTop: '64px',
    overflowY: 'auto',
    height: `calc(100vh - 64px)`,
  },
})

const BaseDrawer = ({
  children,
  open,
  anchor = 'right',
  variant = 'persistent',
}: {
  children: any
  open: boolean
  anchor?: Anchor
  variant?: Variant
}) => {
  const classes = useStyles()
  return (
    <Drawer
      anchor={anchor}
      open={open}
      variant={variant}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <DrawerContent data-e2e="drawer">{children}</DrawerContent>
    </Drawer>
  )
}

export default BaseDrawer

const DrawerContent = styled.div`
  padding: 30px;
  width: 100%;
  background-color: ${themeColors.lightBlue};
  width: 45vw;
  min-width: 700px;
  flex: 1;
  z-index: 1;
  overflow-top: hidden;
`
