import React, { useEffect, useState } from 'react'
import FullScreenLoader from '../components/Shared/FullScreenLoader'
import Error from '../components/PagesComponents/Error'
import { TextH4Bold, TextInfo } from '../components/Styles/TextCustom'
import { CenterContainer } from '../components/Styles/CustomElementsStyled'
import { BaseButton } from '../components/Shared/BaseButton'
import Input from '../components/Shared/Input'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { useMutation, ApolloError } from '@apollo/client'
import {
  IChangeForgottenPasswordData,
  CHANGE_FORGOTTEN_PASSWORD,
} from '../graphql/auth/changeForgottenPassword'

type PasswordChangeFormData = {
  password: string
}
const defaultValues = {
  password: '',
}

interface ICustomWindow extends Window {
  TAG?: string
}
declare var window: ICustomWindow

const RecoverPasswordPage = () => {
  const { t, i18n } = useTranslation([
    'recover_password_page',
    'form',
    'btn',
    'code',
    'error',
  ])
  const [code, setCode] = useState<any>('')
  const [email, setEmail] = useState<any>('')
  const [errorMessage, setErrorMessage] = useState('')
  const [success, setSuccess] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  })

  const changePassword = (formData: PasswordChangeFormData) => {
    changeForgottenPassword({
      variables: {
        email,
        code,
        password: formData.password,
        app: `${window.TAG || 'local'}`,
      },
    })
  }

  const onCompleted = (data: IChangeForgottenPasswordData) => {
    if (data?.changeForgottenPassword.status) {
      setSuccess(true)
    } else {
      setErrorMessage(t(`code:${data.changeForgottenPassword.code}`))
    }
  }

  const [changeForgottenPassword, { loading }] = useMutation(
    CHANGE_FORGOTTEN_PASSWORD,
    {
      onCompleted,
      onError: (error: ApolloError) => setErrorMessage(error.message),
    }
  )

  const checkDevice = () => {
    const isIos =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    const isAndroid = /(android)/i.test(navigator.userAgent)
    if (isIos || isAndroid) {
      setIsMobile(true)
    }
  }

  const openApp = () => {
    const appLinkPrefix = 'myolivia://login/'
    document.location.href = appLinkPrefix
    setTimeout(() => {
      setErrorMessage(t('error:no_app'))
    }, 1500)
  }

  useEffect(() => {
    checkDevice()
    const queryString = window.location.hash
    const params = queryString.split('?')
    const urlParams = new URLSearchParams(params[1])
    const email = urlParams.get('email')
    const code = urlParams.get('verification_code')
    const lang = urlParams.get('lang')
    if (lang !== 'none') {
      i18n.changeLanguage(lang || undefined)
    }
    setEmail(email)
    setCode(code)
  }, [i18n])

  if (loading) {
    return <FullScreenLoader />
  }

  if (errorMessage) {
    return <Error errorMessage={errorMessage} title={true} />
  }

  return (
    <CenterContainer>
      <FormContainer>
        {success ? (
          <>
            <TextInfo>{t('success')}</TextInfo>
            {isMobile && (
              <BaseButton onClick={openApp}>{t('btn:go_to_app')}</BaseButton>
            )}
          </>
        ) : (
          <>
            <TextH4Bold>{t('title')}</TextH4Bold>
            <Input
              label={t('form:new_password')}
              name="password"
              autoCompleteType="password"
              autoCapitalize={'none'}
              control={control}
              errors={errors}
              rules={{ required: `${t('form:field_required')}` }}
            />
            <Button
              onClick={handleSubmit(changePassword)}
              data-e2e="submit-reset-password"
            >
              {t('btn:create_new_password')}
            </Button>
          </>
        )}
      </FormContainer>
    </CenterContainer>
  )
}

const FormContainer = styled.div`
  max-width: 325px;
`
const Button = styled(BaseButton)`
  margin-top: 8px;
`
export default RecoverPasswordPage
