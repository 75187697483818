import React, { useEffect, useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import EmailVerificationPage from './pages/EmailVerificationPage'
import RecoverPasswordPage from './pages/RecoverPasswordPage'
import AdminLogin from './screens/Auth/AdminLogin'
import { ROUTES } from './const/routes'
import NoMatch from './components/NoMatch'
import { ApolloError, useLazyQuery } from '@apollo/client'
import { GET_MY_ROLES, IGetMyRolesData } from './graphql/admin/getMyRoles'
import { useDispatch } from 'react-redux'
import { UserActionTypes } from './redux/user/types/UserActionTypes'
import FullScreenLoader from './components/Shared/FullScreenLoader'
import AdminApp from './AdminApp'
import Home from './screens/Home'
import { checkSignInRoles } from './utils/checkSignInRoles'
import { SIGN_IN_ROLES } from './const/permissions'
import AuthWrapper from './components/AuthWrapper'
import { useNavigate } from 'react-router-dom'
import SignUpPage from './pages/SignUpPage'
import SignInMySolvyPage from './pages/SignInMySolvyPage'
import RemindPasswordPage from './pages/RemindPasswordPage'

const App = () => {
  const [loading, setLoading] = useState(true)
  const [prevRoute, setPrevRoute] = useState('')
  const dispatch = useDispatch()
  const token = localStorage.getItem('accessToken')
  const setRoles = (roles: { name: string }[]) => {
    dispatch({
      type: UserActionTypes.SET_USER_ROLES,
      isAdmin: roles.map((item) => item.name).includes('admin'),
      roles: roles.map((role) => role.name),
    })
  }
  const prev = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (prev.pathname === '/') {
      navigate('/login')
    } else if (prev.pathname !== '/login' && prev.pathname !== '/admin') {
      setPrevRoute(prev.pathname)
    }
  }, [prev])

  const onCompleted = (data: IGetMyRolesData) => {
    if (checkSignInRoles(data?.getMyRoles.roles.map((item) => item.name))) {
      setRoles(data?.getMyRoles.roles)
    }
    setLoading(false)
  }

  const onError = (error: ApolloError) => {
    console.log(error.message)
    setLoading(false)
  }

  const [getMyRoles] = useLazyQuery<IGetMyRolesData>(GET_MY_ROLES, {
    onCompleted,
    onError,
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    !!token ? getMyRoles() : setLoading(false)
  }, [getMyRoles, token])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <Routes>
      <Route path={ROUTES.HOME} element={<Home />} />
      <Route path={ROUTES.SIGN_UP} element={<SignUpPage />} />
      <Route path={ROUTES.SIGN_IN_MY_SOLVY} element={<SignInMySolvyPage />} />
      <Route
        path={ROUTES.EMAIL_VERIFICATION}
        element={<EmailVerificationPage />}
      />
      <Route path={ROUTES.RECOVER_PASSWORD} element={<RecoverPasswordPage />} />
      <Route path={ROUTES.REMIND_PASSWORD} element={<RemindPasswordPage />} />
      <Route
        path={ROUTES.LOGIN}
        element={<AdminLogin prevRoute={prevRoute} />}
      />
      <Route
        path={ROUTES.ADMIN}
        element={
          <AuthWrapper requiredRoles={SIGN_IN_ROLES}>
            <AdminApp />
          </AuthWrapper>
        }
      ></Route>
      <Route path="*" element={<NoMatch />} />
    </Routes>
  )
}

export default App
