import { Controller } from 'react-hook-form'
import styled from 'styled-components'
import CheckboxMui from '@mui/material/Checkbox'
import { themeColors } from '../../const/colors'
import { IFormError, InputRules } from '../../types/FormTypes'

interface ICheckboxProps {
  name: string
  control?: any
  errors?: IFormError | any
  rules?: InputRules
  defaultValue?: boolean
  expandable?: boolean
  value?: boolean
  onChangeCallback?: (value: boolean) => void
  testId?: string
  [k: string]: any
}

/**
 * Checkbox component
 *
 * If using react-hook-form provide 'control', otherwise use 'value' and 'onChangeCallback' to control it.
 *
 * @param {string} name checkbox name
 * @param {string} control react-hook-form control
 * @param {any} errors react-hook-form errors
 * @param {InputRules} rules react-hook-form rules
 * @param {boolean} defaultValue react-hook-form defaultValue
 * @param {boolean} expandable unused for now
 * @param {boolean} value boolean value for checkbox, if not using react-hook-form
 * @param {(value: boolean) => void} onChangeCallback onChange callback function, optional when in use with react-hook-form
 * @param children string or text components
 */

const Checkbox: React.FC<ICheckboxProps> = ({
  name,
  control,
  errors,
  rules,
  defaultValue = false,
  expandable = true,
  value,
  onChangeCallback,
  children,
  testId = 'checkbox',
  ...rest
}) => {
  if (control) {
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field: { onChange, value: val } }) => {
          return (
            <CheckboxContainer
              onClick={() => {
                onChange(!val)
                onChangeCallback && onChangeCallback(!val)
              }}
              {...rest}
            >
              <CheckboxMuiStyled
                isError={errors && !!errors[name]}
                checked={val}
                data-e2e={testId}
              />
              <TextWrap>
                <CheckboxText>
                  {children}
                  {!!rules?.required && (
                    <Star color={themeColors.error}>* </Star>
                  )}
                </CheckboxText>
              </TextWrap>
            </CheckboxContainer>
          )
        }}
      />
    )
  } else {
    return (
      <CheckboxContainer
        onClick={() => {
          onChangeCallback && onChangeCallback(!value)
        }}
        {...rest}
      >
        <CheckboxMuiStyled isError={errors && !!errors[name]} checked={value} />
        <TextWrap>
          <CheckboxText>
            {children}
            {!!rules?.required && <Star>* </Star>}
          </CheckboxText>
        </TextWrap>
      </CheckboxContainer>
    )
  }
}

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  cursor: pointer;
`
const CheckboxMuiStyled = styled(CheckboxMui)<{ isError?: boolean }>`
  .MuiSvgIcon-root {
    ${({ isError }) => isError && `fill: ${themeColors.error};`}
  }
`
const TextWrap = styled.div`
  padding-top: 11px;
  padding-right: 8px;
`
const CheckboxText = styled.p<{ color?: string }>`
  ${({ color }) => color && `color: ${color};`};
  display: inline-block;
  margin: 0;
`
const Star = styled.span`
  color: ${themeColors.error};
`

export default Checkbox
