import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BaseButton } from '../Shared/BaseButton'
import {
  RESEND_INVOICE,
  IResendInvoiceVars,
  IResendInvoiceData,
} from '../../graphql/shared/resendInvoice'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import ErrorContainer from './ErrorContainer'

const ResendInvoice = ({
  paymentId,
  refetch,
  getPaymentLogs,
  invoice,
}: {
  paymentId: string
  refetch: () => void
  getPaymentLogs: () => void
  invoice: boolean
}) => {
  const { t } = useTranslation(['payment_history'])
  const successNotify = (message: string) => toast.dark(message)
  const [error, setError] = useState('')

  const onCompleted = (data: IResendInvoiceData) => {
    const { status, error, code } = data.resendInvoice
    if (status) {
      successNotify(`${t('resend_invoice_success')}`)
      getPaymentLogs()
      refetch()
      setError('')
    } else {
      error &&
        setError(
          t(`code:${code}`) === `${code}` ? `${error}` : t(`code:${code}`)
        )
    }
  }

  const [resendInvoice, { loading }] = useMutation<
    IResendInvoiceData,
    IResendInvoiceVars
  >(RESEND_INVOICE, {
    onCompleted,
    fetchPolicy: 'no-cache',
  })

  const handleSendInvoice = () =>
    resendInvoice({ variables: { paymentSessionId: paymentId } })

  return (
    <Wrapper>
      <BaseButton onClick={handleSendInvoice} loading={loading}>
        {invoice ? t('resend_invoice') : t('resend_receipt')}
      </BaseButton>
      {!!error && <ErrorContainer errorMessage={error} />}
    </Wrapper>
  )
}

export default ResendInvoice

const Wrapper = styled.div`
  padding: 16px 0;
`
