import gql from 'graphql-tag'

export interface IRegexp {
  name: string
  value: string
}

export interface IQueryOlivkaRegexpData {
  offers: IRegexp[]
  restaurants: IRegexp[]
  promo: IRegexp[]
}

export const QUERY_OLIVKA_REGEXP = gql`
  query queryOlivkaRegexp {
    offers: queryGlobalConfig(
      filter: {
        name: { regexp: "/config\\\\.olivka\\\\.regexp\\\\.offer\\\\.*/" }
      }
      order: { asc: name }
    ) {
      name
      value
      enabled
    }
    restaurants: queryGlobalConfig(
      filter: {
        name: { regexp: "/config\\\\.olivka\\\\.regexp\\\\.restaurant\\\\.*/" }
      }
      order: { asc: name }
    ) {
      name
      value
      enabled
    }
    promo: queryGlobalConfig(
      filter: {
        name: { regexp: "/config\\\\.olivka\\\\.regexp\\\\.promo\\\\.*/" }
      }
      order: { asc: name }
    ) {
      name
      value
      enabled
    }
  }
`
