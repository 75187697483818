import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useForm, useWatch } from 'react-hook-form'
import { TextError } from '../../components/Styles/TextCustom'
import { BaseButton } from '../../components/Shared/BaseButton'
import {
  PUSH_NOTIFICATION,
  IPushNotificationVars,
  IPushNotificationData,
} from '../../graphql/push/pushNotification'
import { ApolloError, useMutation, useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import BaseModal from '../../components/Shared/BaseModal'
import { ModalContent } from '../../components/Styles/CustomElementsStyled'
import {
  GET_TARGET_USERS,
  IGetTargetUsersVars,
  IGetTargetUsersData,
} from '../../graphql/push/getTargetUsers'
import { useAppSelector } from '../../redux/store'
import {
  ADD_LOG_LINE,
  IAddLogLineVars,
  IAddLogLineData,
} from '../../graphql/shared/addLogLine'
import { date2isoString } from '../../utils/formatDate'
import PushForm from '../../components/Push/PushForm'
import ErrorContainer from '../../components/Shared/ErrorContainer'

type FormData = {
  title: string
  body: string
  forMarketing: boolean
  logins: string
  tokens: string
  tenant: {
    value: string
    label: string
  }
  mobileView: {
    label: string
    value: string
  }
  lang: {
    label: string
    value: string
  }
}

const now = new Date(Date.now())

const PushScreen = () => {
  const { t } = useTranslation(['push'])
  const [target, setTarget] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const successNotify = (message: string) => toast.dark(message)
  const { login } = useAppSelector((state) => state.user)

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>()

  const getList = (v: string) =>
    v
      .replace(/\n/g, ',')
      .split(',')
      .map((item) => item.trim())
      .filter((item) => item !== '')

  const pushTitle = useWatch({ control, name: 'title' })
  const pushBody = useWatch({ control, name: 'body' })

  const [addLogLine] = useMutation<IAddLogLineData, IAddLogLineVars>(
    ADD_LOG_LINE
  )

  const onCompleted = (data: IPushNotificationData) => {
    const { status, code, error } = data.pushNotification
    if (status) {
      addLogLine({
        variables: {
          createdAt: date2isoString(now),
          message: 'Push',
          parameters: [
            { name: 'operation', value: 'push.message' },
            { name: 'title', value: pushTitle },
            { name: 'body', value: pushBody },
            { name: 'login', value: String(login || '') },
            { name: 'target', value: target },
          ],
        },
      })
      reset()
      setErrorMessage('')
      successNotify(`${t('push_success')}`)
    } else
      error &&
        setErrorMessage(
          t(`code:${code}`) === `${code}` ? `${error}` : t(`code:${code}`)
        )
  }

  const [pushNotification, { loading }] = useMutation<
    IPushNotificationData,
    IPushNotificationVars
  >(PUSH_NOTIFICATION, {
    onCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  const onGetTargetUsersCompleted = (data: IGetTargetUsersData) =>
    pushNotification({
      variables: {
        title: pushTitle,
        body: pushBody,
        logins: data.getTargetUsers,
      },
    })

  const [getTargetUsers, { loading: targetListLoading }] = useLazyQuery<
    IGetTargetUsersData,
    IGetTargetUsersVars
  >(GET_TARGET_USERS, {
    onCompleted: onGetTargetUsersCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
    fetchPolicy: 'no-cache',
  })

  const onSubmit = (data: FormData) => {
    setOpenModal(false)
    const {
      title,
      body,
      logins,
      tokens,
      forMarketing,
      tenant,
      mobileView,
      lang,
    } = data
    let variables = {
      title,
      body,
      redirect: mobileView.value,
      lang: lang.value,
    }
    switch (target) {
      case 'login':
        login &&
          pushNotification({
            variables: {
              ...variables,
              logins: [login],
            },
          })
        break
      case 'logins':
        pushNotification({
          variables: {
            ...variables,
            logins: getList(logins),
          },
        })
        break
      case 'tokens':
        pushNotification({
          variables: {
            ...variables,
            tokens: getList(tokens),
          },
        })
        break
      case 'Employees':
        getTargetUsers({
          variables: {
            scope: target,
            forMarketing,
            filter: tenant.label,
            lang: lang.value,
          },
        })
        break
      default:
        getTargetUsers({
          variables: {
            scope: target,
            forMarketing,
            lang: lang.value,
          },
        })
    }
  }

  if (loading || targetListLoading) {
    return <FullScreenLoader />
  }

  return (
    <Container>
      <PushForm
        control={control}
        errors={errors}
        target={target}
        setTarget={setTarget}
      />
      <Wrapper>
        <BaseButton onClick={() => setOpenModal(true)}>
          {t('send_push')}
        </BaseButton>
        {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
      </Wrapper>
      <BaseModal
        confirmText={'btn:send'}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleAccept={handleSubmit(onSubmit)}
      >
        <ModalContent>{t('push_confirmation')}</ModalContent>
      </BaseModal>
    </Container>
  )
}

export default PushScreen

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const Wrapper = styled.div`
  margin: 16px 0 16px 16px;
`
