import gql from 'graphql-tag'
import { ILockerOrder } from './queryLockerOrders'

export interface IGetLockerOrderVars {
  id?: string
}

export interface IGetLockerOrderData {
  getLockerOrders: ILockerOrder
}

export const GET_LOCKER_PAYMENT = gql`
  query getLockerOrders($id: ID!) {
    getLockerOrders(id: $id) {
      id
      product {
        name
        nameEn: name_en
        seller {
          name
          nip
        }
      }
      locker {
        name
        type
        building {
          name
        }
      }
      validFrom: valid_from
      validTo: valid_to
      invoiceNeeded: invoice_needed
      user {
        login
        firstName: first_name
        lastName: last_name
        tenant {
          tenantName: name
        }
      }
      buyer {
        city
        buyerName: name
        country
        nip
        street
        zipcode
      }
      payment {
        paymentId: id
        sessionId: session_id
        createdAt: created_at
        invoiceEmail: invoice_email
        status
        amount
        sandbox
        logs(order: { desc: created_at }) {
          message
          createdAt: created_at
        }
      }
    }
  }
`
