import { useMemo, ReactNode, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2localeString,
  dateIso2ExcelFormat,
} from '../../utils/formatDate'
import {
  IPlace,
  ParkingPlaceStatus,
} from '../../graphql/parking/queryParkingPlace'
import { ILog } from '../../graphql/shared/sharedTypes'
import { getParkingPlaceStatusColor } from '../../utils/getStatusColor'
import { Checkbox, FormControlLabel } from '@mui/material'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { handleRouteColumnFilter } from '../../utils/handleRouteColumnFilter'

export interface PlaceRow {
  id: string
  parking?: string
  level?: string
  place: string
  shared: string
  status: ParkingPlaceStatus
  leaseTerm: string
  validFromCSV: string
  validToCSV: string
  company: string
  tenant?: string
  info: {
    name: string
    message: string
  }
  building: string
  logs: ILog[]
}

const PlacesTable = ({
  places,
  children,
  setSelectedPlaces,
}: {
  places: IPlace[]
  children: ReactNode
  setSelectedPlaces: (v: PlaceRow[]) => void
}) => {
  const { t, i18n } = useTranslation(['parking_places'])
  const [hideNumbers, setHideNumbers] = useState(false)
  const lang = i18n.language
  const params = useParams()

  const invisibleColumns = {
    id: false,
    place: !hideNumbers,
    validFromCSV: false,
    validToCSV: false,
  }

  const csvHeaders = [
    {
      label: 'ID',
      key: 'id',
    },
    {
      label: 'Parking',
      key: 'parking',
    },
    {
      label: t('level'),
      key: 'level',
    },
    {
      label: t('place'),
      key: 'place',
    },
    {
      label: t('shared'),
      key: 'shared',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: t('from'),
      key: 'validFromCSV',
    },
    {
      label: t('to'),
      key: 'validToCSV',
    },
    {
      label: t('company'),
      key: 'company',
    },
    {
      label: t('tenant'),
      key: 'tenant',
    },
  ]

  const handleSelectRows = useCallback((selectedRowsData: PlaceRow[]) => {
    setSelectedPlaces(selectedRowsData)
  }, [])

  const columns: ColumnDef<PlaceRow, any>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'parking',
        header: 'Parking',
      },
      {
        accessorKey: 'level',
        header: t('level'),
      },
      {
        accessorKey: 'place',
        header: t('place'),
      },
      {
        accessorKey: 'shared',
        header: t('shared'),
        meta: {
          filterVariant: 'select',
          options: [
            { label: 'Tak', value: true },
            { label: 'Nie', value: false },
          ],
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        cell: (value) => t(value.getValue()),
        meta: {
          filterVariant: 'select',
          options: [
            {
              value: ParkingPlaceStatus.available,
              label: t(`parking_places:${ParkingPlaceStatus.available}`),
            },
            {
              value: ParkingPlaceStatus.unavailable,
              label: t(`parking_places:${ParkingPlaceStatus.unavailable}`),
            },
            {
              value: ParkingPlaceStatus.occupied,
              label: t(`parking_places:${ParkingPlaceStatus.occupied}`),
            },
            {
              value: ParkingPlaceStatus.reserved,
              label: t(`parking_places:${ParkingPlaceStatus.reserved}`),
            },
          ],
        },
      },
      {
        accessorKey: 'leaseTerm',
        header: t('lease_term'),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'validFromCSV',
        header: t('from'),
      },
      {
        accessorKey: 'validToCSV',
        header: t('to'),
      },
      {
        accessorKey: 'company',
        header: t('company'),
      },
      {
        accessorKey: 'tenant',
        header: t('tenant'),
      },
    ],
    [t]
  )

  const tableData: PlaceRow[] = useMemo(
    () =>
      places.map(
        ({
          id,
          name,
          status,
          parking,
          level,
          building,
          tenant,
          info,
          assignedTo,
          shared,
          reservations,
          logs,
        }) => ({
          id,
          parking: parking?.name,
          level: level?.name,
          place: name,
          shared: shared ? t('generic:yes') : t('generic:no'),
          status,
          leaseTerm: !!reservations[0]
            ? `${dateIso2localeString(reservations[0].validFrom, lang, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })} - ${dateIso2localeString(reservations[0].validTo, lang, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })}`
            : '-',
          validFromCSV:
            !!reservations[0] && dateIso2ExcelFormat(reservations[0].validFrom),
          validToCSV:
            !!reservations[0] && dateIso2ExcelFormat(reservations[0].validTo),
          company: tenant?.name || '-',
          tenant: assignedTo
            ? `${assignedTo.firstName} ${assignedTo.lastName}`
            : '-',
          building: building?.name,
          info,
          logs,
        })
      ) || [],
    [places, lang, t]
  )

  return (
    <Table
      columns={columns}
      data={tableData}
      columnVisibility={invisibleColumns}
      enableRowSelection
      csvExport
      sortByKey="parking"
      onSelectionChange={handleSelectRows}
      csvHeaders={csvHeaders}
      getCellProps={({ column, row }) =>
        column.id === 'status'
          ? {
              style: {
                color: getParkingPlaceStatusColor(row.original.status),
                fontWeight: 600,
              },
            }
          : {}
      }
      defaultColumnFilter={
        params ? handleRouteColumnFilter(`${params?.param}`) : []
      }
    >
      {children}
      <Wrapper>
        <FormControlLabel
          control={
            <Checkbox
              checked={hideNumbers}
              onChange={() => setHideNumbers(!hideNumbers)}
            />
          }
          label={`${t('hide_numbers')}`}
        />
      </Wrapper>
    </Table>
  )
}

export default PlacesTable

const Wrapper = styled.div`
  padding: 16px 20px;
`
