import gql from 'graphql-tag'
import { ReservationStatus } from '../shared/sharedTypes'

export interface IUpdateLockerReservationVars {
  id: string
  status: ReservationStatus
  tenant?: { id: string }
  validFrom: string
  validTo: string
}

export const UPDATE_LOCKER_RESERVATION = gql`
  mutation updateLockerReservation(
    $id: [ID!]
    $status: ReservationStatus
    $tenant: TenantRef
    $validFrom: DateTime
    $validTo: DateTime
  ) {
    updateLockerReservation(
      input: {
        filter: { id: $id }
        set: {
          status: $status
          tenant: $tenant
          valid_from: $validFrom
          valid_to: $validTo
        }
      }
    ) {
      lockerReservation {
        id
        valid_from
        valid_to
      }
    }
  }
`
