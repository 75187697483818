import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import DrawerHeader from '../Shared/DrawerHeader'
import DrawerButtons from '../Shared/DrawerButtons'
import { LockerRow } from './LockerTable'
import { Grid } from '@mui/material'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import styled from 'styled-components'
import FullScreenLoader from '../Shared/FullScreenLoader'
import Input from '../Shared/Input'
import LogsContainer from '../Shared/LogsContainer'
import {
  UPDATE_LOCKER,
  IUpdateLockerVars,
} from '../../graphql/lockers/updateLocker'
import {
  CHANGE_LOCKER_STATUS,
  IChangeLockerStatusVars,
  IChangeLockerStatusData,
} from '../../graphql/lockers/changeLockerStatus'
import { ApolloError, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import LockerStatusSelect from './LockerStatusSelect'
import { LockerStatus } from '../../graphql/lockers/queryLockers'
import { getCurrentActiveReservations } from '../../utils/getCurrentReservation'
import { dateIso2localeString } from '../../utils/formatDate'
import ErrorContainer from '../Shared/ErrorContainer'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'

type FormData = {
  key: string
  transponder: string
  notes: string
  status: string
}

const EditLocker = ({
  closeDetails,
  locker,
  refetch,
}: {
  closeDetails: () => void
  locker?: LockerRow
  refetch: () => void
}) => {
  const { t, i18n } = useTranslation(['lockers'])
  const lang = i18n.language
  const successNotify = (message: string) => toast.dark(message)
  const [errorMessage, setErrorMessage] = useState('')

  const formatDate = (date: string) =>
    dateIso2localeString(date, lang, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  const onUpdateCompleted = () => {
    successNotify(`${t('generic:success_message')}`)
    refetch()
    closeDetails()
  }

  const onCompleted = (data: IChangeLockerStatusData) => {
    const { status, code, error } = data.changeLockerStatus
    if (status) {
      successNotify(`${t('generic:success_message')}`)
      refetch()
      closeDetails()
    } else
      setErrorMessage(
        t(`code:${code}`) === `${code}` ? `${error}` : t(`code:${code}`)
      )
  }

  const [updateLocker, { loading }] = useMutation<IUpdateLockerVars>(
    UPDATE_LOCKER,
    {
      onCompleted: onUpdateCompleted,
      onError: (error: ApolloError) => setErrorMessage(error.message),
    }
  )

  const [changeLockerStatus, { loading: changeStatusLoading }] = useMutation<
    IChangeLockerStatusData,
    IChangeLockerStatusVars
  >(CHANGE_LOCKER_STATUS, {
    onCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  if (!locker || loading || changeStatusLoading) {
    return <FullScreenLoader />
  }
  const {
    id,
    buildingName,
    name,
    key,
    transponder,
    type,
    tenant,
    leaseTerm,
    notes,
    status,
    logs,
    user,
    userData,
    reservations,
  } = locker

  const activeReservations = getCurrentActiveReservations(reservations)

  const onSubmit = (data: FormData) => {
    const { key, transponder, notes, status } = data
    if (
      key !== locker.key ||
      transponder !== locker.transponder ||
      notes !== locker.notes
    ) {
      updateLocker({
        variables: {
          id,
          key,
          transponder,
          notes,
        },
      })
    }
    if (status !== locker.status) {
      changeLockerStatus({
        variables: {
          id,
          oldStatus: locker.status,
          newStatus: status,
        },
      })
    }
  }

  const details = [
    { label: t('building'), value: buildingName },
    { label: t('locker_type'), value: t(type) },
    { label: t('locker_number'), value: name },
    { label: t('lease_term'), value: leaseTerm },
    {
      label: t('extension'),
      value:
        activeReservations[1] &&
        `${formatDate(activeReservations[1].validFrom)} - ${formatDate(
          activeReservations[1].validTo
        )}`,
    },
    { label: t('tenant'), value: tenant },
    { label: t('user'), value: user },
    { label: 'Login', value: userData?.login },
    { label: 'Email', value: userData?.email.email },
    { label: t('company'), value: userData?.tenant?.name },
  ]

  return (
    <>
      <DrawerHeader title={t('edit_locker')} handleClose={closeDetails} />
      <DetailsWrapper>
        {details.map(
          ({ label, value }) =>
            value &&
            value !== '-' && (
              <RowStyled key={label}>
                <Text>{`${label}: `}</Text>
                <TextBold>{value}</TextBold>
              </RowStyled>
            )
        )}
      </DetailsWrapper>
      <DetailsWrapper>
        <FormStyled onSubmit={handleSubmit(onSubmit)}>
          <Wrapper>
            <LockerStatusSelect
              control={control}
              errors={errors}
              defaultValue={status}
            />
            {status === LockerStatus.available && (
              <Text>{t('change_status_info')}</Text>
            )}
          </Wrapper>
          <InputStyled
            label={t('key_number')}
            name={'key'}
            control={control}
            errors={errors}
            rules={{ required: `${t('form:field_required')}` }}
            defaultValue={key || ''}
            data-e2e="locker-key-input"
          />
          <InputStyled
            label={t('transponder_number')}
            name={'transponder'}
            control={control}
            errors={errors}
            defaultValue={transponder || ''}
            data-e2e="locker-transponder-input"
          />
          <InputStyled
            label={t('notes')}
            name={'notes'}
            control={control}
            errors={errors}
            multiline
            rows={4}
            defaultValue={notes || ''}
            data-e2e="locker-notes-input"
          />

          <DrawerButtons
            handleAccept={handleSubmit(onSubmit)}
            handleCancel={closeDetails}
          />
        </FormStyled>
        {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
      </DetailsWrapper>
      <DetailsWrapper>
        <LogsContainer logs={logs} />
      </DetailsWrapper>
    </>
  )
}

export default EditLocker
const RowStyled = styled(Grid)`
  padding: 6px;
`
const Text = styled(TextBody2Medium)`
  display: inline;
`
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
const InputStyled = styled(Input)`
  max-width: 600px;
`
const FormStyled = styled.form`
  padding: 16px 0 0;
`
const Wrapper = styled(Grid)`
  padding: 0 0 16px;
`
