import { dateIso2localeString } from './formatDate'

export const alertTimestamp = () => {
  return `\n${dateIso2localeString(new Date(), 'en', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })}`
}
