import gql from 'graphql-tag'
import { ID } from '../shared/IQueryData'
import { AccessCardType } from './setAccessCardForUser'

export enum UserAnonymizeStatus {
  empty = 'empty',
  to_anonymize = 'to_anonymize',
  anonymized = 'anonymized',
  error = 'error',
}

export interface IUser {
  login: string
  createdAt: string
  firstName: string
  lastName: string
  email: { email: string } | null
  workEmail: { email: string } | null
  mobilePhone: string
  tenant: {
    name: string
    id: string
    seos?: {
      disabled?: boolean
      id: ID
      prefix: string
    }
  } | null
  accessCard: {
    number: string
    type: AccessCardType
  } | null
  roles: { name: string }[]
  features: { name: string; id: string }[]
  lastMobileApp: string
  lastSeen: string
  apiVersion: number
  disabled: boolean
  anonymizedDate: string
  anonymizeStatus: UserAnonymizeStatus
  acceptances: {
    decision: boolean
    regulation: {
      regulationId: string
      title: string
    }
  }[]
  managesRestaurants: {
    id: string
    name: string
  }[]
  carPlates: {
    name: string
  }[]
}

export interface IQueryUsersData {
  queryUser: IUser[]
}

export const QUERY_USERS_LIST = gql`
  query queryUsersList {
    queryUser {
      login
      createdAt: created_at
      firstName: first_name
      lastName: last_name
      lastMobileApp: last_mobile_app
      lastSeen: last_seen
      apiVersion: api_version
      disabled
      anonymizedDate: anonymized_date
      anonymizeStatus: anonymize_status
      email {
        email
      }
      workEmail: work_email {
        email
      }
      tenant {
        name
        id
        seos {
          disabled
          id
          prefix
        }
      }
      accessCard: access_card {
        number
        type
      }
      roles {
        name
      }
      features {
        name
        id
      }
      acceptances {
        decision
        regulation {
          regulationId: id
          title
        }
      }
      managesRestaurants: manages_restaurants {
        id
        name
      }
      carPlates: car_plates {
        name
      }
    }
  }
`
