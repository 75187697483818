import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import BaseSelect, { OptionType } from '../Shared/BaseSelect'
import { QUERY_UNIT, IQueryUnitData } from '../../graphql/gastro/queryUnit'
import { IFormError, InputRules } from '../../types/FormTypes'

const UnitSelect: FC<{
  unit?: OptionType
  control: any
  errors: IFormError | any
  name: string
  rules?: InputRules
  isValid?: boolean
}> = ({ unit, control, errors, name, rules, isValid }) => {
  const { t } = useTranslation(['menu_item_form'])

  const { data, loading } = useQuery<IQueryUnitData>(QUERY_UNIT)

  const isSelectLoading = loading

  return (
    <BaseSelect
      isValid={isValid}
      defaultValue={unit}
      options={data?.queryUnit.map(({ name }) => ({
        label: name,
        value: name,
      }))}
      name={name}
      control={control}
      errors={errors}
      rules={rules}
      isLoading={isSelectLoading}
      isDisabled={isSelectLoading}
      isClearable={false}
      noOptionsMessage={() => t('no_units')}
      placeholder={t('unit')}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  )
}

export default UnitSelect
