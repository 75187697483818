import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IFormError } from '../../types/FormTypes'
import BaseSelect, { OptionType } from '../Shared/BaseSelect'
import EditSurveyOption from './EditSurveyOption'
import { themeColors } from '../../const/colors'
import { TextButton } from '../Styles/TextCustom'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { TextField } from '@mui/material'
import { styled as styledMaterial } from '@mui/material/styles'

type Option = {
  name: string
  nameEn: string
  id: string
}
type Type = 'radiobutton' | 'checkbox' | 'input' | 'textarea'

const InputStyled = styledMaterial(TextField)({
  width: 410,
  margin: '0 8px 16px',
  backgroundColor: themeColors.white,
  maxWidth: 500,
})

const SurveyQuestion = ({
  control,
  errors,
  number,
  options,
  questionId,
  name,
  nameEn,
  type,
  register,
  questionIndex,
}: {
  control: any
  errors: any
  number: number
  options?: Option[]
  questionId: string
  name?: string
  nameEn?: string
  type: Type
  register: any
  questionIndex: number
}) => {
  const { t } = useTranslation(['survey_form'])
  const questionTypes: OptionType[] = [
    { value: 'radiobutton', label: `${t('radiobutton')}` },
    { value: 'checkbox', label: `${t('checkbox')}` },
    { value: 'input', label: `${t('input')}` },
    { value: 'textarea', label: `${t('textarea')}` },
  ]
  const [questionOptions, setQuestionOptions] = useState<Option[]>()
  const [other, setOther] = useState(false)
  const inputTypes = ['input', 'textarea']

  const handleTypeChange = () => {}

  const renderOptions = () => {
    if (questionOptions) {
      const filteredOptions = questionOptions.filter(
        ({ name }) => name !== 'Inne'
      )
      return filteredOptions.map(({ name, nameEn, id }, i) => (
        <EditSurveyOption
          control={control}
          errors={errors}
          type={type}
          name={name}
          nameEn={nameEn}
          id={id}
          key={i}
          index={i}
          questionIndex={questionIndex}
          register={register}
        />
      ))
    }
  }

  useEffect(() => {
    if (options) {
      setQuestionOptions(options)
      setOther(options.some(({ name }) => name === 'Inne'))
    }
  }, [type, name, options])

  return (
    <Container>
      <Row>
        <Dot>{number}</Dot>
        <InputWrapper>
          <InputStyled
            label={t('question_content')}
            {...register(`${questionId}.pl`)}
            defaultValue={name || ''}
            control={control}
            errors={errors}
            rules={{ required: `${t('form:field_required')}` }}
            variant="outlined"
            color={'primary'}
            multiline
            data-e2e={`survey-input-question-name${number}`}
          />
          <InputStyled
            label={t('question_content_en')}
            {...register(`${questionId}.en`)}
            defaultValue={nameEn || ''}
            control={control}
            errors={errors}
            rules={{ required: `${t('form:field_required')}` }}
            variant="outlined"
            color={'primary'}
            fullWidth={true}
            multiline
            data-e2e={`survey-input-question-name-en${number}`}
          />
        </InputWrapper>
      </Row>
      <div data-e2e={`survey-question-type-select${number}`}>
        <SelectStyled
          value={questionTypes.find(({ value }) => value === type)}
          onChange={handleTypeChange}
          options={questionTypes}
          isClearable={false}
          noOptionsMessage={() => t('no_options')}
          placeholder={t('question_type')}
          isDisabled
        />
      </div>

      {renderOptions()}
      {inputTypes.includes(type) && (
        <DisabledInput
          label={t('option')}
          variant="outlined"
          color={'primary'}
          fullWidth={true}
          defaultValue={'Twoja opowiedź'}
          multiline={type === 'textarea'}
          rows={type === 'textarea' ? 2 : undefined}
          disabled
        />
      )}
      {other && (
        <OtherRow>
          {type === 'radiobutton' && <RadioIcon />}
          {type === 'checkbox' && <CheckboxIcon />}
          <TextStyled color={themeColors.primary}>{t('other')}</TextStyled>
        </OtherRow>
      )}
    </Container>
  )
}

export default SurveyQuestion
const Container = styled.div`
  padding: 24px 0 8px;
  border-bottom: 1px solid #bdbdbd;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const SelectStyled = styled(BaseSelect)`
  max-width: 500px;
  margin-bottom: 16px;
`
const Dot = styled.div`
  background-color: ${themeColors.primary};
  color: ${themeColors.white};
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  margin: 13px 0 16px 0;
  font-size: 19px;
  align-self: flex-start;
`
const RadioIcon = styled(RadioButtonUncheckedIcon)`
  color: ${themeColors.gray};
`
const CheckboxIcon = styled(CheckBoxOutlineBlankIcon)`
  color: ${themeColors.gray};
`
const TextStyled = styled(TextButton)`
  margin: 0 16px;
`
const OtherRow = styled(Row)`
  padding: 16px 0;
`
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const DisabledInput = styled(TextField)`
  background-color: ${themeColors.white};
  max-width: 500px;
  margin-bottom: 16px;
`
