import gql from 'graphql-tag'

export interface IDeleteExtrasVars {
  id: string
}

export const DELETE_EXTRAS = gql`
  mutation deleteQuickPromo($id: [ID!]) {
    deleteQuickPromo(filter: { id: $id }) {
      msg
    }
  }
`
