import React, { useState } from 'react'
import { ApolloError, useQuery } from '@apollo/client'
import {
  IQueryBannerData,
  QUERY_BANNER,
} from '../../graphql/banner/queryBanner'
import { useTranslation } from 'react-i18next'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import { BaseButton } from '../../components/Shared/BaseButton'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { HeaderContainer } from '../../components/Styles/CustomElementsStyled'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import BannerTable from '../../components/Banner/BannerTable'
import { Row } from '../../components/Banner/BannerTable'
import AddBanner from '../../components/Banner/AddBanner'
import EditBanner from '../../components/Banner/EditBanner'
import ErrorContainer from '../../components/Shared/ErrorContainer'

const BannerList = () => {
  const { t } = useTranslation(['banner'])
  const [addBanner, setAddBanner] = useState(false)
  const [editBanner, setEditBanner] = useState<Row | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState('')

  const { data, refetch, loading } = useQuery<IQueryBannerData>(QUERY_BANNER, {
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <HeaderContainer>
        <TypographyStyled variant="h6">{t('title')}</TypographyStyled>
        <BaseButton
          onClick={() => setAddBanner(true)}
          icon={faPlus as IconProp}
          data-e2e="add-banner-button"
        >
          {t('add_banner')}
        </BaseButton>
      </HeaderContainer>
      <BannerTable
        banners={data?.queryBanner}
        setEditBanner={setEditBanner}
        refetch={refetch}
      />
      {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
      <BaseDrawer open={!!editBanner} variant={'temporary'}>
        {!!editBanner && (
          <EditBanner
            closeDetails={() => setEditBanner(undefined)}
            banner={editBanner}
            updateList={refetch}
          />
        )}
      </BaseDrawer>
      <BaseDrawer open={addBanner} variant={'temporary'}>
        <AddBanner
          closeDetails={() => setAddBanner(false)}
          updateList={refetch}
        />
      </BaseDrawer>
    </>
  )
}

export default BannerList

const TypographyStyled = styled(Typography)`
  font-weight: 600;
`
