import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  QUERY_LOG_LINE,
  IQueryLogLineData,
  IQueryLogLineVars,
} from '../../graphql/shared/queryLogLinesWithParameter'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { useQuery } from '@apollo/client'
import { dateIso2localeString } from '../../utils/formatDate'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface Row {
  createdAt: string
  title?: string
  body?: string
  login?: string
  target?: string
}

const PushListScreen = () => {
  const { t, i18n } = useTranslation(['push'])
  const lang = i18n.language

  const { data, loading } = useQuery<IQueryLogLineData, IQueryLogLineVars>(
    QUERY_LOG_LINE,
    {
      fetchPolicy: 'no-cache',
      variables: {
        name: 'push.message',
      },
    }
  )

  const csvHeaders = [
    { label: t('createdAt'), key: 'createdAt' },
    { label: t('title'), key: 'title' },
    { label: t('body'), key: 'body' },
    { label: t('author'), key: 'author' },
    { label: t('target'), key: 'target' },
  ]

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        header: t('createdAt'),
        accessorKey: 'createdAt',
        cell: (value) =>
          !!value && dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        header: t('title'),
        accessorKey: 'title',
      },
      {
        header: t('body'),
        accessorKey: 'body',
      },
      {
        header: t('author'),
        accessorKey: 'login',
      },
      {
        header: 'Target',
        accessorKey: 'target',
        cell: (value) => t(value.getValue().toLocaleLowerCase()),
      },
    ],
    [t, lang]
  )

  const tableData: Row[] = useMemo(
    () =>
      data?.queryLogLinesWithParameter.map(({ createdAt, parameters }) => ({
        title: parameters?.find(({ name }) => name === 'title')?.value,
        body: parameters?.find(({ name }) => name === 'body')?.value,
        login: parameters?.find(({ name }) => name === 'login')?.value,
        target: parameters?.find(({ name }) => name === 'target')?.value,
        createdAt,
      })) || [],
    [data]
  )

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">{t('push_list')}</TypographyStyled>
      <Table
        columns={columns}
        data={tableData}
        csvExport
        enableRowSelection
        csvHeaders={csvHeaders}
        sortByKey="createdAt"
        sortDesc
      />
    </>
  )
}

export default PushListScreen
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
