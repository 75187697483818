import React, { useState } from 'react'
import { TextH4Bold, TextInfo } from '../Styles/TextCustom'
import { Img } from '../Styles/CustomElementsStyled'
import image from '../../assets/images/email-sent.png'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { BaseTextButton } from '../Shared/BaseButton'
import { themeColors } from '../../const/colors'
import {
  IEmailVars,
  RESEND_VERIFICATION_CODE,
} from '../../graphql/auth/resendVerificationCode'
import { ApolloError, useMutation } from '@apollo/client'
import { IQueryBasicData } from '../../graphql/shared/IQueryData'
import { toast } from 'react-toastify'
import ErrorContainer from '../Shared/ErrorContainer'

const ConfirmationEmailSent = ({ email }: { email: string }) => {
  const { t } = useTranslation(['confirm_email_sent_screen'])
  const [errorMessage, setErrorMessage] = useState('')
  const successNotify = (message: string) => toast.dark(message)

  const onResendCompleted = (data: IQueryBasicData) => {
    if (data) {
      successNotify(`${t('header')} ${email}`)
    }
  }

  const [resendVerificationEmail, { loading }] = useMutation<
    IQueryBasicData,
    IEmailVars
  >(RESEND_VERIFICATION_CODE, {
    onCompleted: onResendCompleted,
    onError: (e: ApolloError) => setErrorMessage(e.message),
  })

  const handleResendVerificationEmail = async () => {
    await resendVerificationEmail({ variables: { email } })
  }
  return (
    <Container>
      <TextH4Bold>{t('header')}</TextH4Bold>
      <TextInfo>{email}</TextInfo>
      <TextInfo>{t('info')}</TextInfo>
      <Img src={image} alt="img" width="515" />
      <TextInfo>{t('info2')}</TextInfo>
      <BaseTextButton
        disabled={loading}
        onClick={handleResendVerificationEmail}
        color={themeColors.primary}
      >
        {t('btn:send_link_again')}
      </BaseTextButton>
      {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
    </Container>
  )
}

export default ConfirmationEmailSent
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
`
