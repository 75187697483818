import gql from 'graphql-tag'

export interface IAssignRestaurantVars {
  login: string
  restaurant: string
}
export interface IAssignRestaurantData {
  updateUser: {
    user: {
      login: string
      managesRestaurants: {
        value: string
        label: string
      }[]
    }
  }
}

export const ASSIGN_USER_RESTAURANT = gql`
  mutation assignRestaurant($login: String!, $restaurant: ID!) {
    updateUser(
      input: {
        filter: { login: { eq: $login } }
        set: { manages_restaurants: [{ id: $restaurant }] }
      }
    ) {
      user {
        login
        managesRestaurants: manages_restaurants {
          value: id
          label: name
        }
      }
    }
  }
`
