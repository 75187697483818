import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'
import { IssueStatus } from './queryIssue'

export interface IResolveIssueVars {
  id: string
  comment?: string
  status?: IssueStatus
}
export interface IResolveIssueData {
  resolveIssue: IQueryBasicData
}

export const RESOLVE_ISSUE = gql`
  mutation ResolveIssue($id: ID!, $comment: String, $status: IssueStatus) {
    resolveIssue(id: $id, comment: $comment, status: $status) {
      status
      code
      message
      error
    }
  }
`
