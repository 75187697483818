import gql from 'graphql-tag'
import { ISubscription } from './queryParkingSubscriptions'

export interface IGetParkingSubscriptionVars {
  id?: string
}

export interface IGetParkingSubscriptionData {
  getParkingSubscription: ISubscription
}

export const GET_PARKING_SUBSCRIPTION = gql`
  query getParkingSubscription($id: ID!) {
    getParkingSubscription(id: $id) {
      id
      status
      validFrom: valid_from
      validTo: valid_to
      createdAt: created_at
      carPlate: car_plate
      carPlate2: car_plate_2
      parkingPass: parking_pass {
        name
        nameEn: name_en
        parking {
          operator
          name
        }
      }
      activeParkingTicket: active_parking_ticket {
        payment {
          id
          amount
        }
      }
      pendingParkingTicket: pending_parking_ticket {
        payment {
          id
          amount
        }
      }
      logs(order: { desc: created_at }) {
        message
        createdAt: created_at
        parameters {
          name
          value
          type
        }
      }
      tickets: parking_tickets(order: { desc: valid_to }) {
        id
        saleId: sale_id
        validFrom: valid_from
        validTo: valid_to
        carPlate: car_plate
        carPlate2: car_plate_2
        payment {
          id
          sessionId: session_id
          amount
          createdAt: created_at
          status
          externalId: external_order_id
          logs(order: { desc: created_at }) {
            message
            createdAt: created_at
          }
        }
        reservations @cascade(fields: "place") {
          place(filter: { shared: false }) {
            name
            shared
            info {
              message
            }
          }
        }
      }
      user {
        login
        email {
          email
        }
        tenant {
          name
        }
      }
    }
  }
`
