import { gql } from '@apollo/client'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IChangeForgottenPasswordVars {
  email: string
  code: string
  password: string
  app: string
}
export interface IChangeForgottenPasswordData {
  changeForgottenPassword: IQueryBasicData
}

export const CHANGE_FORGOTTEN_PASSWORD = gql`
  mutation ChangeForgottenPassword(
    $email: String!
    $code: String!
    $password: String
    $app: String
  ) {
    changeForgottenPassword(
      email: $email
      verification_code: $code
      password: $password
      service: "olivia"
      app: $app
    ) {
      status
      code
      message
      error
    }
  }
`
