import React, { FC, useRef } from 'react'
import styled from 'styled-components'
import { themeColors } from '../const/colors'
import { ToolbarPlaceholder } from './Styles/ToolbarPlaceholder'

/**
 * PageContentContainer - 100vh, top toolbar corrected, page container
 *
 * @param children page content
 */

const PageContentContainer: FC<{ bgColor?: string; children: any }> = ({
  bgColor = themeColors.white,
  children,
  ...rest
}) => {
  const placeholderRef = useRef<HTMLDivElement | any>()

  return (
    <Page $bgColor={bgColor}>
      <ToolbarPlaceholder ref={placeholderRef} />
      <Content
        $toolbarHeight={placeholderRef.current?.clientHeight || 64}
        {...rest}
      >
        {children}
      </Content>
    </Page>
  )
}

const Page = styled.div<{ $bgColor: string }>`
  flex-grow: 1;
  background: ${({ $bgColor }) => $bgColor};
  height: 100vh;
  overflow: hidden;
`
const Content = styled.main<{ $toolbarHeight: number }>`
  overflow-y: auto;
  ${({ $toolbarHeight }) => `height: calc(100vh - ${$toolbarHeight}px);`}
`

export default PageContentContainer
