import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseButton from '../Shared/CloseButton'
import { TextH6 } from '../Styles/TextCustom'

const DrawerHeader = ({
  handleClose,
  title,
  children,
}: {
  handleClose: () => void
  title: string
  children?: ReactNode
}) => {
  const { t } = useTranslation()
  return (
    <Row>
      <div>
        <CloseButton onClick={handleClose} data-e2e="close-drawer-button" />
        <MainTitle data-e2e="drawer-title">{t(title)}</MainTitle>
      </div>
      {children}
    </Row>
  )
}

export default DrawerHeader

const Row = styled(Grid)`
  display: flex;
  flex-wrap: nowrap;
  padding: 16px 0;
  justify-content: space-between;
  align-items: center;
`
const MainTitle = styled(TextH6)`
  display: inline;
  padding-left: 16px;
  font-weight: 500;
`
