import styled from 'styled-components'
import { FormControl, MenuItem, InputLabel } from '@mui/material'
import Select from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import { TextBody1Medium } from '../Styles/TextCustom'
import { themeColors } from '../../const/colors'
import {
  QUERY_TENANT_PARKING_POOL,
  IQueryTenantParkingPoolData,
} from '../../graphql/tenant/queryTenantParkingPool'
import { useQuery } from '@apollo/client'
import FullScreenLoader from '../Shared/FullScreenLoader'

const ParkingPoolSelect = ({
  value,
  setValue,
  short = false,
}: {
  value: string
  setValue: (v: string) => void
  short?: boolean
}) => {
  const { t } = useTranslation(['parking_places'])
  const { data, loading } = useQuery<IQueryTenantParkingPoolData>(
    QUERY_TENANT_PARKING_POOL,
    {
      fetchPolicy: 'no-cache',
    }
  )

  if (loading) {
    return <FullScreenLoader />
  }

  if (!data) {
    return null
  }

  const handleChange = (event: any) => {
    setValue(event.target.value as string)
  }

  return (
    <FormControl>
      <InputLabel id="select-label">{t('company')}</InputLabel>
      <SelectStyled
        short={short ? 'short' : ''}
        labelId="select-label"
        value={value}
        label="firma"
        onChange={handleChange}
        data-e2e="tenant-select"
        MenuProps={{
          sx: {
            '& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper':
              {
                maxHeight: 350,
              },
          },
        }}
      >
        {data.queryTenant.map(({ name, parkingPool }, i) => (
          <MenuItem key={i} value={parkingPool?.id}>
            <Text>{name}</Text>
          </MenuItem>
        ))}
      </SelectStyled>
    </FormControl>
  )
}

export default ParkingPoolSelect

const SelectStyled = styled(Select)<{ short: string }>`
  width: ${(props) => (!!props.short ? '174px' : '500px')};
  background-color: ${themeColors.white};
`
const Text = styled(TextBody1Medium)`
  margin: 0;
`
