import styled from 'styled-components'
import { FormControl, MenuItem, InputLabel } from '@mui/material'
import Select from '@mui/material/Select'
import { ParkingPlaceStatus } from '../../graphql/parking/queryParkingPlace'
import { useTranslation } from 'react-i18next'
import { TextBody1Medium } from '../Styles/TextCustom'
import { themeColors } from '../../const/colors'

const PlaceStatusSelect = ({
  value,
  setValue,
  short = false,
}: {
  value: ParkingPlaceStatus | ''
  setValue: (v: ParkingPlaceStatus | '') => void
  short?: boolean
}) => {
  const { t } = useTranslation(['parking_places'])
  const data = [
    {
      name: ParkingPlaceStatus.available,
    },
    {
      name: ParkingPlaceStatus.unavailable,
    },
    {
      name: ParkingPlaceStatus.occupied,
    },
    {
      name: ParkingPlaceStatus.reserved,
    },
  ]

  const handleChange = (event: any) => {
    setValue(event.target.value as ParkingPlaceStatus)
  }

  return (
    <FormControl>
      <InputLabel id="select-label">{'Status'}</InputLabel>
      <SelectStyled
        short={short ? 'short' : ''}
        labelId="select-label"
        value={value}
        label="status"
        onChange={handleChange}
        data-e2e="place-status-select"
      >
        {data.map(({ name }, i) => (
          <MenuItem key={i} value={name}>
            <Text>{t(name)}</Text>
          </MenuItem>
        ))}
      </SelectStyled>
    </FormControl>
  )
}

export default PlaceStatusSelect

const SelectStyled = styled(Select)<{ short: string }>`
  width: ${(props) => (!!props.short ? '174px' : '600px')};
  background-color: ${themeColors.white};
`
const Text = styled(TextBody1Medium)`
  margin: 0;
`
