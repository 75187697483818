import React from 'react'
import styled from 'styled-components'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Input from '../../components/Shared/Input'
import {
  TextBody1Medium,
  TextBody2Medium,
} from '../../components/Styles/TextCustom'
import RadioGroupForm from '../../components/Shared/RadioGroupForm'
import Checkbox from '../../components/Shared/Checkbox'
import TenantSelect from '../../components/Shared/TenantSelect'
import MobileViewSelect from '../Shared/MobileViewSelect'
import ProfileLangSelect from '../Shared/ProfileLangSelect'
import { themeColors } from '../../const/colors'

interface IPushFormProps {
  errors: any
  control: any
  target: string
  setTarget: (v: string) => void
}

const PushForm = (props: IPushFormProps) => {
  const { control, errors, target, setTarget } = props
  const { t } = useTranslation(['push'])
  const options = [
    { value: 'All', label: t('all') },
    { value: 'Residents', label: t('residents') },
    { value: 'NonResidents', label: t('non_residents') },
    { value: 'Employees', label: t('employees') },
    { value: 'Developers', label: t('developers') },
    { value: 'Testers', label: t('testers') },
    { value: 'logins', label: t('logins') },
    { value: 'tokens', label: t('tokens') },
    { value: 'login', label: t('to_me') },
  ]

  return (
    <>
      <TypographyStyled variant="h6">{t('push_notification')}</TypographyStyled>
      <TextBody1Medium>{`${t('select_language')}:`}</TextBody1Medium>
      <SelectWrapper>
        <ProfileLangSelect
          errors={errors}
          control={control}
          defaultValue={'none'}
        />
        <TextBody2Medium color={themeColors.gray}>
          {t('select_language_tooltip')}
        </TextBody2Medium>
      </SelectWrapper>
      <Input
        label={t('title')}
        name="title"
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
      />
      <Input
        label={t('message')}
        name="body"
        control={control}
        errors={errors}
        multiline
        rows={4}
        inputProps={{ maxLength: 180 }}
        rules={{ required: `${t('form:field_required')}` }}
      />
      <TargetTitle>{t('target')}</TargetTitle>
      <Wrapper>
        <RadioGroupForm value={target} setValue={setTarget} options={options} />
      </Wrapper>
      {target === 'logins' && (
        <Input
          label={t('logins')}
          name="logins"
          control={control}
          errors={errors}
          multiline
          rows={4}
          rules={{ required: `${t('form:field_required')}` }}
        />
      )}
      {target === 'tokens' && (
        <Input
          label={t('tokens')}
          name="tokens"
          control={control}
          errors={errors}
          multiline
          rows={4}
          rules={{ required: `${t('form:field_required')}` }}
        />
      )}
      {target === 'Employees' && (
        <SelectWrapper>
          <TenantSelect control={control} errors={errors} />
        </SelectWrapper>
      )}
      <Checkbox control={control} errors={errors} name="forMarketing">
        {t('marketing_message')}
      </Checkbox>
      <TextBody1Medium>{t('mobile_view_target_title')}</TextBody1Medium>
      <MobileViewSelect control={control} errors={errors} />
      <TextBody2Medium color={themeColors.gray}>
        {t('mobile_view_target_info')}
      </TextBody2Medium>
    </>
  )
}

export default PushForm

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
const Wrapper = styled.div`
  margin: 16px 0 16px 16px;
`
const TargetTitle = styled(TextBody1Medium)`
  margin: 0;
`
const SelectWrapper = styled.div`
  max-width: 500px;
  padding: 0 0 16px;
`
