import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import styled from 'styled-components'
import { themeColors } from '../../const/colors'
import { LockerType } from '../../graphql/lockers/queryLockers'

export interface ILocker {
  name: string
  key: string
  transponder: string
  type: LockerType
}

const LockerNumbersContainer = ({
  lockers,
  building,
}: {
  lockers: ILocker[]
  building: string
}) => {
  const { t } = useTranslation(['lockers'])

  const getLockerNumbers = (lockers: ILocker[], value: LockerType) =>
    lockers.filter(({ type }) => type === value).map(({ name }) => name)

  const room = getLockerNumbers(lockers, LockerType.locker_room)
  const garage = getLockerNumbers(lockers, LockerType.garage)

  const details = [
    {
      label: t('locker_room'),
      value: `${building}, ${t('numbers')}: ${room}`,
      amount: room.length,
    },
    {
      label: t('garage'),
      value: `${building}, ${t('numbers')}: ${garage}`,
      amount: garage.length,
    },
  ]

  return (
    <>
      {details.map(
        ({ label, value, amount }) =>
          !!amount && (
            <Row key={label} container>
              <Grid xs={11} item>
                <TextBody2Bold>{`${label}: `}</TextBody2Bold>
                <TextBody2Medium>{value}</TextBody2Medium>
              </Grid>
              <Grid xs={1} item>
                <Text color={themeColors.primary}>{`${amount}x`}</Text>
              </Grid>
            </Row>
          )
      )}
    </>
  )
}

export default LockerNumbersContainer
const Row = styled(Grid)`
  padding: 6px;
  border-bottom: 1px solid #b5b5b5;
`
const Text = styled(TextBody2Bold)`
  text-align: right;
`
