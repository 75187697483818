import React from 'react'
import styled from 'styled-components'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FAIcon = ({ icon }: { icon: IconProp }) => (
  <FAIconWrap>
    <FontAwesomeIcon icon={icon} size='lg' />
  </FAIconWrap>
)

const FAIconWrap = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default FAIcon
