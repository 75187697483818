import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  IFormError,
  setValueType,
  IFormRegulation,
} from '../../types/FormTypes'
import Checkbox from '../Shared/Checkbox'
import renderRegulationContent from '../../utils/regulationsUtils'
import styled from 'styled-components'

const SignUpCheckboxes: React.FC<{
  control: any
  errors: IFormError | any
  regulations: IFormRegulation[]
  setValue: setValueType
}> = ({ control, errors, regulations, setValue }) => {
  const { t } = useTranslation(['form'])

  const handleCheckAllCheckboxes = (value: boolean) => {
    regulations.forEach(({ id: checkboxId }) => setValue(checkboxId, value))
  }

  return (
    <Container>
      <Checkbox
        name="checkAll"
        control={control}
        errors={errors}
        onChangeCallback={handleCheckAllCheckboxes}
      >
        {t('check_all')}
      </Checkbox>
      {regulations.map(({ id, required, content }) => {
        return (
          <Checkbox
            key={id}
            name={id}
            control={control}
            errors={errors}
            rules={{ required }}
          >
            <Text> {renderRegulationContent(content)}</Text>
          </Checkbox>
        )
      })}
    </Container>
  )
}

export default SignUpCheckboxes
const Container = styled.div`
  width: 505px;
`
const Text = styled.span`
  text-wrap: wrap;
  margin: 0;
`
