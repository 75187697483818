import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import DrawerHeader from '../Shared/DrawerHeader'
import DrawerButtons from '../Shared/DrawerButtons'
import { TextBody2Medium } from '../Styles/TextCustom'
import styled from 'styled-components'
import Input from '../Shared/Input'
import PlaceStatusSelect from './PlaceStatusSelect'
import { ParkingPlaceStatus } from '../../graphql/parking/queryParkingPlace'
import { PlaceRow } from './PlacesTable'
import {
  CHANGE_PARKING_PLACE_STATUS,
  IChangeParkingPlaceStatusVars,
} from '../../graphql/parking/changeParkingPlaceStatus'
import { ApolloError, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import FullScreenLoader from '../Shared/FullScreenLoader'
import ErrorContainer from '../Shared/ErrorContainer'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'

const EditPlaces = ({
  closeDetails,
  parking,
  status,
  places,
  refetch,
}: {
  closeDetails: () => void
  parking: string
  status: ParkingPlaceStatus | ''
  places: PlaceRow[]
  refetch: () => void
}) => {
  const { t } = useTranslation(['parking_places'])
  const [newStatus, setNewStatus] = useState<ParkingPlaceStatus | ''>(status)
  const [errorMessage, setErrorMessage] = useState('')
  const successNotify = (message: string) => toast.dark(message)
  const ids = places.map(({ id }) => id)

  const onCompleted = () => {
    successNotify(`${t('generic:success_message')}`)
    refetch()
    closeDetails()
  }

  const [changeParkingPlaceStatus, { loading }] =
    useMutation<IChangeParkingPlaceStatusVars>(CHANGE_PARKING_PLACE_STATUS, {
      onCompleted,
      onError: (error: ApolloError) => setErrorMessage(error.message),
    })

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (data: any) => {
    const { comment } = data
    if (ids.length) {
      changeParkingPlaceStatus({
        variables: {
          ids,
          newStatus,
          comment,
        },
      })
    }
  }

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <DrawerHeader
        title={t('change_places_status')}
        handleClose={closeDetails}
      />
      <DetailsWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextWithPadding>{t('new_status')}:</TextWithPadding>
          <SelectWrapper data-e2e="edit-places-status-select">
            <PlaceStatusSelect value={newStatus} setValue={setNewStatus} />
          </SelectWrapper>
          <InputStyled
            label={t('comment')}
            name={'comment'}
            control={control}
            errors={errors}
            rules={{ required: `${t('form:field_required')}` }}
            multiline
            rows={4}
            data-e2e="change-status-comment"
          />
          <DrawerButtons
            handleAccept={handleSubmit(onSubmit)}
            handleCancel={closeDetails}
          />
          {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
        </form>
      </DetailsWrapper>
    </>
  )
}

export default EditPlaces

const InputStyled = styled(Input)`
  max-width: 600px;
`
const TextWithPadding = styled(TextBody2Medium)`
  padding: 16px 0;
`
const SelectWrapper = styled.div`
  padding-bottom: 16px;
`
