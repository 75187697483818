import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Title } from '../Styles/TextCustom'
import { IconButton, Tooltip } from '@mui/material'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IParkingPoolProfile } from '../../graphql/tenant/queryTenantWithDomains'
import ParkingPoolProfile from './ParkingPoolProfile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import BaseModal from '../../components/Shared/BaseModal'
import AddProfileForm from './AddProfileForm'
import { themeColors } from '../../const/colors'

export interface IParkingPoolData {
  id: string
  profiles: IParkingPoolProfile[]
}

const ParkingPoolProfiles = ({
  handleUpdateTenants,
  parkingPool,
  refetchTenant,
}: {
  handleUpdateTenants: () => void
  parkingPool?: IParkingPoolData
  refetchTenant: () => void
}) => {
  const { t } = useTranslation(['tenant'])
  const [open, setOpen] = useState(false)

  if (!parkingPool) {
    return null
  }

  const { id, profiles } = parkingPool

  return (
    <>
      <DetailsWrapper>
        <Row>
          <Title>{t('parking_pool_profiles')}</Title>
          <Tooltip title={`${t('add_profile')}`}>
            <IconButton
              onClick={() => setOpen(true)}
              edge="end"
              color="primary"
              size="large"
              data-e2e="add-profile-button"
            >
              <FontAwesomeIcon icon={faPlusCircle as IconProp} size="lg" />
            </IconButton>
          </Tooltip>
        </Row>

        {profiles.map((item, i) => (
          <ParkingPoolProfile
            profile={item}
            refetchTenant={refetchTenant}
            key={i}
          />
        ))}
      </DetailsWrapper>
      <BaseModal
        title={t('add_profile')}
        open={open}
        actions={false}
        handleClose={() => setOpen(false)}
        closeButton
      >
        <AddProfileForm
          parkingPoolId={id}
          handleClose={() => setOpen(false)}
          handleUpdateTenants={refetchTenant}
        />
      </BaseModal>
    </>
  )
}

export default ParkingPoolProfiles

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const DetailsWrapper = styled.div`
  background-color: ${themeColors.white};
  border-radius: 16px;
  padding: 0 16px 20px;
  margin: 16px 0;
`
