import gql from 'graphql-tag'
import { IssueType } from './queryIssue'
import { ILog } from '../shared/sharedTypes'

export interface IIssue {
  id: string
  type: IssueType
  logs: ILog[]
}

export interface IGetIssueVars {
  id: string
}

export interface IGetIssueData {
  getIssue: IIssue
}

export const GET_ISSUE = gql`
  query getIssue($id: ID!) {
    getIssue(id: $id) {
      id
      type
      logs {
        message
        createdAt: created_at
        parameters {
          name
          value
          type
        }
      }
    }
  }
`
