import { Chip } from '@mui/material'
import { styled } from '@mui/material/styles'

const ChipStyled = styled(Chip)({
  width: 48,
  height: 48,
  borderRadius: '50%',
  marginRight: 11,
  fontWeight: 600,
  '& .MuiChip-label': {
    padding: '0 8px',
  },
})

const WeekdayChip = ({
  active,
  label,
  ...rest
}: {
  active: boolean
  label: string
  [k: string]: any
}) => {
  return (
    <ChipStyled
      label={label}
      variant={active ? 'filled' : 'outlined'}
      color="primary"
      clickable
      {...rest}
    />
  )
}

export default WeekdayChip
