import gql from 'graphql-tag'

export const DELETE_MENU_ITEM = gql`
  mutation DeleteMenuItem($id: [ID!]!) {
    deleteMenuItem(filter: { id: $id }) {
      menuItem {
        id
        name
      }
    }
  }
`
