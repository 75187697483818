import { useQuery } from '@apollo/client'
import {
  GET_SURVEY_QUESTION_ANSWERS,
  IGetSurveyQuestionAnswersData,
} from '../../graphql/survey/getSurveyQuestionAnswers'
import BaseSelect from '../Shared/BaseSelect'
import styled from 'styled-components'
import React, { useState, FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import BarChart from '../BarChart'
import FullScreenLoader from '../Shared/FullScreenLoader'

interface ISurveyResultsChart {
  surveyId: string
}

type Question = {
  label: string
  value: string
}

type Chart = {
  answer: string
  votes: number
}

const SurveyResultsChart: FC<ISurveyResultsChart> = ({ surveyId }) => {
  const { t } = useTranslation(['survey'])
  const [questions, setQuestions] = useState<Question[]>()
  const [selectedQuestion, setSelectedQuestion] = useState<any>()
  const [chartData, setChartData] = useState<Chart[]>()
  const [loading, setLoading] = useState(true)

  const onGetSurveyCompleted = (data: IGetSurveyQuestionAnswersData) => {
    const types = ['radiobutton', 'checkbox']
    !!data &&
      setQuestions(
        data.getSurvey.questions
          .filter(({ type }) => types.includes(type))
          .map(({ name, id }) => ({
            label: name,
            value: id,
          }))
      )
  }

  const { data: survey } = useQuery<IGetSurveyQuestionAnswersData>(
    GET_SURVEY_QUESTION_ANSWERS,
    {
      variables: {
        id: surveyId,
      },
      onCompleted: onGetSurveyCompleted,
      fetchPolicy: 'no-cache',
    }
  )

  useEffect(() => {
    questions && setSelectedQuestion(questions[0])
  }, [questions])

  useEffect(() => {
    if (selectedQuestion && survey) {
      const options = survey.getSurvey.questions.find(
        ({ id }) => id === selectedQuestion.value
      )?.options
      if (options) {
        const chartOptions = options.map(({ id, name }) => {
          const votes = survey?.getSurvey.questions
            .filter(({ id }) => id === selectedQuestion.value)[0]
            .answers.map(({ responseOptions }) => responseOptions)
            .map((item) => item[0])
            .filter((item) => item.id === id).length

          return {
            answer: name,
            votes,
          }
        })
        setChartData(chartOptions)
      }
    }
    setLoading(false)
  }, [survey, selectedQuestion])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <CategorySelectorContainer data-e2e="survey-select-survey">
        <BaseSelect<Question, true>
          onChange={(value) => setSelectedQuestion(value)}
          options={questions}
          isClearable={false}
          noOptionsMessage={() => t('no_options')}
          placeholder={t('show_answers_to_question')}
          rules={{ required: `${t('form:field_required')}` }}
          value={selectedQuestion}
        />
      </CategorySelectorContainer>

      {chartData && (
        <BarChart
          data={chartData}
          argumentFieldName={'votes'}
          title={t('responses')}
          valueFieldName={'answer'}
        />
      )}
    </>
  )
}

export default SurveyResultsChart

const CategorySelectorContainer = styled.div`
  max-width: 600px;
  margin-bottom: 20px;
`
