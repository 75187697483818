import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IconButton } from '@mui/material'
import { useState } from 'react'
import { IParkingPoolProfile } from '../../graphql/tenant/queryTenantWithDomains'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { themeColors } from '../../const/colors'
import { TextBody1 } from '../Styles/TextCustom'
import BaseModal from '../Shared/BaseModal'
import EdiProfileForm from './EditProfileForm'

const ParkingPoolProfile = ({
  profile,
  refetchTenant,
}: {
  profile: IParkingPoolProfile
  refetchTenant: () => void
}) => {
  const { t } = useTranslation(['parking_pool'])
  const [open, setOpen] = useState(false)
  const { name, disabled } = profile

  const color = disabled ? themeColors.lightGray : themeColors.primary

  return (
    <>
      <Wrapper borderColor={color} data-e2e="profile">
        <TextBody1>{name}</TextBody1>

        <IconButton
          onClick={() => setOpen(true)}
          edge="end"
          color="primary"
          size="small"
          data-e2e="edit-profile-button"
        >
          <FontAwesomeIcon icon={faEdit as IconProp} size="1x" />
        </IconButton>
      </Wrapper>
      <BaseModal
        title={t('edit_profile')}
        open={open}
        actions={false}
        handleClose={() => setOpen(false)}
        closeButton
      >
        <EdiProfileForm
          profile={profile}
          handleClose={() => setOpen(false)}
          handleUpdateTenants={refetchTenant}
        />
      </BaseModal>
    </>
  )
}

export default ParkingPoolProfile

const Wrapper = styled.div<{
  borderColor?: string
}>`
  margin: 8px;
  height: 56px;
  width: 75%;
  border-radius: 20px;
  align-items: center;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1.5px solid ${themeColors.primary};
  ${(props) => props.borderColor && `border-color: ${props.borderColor}`}
`
const ActionsWrapper = styled.div`
  display: flex;
  width: 15%;
  justify-content: space-evenly;
`
