import gql from 'graphql-tag'
import { IReservation, IReservationUser } from '../shared/sharedTypes'
import { IBuilding } from './queryLockerGroupReservation'

export enum LockerStatus {
  available = 'available',
  unavailable = 'unavailable',
  broken = 'broken',
  ready_to_rent = 'ready_to_rent',
  rented = 'rented',
  service = 'service',
  should_be_returned = 'should_be_returned',
  error = 'error',
}

export enum LockerType {
  garage = 'garage',
  locker_room = 'locker_room',
}

export interface ILocker {
  id: string
  type: LockerType
  name: string
  status: LockerStatus
  building: IBuilding
  transponder: string
  key: string
  notes: string
  logs: {
    createdAt: string
    message: string
  }[]
  currentUser?: IReservationUser
  currentTenant?: {
    name: string
    emails: {
      email: string
    }[]
  }
  reservations: IReservation[]
  currentReservation?: IReservation
}

export interface IQueryLockersData {
  lockers: ILocker[]
}

export const QUERY_LOCKERS = gql`
  query queryLockers {
    lockers: queryLocker {
      id
      type
      name
      status
      building {
        name
        location
        seller {
          name
          zipcode
          street
          city
        }
      }
      transponder
      key
      notes
      logs(order: { desc: created_at }) {
        createdAt: created_at
        message
      }
      reservations(order: { desc: created_at }) {
        id
        createdAt: created_at
        validFrom: valid_from
        validTo: valid_to
        status
        locker {
          status
        }
        user {
          email {
            email
          }
          firstName: first_name
          lastName: last_name
          login
          tenant {
            name
          }
        }
        tenant {
          id
          name
          emails {
            email
          }
        }
        groupReservation: group_reservation {
          tenantName: tenant_name
        }
        logs(order: { desc: created_at }) {
          createdAt: created_at
          message
        }
        order {
          orderId: id
          product {
            name
            withShower: with_shower
            seller {
              name
              nip
              street
              city
              zipcode
            }
          }
        }
      }
    }
  }
`
