import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { ApolloError, useMutation } from '@apollo/client'
import { Grid } from '@mui/material'
import { BaseButton } from '../Shared/BaseButton'
import Input from '../Shared/Input'
import {
  UPDATE_MOBILE_ACCESS_ACCOUNT_LIMIT,
  IUpdateMobileAccessAccountLimitData,
  IUpdateMobileAccessAccountLimitVars,
} from '../../graphql/hid/updateMobileAccessAccountLimit'
import {
  DESTROY_HID_CONFIG,
  IDestroyHIDConfigData,
  IDestroyHIDConfigVars,
} from '../../graphql/hid/destroyHIDConfig'
import BaseModal from '../../components/Shared/BaseModal'
import { ModalContent } from '../../components/Styles/CustomElementsStyled'
import { themeColors } from '../../const/colors'
import {
  APPEND_LOG,
  IAppendLogVars,
  IAppendLogData,
} from '../../graphql/shared/appendLog'
import { TenantRow } from '../../screens/Tenants/TenantsList'
import ErrorContainer from '../../components/Shared/ErrorContainer'

type FormData = {
  limit: number
}

const EditTenantLimit = ({
  tenant,
  limit,
  refetch,
}: {
  tenant: TenantRow
  limit: number
  refetch: (v: any) => void
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const { t } = useTranslation(['tenant_access_account'])
  const { id, slug, name, hidConfig } = tenant

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  const addAccessAccountLogLine = (message: string) =>
    appendLog({
      variables: {
        objectId: id,
        message,
        parameters: 'name=operation|value=access_card.setup.limit',
      },
    })

  const onUpdateMobileAccessAccountLimitCompleted = () =>
    addAccessAccountLogLine('Limit updated')

  const onDestroyHIDConfigCompleted = () =>
    addAccessAccountLogLine('Limit deleted')

  const [updateMobileAccessAccountLimit] = useMutation<
    IUpdateMobileAccessAccountLimitData,
    IUpdateMobileAccessAccountLimitVars
  >(UPDATE_MOBILE_ACCESS_ACCOUNT_LIMIT, {
    onCompleted: onUpdateMobileAccessAccountLimitCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  const [destroyHIDConfig] = useMutation<
    IDestroyHIDConfigData,
    IDestroyHIDConfigVars
  >(DESTROY_HID_CONFIG, {
    onCompleted: onDestroyHIDConfigCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  const [appendLog] = useMutation<IAppendLogData, IAppendLogVars>(APPEND_LOG, {
    onCompleted: () => refetch({ variables: { name } }),
  })

  const onSubmit = (data: FormData) =>
    hidConfig &&
    updateMobileAccessAccountLimit({
      variables: {
        configId: hidConfig?.id,
        limit: Number(data.limit),
      },
    })

  const deleteLimit = () => {
    destroyHIDConfig({
      variables: {
        tenant: slug,
      },
    })
    setOpenModal(false)
  }

  return (
    <>
      <Text>{t('edit_limit')}</Text>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label={t('card_limit')}
          name="limit"
          control={control}
          errors={errors}
          rules={{ required: `${t('form:field_required')}` }}
          defaultValue={String(limit || '')}
          data-e2e="edit-hid-limit"
        />
        <ButtonWrapper container justifyContent={'center'}>
          <Col item xs={6}>
            <BaseButton
              onClick={() => setOpenModal(true)}
              customColor={themeColors.negative}
            >
              {t('delete_limit')}
            </BaseButton>
          </Col>
          <Col item xs={6}>
            <BaseButton type="submit" data-e2e="hid-limit-submit">
              {t('btn:save')}
            </BaseButton>
          </Col>
        </ButtonWrapper>
      </Form>
      {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
      <BaseModal
        confirmText={t('delete_limit')}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleAccept={deleteLimit}
      >
        <ModalContent>{t('delete_confirmation')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default EditTenantLimit

const ButtonWrapper = styled(Grid)`
  flex-direction: row;
  padding: 16px;
`
const Form = styled.form`
  padding: 16px 0;
`
const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  margin: 16px 0 0;
`
const Col = styled(Grid)`
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
