import gql from 'graphql-tag'
import { ID, IQueryBasicData } from '../shared/IQueryData'

export interface ICreateParkingPoolVariables {
  productId: number
  maxActiveUserCount: number
  maxAdvanceDays: number
  maxDurationDays: number
  maxReservationCountPerUser: number
  placesCountContract: number
  tenant: string
  parkingName: string
  notifyUserNearingReservation: boolean
  reservationNearingInHours?: number
  customEmailMessage?: string
  notifyOfficeManagersNewReservation: boolean
}

export interface ICreateParkingPoolData {
  createParkingPool: IQueryBasicData & {
    numUids: number
    parkingPool: {
      id: ID
    }
  }
}

export const CREATE_PARKING_POOL = gql`
  mutation createParkingPool(
    $productId: Int!
    $maxActiveUserCount: Int!
    $maxAdvanceDays: Int!
    $maxDurationDays: Int!
    $maxReservationCountPerUser: Int!
    $placesCountContract: Int!
    $tenant: String!
    $parkingName: String!
    $notifyUserNearingReservation: Boolean
    $reservationNearingInHours: Int
    $customEmailMessage: String
    $notifyOfficeManagersNewReservation: Boolean
  ) {
    createParkingPool(
      amano_productno: $productId
      max_active_user_count: $maxActiveUserCount
      max_advance_days: $maxAdvanceDays
      max_duration_days: $maxDurationDays
      max_reservation_count_per_user: $maxReservationCountPerUser
      parking_name: $parkingName
      places_count_contract: $placesCountContract
      tenant_name: $tenant
      notify_user_nearing_reservation: $notifyUserNearingReservation
      reservation_nearing_in_hours: $reservationNearingInHours
      custom_email_message: $customEmailMessage
      notify_office_managers_new_reservation: $notifyOfficeManagersNewReservation
    ) {
      status
      error
      message
      code
      numUids
      parkingPool: parking_pool {
        id
        notifyUserNearingReservation: notify_user_nearing_reservation
        reservationNearingInHours: reservation_nearing_in_hours
        customEmailMessage: custom_email_message
        notifyOfficeManagersNewReservation: notify_office_managers_new_reservation
      }
    }
  }
`
