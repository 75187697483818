import gql from 'graphql-tag'

export interface IDeleteSurveyVars {
  id: string
}

export const DELETE_SURVEY = gql`
  mutation deleteSurvey($id: [ID!]) {
    deleteSurvey(filter: { id: $id }) {
      msg
    }
  }
`
