import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface ICreateHIDConfigVars {
  limit: number
  tenant: string
}
export interface ICreateHIDConfigData {
  createHIDConfig: IQueryBasicData
}

export const CREATE_HID_CONFIG = gql`
  mutation createHIDConfig($limit: Int!, $tenant: String!) {
    createHIDConfig(limit: $limit, tenant: $tenant) {
      status
      error
      message
      code
    }
  }
`
