import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IResendInvoiceVars {
  paymentSessionId: string
}

export interface IResendInvoiceData {
  resendInvoice: IQueryBasicData
}

export const RESEND_INVOICE = gql`
  mutation resendInvoice($paymentSessionId: String!) {
    resendInvoice(payment_session_id: $paymentSessionId) {
      status
      code
      error
      message
    }
  }
`
