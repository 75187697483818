import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, Grid, InputLabel, MenuItem } from '@mui/material'
import {
  TextBody1Medium,
  TextBody2Medium,
  TextBody2Bold,
} from '../Styles/TextCustom'
import styled from 'styled-components'
import { ParkingPlaceStatus } from '../../graphql/parking/queryParkingPlace'
import { PlaceRow } from './PlacesTable'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { ID } from '../../graphql/shared/IQueryData'
import { BaseButton } from '../Shared/BaseButton'
import {
  ASSIGN_PLACES_TO_PARKING_POOL,
  IAssignPlacesToParkingPoolData,
  IAssignPlacesToParkingPoolVars,
} from '../../graphql/parking/assignPlacesToParkingPool'
import Select from '@mui/material/Select'
import { themeColors } from '../../const/colors'
import {
  IQueryParkingPoolData,
  IQueryParkingPoolVars,
  QUERY_PARKING_POOL,
} from '../../graphql/parking/queryParkingPool'
import ErrorContainer from '../Shared/ErrorContainer'
import DrawerHeader from '../Shared/DrawerHeader'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'

const AssignPlaceToParkingPool = ({
  closeDetails,
  parking,
  places,
  refetch,
}: {
  closeDetails: () => void
  parking: string
  places: PlaceRow[]
  refetch: () => void
}) => {
  const { t } = useTranslation(['parking_places'])
  const { data } = useQuery<IQueryParkingPoolData, IQueryParkingPoolVars>(
    QUERY_PARKING_POOL,
    {
      variables: { parking: parking },
      fetchPolicy: 'no-cache',
    }
  )
  const [parkingPool, setParkingPool] = useState<ID>('')
  const [errorMessage, setErrorMessage] = useState('')
  const successNotify = (message: string) => toast.dark(message)
  const ids = places.map(({ id }) => id)

  const onAssignCompleted = (data: IAssignPlacesToParkingPoolData) => {
    if (data.assignPlacesToParkingPool.status) {
      successNotify(`${t('generic:success_message')}`)
      refetch()
      closeDetails()
    } else {
      setErrorMessage(t(`code:${data.assignPlacesToParkingPool.code}`))
    }
  }

  const [assignPlacesToParkingPool] = useMutation<
    IAssignPlacesToParkingPoolData,
    IAssignPlacesToParkingPoolVars
  >(ASSIGN_PLACES_TO_PARKING_POOL, {
    onCompleted: onAssignCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  const handleAssignParkingPool = async () => {
    await assignPlacesToParkingPool({
      variables: { pool: parkingPool, places: ids },
    })
  }

  const handleChange = (event: any) => {
    setParkingPool(event.target.value as ParkingPlaceStatus)
  }

  const selectedPool = data?.queryParkingPool.find(
    (val) => val.id === parkingPool
  )
  const disabled = selectedPool
    ? places.length >
      selectedPool.placesCountContract - selectedPool.places.length
    : false

  return (
    <>
      <DrawerHeader
        title={t('assign_place_to_parking_pool')}
        handleClose={closeDetails}
      />
      <DetailsWrapper>
        <Row>
          <TextStyled>{'Parking: '}</TextStyled>
          <TextBold>{parking}</TextBold>
        </Row>
        <Row>
          <TextStyled>{`${t('selected_places')}: `}</TextStyled>
          {places.map(({ place, level }) => (
            <TextBold>{`${place} (${level}), `}</TextBold>
          ))}
        </Row>
        <Row>
          <TextBody2Bold color={themeColors.primary}>{`${places.length} ${t(
            'generic:selected'
          )}`}</TextBody2Bold>
        </Row>
        <Wrapper>
          <FormControl>
            <InputLabel id="select-label">{t('select_tenant')}</InputLabel>
            <SelectStyled
              labelId="select-label"
              value={parkingPool}
              label={t('select_tenant')}
              onChange={handleChange}
              data-e2e="parking-pool-select"
            >
              {data?.queryParkingPool.map(({ tenant, id }) => (
                <MenuItem key={id} value={id}>
                  <Text>{t(tenant.name)}</Text>
                </MenuItem>
              ))}
            </SelectStyled>
            {!!selectedPool && (
              <Wrapper>
                <Text>
                  {t('taken_places')}: {selectedPool.places.length}/
                  {selectedPool.placesCountContract}
                </Text>
              </Wrapper>
            )}
          </FormControl>
          <TextWithPadding>
            <BaseButton
              onClick={handleAssignParkingPool}
              disabled={!parkingPool || disabled}
              data-e2e="assign-places-submit"
            >
              {t('btn:assign')}
            </BaseButton>
          </TextWithPadding>
          {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
        </Wrapper>
      </DetailsWrapper>
    </>
  )
}

export default AssignPlaceToParkingPool

const TextWithPadding = styled(TextBody2Medium)`
  padding: 16px 0;
`
const SelectStyled = styled(Select)`
  width: 600px;
  background-color: ${themeColors.white};
`
const Text = styled(TextBody1Medium)`
  margin: 0;
`
const Wrapper = styled(Grid)`
  padding: 16px 0;
`
const Row = styled(Grid)`
  padding: 6px;
`
const TextStyled = styled(TextBody2Medium)`
  display: inline;
`
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
