import React from 'react'
import { CheckboxText, LinkText } from '../components/Styles/TextCustom'
import styled from 'styled-components'

interface ILinkContent {
  text: string
  url: string
}

export type RegulationArray = Array<ILinkContent | string>

export const parseRegulation = (html: string): RegulationArray => {
  let url: string | undefined
  return html.split(/(<([^>]+)>)/gi).reduce((acc: any[], cur: string) => {
    if (cur.includes('<a') || cur.includes('/a')) {
      return acc
    }
    if (cur.includes('href')) {
      url = cur.split('"')[1]
      return acc
    }
    if (url) {
      const linkContent = { url, text: cur }
      url = undefined
      return [...acc, linkContent]
    }
    return [...acc, cur]
  }, [])
}

const renderRegulationContent = (html: string) => {
  const regulationsArray = parseRegulation(html)
  return (
    <>
      {regulationsArray.map((element, i) => {
        if (typeof element !== 'string') {
          return (
            <a key={i} href={element.url}>
              <LinkTextStyled>{element.text}</LinkTextStyled>
            </a>
          )
        }
        return <TextStyled key={i}>{element}</TextStyled>
      })}
    </>
  )
}

export default renderRegulationContent

const LinkTextStyled = styled(LinkText)`
  display: inline-block;
  margin: 0 3px;
  text-wrap: wrap;
`
const TextStyled = styled(CheckboxText)`
  display: inline-block;
  margin: 0;
  text-wrap: wrap;
`
