import styled from 'styled-components'
import {
  GET_EXISTING_ROLES,
  IGetExistingRolesData,
} from '../../graphql/admin/queryRoles'
import { useQuery } from '@apollo/client'
import { FormControl, MenuItem, InputLabel } from '@mui/material'
import Select from '@mui/material/Select'
import { useTranslation } from 'react-i18next'

const RoleSelect = ({
  value,
  setValue,
}: {
  value?: string
  setValue: (v: string) => void
}) => {
  const { t } = useTranslation(['user_details'])
  const { data } = useQuery<IGetExistingRolesData>(GET_EXISTING_ROLES)

  const handleChange = (event: any) => {
    setValue(event.target.value as string)
  }

  return (
    <FormControl data-e2e="role-select">
      <InputLabel id="select-label">{t('role')}</InputLabel>
      <SelectStyled
        labelId="select-label"
        value={value}
        label="rola"
        onChange={handleChange}
        MenuProps={{
          sx: {
            '& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper':
              {
                maxHeight: 350,
              },
          },
        }}
      >
        <MenuItem value={'none'}>{t('generic:all')}</MenuItem>
        {data?.queryUserRole.map(({ name }, i) => (
          <MenuItem key={i} value={name}>
            {name}
          </MenuItem>
        ))}
      </SelectStyled>
    </FormControl>
  )
}

export default RoleSelect

const SelectStyled = styled(Select)`
  width: 250px;
  max-height: 150px;
`
