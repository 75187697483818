import gql from 'graphql-tag'

export interface IAddParkingPoolProfileVariables {
  parkingPoolId: string
  name: string
  createdAt: string
  maxAdvanceDays: number
  maxDurationDays: number
  maxReservationCountPerUser: number
  disabled: boolean
  customEmailMessage?: string
}

export interface IAddParkingPoolProfileData {
  addParkingPoolProfile: {
    parkingPoolProfile: {
      id: string
      name: string
    }
  }
}

export const ADD_PARKING_POOL_PROFILE = gql`
  mutation addParkingPoolProfile(
    $parkingPoolId: ID
    $name: String!
    $createdAt: DateTime!
    $maxAdvanceDays: Int!
    $maxDurationDays: Int!
    $maxReservationCountPerUser: Int!
    $disabled: Boolean
    $customEmailMessage: String
  ) {
    addParkingPoolProfile(
      input: {
        parking_pool: { id: $parkingPoolId }
        name: $name
        created_at: $createdAt
        max_advance_days: $maxAdvanceDays
        max_duration_days: $maxDurationDays
        max_reservation_count_per_user: $maxReservationCountPerUser
        disabled: $disabled
        custom_email_message: $customEmailMessage
      }
    ) {
      parkingPoolProfile {
        id
        name
      }
    }
  }
`
