import gql from 'graphql-tag'

export interface IGetSurveyQuestionAnswersData {
  getSurvey: {
    answersAggregate: {
      count: number
    }
    questions: {
      id: string
      name: string
      type: string
      options: {
        name: string
        id: string
      }[]
      answers: {
        responseOptions: {
          name: string
          id: string
        }[]
        user: {
          login: string
        }
      }[]
      answersAggregate: {
        count: number
      }
    }[]
  }
}

export const GET_SURVEY_QUESTION_ANSWERS = gql`
  query getSurvey($id: ID!) {
    getSurvey(id: $id) {
      answersAggregate {
        count
      }
      questions {
        id
        name
        type
        options {
          name
          id
        }
        answers(order: { desc: created_at }) {
          responseOptions {
            name
            id
          }
          user {
            login
          }
        }
        answersAggregate {
          count
        }
      }
    }
  }
`
