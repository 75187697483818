import gql from 'graphql-tag'
import { ILocker } from './queryLockers'

export interface IQueryLockerReservationData {
  lockerReservations: ILocker[]
}

export const QUERY_LOCKER_RESERVATION = gql`
  query queryLockers {
    lockerReservations: queryLocker {
      id
      type
      name
      status
      building {
        name
        location
        seller {
          name
          zipcode
          street
          city
        }
      }
      transponder
      key
      notes
      logs(order: { desc: created_at }) {
        createdAt: created_at
        message
      }
      reservations(order: { desc: created_at }) {
        id
        createdAt: created_at
        validFrom: valid_from
        validTo: valid_to
        status
        locker {
          status
        }
        user {
          email {
            email
          }
          firstName: first_name
          lastName: last_name
          login
          tenant {
            name
          }
        }
        tenant {
          id
          name
          emails {
            email
          }
        }
        groupReservation: group_reservation {
          tenantName: tenant_name
        }
        logs(order: { desc: created_at }) {
          createdAt: created_at
          message
        }
        order {
          orderId: id
          product {
            name
            withShower: with_shower
            seller {
              name
              nip
              street
              city
              zipcode
            }
          }
        }
      }
    }
  }
`
