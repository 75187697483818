import { ApolloError, useMutation } from '@apollo/client'
import {
  IDeleteBannerVars,
  DELETE_BANNER,
} from '../../graphql/banner/deleteBanner'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { themeColors } from '../../const/colors'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { toast } from 'react-toastify'
import BaseModal from '../../components/Shared/BaseModal'
import { ModalContent } from '../../components/Styles/CustomElementsStyled'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const DeleteBanner = ({
  refetch,
  bannerId,
  visible,
}: {
  refetch: () => void
  bannerId: string
  visible: boolean
}) => {
  const { t } = useTranslation(['banner'])
  const [openModal, setOpenModal] = useState(false)
  const successNotify = (message: string) => toast.dark(message)
  const errorNotify = (message: string) => toast.error(message)

  const onDeleteCompleted = () => {
    successNotify(t('generic:success_message'))
    refetch()
  }

  const [deleteBanner, { loading }] = useMutation<IDeleteBannerVars>(
    DELETE_BANNER,
    {
      onCompleted: onDeleteCompleted,
      onError: (error: ApolloError) => errorNotify(error.message),
    }
  )
  const handleDeleteBanner = () => {
    deleteBanner({ variables: { id: bannerId } })
    setOpenModal(false)
  }

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <IconButton
        size="small"
        disabled={visible}
        onClick={() => setOpenModal(true)}
        data-e2e="delete-banner"
      >
        <FontAwesomeIcon
          icon={faTrashAlt as IconProp}
          size="1x"
          color={visible ? themeColors.lightGray : themeColors.error}
        />
      </IconButton>
      <BaseModal
        confirmText={'btn:confirm'}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleAccept={() => handleDeleteBanner()}
      >
        <ModalContent>{t('menu_item_form:delete_confirm_title')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default DeleteBanner
