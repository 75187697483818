import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface ITenantCreateVars {
  name: string
}
export interface ITenantCreateData {
  tenantCreate: IQueryBasicData
}

export const CREATE_TENANT = gql`
  mutation TenantCreate($name: String!) {
    tenantCreate(name: $name) {
      status
      code
      message
      error
    }
  }
`
