import gql from 'graphql-tag'

export interface IUpdateMobileAccessAccountLimitVars {
  limit: number
  configId: string
}
export interface IUpdateMobileAccessAccountLimitData {
  updateHIDConfig: {
    hIDConfig: {
      id: string
    }
  }
}

export const UPDATE_MOBILE_ACCESS_ACCOUNT_LIMIT = gql`
  mutation updateMobileAccessAccountLimit($limit: Int!, $configId: ID!) {
    updateHIDConfig(
      input: {
        filter: { id: [$configId] }
        set: { max_number_of_mobile_cards: $limit }
      }
    ) {
      hIDConfig {
        id
        maxNumberOfMobileCards: max_number_of_mobile_cards
        tenant {
          name
        }
      }
    }
  }
`
