import gql from 'graphql-tag'
export interface IUpdateLockerProductVars {
  id: string
  name: string
  nameEn: string
  regulationId: string
  building: string
  durationDays: number
  minAdvanceDays: number
  maxAdvanceDays: number
  periodName: string
  periodNameEn: string
  price: number
  priceMessage: string
  priceMessageEn: string
  disabled: boolean
  withShower: boolean
}

export const UPDATE_LOCKER_PRODUCT = gql`
  mutation updateLockerProduct(
    $id: [ID!]
    $name: String!
    $nameEn: String
    $regulationId: ID
    $building: String
    $durationDays: Int!
    $minAdvanceDays: Int!
    $maxAdvanceDays: Int!
    $periodName: String
    $periodNameEn: String
    $price: Int!
    $priceMessage: String
    $priceMessageEn: String
    $disabled: Boolean
    $withShower: Boolean
  ) {
    updateLockerProduct(
      input: {
        filter: { id: $id }
        set: {
          name: $name
          name_en: $nameEn
          building: { name: $building }
          regulation: { id: $regulationId }
          duration_days: $durationDays
          min_advance_days: $minAdvanceDays
          max_advance_days: $maxAdvanceDays
          period_name: $periodName
          period_name_en: $periodNameEn
          price: $price
          price_message: $priceMessage
          price_message_en: $priceMessageEn
          disabled: $disabled
          with_shower: $withShower
        }
      }
    ) {
      lockerProduct {
        name
      }
    }
  }
`
