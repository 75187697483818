import React from 'react'
import { Button, Tooltip, TooltipProps, tooltipClasses } from '@mui/material'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { themeColors } from '../../const/colors'
import { styled } from '@mui/material/styles'

const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: themeColors.gray,
    fontSize: 12,
    fontWeight: 600,
    paddingTop: 6,
    paddingBottom: 6,
    paddingRight: 16,
    paddingLeft: 16,
    marginTop: 6,
    borderRadius: 50,
    maxWidth: 500,
  },
}))

const TooltipButton = ({
  children,
  onClick,
  type,
  icon,
  disabled,
  hint,
  ...rest
}: {
  children: any
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset' | undefined
  icon?: IconProp
  disabled?: boolean
  color?: string
  hint: string
  [k: string]: any
}) => {
  return (
    <TooltipStyled title={hint} disableHoverListener={!disabled}>
      <span>
        <ButtonStyled
          {...rest}
          color="primary"
          variant="outlined"
          size="large"
          startIcon={
            icon && <FontAwesomeIcon icon={icon} color={themeColors.primary} />
          }
          disabled={disabled}
          onClick={onClick}
        >
          {children}
        </ButtonStyled>
      </span>
    </TooltipStyled>
  )
}

export default TooltipButton

const ButtonStyled = styled(Button)`
  border-radius: 100px;
`
