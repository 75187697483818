import React from 'react'
import Input from '../Shared/Input'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Row } from './BannerTable'
import MobileViewSelect from '../Shared/MobileViewSelect'
import RadioGroupForm from '../Shared/RadioGroupForm'
import { TextBody1Medium } from '../Styles/TextCustom'

const BannerInputs = ({
  control,
  errors,
  banner,
  type,
  setType,
}: {
  control: any
  errors: any
  banner?: Row
  type: string
  setType: (v: string) => void
}) => {
  const { t } = useTranslation(['banner'])
  const options = [
    { value: 'external', label: t('external') },
    { value: 'internal', label: t('internal') },
  ]

  return (
    <Container>
      <Input
        label={t('name')}
        name={'name'}
        control={control}
        errors={errors}
        defaultValue={banner?.name || ''}
        rules={{ required: `${t('form:field_required')}` }}
        data-e2e="banner-name"
      />
      <Input
        label={t('src')}
        name={'src'}
        control={control}
        errors={errors}
        defaultValue={banner?.src || ''}
        rules={{ required: `${t('form:field_required')}` }}
        data-e2e="banner-src"
      />
      <Input
        label={t('src_en')}
        name={'srcEn'}
        control={control}
        errors={errors}
        defaultValue={banner?.srcEn || ''}
        data-e2e="banner-src-en"
      />
      <Wrapper>
        <RadioGroupForm value={type} setValue={setType} options={options} />
      </Wrapper>
      {type === 'external' && (
        <>
          <TextBody1Medium>{t('external_link')}</TextBody1Medium>
          <Input
            label={t('url')}
            name={'url'}
            control={control}
            errors={errors}
            defaultValue={banner?.type === 'external' ? banner?.url : ''}
            rules={{ required: `${t('form:field_required')}` }}
            data-e2e="banner-url"
          />
          <Input
            label={t('url_en')}
            name={'urlEn'}
            control={control}
            errors={errors}
            defaultValue={banner?.type === 'external' ? banner?.urlEn : ''}
            data-e2e="banner-url-en"
          />
        </>
      )}
      {type === 'internal' && (
        <>
          <TextBody1Medium>{t('mobile_view_target')}</TextBody1Medium>
          <MobileViewSelect
            control={control}
            errors={errors}
            defaultValue={banner?.url}
          />
        </>
      )}
    </Container>
  )
}

export default BannerInputs
const Container = styled.div`
  padding: 24px 0 10px;
`
const Wrapper = styled.div`
  margin: 16px 0 16px 16px;
`
