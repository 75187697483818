import gql from 'graphql-tag'

export interface IQueryUnitData {
  queryUnit: {
    name: string
  }[]
}

export const QUERY_UNIT = gql`
  query queryUnit {
    queryUnit {
      name
    }
  }
`
