import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FormControl, MenuItem, InputLabel } from '@mui/material'
import Select from '@mui/material/Select'
import { ReservationStatus } from '../../graphql/shared/sharedTypes'

const ReservationStatusSelect = ({
  value,
  setValue,
}: {
  value: ReservationStatus | ''
  setValue: (v: ReservationStatus | '') => void
}) => {
  const { t } = useTranslation(['locker_reservation_status'])
  const handleChange = (event: any) => {
    setValue(event.target.value as ReservationStatus)
  }

  const options = [
    ReservationStatus.available,
    ReservationStatus.reserved,
    ReservationStatus.rented,
  ]

  return (
    <FormControl data-e2e="status-filter-select">
      <InputLabel id="select-type">{t('reservation_status')}</InputLabel>
      <SelectStyled
        labelId="select-type"
        value={value}
        label={t('reservation_status')}
        onChange={handleChange}
      >
        {options.map((item, i) => (
          <MenuItem key={i} value={item}>
            {t(item)}
          </MenuItem>
        ))}
      </SelectStyled>
    </FormControl>
  )
}

export default ReservationStatusSelect

const SelectStyled = styled(Select)`
  width: 174px;
`
