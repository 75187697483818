import BaseModal from '../Shared/BaseModal'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ClickAwayListener } from '@mui/material'
import { ModalContent } from '../Styles/CustomElementsStyled'
import { TextError, TextH6 } from '../Styles/TextCustom'
import { useForm, useWatch } from 'react-hook-form'
import Input from '../Shared/Input'
import EditSurveyQuestion from './EditSurveyQuestion'
import { useQuery, useMutation } from '@apollo/client'
import {
  UPDATE_SURVEY_QUESTION,
  IUpdateSurveyQuestionVars,
} from '../../graphql/survey/updateSurveyQuestion'
import {
  UPDATE_SURVEY_QUESTION_OPTION,
  IUpdateSurveyQuestionOption,
} from '../../graphql/survey/updateSurveyQuestionOption'
import { QuestionType } from '../../graphql/survey/getSurvey'
import {
  UPDATE_SURVEY,
  IUpdateSurveyData,
} from '../../graphql/survey/updateSurvey'
import { GET_SURVEY, IGetSurveyData } from '../../graphql/survey/getSurvey'
import FullScreenLoader from '../Shared/FullScreenLoader'
import SurveyBaseInfo from '../SurveyDetails/SurveyBaseInfo'
import DrawerButtons from '../Shared/DrawerButtons'
import DrawerHeader from '../Shared/DrawerHeader'
import AvailabilitySwitch from '../Shared/AvailabilitySwitch'
import { toast } from 'react-toastify'

type Question = {
  questionName: string
  questionNameEn: string
  questionId: string
  type: QuestionType
  options: {
    name: string
    nameEn: string
    id: string
  }[]
}

const EditSurvey = ({
  closeDetails,
  surveyId,
  updateList,
}: {
  closeDetails: () => void
  surveyId?: string
  updateList: () => void
}) => {
  const [openClickAwayModal, setOpenClickAwayModal] = useState(false)
  const [questions, setQuestions] = useState<Question[]>()
  const [loading, setLoading] = useState(true)
  const [residentOnly, setResidentOnly] = useState(false)
  const { t } = useTranslation(['survey_form', 'survey'])
  const successNotify = (message: string) => toast.dark(message)

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm()
  const watchFormData = useWatch({ control })

  const handleCloseModal = (e: any) => {
    e.stopPropagation()
    setOpenClickAwayModal(false)
  }

  const handleClickAway = () => {
    !!Object.keys(watchFormData).length
      ? setOpenClickAwayModal(true)
      : closeDetails()
  }

  const onSubmit = (formData: any) => {
    if (
      !!Object.keys(watchFormData).length ||
      data?.getSurvey.residentOnly !== residentOnly
    ) {
      const variables = {
        id: data?.getSurvey.surveyId,
        name: formData.name.trim(),
        nameEn: formData.nameEn.trim(),
        description: formData.description.trim(),
        descriptionEn: formData.descriptionEn.trim(),
        bannerUrl: formData.banner.trim(),
        bannerUrlEn: formData.bannerEn.trim(),
        thanks: formData.thanks.trim(),
        thanksEn: formData.thanksEn.trim(),
        residentOnly,
      }
      updateSurvey({ variables })
      const updateQuestions = questions?.map(
        ({ questionId, questionName, questionNameEn }) => ({
          id: questionId,
          name: formData[questionId]?.pl.trim() || questionName,
          nameEn: formData[questionId]?.en.trim() || questionNameEn,
        })
      )

      const updateOptions = questions
        ?.map(({ options }) => options)
        .flat(1)
        .map(({ id, name, nameEn }) => ({
          id,
          name: formData[id]?.pl.trim() || name,
          nameEn: formData[id]?.en.trim() || nameEn,
        }))
      updateQuestions?.forEach(({ id, name, nameEn }) => {
        updateSurveyQuestion({
          variables: {
            id,
            name,
            nameEn,
          },
        })
      })
      updateOptions?.forEach(({ name, id, nameEn }) => {
        updateSurveyQuestionOption({
          variables: { id, name, nameEn },
        })
      })
    } else closeDetails()
  }

  const { data } = useQuery<IGetSurveyData>(GET_SURVEY, {
    variables: {
      id: surveyId,
    },
    fetchPolicy: 'network-only',
  })

  const [updateSurvey, { loading: updateLoading }] =
    useMutation<IUpdateSurveyData>(UPDATE_SURVEY, {
      onCompleted: () => {
        updateList()
        successNotify(t('success'))
        closeDetails()
      },
    })

  const [updateSurveyQuestion, { loading: questionLoading }] =
    useMutation<IUpdateSurveyQuestionVars>(UPDATE_SURVEY_QUESTION, {})

  const [updateSurveyQuestionOption, { loading: optionLoading }] =
    useMutation<IUpdateSurveyQuestionOption>(UPDATE_SURVEY_QUESTION_OPTION, {})

  const renderQuestions = () =>
    questions?.map(
      ({ questionName, questionNameEn, questionId, type, options }, i) => (
        <EditSurveyQuestion
          control={control}
          errors={errors}
          number={i + 1}
          name={questionName}
          nameEn={questionNameEn}
          questionId={questionId}
          type={type}
          options={options}
          key={questionId}
          register={register}
          questionIndex={i}
        />
      )
    )

  useEffect(() => {
    if (data?.getSurvey) {
      const {
        getSurvey: { questions, residentOnly },
      } = data
      setQuestions(questions)
      residentOnly && setResidentOnly(residentOnly)
      setLoading(false)
    }
  }, [data])

  if (loading || updateLoading || questionLoading || optionLoading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <>
          <DrawerHeader
            title={'survey:edit_survey'}
            handleClose={closeDetails}
          />
          <SurveyBaseInfo
            errors={errors}
            control={control}
            survey={data?.getSurvey}
          />
          {renderQuestions()}
          <InputWrapper>
            <Input
              label={t('thanks_text')}
              name={'thanks'}
              control={control}
              errors={errors}
              defaultValue={data?.getSurvey.thanks || ''}
              multiline
              rows={2}
              data-e2e="survey-input-thanks"
            />
            <Input
              label={t('thanks_text_en')}
              name={'thanksEn'}
              control={control}
              errors={errors}
              defaultValue={data?.getSurvey.thanksEn || ''}
              multiline
              rows={2}
              data-e2e="survey-input-thanks-en"
            />
          </InputWrapper>
          <TextH6>{t('survey:resident_only_title')}</TextH6>
          <AvailabilitySwitch
            checked={residentOnly}
            checkedText={`${t('survey:resident_only')}`}
            uncheckedText={`${t('survey:for_all')}`}
            onChange={() => setResidentOnly(!residentOnly)}
          />
          <DrawerButtons
            handleAccept={handleSubmit(onSubmit)}
            handleCancel={handleClickAway}
          />
          {!!Object.keys(errors).length && (
            <TextError>{t('form:form_error')}</TextError>
          )}
        </>
      </ClickAwayListener>
      <BaseModal
        confirmText={'btn:exit'}
        open={openClickAwayModal}
        handleClose={(e) => handleCloseModal(e)}
        handleAccept={() => closeDetails()}
      >
        <ModalContent>{t('generic:exit_without_saving')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default EditSurvey

const InputWrapper = styled.div`
  padding-top: 24px;
`
