import gql from 'graphql-tag'

export interface IUpdateOlivkaRegexpVars {
  name: string
  value: string
}

export const UPDATE_OLIVKA_REGEXP = gql`
  mutation updateOlivkaRegexp($name: String, $value: String) {
    updateGlobalConfig(
      input: { filter: { name: { eq: $name } }, set: { value: $value } }
    ) {
      globalConfig {
        name
        value
      }
    }
  }
`
