import { ApolloError, useMutation } from '@apollo/client'
import {
  IDeleteSurveyVars,
  DELETE_SURVEY,
} from '../../graphql/survey/deleteSurvey'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { themeColors } from '../../const/colors'
import { toast } from 'react-toastify'
import BaseModal from '../../components/Shared/BaseModal'
import { ModalContent } from '../../components/Styles/CustomElementsStyled'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const DeleteSurvey = ({
  refetch,
  rowData,
}: {
  refetch: () => void
  rowData: any
}) => {
  const { t } = useTranslation(['survey'])
  const [surveyToDelete, setSurveyToDelete] = useState('')
  const successNotify = (message: string) => toast.dark(message)
  const errorNotify = (message: string) => toast.error(message)

  const onDeleteCompleted = () => {
    successNotify(t('generic:success_message'))
    refetch()
  }

  const [deleteSurvey] = useMutation<IDeleteSurveyVars>(DELETE_SURVEY, {
    onCompleted: onDeleteCompleted,
    onError: (error: ApolloError) => errorNotify(error.message),
  })
  const handleDeleteSurvey = () => {
    deleteSurvey({ variables: { id: surveyToDelete } })
    setSurveyToDelete('')
  }

  return (
    <>
      <IconButton
        data-e2e="survey-delete"
        size="small"
        disabled={!!rowData.quantityOfResponse || rowData.status}
        onClick={() => setSurveyToDelete(rowData.surveyId)}
      >
        <FontAwesomeIcon
          icon={faTrashAlt as IconProp}
          size="1x"
          color={
            !!rowData.quantityOfResponse || rowData.status
              ? themeColors.lightGray
              : themeColors.error
          }
        />
      </IconButton>
      <BaseModal
        confirmText={'btn:confirm'}
        open={!!surveyToDelete}
        handleClose={() => setSurveyToDelete('')}
        handleAccept={() => handleDeleteSurvey()}
      >
        <ModalContent>{t('menu_item_form:delete_confirm_title')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default DeleteSurvey
