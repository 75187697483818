import React, { FC, useState } from 'react'
import { useAppSelector } from '../../redux/store'
import { List, Collapse, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { faPizzaSlice } from '@fortawesome/free-solid-svg-icons'
import FAIcon from '../Shared/FAIcon'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../const/routes'
import { useTranslation } from 'react-i18next'
import { checkRole } from '../../utils/checkRole'
import { RESTAURANT_ROLES } from '../../const/permissions'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { MainListItem, ListItem, LinkStyled } from '../Styles/NavListStyle'

interface IGastroListItemProps {
  isMenuOpen?: boolean
}

const GastroListItem: FC<IGastroListItemProps> = ({ isMenuOpen }) => {
  const { t } = useTranslation(['admin_menu'])
  let { pathname } = useLocation()
  const [isGastroExpanded, setIsGastroExpanded] = useState(false)

  const { roles } = useAppSelector((state) => state.user)

  const isSelected = (route: string, isNestedListExpanded: boolean = true) =>
    !isMenuOpen || !isNestedListExpanded
      ? pathname.includes(route)
      : `/admin/${route}` === pathname

  if (!checkRole(RESTAURANT_ROLES, roles)) {
    return null
  }

  return (
    <>
      <MainListItem
        onClick={() => setIsGastroExpanded(!isGastroExpanded)}
        data-e2e="navigation-gastro"
      >
        <ListItemIcon>
          <FAIcon icon={faPizzaSlice as IconProp} />
        </ListItemIcon>
        <ListItemText primary={t('gastro')} />
        {isGastroExpanded ? <ExpandLess /> : <ExpandMore />}
      </MainListItem>
      <Collapse in={isMenuOpen && isGastroExpanded} timeout="auto">
        <List component="div" disablePadding>
          <LinkStyled to={ROUTES.GASTRO_MENU}>
            <ListItem
              selected={isSelected(ROUTES.GASTRO_MENU, isGastroExpanded)}
              data-e2e="navigation-gastro-menu"
            >
              <ListItemText primary={t('gastro_menu')} />
            </ListItem>
          </LinkStyled>
        </List>
      </Collapse>
    </>
  )
}

export default GastroListItem
