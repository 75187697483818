import { FC } from 'react'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import { themeColors } from '../../const/colors'
import { IGetExistingRolesData } from '../../graphql/admin/queryRoles'

const RolesLegend: FC<{ roles: IGetExistingRolesData }> = ({ roles }) => {
  return (
    <BlueContainer>
      {roles.queryUserRole.map((role) => (
        <Row key={role.name}>
          <TextBold>{`${role.name}: `}</TextBold>
          <Text>{`${role.description}`}</Text>
        </Row>
      ))}
    </BlueContainer>
  )
}

export default RolesLegend
const Text = styled(TextBody2Medium)`
  display: inline;
`
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
const Row = styled(Grid)`
  padding: 2px 0;
`
const BlueContainer = styled(Grid)`
  background-color: ${themeColors.lightBlue};
  border-radius: 20px;
  padding: 20px;
  margin: 0 8px 0 0;
`
