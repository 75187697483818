import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import ParkingPassesTable from '../../components/Parking/ParkingPassesTable'
import { BaseButton } from '../../components/Shared/BaseButton'
import styled from 'styled-components'
import { useState } from 'react'
import { HeaderContainer } from '../../components/Styles/CustomElementsStyled'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const ParkingPassesList = () => {
  const { t } = useTranslation(['parking_subscriptions_products_list'])
  const [openedAddForm, setOpenedAddForm] = useState(false)

  return (
    <>
      <HeaderContainer>
        <TypographyStyled variant="h6">
          {t('subscriptions_list')}
        </TypographyStyled>
        <BaseButton
          onClick={() => setOpenedAddForm(true)}
          data-e2e="parking-add-parking-pass"
          icon={faPlus as IconProp}
        >
          {t('btn:add_subscriptions_parking')}
        </BaseButton>
      </HeaderContainer>
      <ParkingPassesTable
        openedAddForm={openedAddForm}
        closeAddForm={() => setOpenedAddForm(false)}
      />
    </>
  )
}

export default ParkingPassesList

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
