import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'
export interface IPushNotificationData {
  pushNotification: IQueryBasicData
}
export interface IPushNotificationVars {
  title: string
  body: string
  logins?: string[]
  tokens?: string[]
  redirect?: string
  lang?: string
}

export const PUSH_NOTIFICATION = gql`
  mutation PushNotification(
    $title: String!
    $body: String!
    $logins: [String]
    $tokens: [String]
    $redirect: String
    $lang: UserProfileLanguage
  ) {
    pushNotification(
      title: $title
      body: $body
      logins: $logins
      tokens: $tokens
      redirect: $redirect
      lang: $lang
    ) {
      status
      code
      error
      message
    }
  }
`
