import gql from 'graphql-tag'

export interface IQueryRestaurantData {
  queryRestaurant: {
    id: string
    name: string
    visible: boolean
  }[]
}

export const QUERY_RESTAURANT = gql`
  query queryRestaurant($filter: RestaurantFilter, $first: Int, $offset: Int) {
    queryRestaurant(
      filter: $filter
      order: { asc: name }
      first: $first
      offset: $offset
    ) {
      id
      name
      visible
    }
  }
`
