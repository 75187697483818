import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Tab, Typography } from '@mui/material'
import GardenTicketsTable from '../../components/Garden/GardenTicketsTable'
import { TabsStyled } from '../../components/Styles/CustomElementsStyled'

const GardenTicketsList = () => {
  const { t } = useTranslation(['tabs'])
  const [value, setValue] = useState(0)
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {t('admin_menu:garden_tickets')}
      </TypographyStyled>
      <TabsStyled value={value} onChange={handleChange} textColor="primary">
        <Tab label={t('app')} data-e2e="garden-tab-app" />
        <Tab label={t('other')} data-e2e="garden-tab-other" />
      </TabsStyled>
      <GardenTicketsTable tab={value} />
    </>
  )
}

export default GardenTicketsList

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
