import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface ICancelGetResponseSyncVars {
  id: string
}

export interface ICancelGetResponseSyncData {
  cancelSync: IQueryBasicData
}

export const CANCEL_GETRESPONSE_SYNC = gql`
  mutation cancelMailingListSync($id: ID!) {
    cancelSync: cancelMailingListSync(job_id: $id) {
      status
      code
      error
      message
    }
  }
`
