import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IAppendLogVars {
  objectId: string
  message: string
  parameters?: string
}

export interface IAppendLogData {
  appendLog: IQueryBasicData
}

export const APPEND_LOG = gql`
  mutation appendLog($objectId: ID!, $message: String!, $parameters: String) {
    appendLog(
      object_id: $objectId
      message: $message
      parameters: $parameters
    ) {
      status
      code
      error
      message
    }
  }
`
