import gql from 'graphql-tag'

export interface IAddLogLineVars {
  createdAt: string
  message: string
  parameters: {
    name: string
    value: string
  }[]
}

export interface IAddLogLineData {
  addLogLine: {
    createdAt: string
    message: string
    parameters: {
      name: string
      value: string
    }[]
  }
}

export const ADD_LOG_LINE = gql`
  mutation addLogLine(
    $createdAt: DateTime!
    $message: String!
    $parameters: [ParameterRef!]
  ) {
    addLogLine(
      input: {
        created_at: $createdAt
        message: $message
        parameters: $parameters
      }
    ) {
      logLine {
        createdAt: created_at
        message
        parameters {
          name
          value
        }
      }
    }
  }
`
