import gql from 'graphql-tag'

export enum IssueType {
  tenant_status_request = 'tenant_status_request',
  application_problem = 'application_problem',
  account_delete_request = 'account_delete_request',
  card_access_request = 'card_access_request',
}

export enum IssueStatus {
  new = 'new',
  accepted = 'accepted',
  rejected = 'rejected',
  resolved = 'resolved',
}

export type IssueFilter = {
  and?: IssueFilter[] | IssueFilter
  id?: (string | number)[]
  not?: IssueFilter
  or?: [IssueFilter] | IssueFilter
  status?: { eq?: IssueStatus; in?: IssueStatus[] }
  type?: { eq?: IssueType; in?: IssueType[] }
}

export const newTenantIssuesFilter: IssueFilter = {
  and: [
    { type: { eq: IssueType.tenant_status_request } },
    { status: { eq: IssueStatus.new } },
  ],
}

export const resolvedTenantIssuesFilter: IssueFilter = {
  type: { eq: IssueType.tenant_status_request },
  and: { status: { eq: IssueStatus.accepted } },
  or: { status: { eq: IssueStatus.rejected } },
}

export const newMessageIssuesFilter: IssueFilter = {
  and: [
    {
      type: { eq: IssueType.application_problem },
      or: { type: { eq: IssueType.account_delete_request } },
    },
    { status: { eq: IssueStatus.new } },
  ],
}

export const resolvedMessageIssuesFilter: IssueFilter = {
  and: [
    {
      type: { eq: IssueType.application_problem },
      or: { type: { eq: IssueType.account_delete_request } },
    },
    { status: { eq: IssueStatus.resolved } },
  ],
}

export const newCardAccessIssuesFilter: IssueFilter = {
  type: { eq: IssueType.card_access_request },
  and: { status: { eq: IssueStatus.new } },
}

export const resolvedCardAccessIssuesFilter: IssueFilter = {
  type: { eq: IssueType.card_access_request },
  and: { status: { eq: IssueStatus.resolved } },
  or: { status: { eq: IssueStatus.rejected } },
}

export interface IQueryIssueVars {
  filter?: IssueFilter
}
export interface IQueryIssueData {
  queryIssue: {
    id: string
    status: IssueStatus
    description: string
    comment: string
    type: IssueType
    formName?: string
    os?: string
    app: string
    apiVersion: number
    backendVersion: string
    createdAt: string
    modifiedAt: string
    user: {
      firstName: string
      lastName: string
      login: string
      email: { email: string }
      workEmail?: { email: string }
      tenant?: { name: string }
      disabled: boolean
    }
    tenantRequest: {
      code: string
    }
    resolvedBy: {
      login: string
      firstName: string
      lastName: string
    }
  }[]
}

export const QUERY_ISSUE = gql`
  query queryIssue($filter: IssueFilter) {
    queryIssue(filter: $filter) {
      id
      status
      description
      comment
      type
      formName: form_name
      os
      app
      apiVersion: api_version
      backendVersion: backend_version
      createdAt: created_at
      modifiedAt: modified_at
      user {
        firstName: first_name
        lastName: last_name
        login
        email {
          email
        }
        workEmail: work_email {
          email
        }
        tenant {
          name
        }
        disabled
      }
      tenantRequest: tenant_status_request {
        code: verification_code
      }
      resolvedBy: resolved_by {
        login
        firstName: first_name
        lastName: last_name
      }
    }
  }
`
