import { FC, useEffect, useState } from 'react'
import { ActionMeta } from 'react-select'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation, ApolloError } from '@apollo/client'
import BaseSelect, { OptionType } from '../Shared/BaseSelect'
import {
  IUnassignRestaurantVars,
  IUnassignRestaurantData,
  UNASSIGN_USER_RESTAURANT,
} from '../../graphql/users/unassignUserRestaurant'
import {
  IAssignRestaurantVars,
  IAssignRestaurantData,
  ASSIGN_USER_RESTAURANT,
} from '../../graphql/users/assignUserRestaurant'
import {
  QUERY_RESTAURANT,
  IQueryRestaurantData,
} from '../../graphql/gastro/queryRestaurant'
import { Typography } from '@mui/material'
import { toast } from 'react-toastify'
import styled from 'styled-components'

const RestaurantSelect: FC<{
  login: string
  managesRestaurants: {
    id: string
    name: string
  }[]
  refetch: () => void
}> = ({ login, managesRestaurants, refetch }) => {
  const { t } = useTranslation(['user_details'])
  const [restaurantOptions, setRestaurantOptions] = useState<OptionType[]>([])
  const [selectedRestaurants, setSelectedRestaurants] = useState<OptionType[]>(
    []
  )

  const successNotify = (message: string) => toast.dark(message)

  const { data } = useQuery<IQueryRestaurantData>(QUERY_RESTAURANT, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    const options =
      data?.queryRestaurant.filter(
        ({ id }) => !managesRestaurants.map((item) => item.id).includes(id)
      ) || []
    setRestaurantOptions(
      options.map(({ name, id }) => ({ value: id, label: name }))
    )
    setSelectedRestaurants(
      managesRestaurants.map(({ name, id }) => ({
        value: id,
        label: name,
      }))
    )
  }, [managesRestaurants, data?.queryRestaurant])

  const [assignRestaurant, { loading: assignLoading }] = useMutation<
    IAssignRestaurantData,
    IAssignRestaurantVars
  >(ASSIGN_USER_RESTAURANT, {
    onCompleted: () => {
      successNotify(`${t('add_restaurant')}`)
      refetch()
    },
    onError: (error: ApolloError) => console.error(error.message),
  })

  const [unassignRestaurant, { loading: unassignLoading }] = useMutation<
    IUnassignRestaurantData,
    IUnassignRestaurantVars
  >(UNASSIGN_USER_RESTAURANT, {
    onCompleted: () => {
      successNotify(`${t('delete_restaurant')}`)
      refetch()
    },
    onError: (error: ApolloError) => console.error(error.message),
  })

  const handleSelectChange = (
    value: any,
    actionMeta: ActionMeta<OptionType>
  ) => {
    if (actionMeta.action === 'select-option' && actionMeta.option?.value) {
      assignRestaurant({
        variables: { login, restaurant: actionMeta.option.value },
      }).then(({ data }) => {
        if (data?.updateUser.user) {
          setSelectedRestaurants(data.updateUser.user.managesRestaurants)
        }
      })
    }
    if (actionMeta.action === 'remove-value') {
      unassignRestaurant({
        variables: { login, restaurant: actionMeta.removedValue.value },
      }).then(({ data }) => {
        if (data?.updateUser.user) {
          setSelectedRestaurants(data.updateUser.user.managesRestaurants)
        }
      })
    }
  }

  const isSelectLoading = assignLoading || unassignLoading

  return (
    <Container data-e2e="user-restaurant-select">
      <Typography>{t('assigned_restaurants')}</Typography>
      <BaseSelect<OptionType, true>
        value={selectedRestaurants}
        defaultValue={selectedRestaurants}
        onChange={handleSelectChange}
        options={restaurantOptions}
        isLoading={isSelectLoading}
        isDisabled={isSelectLoading}
        isClearable={false}
        isMulti
        closeMenuOnSelect={false}
        noOptionsMessage={() => t('no_restaurants')}
        placeholder={t('choose_restaurant')}
        data-e2e="select-restaurant"
      />
    </Container>
  )
}

export default RestaurantSelect

const Container = styled.div`
  margin-top: 16px;
  max-width: 500px;
`
