import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import BaseDrawer from '../Shared/BaseDrawer'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { dateIso2localeString } from '../../utils/formatDate'
import {
  QUERY_EXTRAS,
  IQueryExtrasData,
  IQueryExtrasVars,
} from '../../graphql/extras/queryExtras'
import ExtrasVisibility from './ExtrasVisibility'
import ExtrasDetails from './ExtrasDetails'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import EditTableRow from '../Shared/EditTableRow'
import DeleteExtras from './DeleteExtras'
import { BaseButton } from '../Shared/BaseButton'
import AddExtras from './AddExtras'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import SortExtras from './SortExtras'
import TooltipButton from '../Shared/TooltipButton'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface Row {
  sort: number
  id: string
  visible: boolean
  companyName: string
  companyLogo: string
  title: string
  dates: string
  titleEn: string
  description: string
  descriptionEn: string
  instruction: string
  instructionEn: string
  location: string
  locationEn: string
  validFrom: string
  validTo: string
  event: boolean
  hasDiscount: boolean
  eventId?: string
  discount?: string
}

const ExtrasTable: FC<{
  visible: boolean
}> = ({ visible }) => {
  const { t, i18n } = useTranslation(['extras'])
  const lang = i18n.language
  const [openedDetails, setOpenedDetails] = useState<Row>()
  const [addExtras, setAddExtras] = useState(false)
  const [sortExtras, setSortExtras] = useState(false)

  const checkIfTimeIsZero = (date?: string) =>
    date && new Date(date).getHours() === 0 && new Date(date).getMinutes() === 0

  const formatDate = (date: string) =>
    checkIfTimeIsZero(date)
      ? dateIso2localeString(date, lang, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      : dateIso2localeString(date, lang)

  const displayDate = (fromDate?: string, toDate?: string) =>
    toDate && fromDate
      ? `${formatDate(fromDate)} - ${formatDate(toDate)}`
      : toDate
      ? `Do ${formatDate(toDate)}`
      : fromDate
      ? `Od ${formatDate(fromDate)}`
      : 'Brak'

  const { data, loading, refetch } = useQuery<
    IQueryExtrasData,
    IQueryExtrasVars
  >(QUERY_EXTRAS, {
    variables: {
      visible,
    },
    fetchPolicy: 'no-cache',
  })

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        header: t('order'),
        accessorKey: 'sort',
      },
      {
        header: 'ID',
        accessorKey: 'id',
      },
      {
        header: t('columns:company'),
        accessorKey: 'companyName',
      },
      {
        header: t('columns:title'),
        accessorKey: 'title',
      },
      {
        header: t('dates'),
        accessorKey: 'dates',
        enableColumnFilter: false,
      },
      {
        header: t('event_promo'),
        accessorKey: 'event',
        cell: (value) => (!!value.getValue() ? t('bool:yes') : t('bool:no')),
      },
      {
        header: t('visibility'),
        accessorKey: 'visible',
        cell: (value) => (
          <ExtrasVisibility
            id={value.row.original.id}
            visible={value.row.original.visible}
            refetch={refetch}
          />
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'actions',
        header: '',
        cell: (value) => (
          <ActionsWrapper
            container
            direction="row"
            justifyContent="space-between"
          >
            <EditTableRow
              onClick={() => setOpenedDetails(value.row.original)}
            />
            <DeleteExtras
              id={value.row.original.id}
              visible={value.row.original.visible}
              refetch={refetch}
            />
          </ActionsWrapper>
        ),
        enableColumnFilter: false,
      },
    ],
    [t, refetch]
  )

  const tableData: Row[] = useMemo(
    () =>
      data?.queryQuickPromo.map(
        ({
          id,
          sort,
          visible,
          companyName,
          companyLogo,
          title,
          titleEn,
          description,
          descriptionEn,
          instruction,
          instructionEn,
          location,
          locationEn,
          validFrom,
          validTo,
          eventId,
          hasDiscount,
          discount,
        }) => ({
          sort,
          id,
          visible,
          companyName,
          companyLogo,
          title,
          titleEn,
          description,
          descriptionEn,
          instruction,
          instructionEn,
          location,
          locationEn,
          dates: displayDate(validFrom, validTo),
          validFrom,
          validTo,
          event: !!eventId,
          hasDiscount,
          eventId,
          discount,
        })
      ) || [],
    [data]
  )

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <ButtonStyled
        onClick={() => setAddExtras(true)}
        icon={faPlus as IconProp}
        data-e2e="add-promo-button"
      >
        {t('add_promo')}
      </ButtonStyled>

      <Table
        columns={columns}
        data={tableData}
        enableRowSelection
        sortByKey="sort"
      >
        <Wrapper>
          <TooltipButton
            onClick={() => setSortExtras(true)}
            hint=""
            disabled={!data?.queryQuickPromo.length}
            data-e2e="set-order-button"
          >
            {t('set_order')}
          </TooltipButton>
        </Wrapper>
      </Table>
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        {openedDetails && (
          <ExtrasDetails
            data={openedDetails}
            refetch={refetch}
            closeDetails={() => setOpenedDetails(undefined)}
          />
        )}
      </BaseDrawer>
      <BaseDrawer open={addExtras} variant={'temporary'}>
        {data && (
          <AddExtras
            refetch={refetch}
            closeDetails={() => setAddExtras(false)}
            itemsAmount={data.queryQuickPromo.length}
          />
        )}
      </BaseDrawer>
      <BaseDrawer open={sortExtras} variant={'temporary'}>
        <SortExtras
          data={data?.queryQuickPromo}
          refetch={refetch}
          closeDetails={() => setSortExtras(false)}
        />
      </BaseDrawer>
    </>
  )
}

export default ExtrasTable

const Wrapper = styled.div`
  padding-bottom: 16px;
`

const ActionsWrapper = styled(Grid)`
  padding-right: 8px;
  width: 80px;
`
const ButtonStyled = styled(BaseButton)`
  position: absolute;
  right: 0;
  top: 0;
`
