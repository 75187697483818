import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FormControl, MenuItem, InputLabel } from '@mui/material'
import Select from '@mui/material/Select'
import { LockerType } from '../../graphql/lockers/queryLockers'

const SelectLockerTypeColumnFilter = ({
  value,
  setValue,
}: {
  value: LockerType | ''
  setValue: (v: LockerType | '') => void
}) => {
  const { t } = useTranslation(['lockers'])
  const handleChange = (event: any) => {
    setValue(event.target.value as LockerType)
  }

  const options = [LockerType.garage, LockerType.locker_room]

  return (
    <FormControl>
      <InputLabel id="select-type">{t('locker_type')}</InputLabel>
      <SelectStyled
        labelId="select-type"
        value={value}
        label={t('locker_type')}
        onChange={handleChange}
      >
        {options.map((item, i) => (
          <MenuItem key={i} value={item}>
            {t(item)}
          </MenuItem>
        ))}
      </SelectStyled>
    </FormControl>
  )
}

export default SelectLockerTypeColumnFilter

const SelectStyled = styled(Select)`
  width: 174px;
`
