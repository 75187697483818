import styled from 'styled-components'
import { Grid } from '@mui/material'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import { useTranslation } from 'react-i18next'
import { OneTimeParkingTicketRow } from '../../screens/Parking/SoldOneTimeTicketList'
import { dateIso2localeString } from '../../utils/formatDate'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { getOneTimeTicketStatusColor } from '../../utils/getStatusColor'
import DrawerHeader from '../Shared/DrawerHeader'
import LogsContainer from '../Shared/LogsContainer'
import AddLogForm from '../Shared/AddLogForm'
import { ROUTES } from '../../const/routes'
import {
  GET_ONE_TIME_PARKING_TICKET,
  IGetOneTimeParkingTicketData,
  IGetOneTimeParkingTicketVars,
} from '../../graphql/parking/getOneTimeParkingTicket'
import { useQuery } from '@apollo/client'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'
import NewTabLink from '../Shared/NewTabLink'

export const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
}

const OneTimeTicketDetails = ({
  data,
  closeDetails,
  refetch,
}: {
  data: OneTimeParkingTicketRow
  closeDetails: () => void
  refetch: () => void
}) => {
  const { t, i18n } = useTranslation(['sold_parking_items'])
  const lang = i18n.language

  const {
    data: ticket,
    refetch: refetchTicket,
    loading,
  } = useQuery<IGetOneTimeParkingTicketData, IGetOneTimeParkingTicketVars>(
    GET_ONE_TIME_PARKING_TICKET,
    {
      variables: {
        id: data?.id,
      },
    }
  )

  if (loading) {
    return <FullScreenLoader />
  }

  const {
    id,
    validFrom,
    validTo,
    status,
    login,
    email,
    tenant,
    carPlate,
    createdAt,
    ticketNumber,
  } = data

  const details = [
    { label: t('ticket_number'), value: ticketNumber },
    {
      label: t('dates'),
      value: `${dateIso2localeString(
        validFrom,
        lang,
        options
      )} - ${dateIso2localeString(validTo, lang, options)}`,
    },
    { label: 'Login', value: login },
    { label: 'Email', value: email },
    { label: t('tenant'), value: tenant },
    { label: t('car_plate'), value: carPlate },
    {
      label: t('created_at'),
      value: dateIso2localeString(createdAt, lang, options),
    },
  ]

  return (
    <>
      <DrawerHeader title={t('ticket_details')} handleClose={closeDetails} />
      <DetailsWrapper>
        {details.map(
          ({ label, value }) =>
            value && (
              <Row key={label}>
                <Text>{`${label}: `}</Text>
                <TextBold>{value}</TextBold>
              </Row>
            )
        )}

        <Row>
          <Text>{`${t('ticket_status')}: `}</Text>
          <Status color={getOneTimeTicketStatusColor(status)}>
            {t(`parking_ticket_status:${status}`)}
          </Status>
        </Row>
        <Row>
          <NewTabLink
            path={`/admin/${ROUTES.PARKING_PAYMENT_HISTORY}/id:${ticket?.getOneTimeParkingTicket.payment.id}`}
            text={t('payment_history:details_title')}
          />
        </Row>
        {!!ticket && (
          <LogsContainer logs={ticket.getOneTimeParkingTicket.logs} />
        )}
      </DetailsWrapper>
      <DetailsWrapper>
        <AddLogForm
          id={id}
          refetch={refetch}
          closeDetails={closeDetails}
          getLogs={refetchTicket}
        />
      </DetailsWrapper>
    </>
  )
}

export default OneTimeTicketDetails

const Row = styled(Grid)`
  padding: 6px;
`
const Status = styled(TextBody2Bold)`
  text-transform: uppercase;
  display: inline;
`
const Text = styled(TextBody2Medium)`
  display: inline;
`
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
