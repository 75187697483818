import gql from 'graphql-tag'
import { ID } from '../shared/IQueryData'

export interface IQueryParkingPoolVars {
  parking: string
}

export interface IQueryParkingPoolData {
  queryParkingPool: {
    id: ID
    placesCountContract: number
    parking_pass: {
      parking: {
        name: string
      }
    }
    tenant: {
      name: string
    }
    places: {
      id: ID
    }[]
  }[]
}

export const QUERY_PARKING_POOL = gql`
    query queryParkingPool($parking: String) {
        queryParkingPool @cascade(fields: "parking_pass") {
            places {
                id
            }
            id
            parking_pass @cascade(fields: "parking"){
                parking (filter: {name: {eq: $parking}}) {
                    name
                }
            }
            placesCountContract: places_count_contract
            tenant {
                name
            }
        }
    }
`
