import React, { FC, useState } from 'react'
import { useAppSelector } from '../../redux/store'
import { List, Collapse, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { faTree } from '@fortawesome/free-solid-svg-icons'
import FAIcon from '../Shared/FAIcon'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../const/routes'
import { useTranslation } from 'react-i18next'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { MainListItem, ListItem, LinkStyled } from '../Styles/NavListStyle'

interface IGardenListItemProps {
  isMenuOpen?: boolean
}

const GardenListItem: FC<IGardenListItemProps> = ({ isMenuOpen }) => {
  const { t } = useTranslation(['admin_menu'])
  let { pathname } = useLocation()
  const [isGardenExpanded, setIsGardenExpanded] = useState(false)
  const { isAdmin } = useAppSelector((state) => state.user)

  const isSelected = (route: string, isNestedListExpanded: boolean = true) =>
    !isMenuOpen || !isNestedListExpanded
      ? pathname.includes(route)
      : `/admin/${route}` === pathname

  if (!isAdmin) {
    return null
  }

  return (
    <>
      <MainListItem onClick={() => setIsGardenExpanded(!isGardenExpanded)}>
        <ListItemIcon>
          <FAIcon icon={faTree as IconProp} />
        </ListItemIcon>
        <ListItemText primary={t('garden')} />
        {isGardenExpanded ? <ExpandLess /> : <ExpandMore />}
      </MainListItem>

      <Collapse in={isMenuOpen && isGardenExpanded} timeout="auto">
        <List component="div" disablePadding>
          <LinkStyled to={ROUTES.GARDEN_TICKETS}>
            <ListItem
              selected={isSelected(ROUTES.GARDEN_TICKETS, isGardenExpanded)}
            >
              <ListItemText primary={t('garden_tickets')} />
            </ListItem>
          </LinkStyled>
        </List>
      </Collapse>
    </>
  )
}

export default GardenListItem
