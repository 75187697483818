import React, { useEffect, useState } from 'react'
import Input from '../Shared/Input'
import { useTranslation } from 'react-i18next'
import { useForm, useWatch } from 'react-hook-form'
import styled from 'styled-components'
import { Title } from '../Styles/TextCustom'
import {
  IUpdateTenantSeosCardVars,
  IUpdateTenantSeosCardData,
  UPDATE_TENANT_SEOS_CARD,
} from '../../graphql/tenant/updateTenantSeosCard'
import { ApolloError, useMutation } from '@apollo/client'
import { Grid } from '@mui/material'
import { BaseButton } from '../Shared/BaseButton'
import FullScreenLoader from '../Shared/FullScreenLoader'
import LogsContainer from '../Shared/LogsContainer'
import AvailabilitySwitch from '../Shared/AvailabilitySwitch'
import { toast } from 'react-toastify'
import { BaseTextButton } from '../Shared/BaseButton'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'
import ErrorContainer from '../Shared/ErrorContainer'
import { ITenant } from '../../graphql/tenant/getTenant'

type FormData = {
  prefix: string
  disabled: boolean
}

const SeosCardForm = ({
  tenantToEdit,
  setSeosFormTouched,
  refetch,
}: {
  tenantToEdit: ITenant
  setSeosFormTouched: (v: boolean) => void
  refetch: () => void
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [disabledSeos, setDisabledSeos] = useState(
    tenantToEdit.seos?.disabled || false
  )
  const [addSeos, setAddSeos] = useState(false)
  const successNotify = (message: string) => toast.dark(message)
  const { t } = useTranslation(['form', 'btn', 'tenant', 'generic'])

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()
  const watchFormData = useWatch({ control })

  const onCompleted = (data: IUpdateTenantSeosCardData) => {
    successNotify(`${t('generic:success_message')}`)
    refetch()
  }

  const [updateTenantSeosCard, { loading }] = useMutation<
    IUpdateTenantSeosCardData,
    IUpdateTenantSeosCardVars
  >(UPDATE_TENANT_SEOS_CARD, {
    onCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  useEffect(() => {
    setSeosFormTouched(!!Object.keys(watchFormData).length)
  }, [watchFormData])

  if (!tenantToEdit || loading) {
    return <FullScreenLoader />
  }

  const { id, seos } = tenantToEdit

  const onSubmit = (data: FormData) => {
    const { prefix } = data
    updateTenantSeosCard({
      variables: {
        id,
        prefix,
        disabled: disabledSeos,
      },
    })
  }

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisabledSeos(!event.target.checked)
  }

  return (
    <DetailsWrapper>
      {!tenantToEdit.seos && !addSeos && (
        <BaseTextButton
          onClick={() => setAddSeos(true)}
          icon={faPlus as IconProp}
        >
          {t('tenant:add_seos_card')}
        </BaseTextButton>
      )}
      {(!!tenantToEdit.seos || addSeos) && (
        <>
          <TitleStyled>{t('tenant:seos_card')}</TitleStyled>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              label={'Prefix'}
              name="prefix"
              control={control}
              errors={errors}
              rules={{ required: `${t('field_required')}` }}
              autoCapitalize={'none'}
              defaultValue={seos?.prefix}
              data-e2e="tenant-seos-prefix"
            />
            <AvailabilitySwitch
              checked={!disabledSeos}
              checkedText={`${t('form:feature_enabled')}`}
              uncheckedText={`${t('form:feature_disabled')}`}
              onChange={handleSwitch}
            />
          </form>
          <ButtonWrapper
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <BaseButton onClick={handleSubmit(onSubmit)} data-e2e="submit">
              {t('btn:save')}
            </BaseButton>
          </ButtonWrapper>
          {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
          <LogsContainer logs={tenantToEdit.seos?.logs} small />
        </>
      )}
    </DetailsWrapper>
  )
}

export default SeosCardForm

const ButtonWrapper = styled(Grid)`
  padding: 16px;
`
const TitleStyled = styled(Title)`
  margin-bottom: 16px;
`
