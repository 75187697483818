import React, { FC, useState } from 'react'
import { useAppSelector } from '../../redux/store'
import { List, Collapse, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import FAIcon from '../Shared/FAIcon'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../const/routes'
import { useTranslation } from 'react-i18next'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  MainListItem,
  ListItem,
  ListItemTextStyled,
  LinkStyled,
} from '../Styles/NavListStyle'
import { checkRole } from '../../utils/checkRole'
import { USER_ROLES } from '../../const/permissions'

interface ITenantListItemProps {
  isMenuOpen?: boolean
}

const TenantListItem: FC<ITenantListItemProps> = ({ isMenuOpen }) => {
  const { t } = useTranslation(['admin_menu'])
  let { pathname } = useLocation()

  const [isTenantsExpanded, setIsTenantsExpanded] = useState(false)
  const { isAdmin, roles } = useAppSelector((state) => state.user)

  const isSelected = (route: string, isNestedListExpanded: boolean = true) =>
    !isMenuOpen || !isNestedListExpanded
      ? pathname.includes(route)
      : `/admin/${route}` === pathname

  if (!checkRole(USER_ROLES, roles)) {
    return null
  }

  return (
    <>
      <MainListItem
        onClick={() => setIsTenantsExpanded(!isTenantsExpanded)}
        data-e2e="navigation-tenants"
      >
        <ListItemIcon>
          <FAIcon icon={faBuilding as IconProp} />
        </ListItemIcon>
        <ListItemTextStyled primary={'Firmy'} />
        {isTenantsExpanded ? <ExpandLess /> : <ExpandMore />}
      </MainListItem>
      <Collapse in={isMenuOpen && isTenantsExpanded} timeout="auto">
        <List component="div">
          {isAdmin && (
            <>
              <LinkStyled to={ROUTES.COMPANIES}>
                <ListItem
                  selected={isSelected(ROUTES.COMPANIES, isTenantsExpanded)}
                  data-e2e="nav-tenant-list"
                >
                  <ListItemText primary={t('companies_list')} />
                </ListItem>
              </LinkStyled>
              <LinkStyled to={ROUTES.MYSOLVY_COMPANIES}>
                <ListItem
                  selected={isSelected(
                    ROUTES.MYSOLVY_COMPANIES,
                    isTenantsExpanded
                  )}
                  data-e2e="nav-my-solvy-tenant-list"
                >
                  <ListItemText primary={'Lista firm MySolvy'} />
                </ListItem>
              </LinkStyled>
            </>
          )}
        </List>
      </Collapse>
    </>
  )
}

export default TenantListItem
