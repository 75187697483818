import { useEffect, useState, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TextBody1Bold } from '../Styles/TextCustom'
import { useQuery, ApolloError } from '@apollo/client'
import {
  IQueryLockersData,
  QUERY_LOCKERS,
  LockerStatus,
  LockerType,
  ILocker,
} from '../../graphql/lockers/queryLockers'
import FullScreenLoader from '../Shared/FullScreenLoader'
import LockerFilters from './LockerFilters'
import { themeColors } from '../../const/colors'
import LockerTable from './LockerTable'
import { useLocation } from 'react-router-dom'
import ErrorContainer from '../Shared/ErrorContainer'

interface stateType {
  prop: {
    lockerId?: string
  }
}

const LockersList: FC<{
  lockerStatus: LockerStatus | string
}> = ({ lockerStatus }) => {
  const { t } = useTranslation(['lockers'])
  const [buildingFilter, setBuildingFilter] = useState('')
  const [lockerTypeFilter, setLockerTypeFilter] = useState<LockerType | ''>('')
  const [error, setError] = useState('')
  const [lockers, setLockers] = useState<ILocker[]>([])
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const state: stateType = location.state as stateType
  const lockerId = state?.prop?.lockerId

  const { data, refetch } = useQuery<IQueryLockersData>(QUERY_LOCKERS, {
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => setError(error.message),
  })

  useEffect(() => {
    if (data) {
      let items = data.lockers
      if (lockerStatus === 'all') {
        items = data.lockers
      } else {
        items = data.lockers.filter(({ status }) => status === lockerStatus)
      }
      if (buildingFilter) {
        items = items.filter(({ building }) => building.name === buildingFilter)
      }
      if (lockerTypeFilter) {
        items = items.filter(({ type }) => type === lockerTypeFilter)
      }
      setLockers(items)
      setLoading(false)
    }
  }, [data, buildingFilter, lockerTypeFilter, lockerStatus])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <LockerTable
        lockers={lockers}
        refetch={refetch}
        lockerId={lockerId}
        status={lockerStatus}
      >
        <LockerFilters
          building={buildingFilter}
          setBuilding={setBuildingFilter}
          lockerTypeFilter={lockerTypeFilter}
          setLockerTypeFilter={setLockerTypeFilter}
        />
      </LockerTable>
      {!lockers.length && (
        <TextBody1Bold color={themeColors.primary}>
          {t('no_results')}
        </TextBody1Bold>
      )}
      {!!error && <ErrorContainer errorMessage={error} />}
    </>
  )
}

export default LockersList
