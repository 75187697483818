import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { themeColors } from '../../const/colors'
import BaseSelect, { OptionType } from '../Shared/BaseSelect'
import { Grid, TextField, ClickAwayListener } from '@mui/material'
import { BaseButton } from '../Shared/BaseButton'
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import {
  CONFIRM_TENANT_STATUS_REQUEST,
  IConfirmTenantStatusRequestVars,
  IConfirmTenantStatusRequestData,
} from '../../graphql/users/confirmTenantStatusRequest'
import { useForm } from 'react-hook-form'
import { ApolloError, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import BaseModal from '../Shared/BaseModal'
import {
  SelectWrapper,
  TenantButtonWrapper,
  Text,
  TextBold,
} from '../Styles/DetailsDrawersStyles'
import AddTenantForm from '../Tenant/AddTenantForm'
import { ModalContent } from '../Styles/CustomElementsStyled'
import { dateIso2localeString } from '../../utils/formatDate'
import DrawerHeader from '../Shared/DrawerHeader'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'
import ErrorContainer from '../Shared/ErrorContainer'

const ResidentRequestDetails = ({
  options,
  issue,
  closeIssue,
  updateList,
  updateTenants,
  updateAggregatedCount,
  tab,
}: {
  options?: OptionType[]
  issue?: any
  closeIssue: () => void
  updateList: () => void
  updateTenants: () => void
  updateAggregatedCount: () => void
  tab: number
}) => {
  const { t, i18n } = useTranslation([
    'form',
    'btn',
    'code',
    'tenant_request_details',
    'tenant',
    'generic',
  ])

  const [comment, setComment] = useState('')
  const [code, setCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [open, setOpen] = useState(false)
  const [openClickAwayModal, setOpenClickAwayModal] = useState(false)

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()

  const issueData = [
    { label: t('tenant_request_details:full_name'), value: issue?.fullName },
    { label: t('tenant_request_details:login'), value: issue?.login },
    { label: t('tenant_request_details:contact_email'), value: issue?.email },
    {
      label: t('tenant_request_details:company_email'),
      value: issue?.workEmail,
    },
    {
      label: t('tenant_request_details:close_at'),
      value: tab && dateIso2localeString(issue?.closeAt, i18n.language),
    },
    {
      label: t('tenant_request_details:resolved_by'),
      value:
        tab && `${issue?.resolvedBy.firstName} ${issue?.resolvedBy.lastName}`,
    },
    { label: t('tenant_request_details:message'), value: issue?.description },
  ]
  const selectedTenant = watch('tenant')

  const handleAccept = (formData: any) => {
    const { tenant } = formData
    confirmTenantStatusRequest({
      variables: {
        code,
        action: 'accept',
        comment,
        tenant: tenant.label,
      },
    })
  }

  const handleReject = () => {
    confirmTenantStatusRequest({
      variables: {
        code,
        action: 'reject',
        comment,
      },
    })
  }

  const handleClickAway = () => {
    if (comment || selectedTenant) {
      setOpenClickAwayModal(true)
    } else closeIssue()
  }
  const handleCloseModal = (e: any) => {
    e.stopPropagation()
    setOpenClickAwayModal(false)
  }

  const onCompleted = (data: IConfirmTenantStatusRequestData) => {
    if (data?.confirmTenantStatusRequest.status) {
      updateList()
      updateAggregatedCount()
      closeIssue()
    } else setErrorMessage(t(`code:${data.confirmTenantStatusRequest.code}`))
  }

  const [confirmTenantStatusRequest, { loading }] = useMutation<
    IConfirmTenantStatusRequestData,
    IConfirmTenantStatusRequestVars
  >(CONFIRM_TENANT_STATUS_REQUEST, {
    onCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  useEffect(() => {
    if (issue) {
      setComment('')
      reset({ tenant: '' })
      setCode(issue?.code)
    }
    updateTenants()
  }, [issue, reset, updateTenants])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <>
          <DrawerHeader
            title={t('tenant_request_details:title')}
            handleClose={handleClickAway}
          />
          <DetailsWrapper>
            {issueData.map(
              (item) =>
                !!item.value && (
                  <Grid key={item.label}>
                    <Text>
                      {item.label}&nbsp;
                      <TextBold>{item.value}</TextBold>
                    </Text>
                  </Grid>
                )
            )}
          </DetailsWrapper>
          {!!tab ? (
            issue?.comment && (
              <DetailsWrapper>
                <TextTitle>{t('tenant_request_details:reply')}</TextTitle>
                <Text>{issue?.comment}</Text>
              </DetailsWrapper>
            )
          ) : (
            <>
              <DetailsWrapper>
                <Row
                  container
                  alignItems={'flex-start'}
                  data-e2e="tenants-select"
                >
                  <SelectWrapper item xs={8}>
                    <BaseSelect
                      placeholder={t('select_tenant')}
                      inputId="tenants-select"
                      name="tenant"
                      options={options}
                      control={control}
                      errors={errors}
                      rules={{ required: `${t('tenant_required')}` }}
                      isValid={!errors.tenant}
                      noOptionsMessage={() => `${t('no_tenants')}`}
                    />
                  </SelectWrapper>
                  <TenantButtonWrapper item xs={4}>
                    <BaseButton onClick={() => setOpen(true)}>
                      {t('btn:add_tenant')}
                    </BaseButton>
                  </TenantButtonWrapper>
                </Row>
              </DetailsWrapper>
              <DetailsWrapper>
                <InputCustom
                  data-e2e="residentDetails-input"
                  label={t('comment')}
                  multiline
                  fullWidth={true}
                  rows={6}
                  variant="outlined"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value)
                  }}
                />
                <Row container justifyContent={'flex-end'}>
                  <FlexCol item xs={6}>
                    <ButtonStyled
                      data-e2e="residentDetails-button-reject"
                      icon={faTimes as IconProp}
                      onClick={handleReject}
                      customColor={themeColors.negative}
                    >
                      {t('btn:reject')}
                    </ButtonStyled>
                  </FlexCol>
                  <Grid item xs={6}>
                    <ButtonStyled
                      icon={faCheck as IconProp}
                      onClick={handleSubmit(handleAccept)}
                      customColor={themeColors.accept}
                    >
                      {t('btn:accept')}
                    </ButtonStyled>
                  </Grid>
                </Row>
              </DetailsWrapper>
            </>
          )}

          {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
        </>
      </ClickAwayListener>
      <BaseModal
        title={t('tenant:add_new_tenant')}
        open={open}
        actions={false}
        handleClick={(e) => e.stopPropagation()}
      >
        <AddTenantForm
          handleClose={() => setOpen(false)}
          handleUpdateTenants={updateTenants}
        />
      </BaseModal>
      <BaseModal
        confirmText={'btn:exit'}
        open={openClickAwayModal}
        handleClose={(e) => handleCloseModal(e)}
        handleAccept={() => closeIssue()}
      >
        <ModalContent>{t('generic:exit_without_saving')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default ResidentRequestDetails

const Row = styled(Grid)`
  padding: 16px 0;
  flex-wrap: nowrap;
`
const FlexCol = styled(Grid)`
  padding: 0 16px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`
const InputCustom = styled(TextField)`
  background-color: ${themeColors.white};
`
const ButtonStyled = styled(BaseButton)`
  width: 140px;
`
const TextTitle = styled(Text)`
  font-weight: 600;
`
