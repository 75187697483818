import { useLazyQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  GET_USER_ACCEPTANCES,
  IGetUserAcceptancesEvents,
  IGetUserAcceptancesVars,
} from '../../graphql/users/getUserAcceptances'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import { useParams } from 'react-router-dom'
import { Typography } from '@mui/material'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { removeLinks } from '../../utils/removeLinks'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

interface Row {
  createdAt: string
  createdAtCSV: string
  service: string
  title: string
  required: string
  current: string
  content: string
}

const UserAcceptancesScreen = () => {
  const { t, i18n } = useTranslation()
  const { usr } = useParams()

  const [getUserAcceptances, { data, loading }] = useLazyQuery<
    IGetUserAcceptancesEvents,
    IGetUserAcceptancesVars
  >(GET_USER_ACCEPTANCES, {
    fetchPolicy: 'no-cache',
  })

  const csvHeaders = [
    'createdAtCSV',
    'service',
    'title',
    'required',
    'current',
    'content',
  ]

  const invisibleColumns = {
    createdAtCSV: false,
  }

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        header: t('user_details_table:createdAt'),
        accessorKey: 'createdAt',
        cell: (value) => dateIso2localeString(value.getValue(), i18n.language),
        enableColumnFilter: false,
      },
      {
        header: 'createdAtCSV',
        accessorKey: 'createdAtCSV',
      },
      {
        header: t('user_details_table:service'),
        accessorKey: 'service',
      },
      {
        header: t('user_details_table:title'),
        accessorKey: 'title',
      },
      {
        header: t('user_details_table:required'),
        accessorKey: 'required',
      },
      {
        header: t('user_details_table:current'),
        accessorKey: 'current',
      },
      {
        header: t('user_details_table:content'),
        accessorKey: 'content',
      },
    ],
    [i18n.language, t]
  )

  const tableData: Row[] = useMemo(
    () =>
      data?.getUser?.events?.map(({ created_at, regulation, comment }) => {
        const accepted = [
          'acceptance set to yes',
          'User changed acceptance to yes',
        ]
        const rejected = [
          'acceptance set to no',
          'User changed acceptance to no',
        ]
        const responseData = {
          createdAt: created_at ? created_at : '',
          createdAtCSV: created_at ? dateIso2ExcelFormat(created_at) : '',
          service: regulation?.service || '',
          title: regulation?.title || '',
          required: regulation?.required ? t('bool:yes') : t('bool:no'),
          current: accepted.includes(comment)
            ? t('bool:yes')
            : rejected.includes(comment)
            ? t('bool:no')
            : t('non_data:non_data'),
          content: removeLinks(regulation?.content || ''),
        }
        return responseData
      }) || [],
    [data, t]
  )

  useEffect(() => {
    if (usr) {
      getUserAcceptances({
        variables: {
          login: usr,
        },
      })
    }
  }, [usr])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {`${t('user_details_table:topic')} ${usr}`}
      </TypographyStyled>

      <Table
        columns={columns}
        data={tableData}
        columnVisibility={invisibleColumns}
        csvExport
        enableRowSelection
        csvHeaders={csvHeaders}
      />
    </>
  )
}

export default UserAcceptancesScreen

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
