import { useState, ReactNode } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { BaseButton } from '../Shared/BaseButton'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import Input from '../Shared/Input'
import { useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import {
  APPEND_LOG,
  IAppendLogVars,
  IAppendLogData,
} from '../../graphql/shared/appendLog'
import ErrorContainer from './ErrorContainer'
import { BaseTextButton } from '../Shared/BaseButton'

type FormData = {
  message: string
}

const AddLogForm = ({
  id,
  refetch,
  closeDetails,
  getLogs,
  children,
}: {
  id: string
  refetch: () => void
  closeDetails: () => void
  getLogs?: () => void
  children?: ReactNode
}) => {
  const { t } = useTranslation()
  const successNotify = (message: string) => toast.dark(message)
  const [error, setError] = useState('')

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  const onCompleted = (data: IAppendLogData) => {
    const { status, error } = data.appendLog
    if (status) {
      successNotify(`${t('generic:success_message')}`)
      refetch()
      getLogs ? getLogs() : closeDetails()
    } else {
      error && setError(error)
    }
  }

  const [appendLog] = useMutation<IAppendLogData, IAppendLogVars>(APPEND_LOG, {
    onCompleted,
    fetchPolicy: 'no-cache',
  })

  const onSubmit = (data: FormData) => {
    const { message } = data

    appendLog({
      variables: {
        objectId: id,
        message,
      },
    })
  }

  return (
    <Container>
      <Input
        label={t('form:log_comment')}
        name={'message'}
        control={control}
        errors={errors}
        rules={{ required: `${t('form:field_required')}` }}
        multiline
        rows={3}
        data-e2e="log-input"
      />
      <ButtonWrapper container spacing={2}>
        <Wrapper item xs={6}>
          <BaseTextButton
            onClick={handleSubmit(onSubmit)}
            data-e2e="submit-log"
          >
            {t('btn:add_comment')}
          </BaseTextButton>
          {!!error && <ErrorContainer errorMessage={error} />}
        </Wrapper>
        <Wrapper item xs={6}>
          {children}
        </Wrapper>
      </ButtonWrapper>
    </Container>
  )
}

export default AddLogForm

const Container = styled(Grid)`
  padding: 16px 0;
`
const ButtonWrapper = styled(Grid)`
  padding: 16px 0;
`
const Wrapper = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`
