import gql from 'graphql-tag'

export interface IAddMenuItemData {
  addMenuItem: {
    namePl: string
  }
}
export const ADD_MENU_ITEM = gql`
  mutation AddMenuItem(
    $visible: Boolean!
    $name: String!
    $nameEn: String
    $description: String
    $descriptionEn: String
    $amount: Int
    $categoryId: String
    $unit: String
    $price: Int
    $dietType: DietType
    $restaurantId: ID
    $availability: [AvailabilityRef]
  ) {
    addMenuItem(
      input: {
        visible: $visible
        name: $name
        name_en: $nameEn
        description: $description
        description_en: $descriptionEn
        amount: $amount
        category: { id: $categoryId }
        unit: { name: $unit }
        price: $price
        diet_type: $dietType
        restaurant: { id: $restaurantId }
        availability: $availability
      }
    ) {
      menuItem {
        namePl: name
      }
    }
  }
`
