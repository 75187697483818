import Drawer from '@mui/material/Drawer'
import styled from 'styled-components'
import { makeStyles } from '@mui/styles'

type Anchor = 'top' | 'left' | 'bottom' | 'right'

const useStyles = makeStyles({
  drawerPaper: {
    marginTop: '64px',
    overflowY: 'auto',
    height: `calc(100vh - 64px)`,
    width: `calc(100vw - 265px)`,
  },
})

const FullWidthDrawer = ({
  children,
  open,
  anchor = 'right',
}: {
  children: any
  open: boolean
  anchor?: Anchor
}) => {
  const classes = useStyles()
  return (
    <Drawer
      anchor={anchor}
      open={open}
      variant="persistent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <DrawerContent data-e2e="drawer">{children}</DrawerContent>
    </Drawer>
  )
}

export default FullWidthDrawer

const DrawerContent = styled.div`
  padding: 30px;
  width: 100%;
  flex: 1;
  z-index: 1;
  overflow-top: hidden;
`
