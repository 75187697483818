import { DatePicker as Picker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs'

const DatePicker = ({
  selectedDate,
  setSelectedDate,
  label,
  disablePast = false,
  minDate,
}: {
  selectedDate: Date | null
  setSelectedDate: (value: Date | null) => void
  label: string
  disablePast?: boolean
  minDate?: Date
}) => {
  const handleChange = (date: Dayjs | null) =>
    setSelectedDate(date?.toDate() || null)

  return (
    <Picker
      label={label}
      value={dayjs(selectedDate)}
      onChange={handleChange}
      minDate={minDate ? dayjs(minDate) : undefined}
      disablePast={disablePast}
      format="DD.MM.YYYY"
    />
  )
}

export default DatePicker
