import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import SummaryTenantDataForm from './SummaryTenantDataForm'
import { BaseButton } from '../Shared/BaseButton'
import { LockerReservationRow } from '../LockerReservation/LockerReservationTable'
import { LockerRow } from '../Lockers/LockerTable'
import DrawerHeader from '../Shared/DrawerHeader'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { useReactToPrint } from 'react-to-print'
import Protocol from './Protocol'
import ReservationSummaryDetails from './ReservationSummaryDetails'
import Input from '../Shared/Input'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'

type FormData = {
  name: string
  nip: string
  street: string
  zipcode: string
  city: string
  representative: string
  annotation?: string
}

const ReservationSummary = ({
  closeDrawer,
  reservations,
  pickUp = false,
}: {
  closeDrawer: () => void
  reservations?: LockerReservationRow[] | LockerRow[]
  pickUp?: boolean
}) => {
  const { t } = useTranslation(['lockers'])
  const componentRef = useRef(null)
  const [protocolData, setProtocolData] = useState<FormData>()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>()

  if (!reservations?.length) {
    return <FullScreenLoader />
  }

  const { building, user, reservation } = reservations[0]
  const noShower = reservation?.order?.product.withShower === false
  const tenant = reservations.length === 1 ? reservations[0].tenant : ''
  const renderProtocol = (data: FormData) => {
    return (
      <Protocol
        ref={componentRef}
        tenantData={data}
        reservations={reservations}
        building={building}
        pickUp={pickUp}
        seller={reservation?.order?.product?.seller}
        user={user}
        noShower={noShower}
      />
    )
  }

  const onSubmit = async (data: FormData) => {
    await setProtocolData(data)
    handlePrint()
  }

  return (
    <>
      <DrawerHeader
        title={pickUp ? t('pickup_protocol') : t('transfer_protocol')}
        handleClose={closeDrawer}
      />
      <DetailsWrapper>
        <ReservationSummaryDetails reservations={reservations} />
      </DetailsWrapper>
      <DetailsWrapper>
        <FormStyled>
          {!user && (
            <SummaryTenantDataForm
              errors={errors}
              control={control}
              tenant={tenant}
              pickUp={pickUp}
            />
          )}
          {pickUp && user && (
            <Input
              label={t('title_annotation')}
              name={'annotation'}
              control={control}
              errors={errors}
            />
          )}
          <Grid container justifyContent="center">
            <BaseButton
              onClick={handleSubmit(onSubmit)}
              data-e2e="print-protocol"
            >
              {t('print_protocol')}
            </BaseButton>
          </Grid>
        </FormStyled>
      </DetailsWrapper>
      {protocolData && <Wrapper>{renderProtocol(protocolData)}</Wrapper>}
    </>
  )
}

export default ReservationSummary

const FormStyled = styled.form`
  padding: 16px 0 0;
`
const Wrapper = styled.div`
  display: none;
`
