import React, { useState, useEffect } from 'react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { TextBody1Medium } from '../Styles/TextCustom'
import { BaseTextButton } from '../Shared/BaseButton'
import EditRegexInput from './EditRegexInput'
import { IRegexp } from '../../graphql/olivka/queryOlivkaRegexp'
import AddRegexInput from './AddRegexInput'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface IOffersBlockingInputsProps {
  items: IRegexp[]
  title: string
  refetch: () => void
}

const OffersBlockingInputs = (props: IOffersBlockingInputsProps) => {
  const { items, title, refetch } = props
  const [inputs, setInputs] = useState<IRegexp[]>([])
  const [newInput, setNewInput] = useState(false)
  const { t } = useTranslation(['olivka'])
  const randomId = Math.floor(Math.random() * (999 - 100 + 1) + 100)

  useEffect(() => {
    setInputs(items)
  }, [items])

  return (
    <>
      <TextBody1Medium>{t(title)}</TextBody1Medium>
      {inputs.map((item) => (
        <EditRegexInput key={item.name} item={item} refetch={refetch} />
      ))}
      {newInput && (
        <AddRegexInput
          name={`config.olivka.regexp.${title}.${randomId}`}
          remove={() => setNewInput(false)}
          refetch={refetch}
        />
      )}
      {!newInput && (
        <BaseTextButton
          onClick={() => setNewInput(!newInput)}
          icon={faPlus as IconProp}
        >
          {t('add_line')}
        </BaseTextButton>
      )}
    </>
  )
}

export default OffersBlockingInputs
