import React, { FC } from 'react'
import { ListContainer } from '../Styles/NavListStyle'
import { List } from '@mui/material'
import GardenListItem from './GardenListItem'
import GastroListItem from './GastroListItem'
import ParkingListItem from './ParkingListItem'
import LockersListItem from './LockersListItem'
import SurveyListItem from './SurveyListItem'
import EventsListItem from './EventsListItem'
import UsersListItem from './UsersListItem'
import GetResponseListItem from './GetResponseListItem'
import PushListItem from './PushListItem'
import BannerListItem from './BannerListItem'
import PayUListItem from './PayUListItem'
import OlivkaListItem from './OlivkaListItem'
import IntegrationsListItem from './IntegrationsListItem'
import ExtrasListItem from './ExtrasListItem'
import MessagesListItem from './MessagesListItem'
import TenantListItem from './TenantListItem'

interface INavListProps {
  tenantRequestCount?: number
  emailVerificationRequestCount?: number
  userMessagesCount?: number
  accessCardIssuesCount?: number
  isMenuOpen?: boolean
}

const NavList: FC<INavListProps> = ({
  tenantRequestCount,
  emailVerificationRequestCount,
  userMessagesCount,
  accessCardIssuesCount,
  isMenuOpen,
}) => {
  return (
    <ListContainer>
      <List>
        <GardenListItem isMenuOpen={isMenuOpen} />
        <GastroListItem isMenuOpen={isMenuOpen} />
        <ParkingListItem isMenuOpen={isMenuOpen} />
        <LockersListItem isMenuOpen={isMenuOpen} />
        <SurveyListItem isMenuOpen={isMenuOpen} />
        <EventsListItem isMenuOpen={isMenuOpen} />
        <ExtrasListItem isMenuOpen={isMenuOpen} />
        <UsersListItem
          isMenuOpen={isMenuOpen}
          tenantRequestCount={tenantRequestCount}
          emailVerificationRequestCount={emailVerificationRequestCount}
        />
        <TenantListItem isMenuOpen={isMenuOpen} />
        <MessagesListItem
          userMessagesCount={userMessagesCount}
          isMenuOpen={isMenuOpen}
        />
        <GetResponseListItem isMenuOpen={isMenuOpen} />
        <PushListItem isMenuOpen={isMenuOpen} />
        <BannerListItem isMenuOpen={isMenuOpen} />
        <PayUListItem isMenuOpen={isMenuOpen} />
        <OlivkaListItem isMenuOpen={isMenuOpen} />
        <IntegrationsListItem isMenuOpen={isMenuOpen} />
      </List>
    </ListContainer>
  )
}

export default NavList
