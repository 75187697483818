import gql from 'graphql-tag'

export interface IQueryEmailsWithDomainData {
  queryEmail: {
    email: string
    domain: {
      name: string
    }
  }[]
}

export interface IQueryEmailsWithDomainVars {
  domain: string
}

export const FIND_EMAILS_WITH_DOMAIN = gql`
  query FindEmailsWithDomain($domain: String!) {
    queryEmail @cascade(fields: "domain") {
      email
      user {
        login
      }
      domain(filter: { name: { eq: $domain } }) {
        name
      }
    }
  }
`
