import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'
import { Roles } from '../shared/sharedTypes'
import { IFormRegulation } from '../../types/FormTypes'

export interface IMySolvyLoginData {
  login: IQueryBasicData & {
    accessToken: string
    refreshToken: string
    user: {
      login: string
      roles: {
        name: Roles
      }[]
      mobilePhone: string
      acceptances: {
        decision: boolean
        id: string
        regulation: IFormRegulation
      }[]
    }
  }
}

export const MYSOLVY_LOGIN_QUERY = gql`
  query login(
    $login: String!
    $password: String!
    $app: String!
    $apiVersion: Int!
    $service: String
  ) {
    login(
      login: $login
      password: $password
      app: $app
      api_version: $apiVersion
      service: $service
    ) {
      accessToken
      code
      error
      message
      refreshToken
      status
      user {
        login
        roles {
          name
        }
        mobilePhone: mobile_phone
        acceptances {
          decision
          id
          regulation {
            content
            current
            id
            required
            topLevel: top_level
            title
          }
        }
      }
    }
  }
`
