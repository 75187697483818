import { useState } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import TooltipButton from '../Shared/TooltipButton'
import { UserRoleRow } from './UserRoleTable'
import { Grid } from '@mui/material'
import {
  UNASSIGN_USER_ROLE,
  IUnassignUserRoleData,
  IUnassignUserRoleVars,
} from '../../graphql/users/userRoleActions'
import { toast } from 'react-toastify'

const RemoveSelectedUserRoles = ({
  role,
  users,
  refetch,
}: {
  role: string
  users: UserRoleRow[]
  refetch: () => void
}) => {
  const { t } = useTranslation(['user_details'])
  const [count, setCount] = useState(1)
  const successNotify = (message: string) => toast.dark(message)
  const errorNotify = (message: string) => toast.error(message)

  const onCompleted = async (data: IUnassignUserRoleData) => {
    const { status, error, code } = data.unassignRole
    if (status) {
      successNotify(t('remove_role_success'))
    } else {
      errorNotify(
        `${t(`code:${code}`) === `${code}` ? `${error}` : t(`code:${code}`)}`
      )
    }
    await setCount(count + 1)
    count === users.length && refetch()
  }

  const [unassignRole] = useMutation<
    IUnassignUserRoleData,
    IUnassignUserRoleVars
  >(UNASSIGN_USER_ROLE, {
    fetchPolicy: 'no-cache',
    onCompleted,
  })

  const handleClick = () =>
    users.forEach(({ login }) => {
      unassignRole({
        variables: { login, role },
      })
    })

  return (
    <Wrapper>
      <TooltipButton
        onClick={handleClick}
        disabled={!role || !users.length}
        hint={t('remove_role_hint')}
        data-e2e="remove-roles-button"
      >
        {t('remove_user_role')}
      </TooltipButton>
    </Wrapper>
  )
}

export default RemoveSelectedUserRoles

const Wrapper = styled(Grid)`
  padding: 16px 0;
  width: 100%;
`
