import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import BaseSelect from './BaseSelect'

interface IPushFormProps {
  errors: any
  control: any
  defaultValue?: string
}

const MobileViewSelect = (props: IPushFormProps) => {
  const { control, errors, defaultValue } = props
  const { t } = useTranslation(['push'])

  const views = [
    { value: 'MainScreen', label: t('main_screen') },
    { value: 'MyAccount', label: t('my_account') },
    { value: 'MyAccountContactUs', label: t('my_account_contact_us') },
    { value: 'Garden', label: t('garden') },
    { value: 'Gastro', label: t('gastro') },
    { value: 'Parking', label: 'Parking' },
    { value: 'News', label: t('news') },
    { value: 'ObservationDeck', label: t('observation_deck') },
    { value: 'FAQ', label: 'FAQ' },
    { value: 'Olivka', label: 'Olivka' },
    { value: 'Events', label: t('events') },
    { value: 'Lockers', label: t('lockers') },
    { value: 'MobileAccessCardModule', label: t('hid') },
  ]

  return (
    <SelectWrapper data-e2e="mobile-view-select">
      <BaseSelect
        defaultValue={
          (defaultValue && views.find((val) => val.value === defaultValue)) ||
          views[0]
        }
        options={views.map(({ value, label }) => ({
          label,
          value,
        }))}
        name="mobileView"
        control={control}
        errors={errors}
        isClearable={false}
        placeholder={t('mobile_view_target')}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </SelectWrapper>
  )
}

export default MobileViewSelect

const SelectWrapper = styled.div`
  max-width: 500px;
`
