import { Grid, Chip, Stack, Button } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { themeColors } from '../../const/colors'
import { ParkingPlaceStatus } from '../../graphql/parking/queryParkingPlace'
import ParkingSelect from '../Parking/ParkingSelect'
import PlaceStatusSelect from './PlaceStatusSelect'
import PlaceSharedSelect from './PlaceSharedSelect'
import TenantSelect from './TenantWithParkingPoolSelect'

const PlaceFilters = ({
  parking,
  setParking,
  status,
  setStatus,
  shared,
  setShared,
  tenantFilter,
  tenant,
  setTenant,
}: {
  parking: string
  setParking: (v: string) => void
  status: ParkingPlaceStatus | ''
  setStatus: (v: ParkingPlaceStatus | '') => void
  shared: string
  setShared: (v: string) => void
  tenantFilter: boolean
  tenant?: string
  setTenant?: (v: string) => void
}) => {
  const { t } = useTranslation(['parking_places'])
  const handleClearFilters = () => {
    setParking('')
    setStatus('')
    setShared('')
  }
  return (
    <>
      <GridStyled container spacing={2}>
        <Grid item>
          <ParkingSelect value={parking} setValue={setParking} />
        </Grid>
        <Grid item>
          <PlaceStatusSelect value={status} setValue={setStatus} short />
        </Grid>
        <Grid item>
          <PlaceSharedSelect value={shared} setValue={setShared} />
        </Grid>
        {tenantFilter && tenant !== undefined && setTenant !== undefined && (
          <Grid item>
            <TenantSelect value={tenant} setValue={setTenant} />
          </Grid>
        )}
      </GridStyled>
      <Stack direction="row" spacing={1}>
        {parking && (
          <ChipStyled label={parking} onDelete={() => setParking('')} />
        )}
        {status && (
          <ChipStyled label={t(status)} onDelete={() => setStatus('')} />
        )}
        {shared && (
          <ChipStyled
            label={`${t('shared')}: ${t(shared)}`}
            onDelete={() => setShared('')}
          />
        )}
        {tenant && setTenant !== undefined && (
          <ChipStyled
            label={`${t('company')}: ${tenant}`}
            onDelete={() => setTenant('')}
          />
        )}
        {(parking || status || shared || tenant) && (
          <ButtonStyled variant="text" onClick={handleClearFilters}>
            {t('btn:clear_filters')}
          </ButtonStyled>
        )}
      </Stack>
    </>
  )
}

export default PlaceFilters

const GridStyled = styled(Grid)`
  padding: 16px 0;
  flex-direction: row;
`
const ChipStyled = styled(Chip)`
  color: ${themeColors.primary};
  background-color: ${themeColors.lightBlue};
  font-size: 14px;
  font-weight: 500;
`
const ButtonStyled = styled(Button)`
  text-transform: none;
  font-weight: 700;
`
