import {
  RESOLVE_ISSUE,
  IResolveIssueVars,
  IResolveIssueData,
} from '../../graphql/userIssues/resolveIssue'
import { ApolloError, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import FullScreenLoader from '../Shared/FullScreenLoader'

import { IssueStatus } from '../../graphql/userIssues/queryIssue'
import { BaseTextButton } from '../Shared/BaseButton'

const CloseIssue = ({
  id,
  closeIssue,
  updateList,
  updateAggregatedCount,
}: {
  id: string
  closeIssue: () => void
  updateList: () => void
  updateAggregatedCount: () => void
}) => {
  const { t } = useTranslation()
  const notify = (message: string) => toast.dark(message)

  const onCompleted = (data: IResolveIssueData) => {
    if (data?.resolveIssue.status) {
      updateList()
      updateAggregatedCount()
      closeIssue()
    } else {
      notify(`${t(`code:${data.resolveIssue.code}`)}`)
    }
  }

  const [resolveIssue, { loading }] = useMutation<
    IResolveIssueData,
    IResolveIssueVars
  >(RESOLVE_ISSUE, {
    onCompleted,
    onError: (error: ApolloError) => notify(error.message),
  })

  if (loading) {
    return <FullScreenLoader />
  }

  const handleCloseIssue = () => {
    resolveIssue({
      variables: {
        id,
        status: IssueStatus.resolved,
      },
    })
  }

  return (
    <BaseTextButton onClick={handleCloseIssue}>
      {t('btn:close_without_message')}
    </BaseTextButton>
  )
}

export default CloseIssue
