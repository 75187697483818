import { gql } from '@apollo/client'
import { IQueryBasicData } from '../shared/IQueryData'
import { Roles } from '../shared/sharedTypes'

export type UserProfileLang = 'en' | 'pl' | 'none'
export interface IMyProfile {
  login: string
  firstName: string
  lastName: string
  tenant?: {
    name: string
  }
  language: string
  email: { email: string }
  workEmail: { email: string }
  roles: {
    name: Roles
  }[]
  managesRestaurants: {
    id: string
    name: string
  }[]
  features: { name: string }[]
}

export interface IGetMyProfileData {
  getMyProfile: IQueryBasicData & {
    user: IMyProfile
  }
}

export const GET_MY_PROFILE_QUERY = gql`
  query getMyProfile {
    getMyProfile {
      status
      message
      code
      error
      user {
        login
        firstName: first_name
        lastName: last_name
        tenant {
          name
        }
        language
        email {
          email
        }
        workEmail: work_email {
          email
        }
        roles {
          name
        }
        managesRestaurants: manages_restaurants {
          id
          name
        }
        features {
          name
        }
      }
    }
  }
`
