import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { themeColors } from '../const/colors'
import { useForm } from 'react-hook-form'
import { BaseButton, BaseTextButton } from '../components/Shared/BaseButton'
import FullScreenLoader from '../components/Shared/FullScreenLoader'
import { TextBody1Medium } from '../components/Styles/TextCustom'
import {
  IMySolvyLoginData,
  MYSOLVY_LOGIN_QUERY,
} from '../graphql/auth/mySolvyLogin'
import { ILoginData, ILoginVars, LOGIN_QUERY } from '../graphql/auth/login'
import { ApolloError, useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import SignInForm from '../components/PagesComponents/SignInForm'
import MySolvyRequirements from '../components/PagesComponents/MySolvyRequirements'
import { useNavigate } from 'react-router-dom'
import ErrorContainer from '../components/Shared/ErrorContainer'

type SignInFormData = {
  login: string
  password: string
}

const defaultValues = {
  login: '',
  password: '',
}

interface ICustomWindow extends Window {
  TAG?: string
}
declare var window: ICustomWindow

const SignInMySolvyPage = () => {
  const { t } = useTranslation(['form', 'btn', 'login_screen', 'code'])
  const [errorMessage, setErrorMessage] = useState('')
  const [email, setEmail] = useState('')
  const [redirect, setRedirect] = useState('')
  const [login, setLogin] = useState('')
  const [mySolvyToken, setMySolvyToken] = useState('')
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<SignInFormData>({
    defaultValues,
  })

  const redirectToMySolvy = () =>
    window.location.replace(`${redirect}?user=${mySolvyToken}`)

  const onMySolvyLoginCompleted = async (data: IMySolvyLoginData) => {
    if (!data?.login.status) {
      return setErrorMessage(t(`code:${data.login.code}`))
    }
    const { accessToken, user } = data.login
    await setLogin(user.login)
    await setMySolvyToken(accessToken)
  }

  const onCompleted = async (data: ILoginData) => {
    const { status, error, code } = data.login
    if (!status) {
      return setErrorMessage(
        t(`code:${code}`) === `${code}` ? `${error}` : t(`code:${code}`)
      )
    }
    const { accessToken, refreshToken } = data.login
    await localStorage.setItem('accessToken', accessToken)
    await localStorage.setItem('refreshToken', refreshToken)
  }

  const [mySolvyLoginUser, { loading }] = useLazyQuery<
    IMySolvyLoginData,
    ILoginVars
  >(MYSOLVY_LOGIN_QUERY, {
    onCompleted: onMySolvyLoginCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
    fetchPolicy: 'no-cache',
  })

  const [loginUser] = useLazyQuery<ILoginData, ILoginVars>(LOGIN_QUERY, {
    onCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
    fetchPolicy: 'no-cache',
  })

  const handleLogin = async (formData: SignInFormData) => {
    const login = formData.login.trim()
    await loginUser({
      variables: {
        login,
        password: formData.password,
        app: `${window.TAG || 'local'}`,
        apiVersion: 36,
        service: 'olivia',
      },
    })
    await mySolvyLoginUser({
      variables: {
        login,
        password: formData.password,
        app: `${window.TAG || 'local'}`,
        apiVersion: 36,
        service: 'mysolvy',
      },
    })
  }

  const getUrlParams = async () => {
    const queryString = window.location.hash
    const params = queryString.split('?')
    const urlParams = new URLSearchParams(params[1])
    const email = await urlParams.get('email')
    const redirect = await urlParams.get('redirect')

    await setEmail(email || '')
    await setRedirect(redirect || '')
    if (email) {
      await setValue('login', email)
    }
  }

  useEffect(() => {
    getUrlParams()
  }, [])

  if (loading) {
    return <FullScreenLoader />
  }

  if (!redirect || !email) {
    return (
      <CenterContainer>
        <TextBody1Medium>{t('generic:no_parameters')}</TextBody1Medium>
      </CenterContainer>
    )
  }

  if (mySolvyToken) {
    return (
      <MySolvyRequirements
        login={login}
        redirectToMySolvy={redirectToMySolvy}
      />
    )
  }

  return (
    <CenterContainer>
      <BlueContainer>
        <Logo>
          {'My'}&nbsp;
          <Color>Solvy</Color>
        </Logo>
        <FormContainer onSubmit={handleSubmit(handleLogin)}>
          <SignInForm control={control} errors={errors} email={email} />
          <TextButton
            onClick={() => navigate('/remind-password')}
            color={themeColors.secondary}
          >
            {t('login_screen:remind_password')}
          </TextButton>
          <ButtonStyled type="submit">{t('btn:log_in')}</ButtonStyled>
          {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
        </FormContainer>
      </BlueContainer>
    </CenterContainer>
  )
}

export default SignInMySolvyPage

export const CenterContainer = styled.div`
  background-color: ${themeColors.darkBlue};
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`

const BlueContainer = styled.div`
  background-color: ${themeColors.white};
  border-radius: 30px;
  width: 566px;
  height: 474px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const FormContainer = styled.form`
  width: 328px;
`
const Logo = styled.h3`
  font-size: 44px;
  margin: 0 0 30px;
  letter-spacing: 0.75px;
  font-weight: 400;
  color: ${themeColors.secondary};
`
const Color = styled.span`
  font-weight: 800;
  color: ${themeColors.secondary};
`
const ButtonStyled = styled(BaseButton)`
  margin-top: 20px;
`
const TextButton = styled(BaseTextButton)`
  margin: 0 auto;
  width: 100%;
`
