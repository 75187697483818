import gql from 'graphql-tag'

export interface IChangeUserTenantVars {
  login: string
  tenantId: string
}

export interface IUpdateUserData {
  updateUser: {
    user: { login: string; tenant: null | { id: string; name: string } }[]
  }
}

export const CHANGE_USER_TENANT = gql`
  mutation ChangeUserTenant($login: String!, $tenantId: ID!) {
    updateUser(
      input: {
        filter: { login: { eq: $login } }
        set: { tenant: { id: $tenantId } }
      }
    ) {
      user {
        login
        tenant {
          id
          name
        }
      }
    }
  }
`
export const CLEAR_USER_TENANT = gql`
  mutation ClearUserTenant($login: String!, $tenantId: ID!) {
    updateUser(
      input: {
        filter: { login: { eq: $login } }
        remove: { tenant: { id: $tenantId } }
      }
    ) {
      user {
        login
        tenant {
          id
          name
        }
      }
    }
  }
`
