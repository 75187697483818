import { Switch } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormControlLabel } from '@mui/material'
import { themeColors } from '../../const/colors'
import styled from 'styled-components'
import { alpha } from '@mui/material/styles'

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: themeColors.negative,
    '&:hover': {
      backgroundColor: alpha(themeColors.negative, 0.1),
    },
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: themeColors.negative,
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: themeColors.accept,
    '&:hover': {
      backgroundColor: alpha(themeColors.accept, 0.1),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: themeColors.accept,
  },
  '& .MuiSwitch-switchBase.Mui-disabled': {
    color: themeColors.lightGray,
  },
  '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
    backgroundColor: themeColors.lightGray,
  },
  '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
    color: themeColors.lightGray,
  },
}))

const AvailabilitySwitch = ({
  checked,
  checkedText = 'visible',
  uncheckedText = 'invisible',
  ...rest
}: {
  checked: boolean
  checkedText?: string
  uncheckedText?: string
  [k: string]: any
}) => {
  const { t } = useTranslation(['generic'])

  return (
    <SwitchLabelStyled
      control={<CustomSwitch checked={checked} {...rest} />}
      label={checked ? `${t(checkedText)}` : `${t(uncheckedText)}`}
      checked={checked}
    />
  )
}

export default AvailabilitySwitch

const SwitchLabelStyled = styled(FormControlLabel)`
  .MuiTypography-root {
    font-size: 16px;
    color: ${(props) =>
      props.checked ? themeColors.accept : themeColors.negative};
  }
  .MuiSwitch-switchBase.Mui-checked {
    color: ${themeColors.accept};
  }
`
