import { ApolloLink } from '@apollo/client'

export const authLink = new ApolloLink((operation, forward) => {
  const accessToken = localStorage.getItem('accessToken')

  operation.setContext(({ headers }: Record<string, any>) => ({
    headers: {
      'X-Auth-Token': accessToken || '',
      ...headers,
    },
  }))
  return forward(operation)
})
