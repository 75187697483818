import gql from 'graphql-tag'

export interface IUnassignRestaurantVars {
  login: string
  restaurant: string
}

export interface IUnassignRestaurantData {
  updateUser: {
    user: {
      login: string
      managesRestaurants: {
        value: string
        label: string
      }[]
    }
  }
}

export const UNASSIGN_USER_RESTAURANT = gql`
  mutation unassignRestaurant($login: String!, $restaurant: ID!) {
    updateUser(
      input: {
        filter: { login: { eq: $login } }
        remove: { manages_restaurants: [{ id: $restaurant }] }
      }
    ) {
      user {
        login
        managesRestaurants: manages_restaurants {
          value: id
          label: name
        }
      }
    }
  }
`
