import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { ApolloError, useMutation } from '@apollo/client'
import { Grid } from '@mui/material'
import { BaseButton } from '../Shared/BaseButton'
import {
  ADD_PARKING_POOL_PROFILE,
  IAddParkingPoolProfileData,
  IAddParkingPoolProfileVariables,
} from '../../graphql/tenant/addParkingPoolProfile'
import { toast } from 'react-toastify'
import { date2isoString } from '../../utils/formatDate'
import ParkingPoolProfileInputs from './ParkingPoolProfileInputs'
import ErrorContainer from '../Shared/ErrorContainer'

type FormData = {
  name: string
  maxAdvanceDays: number
  maxDurationDays: number
  maxReservationCountPerUser: number
  customEmailMessage?: string
}

const AddProfileForm = ({
  handleClose,
  handleUpdateTenants,
  parkingPoolId,
}: {
  handleClose: () => void
  handleUpdateTenants: () => void
  parkingPoolId: string
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const { t } = useTranslation(['form'])
  const successNotify = (message: string) => toast.dark(message)

  const {
    control,
    handleSubmit: handleParkingSubmit,
    formState: { errors },
  } = useForm<FormData>()

  const onCompleted = (data: IAddParkingPoolProfileData) => {
    if (data.addParkingPoolProfile.parkingPoolProfile.name) {
      setErrorMessage('')
      handleClose()
      handleUpdateTenants()
      successNotify(`${t('generic:success_message')}`)
    } else {
      setErrorMessage(`${t('generic:error')}`)
    }
  }

  const [addParkingPoolProfile] = useMutation<
    IAddParkingPoolProfileData,
    IAddParkingPoolProfileVariables
  >(ADD_PARKING_POOL_PROFILE, {
    onCompleted,
    onError: (error: ApolloError) => {
      console.log(error)
      setErrorMessage(error.message)
    },
  })

  const onSubmit = async (data: FormData) => {
    const {
      name,
      maxAdvanceDays,
      maxDurationDays,
      maxReservationCountPerUser,
      customEmailMessage,
    } = data
    await addParkingPoolProfile({
      variables: {
        parkingPoolId,
        name,
        maxAdvanceDays: Number(maxAdvanceDays),
        maxDurationDays: Number(maxDurationDays),
        maxReservationCountPerUser: Number(maxReservationCountPerUser),
        customEmailMessage: customEmailMessage || '',
        disabled: isDisabled,
        createdAt: date2isoString(new Date()),
      },
    })
  }

  return (
    <>
      <DetailsWrapper>
        <Form onSubmit={handleParkingSubmit(onSubmit)}>
          <ParkingPoolProfileInputs
            control={control}
            errors={errors}
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
          />
          <ButtonWrapper>
            <BaseButton type="submit" data-e2e="profile-submit">
              {t('btn:save')}
            </BaseButton>
          </ButtonWrapper>
        </Form>
      </DetailsWrapper>
      {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
    </>
  )
}

export default AddProfileForm

const DetailsWrapper = styled(Grid)`
  padding: 16px;
  width: 600px;
`
const Form = styled.form`
  max-width: 550px;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;
`
