import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Tooltip, Grid } from '@mui/material'
import { themeColors } from '../../const/colors'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

const MenuItemAvailabilityCell = ({
  availabilityDate,
  visible,
  isAvailable,
}: {
  availabilityDate: string
  visible: boolean
  isAvailable: boolean
}) => {
  const { t } = useTranslation(['gastro'])

  return (
    <Grid>
      {availabilityDate ? (
        <AvailabilityContainer container direction="row">
          <Tooltip title={`${t('available_tip')}`}>
            <CalendarIcon
              fontSize="small"
              color={visible && isAvailable ? 'inherit' : 'error'}
            />
          </Tooltip>
          <ItemAmountText
            data-e2e="availability-date"
            color={
              visible && isAvailable ? themeColors.success : themeColors.error
            }
          >
            {availabilityDate}
          </ItemAmountText>
        </AvailabilityContainer>
      ) : (
        <ItemNameText color={visible ? themeColors.success : themeColors.error}>
          {visible ? t('generic:yes') : t('generic:no')}
        </ItemNameText>
      )}
    </Grid>
  )
}

export default MenuItemAvailabilityCell

const AvailabilityContainer = styled(Grid)`
  color: ${themeColors.success};
`
const ItemNameText = styled.p<{ color?: string }>`
  font-weight: 500;
  margin: 3px 0;
  ${({ color }) => color && `color: ${color};`};
`
const ItemAmountText = styled.p<{ color?: string }>`
  font-weight: 500;
  color: ${(props) => props.color || themeColors.gray};
  margin: 0 0;
`
const CalendarIcon = styled(CalendarTodayIcon)`
  margin-right: 8px;
`
