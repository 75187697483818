import React, { FC, useEffect, useMemo, useState } from 'react'
import {
  GET_SURVEY_ANSWERS,
  IGetSurveyAnswersData,
} from '../../graphql/survey/getSurveyAnswers'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import FullScreenLoader from '../Shared/FullScreenLoader'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'
interface ICSVHeader {
  label: string
  key: string
}

interface Row {
  [k: string]: string
}

const headersCSVInitial = [
  { label: 'Id', key: 'id' },
  { label: 'Imię i nazwisko', key: 'user' },
  { label: 'Email', key: 'email' },
  { label: 'Firma', key: 'company' },
  { label: 'Login', key: 'login' },
  { label: 'Data utworzenia', key: 'createdAtCSV' },
]

type UsersAnswer = {
  createdAt: string
  user: {
    login: string
    firstName: string
    lastName: string
    email: {
      email: string
    }
    tenant: {
      name: string
    }
  }
  survey: {
    questions: {
      id: string
      name: string
    }[]
  }
}

const SurveyAnswersTable: FC<{
  surveyId: string
}> = ({ surveyId }) => {
  const { t, i18n } = useTranslation(['survey'])

  const invisibleColumns = {
    id: false,
    login: false,
    createdAtCSV: false,
  }

  const tableColumnInitial = [
    {
      header: 'Id',
      accessorKey: 'id',
    },
    {
      header: t('full_name'),
      accessorKey: 'user',
    },
    {
      header: t('company'),
      accessorKey: 'company',
    },
    {
      header: 'Login',
      accessorKey: 'login',
    },
    {
      header: 'Email',
      accessorKey: 'email',
    },
    {
      header: t('created_at'),
      accessorKey: 'createdAt',
      enableColumnFilter: false,
    },
    {
      header: t('created_at'),
      accessorKey: 'createdAtCSV',
    },
  ]
  const [dynamicTableColumns, setDynamicTableColumns] =
    useState<ColumnDef<Row, any>[]>(tableColumnInitial)
  const [dynamicColumnHeaders, setDynamicColumnHeaders] =
    useState<ICSVHeader[]>()
  const [csvHeaders, setCsvHeaders] = useState<ICSVHeader[]>()
  const [users, setUsers] = useState<UsersAnswer[]>()

  const { data: survey, loading } = useQuery<IGetSurveyAnswersData>(
    GET_SURVEY_ANSWERS,
    {
      variables: {
        id: surveyId || '',
      },
      fetchPolicy: 'no-cache',
    }
  )

  const removeDuplicateAnswers = () => {
    if (survey) {
      let modifiedAnswers = survey.getSurvey.answers.map(
        ({ user, survey, createdAt }) => ({
          user,
          survey,
          createdAt,
        })
      )
      const logins = modifiedAnswers.map((item: UsersAnswer) => item.user.login)
      const filtered = modifiedAnswers.filter(
        ({ user }: any, index: any) => !logins.includes(user.login, index + 1)
      )
      setUsers(filtered)
    }
  }

  const tableData: Row[] = useMemo(() => {
    const lastUserAnswer = (userLogin: string, questionId: string) => {
      const lastAnswer = survey?.getSurvey.answers
        .filter(({ user }) => user.login === userLogin)
        .find(({ question }) => question.id === questionId)
      return (
        lastAnswer?.responseText ||
        lastAnswer?.responseOptions.map((item) => item.name).join(', ')
      )
    }

    const tableRow = (
      questions: { id: string; name: string }[],
      user: any,
      createdAt: string
    ) => {
      setDynamicColumnHeaders(
        questions.map((item) => ({ label: item.name, key: item.id }))
      )
      const row = questions
        .map((item) => ({
          user: `${user.firstName} ${user.lastName}`,
          company: user.tenant?.name || '',
          email: user.email.email,
          login: user.login,
          createdAt: dateIso2localeString(createdAt, i18n.language),
          createdAtCSV: dateIso2ExcelFormat(createdAt),
          [item.id]: lastUserAnswer(user.login, item.id),
        }))
        .reduce((obj1, obj2) => Object.assign(obj1, obj2), {})
      return row
    }

    return (
      users?.map(({ user, survey, createdAt }) => ({
        ...tableRow(survey.questions, user, createdAt),
      })) || []
    )
  }, [users, i18n, survey])

  useEffect(() => {
    if (survey) {
      setDynamicTableColumns(() => [
        ...tableColumnInitial,
        ...survey.getSurvey.questions.map(({ id, name }) => ({
          header: name,
          accessorKey: id,
        })),
      ])
    }
    removeDuplicateAnswers()
  }, [survey])

  useEffect(() => {
    if (dynamicColumnHeaders?.length) {
      setCsvHeaders([...headersCSVInitial, ...dynamicColumnHeaders])
    }
  }, [dynamicColumnHeaders])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <Table
      columns={dynamicTableColumns}
      data={tableData}
      columnVisibility={invisibleColumns}
      csvExport
      enableRowSelection
      csvFileName={`ankieta-${survey?.getSurvey.surveyName}-${survey?.getSurvey.surveyId}.csv`}
      csvHeaders={csvHeaders}
    />
  )
}

export default SurveyAnswersTable
