import { IPlace } from '../graphql/parking/queryParkingPlace'

export const sortParkingPlaces = (places: IPlace[]) =>
  places.sort(
    (a, b) =>
      a.level.name.localeCompare(b.level.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      }) ||
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
  )
