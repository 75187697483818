import { useState, useEffect } from 'react'
import { Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TextBody1 } from '../Styles/TextCustom'

export type ExtrasType = 'fixed' | 'temporary' | 'event'

const ExtrasTypeRadio = ({
  type,
  setType,
}: {
  type: ExtrasType
  setType: (value: ExtrasType) => void
}) => {
  const [value, setValue] = useState<ExtrasType>(type)
  const { t } = useTranslation(['extras'])

  useEffect(() => {
    setValue(type)
  }, [type])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value as ExtrasType)
    setType((event.target as HTMLInputElement).value as ExtrasType)
  }

  return (
    <FormContainer>
      <TextStyled>{t('select_extras_type')}</TextStyled>
      <RadioGroup
        value={value}
        onChange={handleChange}
        data-e2e="extras-type-radio"
      >
        <LabelStyled
          value="fixed"
          control={<Radio color="primary" />}
          label={`${t('fixed_type')}`}
        />
        <LabelStyled
          value="temporary"
          control={<Radio color="primary" />}
          label={`${t('temporary_type')}`}
        />
        <LabelStyled
          value="event"
          control={<Radio color="primary" />}
          label={`${t('event_type')}`}
        />
      </RadioGroup>
    </FormContainer>
  )
}

export default ExtrasTypeRadio

const LabelStyled = styled(FormControlLabel)`
  font-size: 16px;
`
const FormContainer = styled(FormControl)`
  padding-bottom: 16px;
`
const TextStyled = styled(TextBody1)`
  margin-top: 0;
`
