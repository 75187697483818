import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloError, useMutation } from '@apollo/client'
import { Grid } from '@mui/material'
import {
  SET_MYSOLVY_TENANT,
  ISetMySolvyTenantData,
  ISetMySolvyTenantVars,
} from '../../graphql/tenant/setMySolvyTenant'
import {
  REMOVE_MYSOLVY_TENANT,
  IRemoveMySolvyTenantData,
  IRemoveMySolvyTenantVars,
} from '../../graphql/tenant/removeMySolvyTenant'
import { OptionType } from '../Shared/BaseSelect'
import { ActionMeta } from 'react-select'
import { toast } from 'react-toastify'
import { Title } from '../Styles/TextCustom'
import styled from 'styled-components'
import { ITenant } from '../../graphql/tenant/getTenant'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'
import MySolvyTenantSelect from './MySolvyTenantSelect'
import Loader from '../Shared/Loader'
import ErrorContainer from '../../components/Shared/ErrorContainer'

const AssignMySolvyCompany: FC<{
  tenant: ITenant
  refetch: () => void
}> = ({ tenant, refetch }) => {
  const { t } = useTranslation(['tenant'])
  const [selectedTenant, setSelectedTenant] = useState<OptionType | null>(null)
  const [errorMessage, setErrorMessage] = useState('')
  const { id, mySolvyTenant } = tenant
  const successNotify = (message: string) => toast.dark(message)

  const [setMySolvyTenant, { loading: tenantChangeLoading }] = useMutation<
    ISetMySolvyTenantData,
    ISetMySolvyTenantVars
  >(SET_MYSOLVY_TENANT, {
    onCompleted: (data: ISetMySolvyTenantData) => {
      successNotify(`${t('ms_company_assigned')}`)
      const { mySolvyTenant } = data.updateTenant.tenant
      mySolvyTenant &&
        setSelectedTenant({
          value: mySolvyTenant.id,
          label: mySolvyTenant.name,
        })
      refetch()
    },
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  const [removeMySolvyTenant, { loading: tenantClearLoading }] = useMutation<
    IRemoveMySolvyTenantData,
    IRemoveMySolvyTenantVars
  >(REMOVE_MYSOLVY_TENANT, {
    onCompleted: (data: IRemoveMySolvyTenantData) => {
      successNotify(`${t('ms_company_unassigned')}`)
      const { mySolvyTenant } = data.updateTenant.tenant
      !mySolvyTenant && setSelectedTenant(mySolvyTenant)
      refetch()
    },
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  useEffect(() => {
    if (mySolvyTenant) {
      setSelectedTenant({ value: mySolvyTenant.id, label: mySolvyTenant.name })
    }
  }, [mySolvyTenant])

  const handleSelectChange = (
    value: OptionType | null,
    actionMeta: ActionMeta<OptionType>
  ) => {
    setErrorMessage('')
    if (actionMeta.action === 'select-option' && value) {
      setMySolvyTenant({ variables: { id, mySolvyTenantId: value.value } })
    } else if (actionMeta.action === 'clear' && selectedTenant) {
      removeMySolvyTenant({
        variables: { id, mySolvyTenantId: selectedTenant.value },
      })
    }
  }

  const loading = tenantChangeLoading || tenantClearLoading

  return (
    <DetailsWrapper>
      <Title>{t('assigned_mysolvy_company')}</Title>
      {loading && <Loader />}
      {!loading && (
        <Container data-e2e="mysolvy-tenant-select">
          <MySolvyTenantSelect
            selectedMySolvyTenant={selectedTenant}
            handleSelectChange={handleSelectChange}
          />
        </Container>
      )}
      {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
    </DetailsWrapper>
  )
}

export default AssignMySolvyCompany

const Container = styled(Grid)`
  padding: 16px 0;
`
