import { useState } from 'react'
import { ClickAwayListener } from '@mui/material'
import { TextH6, DrawerSubTitle } from '../Styles/TextCustom'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import DietTypeRadio from './DietTypeRadio'
import MenuItemAvailability from './MenuItemAvailability'
import {
  ADD_MENU_ITEM,
  IAddMenuItemData,
} from '../../graphql/gastro/addMenuItem'
import { ApolloError, useMutation } from '@apollo/client'
import MenuItemInputs from './MenuItemInputs'
import { toast } from 'react-toastify'
import { date2isoString } from '../../utils/formatDate'
import DrawerHeader from '../Shared/DrawerHeader'
import DrawerButtons from '../Shared/DrawerButtons'
import FullScreenLoader from '../Shared/FullScreenLoader'
import ErrorContainer from '../Shared/ErrorContainer'

const AddMenuItem = ({
  closeDrawer,
  updateList,
  currentRestaurant,
}: {
  closeDrawer: () => void
  updateList: () => void
  currentRestaurant: string
}) => {
  const { t } = useTranslation([
    'menu_item_form',
    'form',
    'btn',
    'code',
    'generic',
  ])

  const [openClickAwayModal, setOpenClickAwayModal] = useState(false)
  const [selectedDietType, setSelectedDietType] = useState<string>()
  const [selectedAvailability, setSelectedAvailability] =
    useState<boolean>(true)
  const [availabilityDetails, setAvailabilityDetails] = useState<any[]>([])
  const [errorMessage, setErrorMessage] = useState('')
  const successNotify = (message: string) => toast.dark(message)
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const watchFormData = useWatch({ control })
  const onSubmit = (formData: any) => {
    const {
      name,
      nameEn,
      descriptionPl,
      descriptionEn,
      amount,
      category,
      unit,
      price,
    } = formData
    addMenuItem({
      variables: {
        visible: selectedAvailability,
        name: name.trim(),
        nameEn: nameEn.trim(),
        description: descriptionPl.trim(),
        descriptionEn: descriptionEn.trim(),
        amount: amount * 1,
        categoryId: category.value,
        unit: unit.value,
        price: Math.floor(parseFloat(price) * 100),
        dietType: selectedDietType,
        restaurantId: currentRestaurant,
        availability: availabilityDetails,
      },
    })
  }

  const onCompleted = () => {
    successNotify(`${t('success_add')}`)
    updateList()
    closeDrawer()
  }

  const [addMenuItem, { loading }] = useMutation<IAddMenuItemData>(
    ADD_MENU_ITEM,
    {
      onCompleted,
      onError: (error: ApolloError) => setErrorMessage(error.message),
    }
  )

  const handleAvailabilityDetails = (value: any, type: string) => {
    setAvailabilityDetails([])
    if (type === 'date') {
      return setAvailabilityDetails(
        availabilityDetails.concat([
          { dateAvailabilityRef: { date: date2isoString(value) } },
        ])
      )
    } else if (type === 'weekdays') {
      return setAvailabilityDetails(
        value.map((item: string) => ({
          weekDayAvailabilityRef: { week_day: item },
        }))
      )
    }
  }

  const handleClickAway = () => {
    !!Object.keys(watchFormData).length
      ? setOpenClickAwayModal(true)
      : closeDrawer()
  }

  const handleCloseModal = (e: any) => {
    e.stopPropagation()
    setOpenClickAwayModal(false)
  }

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <>
          <DrawerHeader title={t('add_title')} handleClose={handleClickAway} />
          <TextH6>{t('name_and_price')}</TextH6>

          <DrawerSubTitle>{t('polish_version')}</DrawerSubTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <MenuItemInputs control={control} errors={errors} />
            <TextH6>{t('diet')}</TextH6>
            <DietTypeRadio
              handleChangeValue={(dietType: string) =>
                setSelectedDietType(dietType)
              }
            />
            <TextH6>{t('availability')}</TextH6>
            <MenuItemAvailability
              handleChangeAvailability={(availability: boolean) =>
                setSelectedAvailability(availability)
              }
              handleChangeAvailabilityDate={(value, type) =>
                handleAvailabilityDetails(value, type)
              }
            />
            <DrawerButtons
              handleAccept={handleSubmit(onSubmit)}
              handleCancel={handleClickAway}
            />
            {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
          </form>
        </>
      </ClickAwayListener>
      <BaseModal
        confirmText={'btn:exit'}
        open={openClickAwayModal}
        handleClose={(e) => handleCloseModal(e)}
        handleAccept={() => closeDrawer()}
      >
        <ModalContent>{t('generic:exit_without_saving')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default AddMenuItem
