import gql from 'graphql-tag'
export const TENANT_DOMAIN_CREATE_AND_ASSIGN = gql`
  mutation TenantDomainCreateAndAssign($tenant: String!, $domain: String!) {
    domainCreate(name: $domain) {
      status
      code
      message
      error
    }
    tenantDomainAssign(tenant: $tenant, domain: $domain) {
      status
      code
      message
      error
    }
  }
`
