import gql from 'graphql-tag'

export interface ISetSurveyPreviewVars {
  id: string
  preview: boolean
}

export const SET_SURVEY_PREVIEW = gql`
  mutation setSurveyPreview($id: ID!, $preview: Boolean) {
    updateSurvey(input: { filter: { id: [$id] }, set: { preview: $preview } }) {
      survey {
        id
      }
    }
  }
`
