import React, { FC, ReactNode } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TextButton } from '../Styles/TextCustom'
import { themeColors } from '../../const/colors'
import CloseButton from '../Shared/CloseButton'

const BaseModal: FC<{
  open: boolean
  handleClose?: (e: any) => void
  handleAccept?: () => void
  title?: string
  actions?: boolean
  confirmText?: string
  cancelText?: string
  handleClick?: (e: any) => void
  children?: ReactNode
  closeButton?: boolean
}> = ({
  open,
  handleClose,
  handleAccept,
  title,
  children,
  actions = true,
  confirmText = 'save',
  cancelText = 'cancel',
  handleClick,
  closeButton = false,
}) => {
  const { t } = useTranslation('btn')
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      onClick={handleClick}
    >
      {title && (
        <Row>
          <DialogTitle
            id="form-dialog-title"
            data-e2e="usersList-userBaseInfo-confirm-title"
          >
            {title}
          </DialogTitle>
          {closeButton && <CloseButton onClick={handleClose} />}
        </Row>
      )}
      {!!children && <CustomContent>{children}</CustomContent>}

      {actions && (
        <CustomActions>
          <ButtonText
            onClick={handleClose}
            color={themeColors.primary}
            data-e2e="modal-cancel"
          >
            {t(`${cancelText}`)}
          </ButtonText>
          <ButtonText
            onClick={handleAccept}
            color={themeColors.primary}
            data-e2e="modal-confirm"
          >
            {t(`${confirmText}`)}
          </ButtonText>
        </CustomActions>
      )}
    </Dialog>
  )
}

export default BaseModal

const ButtonText = styled(TextButton)`
  padding: 0 8px;
`

const CustomActions = styled(DialogActions)`
  background-color: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1px 16px;
`
const CustomContent = styled(DialogContent)`
  padding: 0;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 16px 16px 0;
  align-items: center;
`
