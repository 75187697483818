import { useTranslation } from 'react-i18next'
import { IFormError } from '../../types/FormTypes'
import BaseSelect from '../Shared/BaseSelect'
import {
  QUERY_TENANT,
  IQueryTenantData,
} from '../../graphql/shared/queryTenant'
import { useQuery } from '@apollo/client'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'

const TenantSelect = ({
  control,
  errors,
  rules,
  defaultValue,
  disabled,
}: {
  control: Object
  errors: IFormError | any
  rules?: any
  defaultValue?: { value: string; label: string }
  disabled?: boolean
}) => {
  const { t } = useTranslation(['lockers'])

  const { data, loading } = useQuery<IQueryTenantData>(QUERY_TENANT)

  if (loading) {
    return <FullScreenLoader />
  }

  if (!data) {
    return null
  }

  return (
    <div data-e2e="tenant-select">
      <BaseSelect
        options={data.queryTenant.map(({ id, name }) => ({
          value: id,
          label: name,
        }))}
        control={control}
        // errors={errors}
        name={'tenant'}
        rules={rules}
        label={t('company')}
        defaultValue={defaultValue}
        disabled={disabled}
        placeholder={t('company')}
      />
    </div>
  )
}

export default TenantSelect
