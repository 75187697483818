import styled from 'styled-components'

export const ToolbarPlaceholder = styled.div`
  /* default styles for toolbar copied from theme.mixins.toolbar */
  @media (min-width: 0px) and (orientation: landscape) {
    min-height: 48px;
  }
  @media (min-width: 600px) {
    min-height: 64px;
  }
  min-height: 56px;
`
