import React, { useState } from 'react'
import styled from 'styled-components'
import { FieldErrors, useForm } from 'react-hook-form'
import { BaseButton } from '../components/Shared/BaseButton'
import { useTranslation } from 'react-i18next'
import {
  ApolloError,
  NetworkStatus,
  useMutation,
  useQuery,
} from '@apollo/client'
import SignUpForm from '../components/PagesComponents/SignUpForm'
import SignUpCheckboxes from '../components/PagesComponents/SignUpCheckboxes'
import { SignUpFormData, IFormRegulation } from '../types/FormTypes'
import FullScreenLoader from '../components/Shared/FullScreenLoader'
import {
  IRegisterData,
  IRegisterVars,
  REGISTER_USER_MUTATION,
} from '../graphql/auth/register'
import {
  IRegulationsByServiceData,
  IRegulationsByServiceVars,
  GET_REGULATIONS_BY_SERVICE_EN,
  GET_REGULATIONS_BY_SERVICE_PL,
} from '../graphql/auth/queryRegulations'
import { themeColors } from '../const/colors'
import { CheckboxText } from '../components/Styles/TextCustom'
import ConfirmationEmailSent from '../components/PagesComponents/ConfirmationEmailSent'
import ErrorContainer from '../components/Shared/ErrorContainer'

const SignUpPage = () => {
  const { t, i18n } = useTranslation(['form', 'generic'])
  const currentLanguage = i18n.language
  const [errorMessage, setErrorMessage] = useState('')
  const [regulations, setRegulations] = useState<IFormRegulation[]>([])
  const [email, setEmail] = useState('')

  const onRegulationsCompleted = ({
    queryRegulation,
  }: IRegulationsByServiceData) => {
    setRegulations(queryRegulation || [])
  }

  const { loading: regulationsLoading, networkStatus } = useQuery<
    IRegulationsByServiceData,
    IRegulationsByServiceVars
  >(
    currentLanguage.includes('pl')
      ? GET_REGULATIONS_BY_SERVICE_PL
      : GET_REGULATIONS_BY_SERVICE_EN,
    {
      notifyOnNetworkStatusChange: true,
      variables: { service: 'olivia', login: '' },
      fetchPolicy: 'network-only',
      onError: (error: ApolloError) => setErrorMessage(error.message),
      onCompleted: onRegulationsCompleted,
    }
  )

  const onRegisterCompleted = (data: IRegisterData) => {
    if (data?.registerUser.status) {
      const email = getValues('email')
      setEmail(email)
    } else {
      setErrorMessage(t(`code:${data.registerUser.code}`))
    }
  }

  const [registerUser, { loading: registerLoading }] = useMutation<
    IRegisterData,
    IRegisterVars
  >(REGISTER_USER_MUTATION, {
    onCompleted: onRegisterCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<SignUpFormData>({
    shouldFocusError: false,
  })

  const onSubmit = async (formData: SignUpFormData) => {
    const {
      firstName,
      lastName,
      email,
      login,
      password,
      checkAll,
      ...checkboxes
    } = formData

    const loginLowerCase = login.toLowerCase()

    const acceptances = Object.entries(checkboxes).reduce(
      (acc: string, cur: [string, boolean]) =>
        `${acc}${cur[0]}=${cur[1] ? 'yes' : 'no'} `,
      ''
    )

    await registerUser({
      variables: {
        firstName,
        lastName,
        email,
        login: loginLowerCase,
        password,
        acceptances,
        language: currentLanguage,
        app: 'myolivia-www',
      },
    })
  }

  const onInvalidSubmit = (submitErrors: FieldErrors) => {
    !!Object.keys(submitErrors).length &&
      setErrorMessage(t('form:correct_form'))
  }

  if (regulationsLoading || networkStatus === NetworkStatus.refetch) {
    return <FullScreenLoader />
  }

  if (email) {
    return <ConfirmationEmailSent email={email} />
  }

  return (
    <Container>
      <BlueContainer>
        <Logo>
          {'My'}&nbsp;
          <Color>Olivia</Color>
        </Logo>
        <SignUpForm control={control} errors={errors} />
        <SignUpCheckboxes
          control={control}
          errors={errors}
          regulations={regulations}
          setValue={setValue}
        />
        <CheckboxText color={themeColors.error}>{`* ${t(
          'required_fields'
        )}`}</CheckboxText>
        <BaseButton
          onClick={handleSubmit(onSubmit, onInvalidSubmit)}
          disabled={registerLoading}
        >
          {t('btn:sign_up')}
        </BaseButton>
        {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
      </BlueContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
`
const BlueContainer = styled.div`
  background-color: ${themeColors.lightBlue};
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
`
const Color = styled.span`
  font-weight: 800;
  color: ${themeColors.secondary};
`
const Logo = styled.h3`
  font-size: 44px;
  margin: 0 0 30px;
  letter-spacing: 0.75px;
  font-weight: 400;
`

export default SignUpPage
