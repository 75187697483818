import { useMemo, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { LockerType } from '../../graphql/lockers/queryLockers'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'
export interface Row {
  building?: string
  type?: LockerType
  appRental: number
  adminRental: number
  service: number
  available: number
}

const LockerStatisticsTable: FC<{
  lockers: Row[]
}> = ({ lockers }) => {
  const { t } = useTranslation(['lockers'])

  const csvHeaders = [
    {
      label: t('building'),
      key: 'building',
    },
    {
      label: t('locker_type'),
      key: 'type',
    },
    {
      label: t('app_rental'),
      key: 'appRental',
    },
    {
      label: t('admin_rental'),
      key: 'adminRental',
    },
    {
      label: t('service'),
      key: 'service',
    },
    {
      label: t('available'),
      key: 'available',
    },
  ]

  // @ts-ignore
  const columns: ColumnDef<Row, any>[] = useMemo(() => {
    const handleTotal = (rows: any[], value: string) => {
      const formattedRows = rows.map((row) => row.original)
      return formattedRows.reduce(
        (sum: number, row: any) => row[value] + sum,
        0
      )
    }

    return [
      {
        accessorKey: 'building',
        header: t('building'),
        footer: t('total_table'),
      },
      {
        accessorKey: 'type',
        header: t('locker_type'),
        cell: (value) => t(value.getValue()),
      },
      {
        accessorKey: 'appRental',
        header: t('app_rental'),
        footer: (data) =>
          handleTotal(data.table.getRowModel().rows, 'appRental'),
      },
      {
        accessorKey: 'adminRental',
        header: t('admin_rental'),
        footer: (data) =>
          handleTotal(data.table.getRowModel().rows, 'adminRental'),
      },
      {
        accessorKey: 'service',
        header: t('service'),
        footer: (data) => handleTotal(data.table.getRowModel().rows, 'service'),
      },
      {
        accessorKey: 'available',
        header: t('available'),
        footer: (data) =>
          handleTotal(data.table.getRowModel().rows, 'available'),
      },
    ]
  }, [t])

  const tableData: Row[] = useMemo(() => {
    return (
      lockers.map(
        ({ building, type, appRental, adminRental, service, available }) => {
          return {
            building,
            type,
            appRental,
            adminRental,
            service,
            available,
          }
        }
      ) || []
    )
  }, [lockers])

  return (
    <Table
      columns={columns}
      data={tableData}
      enableRowSelection
      csvExport
      csvHeaders={csvHeaders}
      // isFiltersOff={true}
    />
  )
}

export default LockerStatisticsTable
