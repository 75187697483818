import gql from 'graphql-tag'
import { IBanner, BannerTargetType } from './queryBanner'

export interface IUpdateBannerVars {
  id: string
  name: string
  url: string
  urlEn: string
  src: string
  srcEn: string
  visible: boolean
  type: BannerTargetType
  residentOnly: boolean
}

export interface IUpdateBannerData {
  updateBanner: { banner: IBanner }
}

export const UPDATE_BANNER = gql`
  mutation updateBanner(
    $id: [ID!]
    $name: String!
    $url: String!
    $urlEn: String
    $src: String!
    $srcEn: String
    $visible: Boolean!
    $type: BannerTargetType!
    $residentOnly: Boolean
  ) {
    updateBanner(
      input: {
        filter: { id: $id }
        set: {
          name: $name
          url: $url
          url_en: $urlEn
          src: $src
          src_en: $srcEn
          visible: $visible
          type: $type
          resident_only: $residentOnly
        }
      }
    ) {
      banner {
        id
        name
        url
        src
        visible
        type
      }
    }
  }
`
