import gql from 'graphql-tag'

export interface IUpdateSurveyStatusVars {
  id: string
  active: boolean
}

export const UPDATE_SURVEY_STATUS = gql`
  mutation updateSurveyStatus($id: ID!, $active: Boolean) {
    updateSurvey(input: { filter: { id: [$id] }, set: { active: $active } }) {
      survey {
        id
      }
    }
  }
`
