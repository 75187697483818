import React from 'react'
import { useLocation } from 'react-router'

const NoMatch = () => {
  let location = useLocation()
  return (
    <div>
      <h3>
        Adres <code>{location.pathname}</code> nie istnieje
      </h3>
    </div>
  )
}

export default NoMatch
