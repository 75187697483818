import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import BaseSelect from '../Shared/BaseSelect'
import { useEffect } from 'react'
import { Grid } from '@mui/material'

const ExtrasImage = ({
  control,
  imageUrl,
  watch,
  setValue,
}: {
  control: Object
  imageUrl?: string
  watch: any
  setValue: any
}) => {
  const { t } = useTranslation(['extras'])
  const chosenLogo = watch('companyLogo')

  const [logos, setLogos] = useState([])

  useEffect(() => {
    fetchLogos()
  }, [])

  const fetchLogos = async () => {
    try {
      const response = await fetch(
        'https://myolivia.blob.core.windows.net/myolivia/service/quickpromo/logos.json',
        { cache: 'no-cache' }
      )
      const jsonData = await response.json()
      setLogos(jsonData)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (imageUrl) {
      // @ts-ignore
      const image: { name: string; url: string } = logos.find(
        ({ url }) => imageUrl === url
      )
      image && setValue('companyLogo', { value: imageUrl, label: image.name })
    }
  }, [imageUrl, logos])

  return (
    <Wrapper data-e2e="logo-select">
      <BaseSelect
        options={logos.map(({ name, url }) => ({
          value: url,
          label: name,
        }))}
        control={control}
        name={'companyLogo'}
        label={'Logo'}
        placeholder={t('choose_logo')}
      />
      {chosenLogo && <Image src={chosenLogo.value} />}
    </Wrapper>
  )
}

export default ExtrasImage

const Wrapper = styled(Grid)`
  padding: 0 0 16px 0;
`
const Image = styled.img`
  padding: 16px;
  width: 180px;
  height: 165px;
  object-fit: contain;
`
