import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'
export interface IDomainDeleteVars {
  name: string
}
export interface IDomainDeleteData {
  domainDelete: IQueryBasicData
}
export const DOMAIN_DELETE = gql`
  mutation DomainDelete($name: String!) {
    domainDelete(name: $name) {
      status
      code
      message
      error
    }
  }
`
