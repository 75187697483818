import { useState } from 'react'
import { Box, Grid } from '@mui/material'
import { TextBody2Medium, TextBody2Bold } from '../Styles/TextCustom'
import { themeColors } from '../../const/colors'
import { ILog } from '../../graphql/shared/sharedTypes'
import styled from 'styled-components'
import { dateIso2localeString } from '../../utils/formatDate'
import { useTranslation } from 'react-i18next'
import { BaseTextButton } from '../Shared/BaseButton'

const LogsContainer = ({
  logs,
  small = false,
}: {
  logs?: ILog[]
  small?: boolean
}) => {
  const { t, i18n } = useTranslation(['logs'])
  const lang = i18n.language
  const [show, setShow] = useState(false)

  const opt: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    fractionalSecondDigits: 2,
  }

  return (
    <>
      {!!logs?.length && (
        <Wrapper>
          <BaseTextButton onClick={() => setShow(!show)}>
            {show ? t('hide_logs') : t('show_logs')}
          </BaseTextButton>

          {show && (
            <Box
              component="div"
              sx={{
                overflow: 'auto',
                height: small ? 120 : 270,
                width: '100%',
                p: 2,
                border: '0.5px solid',
                borderRadius: 3,
                borderColor: themeColors.lightGray,
              }}
            >
              {logs.map(({ createdAt, message, parameters }, i) => (
                <Row key={i}>
                  <TextBody2Medium>{message}</TextBody2Medium>
                  <TextBody2Bold>{`${dateIso2localeString(
                    createdAt,
                    lang,
                    opt
                  )}`}</TextBody2Bold>
                  {!!parameters && !!parameters.length && (
                    <Grid container>
                      {parameters.map(
                        ({ name, value, type }, i) =>
                          !!value &&
                          value !== 'null' && (
                            <Grid key={i}>
                              <TextBold>{`${t(name)}: `}</TextBold>
                              <Text>{value}</Text>
                            </Grid>
                          )
                      )}
                    </Grid>
                  )}
                </Row>
              ))}
            </Box>
          )}
        </Wrapper>
      )}
      {!logs?.length && <TextBody2Medium>{t('no_logs')}</TextBody2Medium>}
    </>
  )
}

export default LogsContainer

const Row = styled(Grid)`
  padding: 6px 0;
  border-bottom: 1px solid ${themeColors.lightGray};
`
const Wrapper = styled(Grid)`
  padding: 6px 0;
  width: 100%;
`
const Text = styled(TextBody2Medium)`
  display: inline;
`
const TextBold = styled(TextBody2Bold)`
  display: inline;
`
