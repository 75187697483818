import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IRescheduleParkingSubscriptionVars {
  id: string
  comment: string
  date?: string
}

export interface IRescheduleParkingSubscriptionData {
  rescheduleParkingSubscription: IQueryBasicData
}

export const RESCHEDULE_PARKING_SUBSCRIPTION = gql`
  mutation RescheduleParkingSubscription(
    $id: String!
    $comment: String!
    $date: DateTime
  ) {
    rescheduleParkingSubscription(
      subscription_id: $id
      comment: $comment
      date: $date
    ) {
      status
      message
      error
      code
    }
  }
`
