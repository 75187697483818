import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IConfirmEmailVars {
  email: string
  code: string
  declaredTenant: string
}
export interface IConfirmEmailData {
  confirmEmail: IQueryBasicData & {
    user: {
      login: string
      email: { email: string }
      work_email: { email: string }
    }
  }
}

export const CONFIRM_EMAIL = gql`
  mutation ConfirmEmail(
    $email: String!
    $code: String!
    $declaredTenant: String
  ) {
    confirmEmail(email: $email, code: $code, declared_tenant: $declaredTenant) {
      status
      message
      error
      code
      user {
        login
        email {
          email
        }
        work_email {
          email
        }
      }
    }
  }
`
