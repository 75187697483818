import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface ICancelGroupReservationVars {
  id: string
  comment: string
}

export interface ICancelGroupReservationData {
  cancelGroupReservation: IQueryBasicData & {
    parameters: {
      name: string
      value: string
    }
  }
}

export const CANCEL_LOCKER_GROUP_RESERVATION = gql`
  mutation cancelGroupReservation($id: ID!, $comment: String) {
    cancelGroupReservation(id: $id, comment: $comment) {
      status
      code
      error
      message
      parameters {
        name
        value
      }
    }
  }
`
