import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IPayuBalanceVars {
  company: string
}

export interface IPayuBalanceData {
  payuBalance: IQueryBasicData & {
    availableAmount: number
    totalAmount: number
  }
}

export const PAYU_BALANCE = gql`
  query payuBalance($company: String!) {
    payuBalance: PayuBalance(company: $company) {
      status
      code
      message
      error
      availableAmount: available_amount
      totalAmount: total_amount
    }
  }
`
