import { ParkingSubscriptionStatus } from '../graphql/parking/queryParkingSubscriptions'
import { themeColors } from '../const/colors'
import { OneTimeParkingTicketStatus } from '../graphql/parking/queryOneTimeParkingTicket'
import { ParkingPlaceStatus } from '../graphql/parking/queryParkingPlace'
import { ReservationStatus } from '../graphql/shared/sharedTypes'
import { LockerStatus } from '../graphql/lockers/queryLockers'
import { PaymentStatus } from '../graphql/shared/sharedTypes'
import { GetResponseJobStatus } from '../graphql/getResponseSync/getResponseSync'
import { GardenTicketStatusType } from '../graphql/garden/queryGardenTickets'

export const getGardenTicketStatusColor = (status: GardenTicketStatusType) => {
  switch (status) {
    case 'new':
      return 'initial'
    case 'reserved':
      return themeColors.primary
    case 'active':
      return themeColors.garden
    case 'active_outside':
      return themeColors.garden
    case 'cancelled':
      return themeColors.error
    case 'past':
      return 'initial'
    case 'past_overridden':
      return 'initial'
    case 'unused':
      return themeColors.gray
    case 'unavailable':
      return themeColors.gray
    case 'error':
      return themeColors.error
  }
}

export const getSubscriptionStatusColor = (
  status: ParkingSubscriptionStatus
) => {
  switch (status) {
    case 'new':
      return 'initial'
    case 'pending':
      return themeColors.yellow
    case 'active':
      return themeColors.success
    case 'past':
      return themeColors.gray
    case 'cancelled':
      return themeColors.error
    case 'error':
      return themeColors.error
  }
}

export const getPaymentStatusColor = (status: PaymentStatus) => {
  switch (status) {
    case 'completed':
      return themeColors.success
    case 'new':
      return themeColors.gray
    case 'processing':
      return themeColors.yellow
    case 'received':
      return themeColors.gray
    case 'confirmed':
      return themeColors.gray
    case 'failed':
      return themeColors.error
    case 'error':
      return themeColors.error
    case 'timedout':
      return themeColors.error
    case 'partially_refunded':
      return themeColors.yellow
    case 'fully_refunded':
      return themeColors.gray
  }
}

export const getOneTimeTicketStatusColor = (
  status: OneTimeParkingTicketStatus
) => {
  switch (status) {
    case 'new':
      return themeColors.gray
    case 'reserved':
      return themeColors.gray
    case 'processing':
      return themeColors.yellow
    case 'paid':
      return themeColors.accept
    case 'cancelled':
      return themeColors.error
    case 'unavailable':
      return themeColors.gray
    case 'error':
      return themeColors.error
  }
}

export const getParkingPlaceStatusColor = (status: ParkingPlaceStatus) => {
  switch (status) {
    case 'available':
      return themeColors.accept
    case 'unavailable':
      return themeColors.error
    case 'reserved':
      return themeColors.yellow
    case 'occupied':
      return themeColors.error
  }
}

export const getParkingPlaceReservationStatusColor = (
  status: ReservationStatus
) => {
  switch (status) {
    case 'reserved':
      return themeColors.yellow
    case 'rented':
      return themeColors.yellow
  }
}

export const getReservationStatusColor = (status: ReservationStatus) => {
  switch (status) {
    case 'available':
      return themeColors.accept
    case 'rented':
      return themeColors.gray
    case 'reserved':
      return themeColors.gray
    case 'prereserved':
      return themeColors.gray
    case 'error':
      return themeColors.error
    case 'cancelled':
      return themeColors.error
  }
}

export const getLockerStatusColor = (status: LockerStatus) => {
  switch (status) {
    case 'available':
      return themeColors.accept
    case 'unavailable':
      return themeColors.gray
    case 'broken':
      return themeColors.error
    case 'error':
      return themeColors.error
    case 'ready_to_rent':
      return themeColors.yellow
    case 'rented':
      return themeColors.gray
    case 'service':
      return themeColors.error
    case 'should_be_returned':
      return themeColors.error
  }
}

export const getSyncJobStatusColor = (status: GetResponseJobStatus) => {
  switch (status) {
    case 'pending':
      return themeColors.yellow
    case 'processing':
      return themeColors.yellow
    case 'completed':
      return themeColors.accept
    case 'error':
      return themeColors.error
    case 'canceled':
      return themeColors.error
  }
}

export const getAccountStatusColor = (status: string) => {
  switch (status) {
    case 'active':
      return themeColors.accept
    case 'disabled':
      return themeColors.error
    case 'anonimized':
      return themeColors.yellow
  }
}
