import gql from 'graphql-tag'

export interface IUpdateParkingPoolProfileVariables {
  profileId: string
  name: string
  maxAdvanceDays: number
  maxDurationDays: number
  maxReservationCountPerUser: number
  disabled: boolean
  customEmailMessage?: string
}

export interface IUpdateParkingPoolProfileData {
  updateParkingPoolProfile: {
    parkingPoolProfile: {
      id: string
      name: string
    }[]
  }
}

export const UPDATE_PARKING_POOL_PROFILE = gql`
  mutation updateParkingPoolProfile(
    $profileId: [ID!]
    $name: String
    $maxAdvanceDays: Int
    $maxDurationDays: Int
    $maxReservationCountPerUser: Int
    $disabled: Boolean
    $customEmailMessage: String
  ) {
    updateParkingPoolProfile(
      input: {
        filter: { id: $profileId }
        set: {
          name: $name
          max_advance_days: $maxAdvanceDays
          max_duration_days: $maxDurationDays
          max_reservation_count_per_user: $maxReservationCountPerUser
          disabled: $disabled
          custom_email_message: $customEmailMessage
        }
      }
    ) {
      parkingPoolProfile {
        id
        name
      }
    }
  }
`
