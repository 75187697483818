import { gql } from '@apollo/client'

export interface IUpdateUserMobilePhoneVars {
  login: string
  mobilePhone: string
}

export const UPDATE_USER_MOBILE_PHONE = gql`
  mutation updateUser($login: String!, $mobilePhone: String) {
    updateUser(
      input: {
        filter: { login: { eq: $login } }
        set: { mobile_phone: $mobilePhone }
      }
    ) {
      user {
        login
        mobilePhone: mobile_phone
      }
    }
  }
`
