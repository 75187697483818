import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { Controller } from 'react-hook-form'
import { TextField } from '@mui/material'
import { IFormError, InputRules } from '../../types/FormTypes'
import { themeColors } from '../../const/colors'

const CustomValidationInput: React.FC<{
  label: string
  name: string
  control: any
  errors: IFormError | any
  errorIndex: number
  option?: number
  errorName: string
  rules?: InputRules
  defaultValue?: string
  [k: string]: any
}> = forwardRef(
  (
    {
      label,
      name,
      control,
      errors,
      errorIndex,
      option,
      errorName,
      rules,
      defaultValue = '',
      children,
      ...rest
    },
    ref?: React.Ref<HTMLInputElement>
  ) => {
    const checkError = () => {
      if (option) {
        return (
          !!errors.questions &&
          !!errors.questions[errorIndex].options[option][errorName]
        )
      } else
        return (
          !!errors.questions &&
          !!errors.questions[errorIndex] &&
          !!errors.questions[errorIndex][errorName]
        )
    }
    return (
      <>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field: { onChange, value, ref } }) => (
            <InputCustom
              ref={ref}
              variant="outlined"
              label={label}
              value={value}
              onChange={onChange}
              error={checkError()}
              color={'primary'}
              fullWidth={true}
              {...rest}
            />
          )}
        />
      </>
    )
  }
)
const InputCustom = styled(TextField)`
  background-color: ${themeColors.white};
  max-width: 500px;
`

export default CustomValidationInput
