import gql from 'graphql-tag'

export interface IDeleteOlivkaRegexpVars {
  name: string
}

export const DELETE_OLIVKA_REGEXP = gql`
  mutation deleteOlivkaRegexp($name: String) {
    deleteGlobalConfig(filter: { name: { eq: $name } }) {
      globalConfig {
        name
        value
      }
      msg
    }
  }
`
