import { gql } from '@apollo/client'
import { IQueryBasicData } from '../shared/IQueryData'

export interface ISendPasswordResetVars {
  email: string
  app: string
}
export interface ISendPasswordResetData {
  sendPasswordReset: IQueryBasicData
}

export const SEND_PASSWORD_RESET = gql`
  mutation SendPasswordReset($email: String!, $app: String) {
    sendPasswordReset(email: $email, service: "olivia", app: $app) {
      status
      code
      message
      error
    }
  }
`
