import gql from 'graphql-tag'

export interface IUpdateBannerVisibilityVars {
  id: string
  visible: boolean
}

export const UPDATE_BANNER_VISIBILITY = gql`
  mutation updateBannerVisibility($id: [ID!], $visible: Boolean) {
    updateBanner(input: { filter: { id: $id }, set: { visible: $visible } }) {
      banner {
        id
        visible
      }
    }
  }
`
