import { CSVLink } from 'react-csv'
import styled from 'styled-components'
import { Button } from '@mui/material'
import GetAppIcon from '@mui/icons-material/GetApp'
import { useTranslation } from 'react-i18next'

const TableCSVLink = ({
  csvHeaders,
  data,
  fileName = 'table-data-export.csv',
  filterCsvValue,
  filterCsvKey,
}: {
  csvHeaders?: { label: string; key: string }[] | string[]
  data: any[]
  fileName?: string
  filterCsvValue?: string
  filterCsvKey?: string
}) => {
  const { t } = useTranslation('table_component')
  const csvFilteredData =
    filterCsvValue && filterCsvKey
      ? data.filter((row) => row[filterCsvKey] !== filterCsvValue)
      : data

  return (
    <CSVLinkStyled
      separator={';'}
      data={csvFilteredData}
      filename={fileName}
      headers={csvHeaders ? csvHeaders : undefined}
    >
      <ButtonCSV
        color="primary"
        variant="outlined"
        size="large"
        startIcon={<GetAppIcon />}
        disabled={!data.length}
      >
        {t('download_csv')}
      </ButtonCSV>
    </CSVLinkStyled>
  )
}

export default TableCSVLink

const CSVLinkStyled = styled(CSVLink)`
  text-decoration: none;
`
const ButtonCSV = styled(Button)`
  border-radius: 100px;
`
