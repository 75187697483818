import gql from 'graphql-tag'

export interface IQueryParkingData {
  queryParking: {
    id: string
    name: string
  }[]
}

export const QUERY_PARKING = gql`
  query queryParking {
    queryParking(order: { asc: name }) {
      id
      name
    }
  }
`
