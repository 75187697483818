import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useQuery, ApolloError } from '@apollo/client'
import {
  IQueryEventOrdersData,
  QUERY_EVENT_ORDERS,
} from '../../graphql/events/queryEventOrders'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { Typography } from '@mui/material'
import { PaymentStatus } from '../../graphql/shared/sharedTypes'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import { getPaymentStatusColor } from '../../utils/getStatusColor'
import ErrorContainer from '../../components/Shared/ErrorContainer'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface Row {
  id: string
  createdAt: string
  createdAtCSV: string
  orderId: string
  residentTickets: number
  nonResidentTickets: number
  eventDate: string
  eventDateCSV: string
  eventName: string
  user: string
  email: string
  tenant: string
  service: string
  price: string
  priceCSV: string
  paymentStatus: PaymentStatus
  phoneNumber?: string
}

const EventTicketList = () => {
  const { t, i18n } = useTranslation(['events'])
  const lang = i18n.language
  const [error, setError] = useState('')

  const invisibleColumns = {
    id: false,
    createdAtCSV: false,
    eventDateCSV: false,
    priceCSV: false,
  }

  const csvHeaders = [
    {
      label: 'ID',
      key: 'id',
    },
    {
      label: t('created_at'),
      key: 'createdAtCSV',
    },
    {
      label: t('order_id'),
      key: 'orderId',
    },
    {
      label: t('resident_tickets'),
      key: 'residentTickets',
    },
    {
      label: t('non_resident_tickets'),
      key: 'nonResidentTickets',
    },
    {
      label: t('event_date'),
      key: 'eventDateCSV',
    },
    {
      label: t('event_name'),
      key: 'eventName',
    },
    {
      label: t('user'),
      key: 'user',
    },
    {
      label: t('tenant'),
      key: 'tenant',
    },
    {
      label: t('purchase_location'),
      key: 'service',
    },
    {
      label: `${t('price')} (PLN)`,
      key: 'priceCSV',
    },
    {
      label: t('payment_status'),
      key: 'paymentStatus',
    },
    {
      label: t('form:phone'),
      key: 'phoneNumber',
    },
  ]

  const { data, loading } = useQuery<IQueryEventOrdersData>(
    QUERY_EVENT_ORDERS,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => setError(error.message),
    }
  )

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        header: t('created_at'),
        accessorKey: 'createdAt',
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        enableColumnFilter: false,
      },
      {
        header: t('created_at'),
        accessorKey: 'createdAtCSV',
      },
      {
        header: t('order_id'),
        accessorKey: 'orderId',
      },
      {
        header: t('resident_tickets'),
        accessorKey: 'residentTickets',
      },
      {
        header: t('non_resident_tickets'),
        accessorKey: 'nonResidentTickets',
      },
      {
        header: t('event_date'),
        accessorKey: 'eventDate',
        cell: (value) => value && dateIso2localeString(value.getValue(), lang),
      },
      {
        header: t('event_date'),
        accessorKey: 'eventDateCSV',
      },
      {
        header: t('event_name'),
        accessorKey: 'eventName',
      },
      {
        header: t('user'),
        accessorKey: 'user',
      },
      {
        header: 'Email',
        accessorKey: 'email',
      },
      {
        header: t('tenant'),
        accessorKey: 'tenant',
      },
      {
        header: t('purchase_location'),
        accessorKey: 'service',
      },
      {
        header: t('price'),
        accessorKey: 'price',
      },
      {
        header: t('price'),
        accessorKey: 'priceCSV',
      },
      {
        header: t('payment_status'),
        accessorKey: 'paymentStatus',
        cell: (value) => t(`payment_status:${value.getValue()}`),
      },
      {
        header: t('form:phone'),
        accessorKey: 'phoneNumber',
      },
    ],
    [t, lang]
  )

  const tableData: Row[] = useMemo(() => {
    return (
      data?.queryEventOrders.map(
        ({
          id,
          orderId,
          createdAt,
          paymentId,
          user: { firstName, lastName, email, tenant },
          service,
          event,
          tickets,
          amount,
          paymentStatus,
          phoneNumber,
        }) => ({
          id,
          orderId,
          createdAt,
          createdAtCSV: dateIso2ExcelFormat(createdAt),
          residentTickets: tickets.filter(({ resident }) => resident).length,
          nonResidentTickets: tickets.filter(({ resident }) => !resident)
            .length,
          eventDate: event?.startDate && event.startDate,
          eventDateCSV:
            event?.startDate && dateIso2ExcelFormat(event.startDate),
          eventName: event?.title ? event.title : 'brak',
          user: `${firstName} ${lastName}`,
          email: email?.email,
          tenant: !!tenant?.name ? tenant.name : 'brak',
          service,
          price: `${amount.toFixed(2)} PLN`,
          priceCSV: `${amount.toFixed(2).replace('.', ',')}`,
          paymentStatus: paymentStatus.toLocaleLowerCase() as PaymentStatus,
          phoneNumber: phoneNumber || 'brak',
        })
      ) || []
    )
  }, [data])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">{t('title')}</TypographyStyled>
      <Table
        columns={columns}
        data={tableData}
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvExport
        csvHeaders={csvHeaders}
        getCellProps={({ column, row }) =>
          column.id === 'paymentStatus'
            ? {
                style: {
                  color: getPaymentStatusColor(row.original.paymentStatus),
                  fontWeight: 600,
                },
              }
            : {}
        }
        sortByKey="createdAt"
        sortDesc
      />
      {!!error && <ErrorContainer errorMessage={error} />}
    </>
  )
}

export default EventTicketList
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
