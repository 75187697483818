import { gql } from '@apollo/client'

export interface IGetAppVersionData {
  iosVersion: {
    value: string
  }
  androidVersion: {
    value: string
  }
}

export const GET_APP_VERSION = gql`
  query getGlobalConfig {
    iosVersion: getGlobalConfig(name: "public.app.store.ios.current_version") {
      value
    }
    androidVersion: getGlobalConfig(
      name: "public.app.store.android.current_version"
    ) {
      value
    }
  }
`
