import gql from 'graphql-tag'
import { LockerType } from './queryLockers'
import { ILog, ReservationStatus } from '../shared/sharedTypes'

export interface IBuilding {
  name: string
  location: string
  seller: {
    name: string
    zipcode: string
    street: string
    city: string
  }
}

export interface ILockerGroupReservation {
  id: string
  createdAt: string
  validFrom: string
  validTo: string
  status: ReservationStatus
  building: IBuilding
  garageLockers: number
  roomLockers: number
  lockers: {
    name: string
    key: string
    transponder: string
    type: LockerType
  }[]
  tenant: {
    name: string
    id: string
  }
  tenantName: string
  tenantEmail: string
  comment: string
  buyer: {
    name: string
    nip: string
    street: string
    city: string
    zipcode: string
  }
  representative: string
  price: number
  logs: ILog[]
}

export interface IQueryLockerGroupReservationData {
  groupReservations: ILockerGroupReservation[]
}

export const QUERY_LOCKER_GROUP_RESERVATION = gql`
  query queryLockerGroupReservation {
    groupReservations: queryLockerGroupReservation {
      id
      createdAt: created_at
      validFrom: valid_from
      validTo: valid_to
      status
      building {
        name
        location
        seller {
          name
          zipcode
          street
          city
        }
      }
      garageLockers: requested_garage_lockers
      roomLockers: requested_room_lockers
      lockers {
        name
        key
        transponder
        type
      }
      tenant {
        name
        id
      }
      tenantName: tenant_name
      tenantEmail: tenant_email
      comment
      buyer {
        name
        nip
        street
        city
        zipcode
      }
      representative: buyer_represented_by
      price
      logs(order: { desc: created_at }) {
        createdAt: created_at
        message
        parameters {
          name
          value
          type
        }
      }
    }
  }
`
