import { gql } from '@apollo/client'
import { IUser } from './queryUserList'

export interface IGetUserVars {
  login: string
}

export interface IGetUsersData {
  getUser: IUser
}

export const GET_USER = gql`
  query getUser($login: String!) {
    getUser(login: $login) {
      login
      createdAt: created_at
      firstName: first_name
      lastName: last_name
      lastMobileApp: last_mobile_app
      lastSeen: last_seen
      apiVersion: api_version
      disabled
      anonymizedDate: anonymized_date
      anonymizeStatus: anonymize_status
      email {
        email
      }
      workEmail: work_email {
        email
      }
      tenant {
        name
        id
        seos {
          disabled
          id
          prefix
        }
      }
      accessCard: access_card {
        number
        type
      }
      roles {
        name
      }
      features {
        name
        id
      }
      acceptances {
        decision
        regulation {
          regulationId: id
          title
        }
      }
      managesRestaurants: manages_restaurants {
        id
        name
      }
      carPlates: car_plates {
        name
      }
    }
  }
`
