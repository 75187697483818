import gql from 'graphql-tag'

export interface IUpdateSurveyQuestionOption {
  id: string
  name: string
  nameEm: string
}

export const UPDATE_SURVEY_QUESTION_OPTION = gql`
  mutation updateSurveyQuestionOption(
    $id: ID!
    $name: String
    $nameEn: String
  ) {
    updateSurveyQuestionOption(
      input: { filter: { id: [$id] }, set: { name: $name, name_en: $nameEn } }
    ) {
      surveyQuestionOption {
        id
        name
      }
    }
  }
`
