import React, { useState } from 'react'
import FullScreenLoader from '../components/Shared/FullScreenLoader'
import Error from '../components/PagesComponents/Error'
import { TextInfo } from '../components/Styles/TextCustom'
import { CenterContainer } from '../components/Styles/CustomElementsStyled'
import { BaseButton } from '../components/Shared/BaseButton'
import Input from '../components/Shared/Input'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { useMutation, ApolloError } from '@apollo/client'
import {
  SEND_PASSWORD_RESET,
  ISendPasswordResetVars,
  ISendPasswordResetData,
} from '../graphql/auth/sendPasswordReset'
import { themeColors } from '../const/colors'
import RemindPasswordEmailSent from '../components/PagesComponents/RemindPasswordEmailSent'
import { toast } from 'react-toastify'

type FormData = {
  emailOrLogin: string
}

interface ICustomWindow extends Window {
  TAG?: string
}
declare var window: ICustomWindow

const RemindPasswordPage = () => {
  const { t } = useTranslation([
    'recover_password_page',
    'form',
    'btn',
    'code',
    'error',
  ])
  const [param, setParam] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [success, setSuccess] = useState(false)
  const successNotify = (message: string) => toast.dark(message)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  const onCompleted = (data: ISendPasswordResetData) => {
    if (data?.sendPasswordReset.status) {
      setSuccess(true)
      successNotify(`${t('remind_password_email_sent_screen:header')} ${param}`)
    } else {
      setErrorMessage(t(`code:${data.sendPasswordReset.code}`))
    }
  }

  const [sendPasswordReset, { loading }] = useMutation<
    ISendPasswordResetData,
    ISendPasswordResetVars
  >(SEND_PASSWORD_RESET, {
    onCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  const sendReset = () =>
    sendPasswordReset({
      variables: {
        email: param,
        app: `${window.TAG || 'local'}`,
      },
    })

  const onSubmit = async (data: FormData) => {
    const { emailOrLogin } = data
    setParam(emailOrLogin)
    await sendPasswordReset({
      variables: {
        email: emailOrLogin,
        app: `${window.TAG || 'local'}`,
      },
    })
  }

  if (loading) {
    return <FullScreenLoader />
  }

  if (errorMessage) {
    return <Error errorMessage={errorMessage} title={true} />
  }

  if (success) {
    return (
      <RemindPasswordEmailSent
        data={param}
        resend={sendReset}
        loading={loading}
      />
    )
  }

  return (
    <CenterContainer>
      <BlueContainer>
        <FormContainer>
          <TextInfo>{t('remind_password_screen:info')}</TextInfo>
          <Input
            label={t('form:email_or_login')}
            name="emailOrLogin"
            control={control}
            errors={errors}
            rules={{ required: `${t('form:field_required')}` }}
          />
          <Button onClick={handleSubmit(onSubmit)}>{t('btn:send_link')}</Button>
        </FormContainer>
      </BlueContainer>
    </CenterContainer>
  )
}
const BlueContainer = styled.div`
  background-color: ${themeColors.lightBlue};
  border-radius: 30px;
  width: 566px;
  height: 474px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const FormContainer = styled.div`
  max-width: 325px;
`
const Button = styled(BaseButton)`
  margin-top: 8px;
`
export default RemindPasswordPage
