import { useState } from 'react'
import { Grid, Chip, Stack, Button } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { themeColors } from '../../const/colors'
import DateRangeFilter from '../Table/DateRangeFilter'
import { date2localeString } from '../../utils/formatDate'
import { ReservationStatus } from '../../graphql/shared/sharedTypes'
import ParkingSelect from '../Parking/ParkingSelect'
import PlaceSharedSelect from './PlaceSharedSelect'
import PlaceReservationStatusSelect from './PlaceReservationStatusSelect'

const ReservationFilters = ({
  parking,
  setParking,
  status,
  setStatus,
  shared,
  setShared,
  rangeFrom,
  rangeTo,
  setRangeFrom,
  setRangeTo,
}: {
  parking: string
  setParking: (v: string) => void
  status: ReservationStatus | ''
  setStatus: (v: ReservationStatus | '') => void
  shared: string
  setShared: (v: string) => void
  rangeFrom: Date | null
  setRangeFrom: (value: Date | null) => void
  rangeTo: Date | null
  setRangeTo: (value: Date | null) => void
}) => {
  const { t, i18n } = useTranslation(['parking_places'])
  const lang = i18n.language
  const [fromDate, setFromDate] = useState<Date | null>(rangeFrom)
  const [toDate, setToDate] = useState<Date | null>(rangeTo)

  const formatDate = (date: Date) =>
    date2localeString(date, lang, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })

  const handleClearFilters = () => {
    setParking('')
    setStatus('')
    setShared('')
    setFromDate(null)
    setToDate(null)
    setRangeFrom(null)
    setRangeTo(null)
  }

  const handleDeleteRangeFrom = () => {
    setFromDate(null)
    setRangeFrom(null)
  }
  const handleDeleteRangeTo = () => {
    setToDate(null)
    setRangeTo(null)
  }

  const onSubmitRange = () => {
    setRangeFrom(fromDate)
    toDate && setRangeTo(toDate)
  }

  return (
    <>
      <GridStyled container spacing={2}>
        <Grid item>
          <ParkingSelect value={parking} setValue={setParking} />
        </Grid>
        <Grid item>
          <PlaceReservationStatusSelect
            value={status}
            setValue={setStatus}
            short
          />
        </Grid>
        <Grid item>
          <PlaceSharedSelect value={shared} setValue={setShared} />
        </Grid>
        {status && status !== ReservationStatus.available && (
          <>
            <Grid item>
              <DateRangeFilter
                rangeFrom={fromDate}
                rangeTo={toDate}
                setRangeFrom={setFromDate}
                setRangeTo={setToDate}
                labelFrom={`${t(`parking_places:${status}`)} ${t(
                  'lockers:small_from'
                )}`}
                labelTo={`${t(`parking_places:${status}`)} ${t(
                  'lockers:small_to'
                )}`}
              />
            </Grid>
            <ButtonWrapper>
              {fromDate && (
                <ButtonStyled variant="text" onClick={onSubmitRange}>
                  {t('btn:submit_range')}
                </ButtonStyled>
              )}
            </ButtonWrapper>
          </>
        )}
      </GridStyled>
      <Stack direction="row" spacing={1}>
        {parking && (
          <ChipStyled label={parking} onDelete={() => setParking('')} />
        )}
        {status && (
          <ChipStyled label={t(status)} onDelete={() => setStatus('')} />
        )}
        {shared && (
          <ChipStyled
            label={`${t('shared')}: ${t(shared)}`}
            onDelete={() => setShared('')}
          />
        )}
        {rangeFrom && (
          <ChipStyled
            label={`${t('from')} ${formatDate(rangeFrom)}`}
            onDelete={handleDeleteRangeFrom}
          />
        )}
        {rangeTo && (
          <ChipStyled
            label={`${t('to')} ${formatDate(rangeTo)}`}
            onDelete={handleDeleteRangeTo}
          />
        )}
        {(parking || status || shared) && (
          <ButtonStyled variant="text" onClick={handleClearFilters}>
            {t('btn:clear_filters')}
          </ButtonStyled>
        )}
      </Stack>
    </>
  )
}

export default ReservationFilters

const GridStyled = styled(Grid)`
  padding: 16px 0;
  flex-direction: row;
`
const ChipStyled = styled(Chip)`
  color: ${themeColors.primary};
  background-color: ${themeColors.lightBlue};
  font-size: 14px;
  font-weight: 500;
`
const ButtonStyled = styled(Button)`
  text-transform: none;
  font-weight: 700;
`
const ButtonWrapper = styled(Grid)`
  padding: 24px 0 0 16px;
`
