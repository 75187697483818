import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { themeColors } from '../../const/colors'
import { IconButton } from '@mui/material'
import Input from '../Shared/Input'
import { faTrash, faEdit, faSave } from '@fortawesome/free-solid-svg-icons'
import { useForm } from 'react-hook-form'
import { IRegexp } from '../../graphql/olivka/queryOlivkaRegexp'
import {
  UPDATE_OLIVKA_REGEXP,
  IUpdateOlivkaRegexpVars,
} from '../../graphql/olivka/updateOlivkaRegexp'
import {
  DELETE_OLIVKA_REGEXP,
  IDeleteOlivkaRegexpVars,
} from '../../graphql/olivka/deleteOlivkaRegexp'
import { ApolloError, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { styled as styledMaterial } from '@mui/material/styles'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import ErrorContainer from '../Shared/ErrorContainer'

interface IEditRegexInputProps {
  item: IRegexp
  refetch: () => void
}
interface IFormData {
  value: string
}

const InputStyled = styledMaterial(Input)({
  width: 410,
  margin: '0 8px',
})

const EditRegexInput = (props: IEditRegexInputProps) => {
  const { item, refetch } = props
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState('')
  const { t } = useTranslation(['olivka'])
  const successNotify = (message: string) => toast.dark(message)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>()

  const onUpdateRegexCompleted = () => {
    successNotify(t('regex_updated'))
    refetch()
    setDisabled(true)
  }
  const onDeleteRegexCompleted = () => {
    successNotify(t('regex_removed'))
    refetch()
  }

  const [updateRegex] = useMutation<IUpdateOlivkaRegexpVars>(
    UPDATE_OLIVKA_REGEXP,
    {
      onCompleted: onUpdateRegexCompleted,
      onError: (error: ApolloError) => setError(error.message),
      fetchPolicy: 'no-cache',
    }
  )
  const [deleteRegex] = useMutation<IDeleteOlivkaRegexpVars>(
    DELETE_OLIVKA_REGEXP,
    {
      onCompleted: onDeleteRegexCompleted,
      onError: (error: ApolloError) => setError(error.message),
      fetchPolicy: 'no-cache',
    }
  )

  const deleteItem = () => deleteRegex({ variables: { name: item.name } })
  const onSubmit = (data: IFormData) =>
    updateRegex({ variables: { name: item.name, value: data.value } })

  return (
    <>
      <Row>
        <div>
          <InputStyled
            label={'Regex'}
            name={'value'}
            control={control}
            errors={errors}
            autoCapitalize={'none'}
            disabled={disabled}
            defaultValue={item.value}
            rules={{ required: `${t('form:field_required')}` }}
          />
        </div>
        {disabled ? (
          <IconButtonStyled size="small" onClick={() => setDisabled(false)}>
            <FontAwesomeIcon
              icon={faEdit as IconProp}
              size="1x"
              color={themeColors.primary}
            />
          </IconButtonStyled>
        ) : (
          <IconButtonStyled size="small" onClick={handleSubmit(onSubmit)}>
            <FontAwesomeIcon
              icon={faSave as IconProp}
              size="1x"
              color={themeColors.primary}
            />
          </IconButtonStyled>
        )}

        <IconButtonStyled size="small" onClick={deleteItem}>
          <FontAwesomeIcon
            icon={faTrash as IconProp}
            size="1x"
            color={themeColors.error}
          />
        </IconButtonStyled>
      </Row>
      {error && <ErrorContainer errorMessage={error} />}
    </>
  )
}

export default EditRegexInput

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const IconButtonStyled = styled(IconButton)`
  margin: 16px 0 0;
  width: 30px;
  align-self: flex-start;
`
