import gql from 'graphql-tag'

export interface IQueryCategoryData {
  queryCategory: {
    id: string
    titlePl: string
    titleEn: string
  }[]
}

export const QUERY_CATEGORY = gql`
  query queryCategory {
    queryCategory {
      id
      titlePl: title
      titleEn: title_en
    }
  }
`
