// @ts-ignore-file
import Paper from '@mui/material/Paper'
import {
  ArgumentAxis,
  BarSeries,
  Chart,
  Title,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui'
import { Animation } from '@devexpress/dx-react-chart'
import React from 'react'

type TChart = {
  answer: string
  votes: number
}

interface IBarChart {
  data: TChart[]
  argumentFieldName: string
  valueFieldName: string
  title: string
}

const BarChart: React.FC<IBarChart> = ({
  data,
  argumentFieldName,
  valueFieldName,
  title,
}) => {
  return (
    <Paper>
      <Chart data={data} rotated>
        <ArgumentAxis />
        <ValueAxis />
        <BarSeries
          valueField={argumentFieldName}
          argumentField={valueFieldName}
        />
        <Title text={title} />
        <Animation />
      </Chart>
    </Paper>
  )
}

export default BarChart
