import { useState, FC, useEffect } from 'react'
import styled from 'styled-components'
import { TextField, InputAdornment } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { themeColors } from '../../const/colors'
import { useTranslation } from 'react-i18next'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const GlobalFilterInput: FC<{
  filter: string
  setFilter: (value: string) => void
}> = ({ filter, setFilter }) => {
  const [value, setValue] = useState(filter)

  useEffect(() => {
    setValue(filter)
  }, [filter])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFilter(value)
    }, 500)

    return () => clearTimeout(timeout)
  }, [value])

  const { t } = useTranslation(['form'])
  return (
    <span>
      <Input
        data-e2e="search-input"
        value={value || ''}
        onChange={(e) => setValue(e.target.value)}
        label={t('search')}
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon
                icon={faSearch as IconProp}
                size="lg"
                color={themeColors.gray}
              />
            </InputAdornment>
          ),
        }}
      />
    </span>
  )
}

const Input = styled(TextField)`
  width: 460px;
`

export default GlobalFilterInput
