import { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormError } from '../../types/FormTypes'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import BasicSelect from './BasicSearchableSelect'
import { BaseTextButton } from '../Shared/BaseButton'
import {
  QUERY_REGULATION,
  IQueryRegulationData,
} from '../../graphql/shared/queryRegulation'
import { useQuery } from '@apollo/client'
import { themeColors } from '../../const/colors'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'

const RegulationComponent: FC<{
  control: any
  errors: IFormError | any
  regulationId: string
  watch: any
  service: string
}> = ({ control, errors, regulationId, watch, service }) => {
  const [regulationContent, setRegulationContent] = useState('')
  const [regulationExpanded, setRegulationExpanded] = useState(false)
  const selectedRegulation = watch('regulationId')
  const { t } = useTranslation(['parking_subscription_form', 'form'])
  const { data: regulations, loading } = useQuery<IQueryRegulationData>(
    QUERY_REGULATION,
    {
      variables: { service },
    }
  )

  useEffect(() => {
    selectedRegulation &&
      setRegulationContent(
        regulations?.queryRegulation.find(({ id }) => id === selectedRegulation)
          ?.content || ''
      )
  }, [selectedRegulation, regulations])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <Container>
      {!!regulations && (
        <div data-e2e="regulation-select">
          <BasicSelect
            options={regulations.queryRegulation.map(({ title, id }) => ({
              value: id,
              label: title,
            }))}
            control={control}
            errors={errors}
            label={t('select_rules')}
            name={'regulationId'}
            rules={{ required: `${t('form:field_required')}` }}
            defaultValue={regulationId}
          />
        </div>
      )}

      <ButtonWrapper>
        <BaseTextButton
          onClick={() => setRegulationExpanded(!regulationExpanded)}
        >
          {regulationExpanded
            ? t('btn:hide_regulation')
            : t('btn:show_regulation')}
        </BaseTextButton>
      </ButtonWrapper>

      {regulationExpanded && <GridStyled>{regulationContent}</GridStyled>}
    </Container>
  )
}

export default RegulationComponent

const Container = styled.div`
  max-width: 600px;
  padding: 16px 0;
`
const GridStyled = styled(Grid)`
  background-color: ${themeColors.background};
  padding: 16px;
  border-radius: 16px;
`
const ButtonWrapper = styled(Grid)`
  padding: 8px 0;
  display: flex;
  justify-content: center;
  align-content: center;
`
