import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IStatParkingSubscriptionVars {
  from: string
  to: string
}

export interface IStatParkingSubscriptionData {
  statParkingSubscriptionsDailyUsage: IQueryBasicData & {
    stats: {
      date: string
      parking: string
      capacity: number
      sold: number
      used: number
    }[]
  }
}

export const PARKING_DAILY_USAGE_STAT = gql`
  query statParkingSubscriptionsDailyUsage($from: DateTime!, $to: DateTime!) {
    statParkingSubscriptionsDailyUsage(from: $from, to: $to) {
      status
      message
      error
      code
      stats {
        date
        parking: parking_name
        capacity
        sold
        used
      }
    }
  }
`
