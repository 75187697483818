import styled from 'styled-components'
import { IconButton } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { themeColors } from '../../const/colors'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const IconButtonStyled = styled(IconButton)({
  width: 40,
})

const CloseButton = ({ ...rest }) => {
  return (
    <IconButtonStyled {...rest}>
      <FontAwesomeIcon icon={faTimes as IconProp} color={themeColors.black} />
    </IconButtonStyled>
  )
}
export default CloseButton
