import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  split,
} from '@apollo/client'
import getEnv from '../env'
import { errorLink } from './errorLink'
import { refreshTokenLink } from './refreshTokenLink'
import { authLink } from './authLink'
import { WebSocketLink } from '@apollo/client/link/ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { getMainDefinition } from '@apollo/client/utilities'

const httpLink = createHttpLink({
  uri: getEnv('API_URL'),
})

const wsLink = new WebSocketLink(
  new SubscriptionClient(getEnv('API_WSS_URL'), {
    reconnect: true,
    lazy: true,
    connectionParams: () => {
      const accessToken = localStorage.getItem('accessToken')
      return {
        'X-Auth-Token': accessToken || '',
      }
    },
  })
)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          queryIssue: {
            merge(existing = [], incoming: any[]) {
              return incoming
            },
          },
        },
      },
    },
  }),
  link: from([refreshTokenLink, errorLink, authLink, splitLink]),
})
