import React, { FC } from 'react'
import { useAppSelector } from '../../redux/store'
import { faMobileScreenButton } from '@fortawesome/free-solid-svg-icons'
import FAIcon from '../Shared/FAIcon'
import { ROUTES } from '../../const/routes'
import { useTranslation } from 'react-i18next'
import { checkRole } from '../../utils/checkRole'
import { BANNER } from '../../const/permissions'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useIsRouteSelected } from '../../hooks/useIsRouteSelected'
import {
  MainListItem,
  CustomListItemText,
  ListItemIconStyled,
  LinkStyled,
} from '../Styles/NavListStyle'

interface IBannerListItemProps {
  isMenuOpen?: boolean
}

const BannerListItem: FC<IBannerListItemProps> = ({ isMenuOpen }) => {
  const { t } = useTranslation(['admin_menu'])
  const { roles } = useAppSelector((state) => state.user)
  const { isSelected } = useIsRouteSelected(
    ROUTES.COMMUNICATION_BANNER,
    !!isMenuOpen
  )

  if (!checkRole(BANNER, roles)) {
    return null
  }

  return (
    <MainListItem selected={isSelected}>
      <LinkStyled to={ROUTES.COMMUNICATION_BANNER} data-e2e="navigation-banner">
        <ListItemIconStyled>
          <FAIcon icon={faMobileScreenButton as IconProp} />
        </ListItemIconStyled>
        <CustomListItemText primary={t('banner_in_app')} />
      </LinkStyled>
    </MainListItem>
  )
}

export default BannerListItem
