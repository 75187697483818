import { IQueryBasicData } from '../shared/IQueryData'
import { gql } from '@apollo/client'

export interface IAcceptanceChangeVars {
  decision?: 'yes' | 'no'
  regulation?: string
}

export interface IAcceptanceChangeData {
  changeAcceptance: IQueryBasicData
}

export const ACCEPTANCE_CHANGE = gql`
  mutation AcceptanceChange($decision: String!, $regulation: ID!) {
    changeAcceptance(decision: $decision, regulation: $regulation) {
      status
      code
      message
      error
    }
  }
`
