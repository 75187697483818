import gql from 'graphql-tag'
import { GetResponseJobStatus } from './getResponseSync'

export interface IGetResponseJob {
  id: string
  createdAt: string
  startedAt: string
  finishedAt: string
  status: GetResponseJobStatus
  recordsTotal: number
  recordsProcessed: number
  recordsCreated: number
  recordsUpdated: number
  recordsDeleted: number
  recordsError: number
}

export interface IQueryGetResponseJobData {
  queryGetResponseJob: IGetResponseJob[]
}

export const QUERY_GETRESPONSE_JOB = gql`
  query queryGetResponseJob {
    queryGetResponseJob {
      id
      createdAt: created_at
      startedAt: started_at
      finishedAt: finished_at
      status
      recordsTotal: records_total
      recordsProcessed: records_processed
      recordsCreated: records_created
      recordsUpdated: records_updated
      recordsDeleted: records_deleted
      recordsError: records_error
    }
  }
`
