import { useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ApolloError, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'
import { BaseButton } from '../Shared/BaseButton'
import Input from '../Shared/Input'
import { Title, TextError } from '../Styles/TextCustom'
import { CAR_PLATE_REGEX } from '../../const/regex'
import { useAppSelector } from '../../redux/store'
import { checkRole } from '../../utils/checkRole'
import { PARKING_ROLES } from '../../const/permissions'
import {
  CHANGE_PARKING_SUBSCRIPTION_CAR_PLATES,
  IChangeParkingSubscriptionCarPlatesVars,
  IChangeParkingSubscriptionCarPlatesData,
} from '../../graphql/parking/changeParkingSubscriptionCarPlates'
import { ISubscription } from '../../graphql/parking/queryParkingSubscriptions'

export type FormData = {
  carPlate: string
  carPlate2: string
  comment?: string
}

const EditTicketCarPlates = ({
  subscription,
  refetch,
}: {
  subscription: ISubscription
  refetch: () => void
}) => {
  const { t } = useTranslation(['sold_parking_items'])
  const successNotify = (message: string) => toast.dark(message)
  const [error, setError] = useState('')
  const { roles } = useAppSelector((state) => state.user)

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>()

  const onCompleted = (data: IChangeParkingSubscriptionCarPlatesData) => {
    const { code, error, status } = data.changeParkingSubscriptionCarPlates
    if (status) {
      refetch()
      successNotify(`${t('generic:success_message')}`)
    } else {
      setError(t(`code:${code}`) === `${code}` ? `${error}` : t(`code:${code}`))
    }
  }

  const [changeCarPlates, { loading }] = useMutation<
    IChangeParkingSubscriptionCarPlatesData,
    IChangeParkingSubscriptionCarPlatesVars
  >(CHANGE_PARKING_SUBSCRIPTION_CAR_PLATES, {
    onCompleted,
    onError: (error: ApolloError) => setError(error.message),
  })

  const updateSharedParking = async (data: FormData) => {
    await changeCarPlates({
      variables: {
        id: subscription.id,
        carPlate: data.carPlate.trim().toUpperCase(),
        carPlate2: data.carPlate2
          ? data.carPlate2.trim().toUpperCase()
          : undefined,
        comment: data.comment,
      },
    })
  }

  if (
    !checkRole(PARKING_ROLES, roles) ||
    !subscription.parkingPass?.parking?.name?.includes('Familia')
  ) {
    return null
  }

  return (
    <DetailsWrapper>
      <TitleStyled>{t('assigned_car_plates')}</TitleStyled>
      <Input
        label={t('car_plate')}
        name={'carPlate'}
        control={control}
        errors={errors}
        rules={{
          required: `${t('form:field_required')}`,
          pattern: {
            value: CAR_PLATE_REGEX,
            message: t('form:valid_car_plate'),
          },
        }}
        defaultValue={subscription.tickets[0].carPlate || ''}
        data-e2e="car-plate"
      />
      <Input
        label={t('car_plate')}
        name={'carPlate2'}
        control={control}
        errors={errors}
        rules={{
          pattern: {
            value: CAR_PLATE_REGEX,
            message: t('form:valid_car_plate'),
          },
        }}
        defaultValue={subscription.tickets[0].carPlate2 || ''}
        data-e2e="car-plate2"
      />
      <Input
        label={t('comment')}
        name="comment"
        control={control}
        errors={errors}
        multiline
        rows={2}
        defaultValue={''}
        data-e2e="comment"
      />
      <BaseButton
        onClick={handleSubmit(updateSharedParking)}
        loading={loading}
        data-e2e="submit"
      >
        {t('btn:save')}
      </BaseButton>
      <TextError>{error}</TextError>
    </DetailsWrapper>
  )
}

export default EditTicketCarPlates

const TitleStyled = styled(Title)`
  margin-bottom: 16px;
  display: block;
`
