import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { themeColors } from '../../const/colors'
import {
  CANCEL_PARKING_SUBSCRIPTION,
  ICancelParkingSubscriptionVars,
  ICancelParkingSubscriptionData,
} from '../../graphql/parking/cancelParkingSubscription'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import ErrorContainer from '../Shared/ErrorContainer'
import { FormData } from './SubscriptionActions'
import { BaseButton } from '../Shared/BaseButton'

const CancelParkingSubscription = ({
  id,
  refetch,
  handleSubmit,
}: {
  id: string
  refetch: () => void
  handleSubmit: any
}) => {
  const { t } = useTranslation(['sold_parking_items'])
  const successNotify = (message: string) => toast.dark(message)
  const [error, setError] = useState('')
  const [comment, setComment] = useState('')
  const [confirmModal, setConfirmModal] = useState(false)

  const onCompleted = (data: ICancelParkingSubscriptionData) => {
    const { status, error } = data.cancelParkingSubscription
    if (status) {
      successNotify(`${t('subscription_cancelled')}`)
      refetch()
    } else {
      error && setError(error)
    }
  }

  const [cancelParkingSubscription] = useMutation<
    ICancelParkingSubscriptionData,
    ICancelParkingSubscriptionVars
  >(CANCEL_PARKING_SUBSCRIPTION, {
    onCompleted,
    fetchPolicy: 'no-cache',
  })

  const onSubmit = (data: FormData) => {
    setComment(data.comment)
    setConfirmModal(true)
  }
  const handleCancelSubscription = () => {
    cancelParkingSubscription({
      variables: { id, comment },
    })
    setConfirmModal(false)
  }

  return (
    <>
      <BaseButton
        customColor={themeColors.negative}
        onClick={handleSubmit(onSubmit)}
        data-e2e="cancel-button"
      >
        {t('cancel_subscription')}
      </BaseButton>

      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseModal
        confirmText={'btn:confirm'}
        cancelText={'btn:exit'}
        open={confirmModal}
        handleClose={(e) => setConfirmModal(false)}
        handleAccept={handleCancelSubscription}
      >
        <ModalContent>{t('cancel_subscription_info')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default CancelParkingSubscription
