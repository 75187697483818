import { IQueryBasicData } from '../shared/IQueryData'
import { gql } from '@apollo/client'

export interface IEvent {
  id: number
  title: string
}

export interface IGetEventsData {
  getEvents: {
    events: IEvent[]
  } & IQueryBasicData
}

export const GET_EVENTS = gql`
  query getEvents {
    getEvents(order: "asc") {
      code
      message
      status
      error
      events {
        id: remoteID
        title
      }
    }
  }
`
