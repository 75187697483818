import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Grid, ClickAwayListener } from '@mui/material'
import { TextError, TextBody1 } from '../Styles/TextCustom'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import { ApolloError, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import ParkingPassInputs from './ParkingPassInputs'
import { UPDATE_PARKING_PASS } from '../../graphql/parking/updateParkingPass'
import { ParkingPassRow } from './ParkingPassesTable'
import FullScreenLoader from '../Shared/FullScreenLoader'
import AvailabilitySwitch from '../Shared/AvailabilitySwitch'
import ParkingPassSelects from './ParkingPassSelects'
import { themeColors } from '../../const/colors'
import DrawerButtons from '../Shared/DrawerButtons'
import DrawerHeader from '../Shared/DrawerHeader'

const EditParkingPass = ({
  closeDetails,
  data,
  updateList,
}: {
  closeDetails: () => void
  data?: ParkingPassRow
  updateList: () => void
}) => {
  const { t } = useTranslation([
    'parking_subscription_form',
    'form',
    'btn',
    'code',
    'generic',
    'menu_item_form',
  ])

  const [openClickAwayModal, setOpenClickAwayModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [disabled, setDisabled] = useState(true)
  // const [amanoSellCard, setAmanoSellCard] = useState(!!data?.amanoSellCard)

  const successNotify = (message: string) => toast.dark(message)
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()

  const watchFormData = useWatch({ control })
  const onSubmit = (formData: any) => {
    const {
      name,
      nameEn,
      amanoProductNo,
      durationDays,
      price,
      minAdvanceDays,
      maxAdvanceDays,
      minAdvanceWorkDays,
      overflowReservationDays,
      parkingId,
      regulationId,
      sort,
      amanoCurrency,
      vehicleType,
    } = formData

    updateParkingPass({
      variables: {
        parkingPassId: data?.productId,
        minAdvanceDays: parseInt(minAdvanceDays),
        maxAdvanceDays: parseInt(maxAdvanceDays),
        durationDays: parseInt(durationDays),
        minAdvanceWorkDays: parseInt(minAdvanceWorkDays),
        amanoProductNo: amanoProductNo ? parseInt(amanoProductNo) : 0,
        name,
        nameEn,
        overflowReservationDays: parseInt(overflowReservationDays),
        parkingId,
        price: parseInt(price) * 100,
        regulationId,
        disabled,
        sort: parseInt(sort),
        amanoCurrency,
        vehicleType,
        // amanoSellCard,
      },
    })
  }

  const onCompleted = () => {
    successNotify(`${t('generic:success_message')}`)
    updateList()
    closeDetails()
  }

  const [updateParkingPass, { loading }] = useMutation(UPDATE_PARKING_PASS, {
    onCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  const handleClickAway = () => {
    !!Object.keys(watchFormData).length
      ? setOpenClickAwayModal(true)
      : closeDetails()
  }
  const handleCloseModal = (e: any) => {
    e.stopPropagation()
    setOpenClickAwayModal(false)
  }

  useEffect(() => {
    !!data?.active && setDisabled(false)
  }, [data])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <>
          <DrawerHeader title={t('edit_title')} handleClose={handleClickAway} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ParkingPassInputs
              control={control}
              errors={errors}
              parkingPass={data}
            />
            {/* <TextBody1>{t('amano_sell_card')}</TextBody1>
            <AvailabilitySwitch
              checked={amanoSellCard}
              onChange={() => setAmanoSellCard(!amanoSellCard)}
              checkedText={'generic:yes'}
              uncheckedText={'generic:no'}
            /> */}
            <TextBody1>{t('active')}</TextBody1>
            <AvailabilitySwitch
              checked={!disabled}
              onChange={() => setDisabled(!disabled)}
              checkedText={'generic:yes'}
              uncheckedText={'generic:no'}
            />
            <ParkingPassSelects
              control={control}
              errors={errors}
              parkingPass={data}
              watch={watch}
            />
            <TextBody1>{t('company')}:</TextBody1>
            <GridStyled>
              <TextBody1>{data?.sellerName}</TextBody1>
            </GridStyled>

            <DrawerButtons
              handleAccept={handleSubmit(onSubmit)}
              handleCancel={handleClickAway}
            />

            {(!!Object.keys(errors).length || errorMessage) && (
              <TextError>
                {!errorMessage ? t('form:form_error') : errorMessage}
              </TextError>
            )}
          </form>
        </>
      </ClickAwayListener>
      <BaseModal
        confirmText={'btn:exit'}
        open={openClickAwayModal}
        handleClose={(e) => handleCloseModal(e)}
        handleAccept={() => closeDetails()}
      >
        <ModalContent>{t('generic:exit_without_saving')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default EditParkingPass

const GridStyled = styled(Grid)`
  background-color: ${themeColors.background};
  padding: 16px;
  max-width: 600px;
  border-radius: 16px;
`
