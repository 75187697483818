import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IConfirmTenantStatusRequestVars {
  code: string
  action: string
  comment?: string
  tenant?: string
}

export interface IConfirmTenantStatusRequestData {
  confirmTenantStatusRequest: IQueryBasicData
}

export const CONFIRM_TENANT_STATUS_REQUEST = gql`
  mutation ConfirmTenantStatusRequest(
    $code: String!
    $action: String!
    $comment: String
    $tenant: String
  ) {
    confirmTenantStatusRequest(
      verification_code: $code
      action: $action
      comment: $comment
      tenant: $tenant
    ) {
      status
      code
      message
      error
    }
  }
`
