import gql from 'graphql-tag'

export interface IUpdateLockerVars {
  id: string
  key: string
  transponder: string
  notes?: string
}

export const UPDATE_LOCKER = gql`
  mutation updateLocker(
    $id: [ID!]
    $key: String
    $transponder: String
    $notes: String
  ) {
    updateLocker(
      input: {
        filter: { id: $id }
        set: { key: $key, transponder: $transponder, notes: $notes }
      }
    ) {
      locker {
        key
      }
    }
  }
`
