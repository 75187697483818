import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IFormError } from '../../types/FormTypes'
import { themeColors } from '../../const/colors'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { TextField } from '@mui/material'
import { styled as styledMaterial } from '@mui/material/styles'

type Type = 'radiobutton' | 'checkbox' | 'input' | 'textarea'

const InputStyled = styledMaterial(TextField)({
  width: 410,
  margin: '0 8px 16px',
  backgroundColor: themeColors.white,
  maxWidth: 500,
})

const SurveyOption = ({
  control,
  errors,
  type,
  name,
  nameEn,
  id,
  register,
  index,
  questionIndex,
}: {
  control: any
  errors: IFormError
  type: Type
  name?: string
  nameEn?: string
  id: string
  register: any
  index: number
  questionIndex: number
}) => {
  const { t } = useTranslation(['survey_form'])

  return (
    <Row>
      {type === 'radiobutton' && <RadioIcon />}
      {type === 'checkbox' && <CheckboxIcon />}
      <InputWrapper>
        <InputStyled
          label={t('option')}
          {...register(`${id}.pl`)}
          defaultValue={name || ''}
          control={control}
          errors={errors}
          variant="outlined"
          color={'primary'}
          data-e2e={`survey-input-reply-name${questionIndex}${index}`}
        />
        <InputStyled
          label={t('option_en')}
          {...register(`${id}.en`)}
          defaultValue={nameEn || ''}
          control={control}
          errors={errors}
          variant="outlined"
          color={'primary'}
          data-e2e={`survey-input-reply-name-en${questionIndex}${index}`}
        />
      </InputWrapper>
    </Row>
  )
}

export default SurveyOption
const Row = styled.div`
  padding: 5px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const RadioIcon = styled(RadioButtonUncheckedIcon)`
  margin-top: 24px;
  color: ${themeColors.gray};
  align-self: flex-start;
`
const CheckboxIcon = styled(CheckBoxOutlineBlankIcon)`
  margin-top: 24px;
  color: ${themeColors.gray};
  align-self: flex-start;
`
const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
