import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IAnonymizeUserVars {
  login: string
  anonymize: boolean
}

export interface IAnonymizeUserData {
  anonymizeUser: IQueryBasicData
}

export const ANONYMIZE_USER = gql`
  mutation anonymizeUser($login: String!, $anonymize: Boolean!) {
    anonymizeUser: AnonymizeUser(login: $login, anonymize: $anonymize) {
      status
      code
      message
      error
    }
  }
`
