import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import DrawerHeader from '../Shared/DrawerHeader'
import RentManyLockersForm from './RentManyLockersForm'
import GroupReservationSummary from '../LockerProtocols/GroupReservationSummary'
import { ILocker, LockerType } from '../../graphql/lockers/queryLockers'
import {
  GROUP_RESERVE_LOCKERS,
  IGroupReserveLockersVars,
  IGroupReserveLockersData,
} from '../../graphql/lockers/groupReserveLockers'
import { ApolloError, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import DrawerButtons from '../Shared/DrawerButtons'
import { TextError } from '../Styles/TextCustom'
import { date2isoString, date2localeString } from '../../utils/formatDate'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { ReservationStatus } from '../../graphql/shared/sharedTypes'
import { LockerReservationRow } from '../LockerReservation/LockerReservationTable'
import {
  UPDATE_LOCKER,
  IUpdateLockerVars,
} from '../../graphql/lockers/updateLocker'
import { Alert } from '@mui/material'
import styled from 'styled-components'

type FormData = {
  building: string
  tenant: {
    value: string
    label: string
  }
  tenantEmail: string
  tenantName: string
  comment: string
  status: ReservationStatus
  validFrom: Date
  validTo: Date
}

const RentManyLockersManually = ({
  closeDrawer,
  lockers,
  refetch,
  selectedLockers,
  building,
}: {
  closeDrawer: () => void
  lockers: ILocker[]
  refetch: () => void
  selectedLockers: LockerReservationRow[]
  building: string
}) => {
  const { t, i18n } = useTranslation(['lockers'])
  const lang = i18n.language
  const successNotify = (message: string) => toast.dark(message)
  const [reservationId, setReservationId] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [requestedGarageLockers, setRequestedGarageLockers] = useState(0)
  const [requestedRoomLockers, setRequestedLockerroomLockers] = useState(0)

  const now = date2localeString(new Date(), lang, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>()

  const suggestedIds =
    selectedLockers.length && !!building
      ? selectedLockers.map(({ id }) => id).join(',')
      : undefined

  useEffect(() => {
    if (selectedLockers.length && !!building) {
      setRequestedGarageLockers(
        selectedLockers.filter(({ type }) => type === LockerType.garage).length
      )
      setRequestedLockerroomLockers(
        selectedLockers.filter(({ type }) => type === LockerType.locker_room)
          .length
      )
    }
  }, [selectedLockers, building])

  const onCompleted = (data: IGroupReserveLockersData) => {
    const { status, error, parameters } = data.groupReserveLockers
    if (status) {
      successNotify(`${t('generic:reservation_created')}`)
      refetch()
      setReservationId(
        parameters.find(({ name }) => name === 'locker_group_reservation_id')
          ?.value || ''
      )
    }
    if (error) {
      setErrorMessage(error)
    }
  }

  const [groupReserveLockers, { loading }] = useMutation<
    IGroupReserveLockersData,
    IGroupReserveLockersVars
  >(GROUP_RESERVE_LOCKERS, {
    onCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })
  const [updateLocker] = useMutation<IUpdateLockerVars>(UPDATE_LOCKER, {
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  const onSubmit = (data: FormData) => {
    if (!requestedRoomLockers && !requestedGarageLockers) {
      setErrorMessage(t('no_lockers_requested'))
    }

    groupReserveLockers({
      variables: {
        validFrom: date2isoString(data.validFrom),
        validTo: data.validTo ? date2isoString(data.validTo) : undefined,
        requestedGarageLockers,
        requestedRoomLockers,
        buildingName: data.building,
        requestedStatus: data.status,
        tenantId: data.tenant ? data.tenant.value : undefined,
        tenantEmail: data.tenantEmail,
        tenantName: data.tenantName,
        comment: data.comment,
        suggestedIds,
      },
    })
    if (data.comment && selectedLockers.length === 1 && building) {
      const { id, notes } = selectedLockers[0]
      updateLocker({
        variables: {
          id,
          notes: `${now}:${data.comment}${notes ? `, ${notes}` : ''}`,
        },
      })
    }
  }

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <DrawerHeader
        title={
          !reservationId ? t('rent_locker_manually') : t('rent_confirmation')
        }
        handleClose={closeDrawer}
      />
      {!!selectedLockers.length && !building && !reservationId && (
        <AlertStyled severity="error">
          {t('rent_selected_lockers_tip')}
        </AlertStyled>
      )}

      {!reservationId && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <RentManyLockersForm
            selectedLockers={selectedLockers}
            lockers={lockers}
            garageLockers={requestedGarageLockers}
            setGarageLockers={setRequestedGarageLockers}
            lockerroomLockers={requestedRoomLockers}
            setLockerroomLockers={setRequestedLockerroomLockers}
            control={control}
            errors={errors}
            watch={watch}
            building={building}
          />
          <DrawerButtons
            handleAccept={handleSubmit(onSubmit)}
            handleCancel={closeDrawer}
          />
          {(!!Object.keys(errors).length || errorMessage) && (
            <TextError>
              {!errorMessage ? t('form:form_error') : errorMessage}
            </TextError>
          )}
        </form>
      )}
      {!!reservationId && (
        <GroupReservationSummary reservationId={reservationId} />
      )}
    </>
  )
}

export default RentManyLockersManually
const AlertStyled = styled(Alert)`
  margin: 16px 0;
`
