import gql from 'graphql-tag'
import { ILog } from './sharedTypes'

export interface IQueryLogLineVars {
  name: string
}

export interface IQueryLogLineData {
  queryLogLinesWithParameter: ILog[]
}

export const QUERY_LOG_LINE = gql`
  query queryLogLinesWithParameter($name: String!) {
    queryLogLinesWithParameter(name: $name) {
      id
      createdAt: created_at
      message
      parameters {
        name
        value
      }
    }
  }
`
