import gql from 'graphql-tag'

export interface ISetBannerPreviewVars {
  id: string
  preview: boolean
}

export const SET_BANNER_PREVIEW = gql`
  mutation setBannerPreview($id: ID!, $preview: Boolean) {
    updateBanner(input: { filter: { id: [$id] }, set: { preview: $preview } }) {
      banner {
        id
      }
    }
  }
`
