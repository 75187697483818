import { useTranslation } from 'react-i18next'
import { IFormError } from '../../types/FormTypes'
import BasicSelect from './BasicSearchableSelect'
import {
  QUERY_BUILDING,
  IQueryBuildingData,
} from '../../graphql/shared/queryBuilding'
import { useQuery } from '@apollo/client'
import FullScreenLoader from './FullScreenLoader'

const UncontrolledBuildingSelect = ({
  control,
  errors,
  defaultValue,
  disabled = false,
}: {
  control: Object
  errors: IFormError
  defaultValue?: string
  disabled?: boolean
}) => {
  const { t } = useTranslation(['lockers'])

  const { data, loading } = useQuery<IQueryBuildingData>(QUERY_BUILDING)

  if (loading || !data) {
    return <FullScreenLoader />
  }

  return (
    <div data-e2e="building-select">
      <BasicSelect
        options={data.queryBuilding.map(({ id, name }) => ({
          value: name,
          label: name,
        }))}
        control={control}
        name={'building'}
        // rules={{ required: `${t('form:field_required')}` }}
        label={t('building')}
        defaultValue={defaultValue}
        disabled={disabled}
      />
    </div>
  )
}

export default UncontrolledBuildingSelect
