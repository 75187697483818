import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TextBody2Bold, TextBody2, TextBody2Medium } from '../Styles/TextCustom'
import { themeColors } from '../../const/colors'
import {
  IPlace,
  ParkingPlaceStatus,
  IQueryParkingPlaceData,
} from '../../graphql/parking/queryParkingPlace'
import { getCurrentReservation } from '../../utils/getCurrentReservation'

const PlaceOccupancyInfo = ({
  data,
  parking,
}: {
  data: IQueryParkingPlaceData
  parking: string
}) => {
  const { t } = useTranslation(['parking_places'])
  const [places, setPlaces] = useState<IPlace[]>([])

  useEffect(() => {
    let items = data.places
    if (parking) {
      items = items.filter(({ parking: { name } }) => name === parking)
    }
    setPlaces(items)
  }, [data, parking])

  const available = places.filter(
    ({ status }) => status === ParkingPlaceStatus.available
  ).length

  const unavailable = places.filter(
    ({ status }) => status === ParkingPlaceStatus.unavailable
  ).length

  const reserved = places.filter(({ reservations }) =>
    getCurrentReservation(reservations)
  ).length

  const toRent = available - reserved

  return (
    <Container container>
      {parking && (
        <Row item>
          <TextBody2Medium>{`${parking} :`}</TextBody2Medium>
        </Row>
      )}
      <Grid container item>
        <Row item xs={2}>
          <TextBody2>{`${t('available')}:`}</TextBody2>
          <Number color={themeColors.accept}>{available}</Number>
        </Row>
        <Row item xs={2}>
          <TextBody2>{`${t('unavailable')}:`}</TextBody2>
          <Number color={themeColors.error}>{unavailable}</Number>
        </Row>
        <Row item xs={2}>
          <TextBody2>{`${t('reserved')}:`}</TextBody2>
          <Number color={themeColors.primary}>{reserved}</Number>
        </Row>
        <Row item xs={2}>
          <TextBody2>{`${t('to_rent')}:`}</TextBody2>
          <Number color={themeColors.primary}>{toRent}</Number>
        </Row>
      </Grid>
    </Container>
  )
}

export default PlaceOccupancyInfo

const Container = styled(Grid)`
  padding: 16px 0 26px;
`
const Row = styled(Grid)`
  display: flex;
  flex-direction: row;
`
const Number = styled(TextBody2Bold)`
  margin: 0 8px;
`
