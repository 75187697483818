import { useQuery } from '@apollo/client'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  GET_USER_ACCEPTANCES,
  IGetUserAcceptancesVars,
  IGetUserAcceptancesEvents,
} from '../../graphql/users/getUserAcceptances'
import { dateIso2localeString } from '../../utils/formatDate'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import { themeColors } from '../../const/colors'
import { BaseTextButton } from '../Shared/BaseButton'
import NewTabLink from '../Shared/NewTabLink'
import { removeLinks } from '../../utils/removeLinks'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { TextBody1Bold } from '../Styles/TextCustom'

interface IUserAcceptances {
  login: string
}

const UserAcceptances: FC<IUserAcceptances> = ({ login }) => {
  const { t, i18n } = useTranslation('user_details')
  const [show, setShow] = useState(false)

  const { data, loading } = useQuery<
    IGetUserAcceptancesEvents,
    IGetUserAcceptancesVars
  >(GET_USER_ACCEPTANCES, {
    variables: {
      login,
    },
    fetchPolicy: 'network-only',
  })

  const transformBoolean = (bool?: boolean) =>
    bool ? t('bool:yes') : t('bool:no')

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <BlueContainer>
      <Row>
        <BaseTextButton onClick={() => setShow(!show)}>
          {show ? t('collapse_acceptances') : t('expand_acceptances')}
        </BaseTextButton>
        <NewTabLink
          text={t('change_history')}
          path={`/admin/user/acceptances/${login}`}
        />
      </Row>

      {show && data?.getUser.acceptances[0] && (
        <>
          {data?.getUser.acceptances.map((element, i) => (
            <AccordionStyled key={i}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Title>{element.regulation.title}:</Title>
              </AccordionSummary>
              <AccordionDetails>
                <p>{removeLinks(element.regulation.content)}</p>
                <p>
                  {t('user_acceptances:required')}{' '}
                  <strong>{`${transformBoolean(
                    element.regulation.required
                  )}`}</strong>
                </p>
                <p>
                  {t('user_acceptances:selected')}{' '}
                  <strong>{`${transformBoolean(element.decision)}`}</strong>
                </p>
                <p>
                  {t('user_acceptances:service')}{' '}
                  <strong>{element.regulation.service}</strong>
                </p>
                <p>
                  {t('user_acceptances:current')}{' '}
                  <strong>{`${transformBoolean(
                    element.regulation.current
                  )}`}</strong>
                </p>
                <p>
                  {t('user_acceptances:createdAt')}{' '}
                  <strong>{`${dateIso2localeString(
                    element.created_at,
                    i18n.language
                  )}`}</strong>
                </p>
                <p>
                  {t('user_acceptances:modifiedAt')}{' '}
                  <strong>{`${dateIso2localeString(
                    element.modified_at,
                    i18n.language
                  )}`}</strong>
                </p>
              </AccordionDetails>
            </AccordionStyled>
          ))}
        </>
      )}
    </BlueContainer>
  )
}

export default UserAcceptances

const BlueContainer = styled(Grid)`
  background-color: ${themeColors.lightBlue};
  border-radius: 20px;
  padding: 20px;
  margin-top: 16px;
`
const Row = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const AccordionStyled = styled(Accordion)`
  background-color: transparent;
  box-shadow: none;
`
const Title = styled(TextBody1Bold)`
  margin: 8px 0;
`
