import gql from 'graphql-tag'
import { ParkingPlaceStatus } from '../parking/queryParkingPlace'

export interface IQueryParkingAvailablePlacesData {
  queryParking: {
    name: string
    places: {
      status: ParkingPlaceStatus
    }[]
  }[]
}

export const QUERY_PARKING_AVAILABLE_PLACES = gql`
  query queryParking {
    queryParking {
      name
      places(filter: { status: { eq: available }, not: { has: tenant } }) {
        status
        tenant {
          name
        }
      }
    }
  }
`
