import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import BaseSelect from './BaseSelect'

interface IPushFormProps {
  errors: any
  control: any
  defaultValue?: string
}

const ProfileLangSelect = (props: IPushFormProps) => {
  const { control, errors, defaultValue } = props
  const { t } = useTranslation(['generic'])

  const views = [
    { value: 'pl', label: t('pl') },
    { value: 'en', label: t('en') },
    { value: 'none', label: t('all') },
  ]

  return (
    <SelectWrapper>
      <BaseSelect
        defaultValue={
          (defaultValue && views.find((val) => val.value === defaultValue)) ||
          views[0]
        }
        options={views.map(({ value, label }) => ({
          label,
          value,
        }))}
        name="lang"
        control={control}
        errors={errors}
        isClearable={false}
        placeholder={t('push:select_language')}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </SelectWrapper>
  )
}

export default ProfileLangSelect

const SelectWrapper = styled.div`
  max-width: 500px;
`
