import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { FormControl, MenuItem, TextField } from '@mui/material'
import { AutoCompleteType, InputRules, IFormError } from '../../types/FormTypes'
import { themeColors } from '../../const/colors'
import { Controller } from 'react-hook-form'
import { TextBody1Medium } from '../Styles/TextCustom'

export type OptionType = { value: string; label: string; color?: string }

const BasicSearchableSelect: React.FC<{
  label: string
  name: string
  options: OptionType[]
  control: any
  errors?: IFormError | any
  rules?: InputRules
  disabled?: boolean
  defaultValue?: string
  message?: string
  autoCompleteType?: AutoCompleteType
  InputProps?: any
}> = forwardRef(
  (
    {
      label,
      name,
      options,
      control,
      errors,
      rules,
      defaultValue = '',
      disabled,
      message = '',
      autoCompleteType,
      InputProps,
    },
    ref?: React.Ref<HTMLInputElement>
  ) => {
    return (
      <>
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field: { onChange, value, ref } }) => (
            <FormControl fullWidth>
              <Select
                ref={ref}
                id="select"
                variant="outlined"
                color={'primary'}
                value={value}
                onChange={onChange}
                label={label}
                error={errors && !!errors[name]}
                disabled={disabled}
                select
                SelectProps={{
                  MenuProps: { disablePortal: true },
                }}
              >
                {options.map((item, i) => (
                  <MenuItem value={item.value} key={i}>
                    <Text color={item.color ? item.color : themeColors.gray}>
                      {item.label}
                    </Text>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </>
    )
  }
)

export default BasicSearchableSelect

const Select = styled(TextField)`
  background-color: ${themeColors.white};
  max-width: 600px;
`
const Text = styled(TextBody1Medium)`
  margin: 0;
`
