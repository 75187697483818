import React from 'react'
import { TextH4Bold, TextInfo } from '../Styles/TextCustom'
import {
  Img,
  CenterContainer,
} from '../../components/Styles/CustomElementsStyled'
import image from '../../assets/images/error.svg'
import { useTranslation } from 'react-i18next'

const Error = ({
  title,
  errorMessage,
}: {
  title: boolean
  errorMessage: string
}) => {
  const { t } = useTranslation(['generic', 'error'])

  return (
    <CenterContainer>
      {title && <TextH4Bold>{t('ups')}</TextH4Bold>}
      <Img src={image} alt="img" width="515" />
      <TextInfo>{errorMessage}</TextInfo>
    </CenterContainer>
  )
}

export default Error
