import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import pl from './assets/locales/pl'
import en from './assets/locales/en'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // debug: true,
    // lng: 'en',
    fallbackLng: 'en',
    resources: {
      pl,
      en,
    },
    detection: {
      order: ['queryString', 'navigator', 'localStorage'],
      lookupQuerystring: 'lang',
    },

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
