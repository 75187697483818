import gql from 'graphql-tag'
import { Roles } from '../shared/sharedTypes'

export interface IMyRoles {
  roles: {
    name: Roles
  }[]
}
export interface IGetMyRolesData {
  getMyRoles: {
    status: boolean
    errors: {
      message: string
      code: number
    }[]
    roles: {
      name: Roles
    }[]
  }
}

export const GET_MY_ROLES = gql`
  query GetMyRoles {
    getMyRoles {
      errors {
        message
        code
      }
      roles {
        name
      }
    }
  }
`
