import { FC, useEffect, useState } from 'react'
import { ActionMeta } from 'react-select'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import BaseSelect, { OptionType } from '../Shared/BaseSelect'
import {
  IAssignUserRoleData,
  IAssignUserRoleVars,
  ASSIGN_USER_ROLE,
  IUnassignUserRoleData,
  IUnassignUserRoleVars,
  UNASSIGN_USER_ROLE,
} from '../../graphql/users/userRoleActions'
import {
  GET_EXISTING_ROLES,
  IGetExistingRolesData,
} from '../../graphql/admin/queryRoles'
import { toast } from 'react-toastify'
import RestaurantSelect from './RestaurantSelect'
import { Title } from '../Styles/TextCustom'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import FullScreenLoader from '../Shared/FullScreenLoader'
import styled from 'styled-components'
import { IUser } from '../../graphql/users/queryUserList'
import { Grid } from '@mui/material'
import { themeColors } from '../../const/colors'
import RolesLegend from './RolesLegend'
import ErrorContainer from '../Shared/ErrorContainer'
import { BaseTextButton } from '../Shared/BaseButton'

const UserRoles: FC<{
  userData: IUser
  refetch: () => void
}> = ({ userData, refetch }) => {
  const { roles, login, managesRestaurants } = userData
  const { t } = useTranslation(['user_details', 'code'])
  const userRoles = roles.map(({ name }) => name)
  const [selectedRoles, setSelectedRoles] = useState<OptionType[]>([])
  const [value, setValue] = useState<OptionType[]>([])
  const [addRole, setAddRole] = useState('')
  const [removeRole, setRemoveRole] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [show, setShow] = useState(false)
  const successNotify = (message: string) => toast.dark(message)

  const { data: existingRoles } = useQuery<IGetExistingRolesData>(
    GET_EXISTING_ROLES,
    {
      fetchPolicy: 'network-only',
    }
  )
  useEffect(() => {
    if (!!existingRoles) {
      const selectedRoles = existingRoles.queryUserRole.map(({ name }) => ({
        name,
        value: userRoles.includes(name),
      }))
      const initialSelectedRoles = selectedRoles?.reduce(
        (acc: { value: string; label: string }[], { name, value }) =>
          value ? [...acc, { value: name, label: name }] : acc,
        []
      )
      setSelectedRoles(initialSelectedRoles ? initialSelectedRoles : [])
    }
  }, [existingRoles, roles])

  const onAssignCompleted = (data: IAssignUserRoleData) => {
    const { status, code } = data.assignRole
    if (status) {
      successNotify(`${t('user_details:add_role')}`)
      setSelectedRoles(value)
      setValue([])
      setAddRole('')
      setErrorMessage('')
      refetch()
    } else {
      setErrorMessage(t(`code:${code}`))
    }
  }

  const onUnassignCompleted = (data: IUnassignUserRoleData) => {
    const { status, code } = data.unassignRole
    if (status) {
      successNotify(`${t('user_details:delete_role')}`)
      setSelectedRoles(value)
      setValue([])
      setRemoveRole('')
      setErrorMessage('')
      refetch()
    } else {
      setErrorMessage(t(`code:${code}`))
    }
  }

  const [assignUserRole, { loading: assignLoading }] = useMutation<
    IAssignUserRoleData,
    IAssignUserRoleVars
  >(ASSIGN_USER_ROLE, {
    onCompleted: onAssignCompleted,
  })

  const [unassignUserRole, { loading: unassignLoading }] = useMutation<
    IUnassignUserRoleData,
    IUnassignUserRoleVars
  >(UNASSIGN_USER_ROLE, {
    onCompleted: onUnassignCompleted,
  })

  const handleSelectChange = (
    value: any,
    actionMeta: ActionMeta<OptionType>
  ) => {
    if (actionMeta.action === 'select-option' && actionMeta.option?.value) {
      setAddRole(actionMeta.option?.value)
      setValue(value)
    }
    if (actionMeta.action === 'remove-value') {
      setRemoveRole(actionMeta.removedValue.value)
      setValue(value)
    }
  }

  const restaurantRole = selectedRoles
    .map(({ label }) => label)
    .includes('restaurant')
  const options = existingRoles?.queryUserRole.map(({ name }) => ({
    value: name,
    label: name,
  }))
  const isSelectLoading = assignLoading || unassignLoading

  const handleChangeRole = () => {
    if (!!addRole) {
      assignUserRole({
        variables: { login, role: addRole },
      })
    } else if (!!removeRole) {
      unassignUserRole({
        variables: { login, role: removeRole },
      })
    }
  }
  const handleCloseModal = () => {
    setAddRole('')
    setRemoveRole('')
  }

  if (!existingRoles) {
    return <FullScreenLoader />
  }

  return (
    <BlueContainer data-e2e="user-role-select">
      <Row>
        <Title>{t('user_roles')}</Title>
        <BaseTextButton onClick={() => setShow(!show)}>
          {show ? t('collapse_role_legend') : t('expand_role_legend')}
        </BaseTextButton>
      </Row>
      <Container>
        <BaseSelect<OptionType, true>
          value={selectedRoles}
          defaultValue={selectedRoles}
          onChange={handleSelectChange}
          options={options}
          isLoading={isSelectLoading}
          isDisabled={isSelectLoading}
          isClearable={false}
          isMulti
          closeMenuOnSelect={false}
          noOptionsMessage={() => t('no_roles')}
          placeholder={t('choose_role')}
        />
        {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
      </Container>
      {restaurantRole && (
        <RestaurantSelect
          login={login}
          managesRestaurants={managesRestaurants}
          refetch={refetch}
        />
      )}
      {show && <RolesLegend roles={existingRoles} />}
      <BaseModal
        confirmText={'btn:confirm'}
        open={!!addRole || !!removeRole}
        handleClose={handleCloseModal}
        handleAccept={handleChangeRole}
      >
        {addRole && (
          <ModalContent>{`${t('add_role_confirmation1')}${addRole}${t(
            'add_role_confirmation2'
          )}`}</ModalContent>
        )}
        {removeRole && (
          <ModalContent>{`${t('remove_role_confirmation1')}${removeRole}${t(
            'remove_role_confirmation2'
          )}`}</ModalContent>
        )}
      </BaseModal>
    </BlueContainer>
  )
}

export default UserRoles

const BlueContainer = styled(Grid)`
  background-color: ${themeColors.lightBlue};
  border-radius: 20px;
  padding: 20px;
  margin-top: 16px;
`
const Container = styled(Grid)`
  max-width: 500px;
`
const Row = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
