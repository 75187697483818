import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface ICancelParkingSubscriptionVars {
  id: string
  comment: string
}

export interface ICancelParkingSubscriptionData {
  cancelParkingSubscription: IQueryBasicData
}

export const CANCEL_PARKING_SUBSCRIPTION = gql`
  mutation cancelParkingSubscription($id: ID!, $comment: String!) {
    cancelParkingSubscription(parking_subscription: $id, comment: $comment) {
      status
      code
      error
      message
    }
  }
`
