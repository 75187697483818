import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IGetOrdersReportsVars {
  from: string
  to: string
}

export interface IGetOrdersReportsData {
  getOrdersReports: IQueryBasicData & {
    csv: string
  }
}

export const GET_ORDERS_REPORTS = gql`
  query GetOrdersReports($from: DateTime!, $to: DateTime!) {
    getOrdersReports(from: $from, to: $to) {
      status
      code
      message
      error
      csv
    }
  }
`
