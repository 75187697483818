import gql from 'graphql-tag'
import { IssueType } from './queryIssue'

export interface IUpdateIssueTypeVars {
  id: string
  type: IssueType
}
export interface IUpdateIssueTypeData {
  updateIssue: {
    issue: {
      type: IssueType
    }[]
  }
}

export const UPDATE_ISSUE_TYPE = gql`
  mutation updateIssue($id: [ID!], $type: IssueType) {
    updateIssue(input: { filter: { id: $id }, set: { type: $type } }) {
      issue {
        type
      }
    }
  }
`
