import React, { forwardRef, useState } from 'react'
import styled from 'styled-components'
import { Controller } from 'react-hook-form'
import { TextField, InputAdornment, IconButton } from '@mui/material'
import { AutoCompleteType, IFormError, InputRules } from '../../types/FormTypes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { themeColors } from '../../const/colors'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const Input: React.FC<{
  label: string
  name: `${string}`
  control: any
  errors: IFormError | any
  rules?: InputRules
  defaultValue?: string
  message?: string
  autoCompleteType?: AutoCompleteType
  InputProps?: any
  disabled?: boolean
  [k: string]: any
}> = forwardRef(
  (
    {
      label,
      name,
      control,
      errors,
      rules,
      defaultValue = '',
      message = '',
      autoCompleteType,
      children,
      InputProps,
      ...rest
    },
    ref?: React.Ref<HTMLInputElement>
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const isPasswordInput = autoCompleteType === 'password'

    const handleClickShowPassword = () => {
      setIsPasswordVisible(!isPasswordVisible)
    }
    const handleMouseDownPassword = (event: any) => {
      event.preventDefault()
    }

    return (
      <>
        <Controller
          control={control}
          name={name}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field: { onChange, onBlur, value } }) => (
            <InputContainer
              $isSpacedBelow={!(message.length || errors[name]?.message)}
            >
              <InputCustom
                ref={ref}
                variant="outlined"
                label={label}
                value={value}
                type={
                  !isPasswordVisible && isPasswordInput ? 'password' : 'text'
                }
                onChange={onChange}
                onWheel={(e: any) => e.target.blur()}
                onBlur={onBlur}
                error={!!errors[name]}
                autoComplete={autoCompleteType}
                color={'primary'}
                fullWidth={true}
                {...rest}
                InputProps={
                  isPasswordInput
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {isPasswordVisible ? (
                                <FontAwesomeIcon
                                  icon={faEye as IconProp}
                                  size="sm"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faEyeSlash as IconProp}
                                  size="sm"
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }
                    : InputProps
                    ? InputProps
                    : undefined
                }
              />
            </InputContainer>
          )}
        />
        {errors[name]?.message ? (
          <InputMessage color={themeColors.error}>
            {errors[name].message}
          </InputMessage>
        ) : (
          !!message.length && (
            <InputMessage color="rgba(0,0,0,0.6)">{message}</InputMessage>
          )
        )}
      </>
    )
  }
)
const InputCustom = styled(TextField)`
  background-color: ${themeColors.white};
  /* max-width: 500px; */
`

const InputContainer = styled.div<{ $isSpacedBelow: boolean }>`
  ${(props) => props.$isSpacedBelow && 'margin-bottom: 16px;'}
`
const InputMessage = styled.p<{ color: string }>`
  color: ${(props) => props.color};
  font-size: 14px;
  margin: 0px;
  padding-left: 16px;
  padding-top: 5px;
  padding-bottom: 9px;
`

export default Input
