import React from 'react'
import { ToastContainer } from 'react-toastify'

export const Toast = () => (
  <ToastContainer
    position="bottom-center"
    autoClose={3000}
    hideProgressBar={true}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable={false}
    pauseOnHover={false}
    style={{ width: '600px' }}
  />
)
