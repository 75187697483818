import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { ApolloError, useMutation } from '@apollo/client'
import { Grid } from '@mui/material'
import { BaseButton } from '../Shared/BaseButton'
import Input from '../Shared/Input'
import {
  CREATE_HID_CONFIG,
  ICreateHIDConfigData,
  ICreateHIDConfigVars,
} from '../../graphql/hid/createHIDConfig'
import {
  APPEND_LOG,
  IAppendLogVars,
  IAppendLogData,
} from '../../graphql/shared/appendLog'
import { TenantRow } from '../../screens/Tenants/TenantsList'
import ErrorContainer from '../Shared/ErrorContainer'

type FormData = {
  limit: number
}

const AddTenantLimit = ({
  tenant,
  refetch,
}: {
  tenant: TenantRow
  refetch: (v: any) => void
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const { t } = useTranslation(['tenant_access_account'])
  const { id, name, slug } = tenant

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  const onCreateHIDConfigCompleted = (data: ICreateHIDConfigData) =>
    appendLog({
      variables: {
        objectId: id,
        message: 'Limit added',
        parameters: 'name=operation|value=access_card.setup.limit',
      },
    })

  const [createHIDConfig] = useMutation<
    ICreateHIDConfigData,
    ICreateHIDConfigVars
  >(CREATE_HID_CONFIG, {
    onCompleted: onCreateHIDConfigCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  const [appendLog] = useMutation<IAppendLogData, IAppendLogVars>(APPEND_LOG, {
    onCompleted: () => refetch({ variables: { name } }),
  })

  const onSubmit = (data: FormData) =>
    createHIDConfig({
      variables: {
        tenant: slug,
        limit: Number(data.limit),
      },
    })

  return (
    <>
      <Text>{t('set_limit')}</Text>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label={t('card_limit')}
          name="limit"
          control={control}
          errors={errors}
          rules={{ required: `${t('form:field_required')}` }}
          data-e2e="set-hid-limit"
        />
        <ButtonWrapper
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <BaseButton type="submit" data-e2e="hid-limit-submit">
            {t('btn:save')}
          </BaseButton>
        </ButtonWrapper>
      </Form>
      {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
    </>
  )
}

export default AddTenantLimit

const ButtonWrapper = styled(Grid)`
  padding: 16px;
`
const Form = styled.form`
  padding: 16px 0;
`
const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  margin: 16px 0 0;
`
