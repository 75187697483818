import styled from 'styled-components'
import { FormControl, MenuItem } from '@mui/material'
import Select from '@mui/material/Select'

const SimpleSelect = ({
  value,
  setValue,
  options,
  variant = 'outlined',
}: {
  value?: string
  setValue: (v: string) => void
  options: {
    value: string
    label: string
  }[]
  variant: 'outlined' | 'filled' | 'standard' | undefined
}) => {
  const handleChange = (event: any) => {
    setValue(event.target.value as string)
  }

  return (
    <FormControl data-e2e="simple-select">
      <SelectStyled
        labelId="select-label"
        value={value}
        variant={variant}
        onChange={handleChange}
        MenuProps={{
          sx: {
            '& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper':
              {
                maxHeight: 350,
              },
          },
        }}
      >
        {options.map(({ label, value }, i) => (
          <MenuItem key={i} value={value}>
            {label}
          </MenuItem>
        ))}
      </SelectStyled>
    </FormControl>
  )
}

export default SimpleSelect

const SelectStyled = styled(Select)`
  width: 150px;
  max-height: 150px;
`
