import { useMemo, ReactNode, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2localeString,
  dateIso2ExcelFormat,
} from '../../utils/formatDate'
import { IPlace } from '../../graphql/parking/queryParkingPlaceReservations'
import { ReservationStatus } from '../../graphql/shared/sharedTypes'
import { ILog } from '../../graphql/shared/sharedTypes'
import { getReservationStatusColor } from '../../utils/getStatusColor'
import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import { TextBody1Bold } from '../Styles/TextCustom'
import { themeColors } from '../../const/colors'
import styled from 'styled-components'
import { BaseButton } from '../Shared/BaseButton'
import { getCurrentReservation } from '../../utils/getCurrentReservation'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface PlaceReservationRow {
  id: string
  parking?: string
  level?: string
  place: string
  shared: string
  status: ReservationStatus
  leaseTerm: string
  validFromCSV?: string
  validToCSV?: string
  company: string
  tenant?: string
  info: {
    name: string
    message: string
  }
  building: string
  logs: ILog[]
}

const PlaceReservationsTable = ({
  places,
  children,
  status,
  rangeFrom,
  setOpenedEditForm,
}: {
  places: IPlace[]
  children: ReactNode
  status: ReservationStatus | ''
  rangeFrom: Date | null
  setOpenedEditForm: (v: PlaceReservationRow[]) => void
}) => {
  const { t, i18n } = useTranslation(['parking_places'])
  const [hideNumbers, setHideNumbers] = useState(false)
  const [selectedPlaces, setSelectedPlaces] = useState<PlaceReservationRow[]>(
    []
  )
  const lang = i18n.language

  const invisibleColumns = {
    id: false,
    place: !hideNumbers,
    validFromCSV: false,
    validToCSV: false,
  }

  const csvHeaders = [
    {
      label: 'ID',
      key: 'id',
    },
    {
      label: 'Parking',
      key: 'parking',
    },
    {
      label: t('level'),
      key: 'level',
    },
    {
      label: t('place'),
      key: 'place',
    },
    {
      label: t('shared'),
      key: 'shared',
    },
    {
      label: 'Status',
      key: 'status',
    },
    {
      label: t('from'),
      key: 'validFromCSV',
    },
    {
      label: t('to'),
      key: 'validToCSV',
    },
    {
      label: t('company'),
      key: 'company',
    },
    {
      label: t('tenant'),
      key: 'tenant',
    },
  ]

  const handleSelectRows = useCallback(
    (selectedRowsData: PlaceReservationRow[]) => {
      setSelectedPlaces(selectedRowsData)
    },
    []
  )

  const handleOpenEditForm = () => setOpenedEditForm(selectedPlaces)

  const columns: ColumnDef<PlaceReservationRow, any>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'parking',
        header: 'Parking',
      },
      {
        accessorKey: 'level',
        header: t('level'),
      },
      {
        accessorKey: 'place',
        header: t('place'),
      },
      {
        accessorKey: 'shared',
        header: t('shared'),
        meta: {
          filterVariant: 'select',
          options: [
            { label: 'Tak', value: true },
            { label: 'Nie', value: false },
          ],
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        cell: (value) => t(`reservation_status:${value.getValue()}`),
        meta: {
          filterVariant: 'select',
          options: [
            {
              value: ReservationStatus.available,
              label: t(`reservation_status:${ReservationStatus.available}`),
            },
            {
              value: ReservationStatus.rented,
              label: t(`reservation_status:${ReservationStatus.rented}`),
            },
            {
              value: ReservationStatus.reserved,
              label: t(`reservation_status:${ReservationStatus.reserved}`),
            },
            {
              value: ReservationStatus.cancelled,
              label: t(`reservation_status:${ReservationStatus.cancelled}`),
            },
          ],
        },
      },
      {
        accessorKey: 'leaseTerm',
        header: t('lease_term'),
      },
      {
        accessorKey: 'validFromCSV',
        header: t('from'),
      },
      {
        accessorKey: 'validToCSV',
        header: t('to'),
      },
      {
        accessorKey: 'company',
        header: t('company'),
      },
      {
        accessorKey: 'tenant',
        header: t('tenant'),
      },
    ],
    [t]
  )

  const tableData: PlaceReservationRow[] = useMemo(() => {
    return (
      places.map(
        ({
          id,
          name,
          parking,
          level,
          building,
          tenant,
          info,
          assignedTo,
          shared,
          reservations,
          logs,
        }) => {
          const currentReservation = getCurrentReservation(reservations)
          return {
            id,
            parking: parking?.name,
            level: level?.name,
            place: name,
            shared: shared ? t('generic:yes') : t('generic:no'),
            status: currentReservation
              ? currentReservation.status
              : ReservationStatus.available,
            leaseTerm: currentReservation
              ? `${dateIso2localeString(currentReservation.validFrom, lang, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })} - ${dateIso2localeString(currentReservation.validTo, lang, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}`
              : '-',
            validFromCSV:
              currentReservation &&
              dateIso2ExcelFormat(currentReservation.validFrom),
            validToCSV:
              currentReservation &&
              dateIso2ExcelFormat(currentReservation.validTo),
            company: tenant?.name || '-',
            tenant: assignedTo
              ? `${assignedTo.firstName} ${assignedTo.lastName}`
              : '-',
            building: building?.name,
            info,
            logs,
          }
        }
      ) || []
    )
  }, [places, lang, t])

  return (
    <Table
      columns={columns}
      data={tableData}
      columnVisibility={invisibleColumns}
      enableRowSelection
      csvExport
      sortByKey="parking"
      onSelectionChange={handleSelectRows}
      csvHeaders={csvHeaders}
      getCellProps={({ column, row }) =>
        column.id === 'status'
          ? {
              style: {
                color: getReservationStatusColor(row.original.status),
                fontWeight: 600,
              },
            }
          : {}
      }
    >
      {children}
      <Wrapper>
        <FormControlLabel
          control={
            <Checkbox
              checked={hideNumbers}
              onChange={() => setHideNumbers(!hideNumbers)}
            />
          }
          label={`${t('hide_numbers')}`}
        />
      </Wrapper>
      {!!selectedPlaces.length && (
        <RowContainer>
          <TextBody1Bold color={themeColors.primary}>{`${
            selectedPlaces.length
          } ${t('generic:selected')}`}</TextBody1Bold>
          {status && rangeFrom && (
            <ButtonStyled onClick={handleOpenEditForm}>
              {t('change_status')}
            </ButtonStyled>
          )}
        </RowContainer>
      )}
    </Table>
  )
}

export default PlaceReservationsTable

const RowContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
`
const ButtonStyled = styled(BaseButton)`
  margin-left: 16px;
`
const Wrapper = styled.div`
  padding: 16px 20px;
`
