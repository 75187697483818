import gql from 'graphql-tag'

export interface IGetExistingRolesData {
  queryUserRole: { name: string; description: string; group: string }[]
}

export const GET_EXISTING_ROLES = gql`
  query queryExistingRoles {
    queryUserRole(order: { asc: name }) {
      name
      description
      group
    }
  }
`
