import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IFormError } from '../../types/FormTypes'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '@mui/material'
import { themeColors } from '../../const/colors'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Input from './CustomValidationInput'
import { styled as styledMaterial } from '@mui/material/styles'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type Type = 'radiobutton' | 'checkbox' | 'input' | 'textarea'

const InputStyled = styledMaterial(Input)({
  width: 410,
  margin: '8px 8px 8px 16px;',
})

const SurveyOption = ({
  control,
  errors,
  type,
  name,
  nameEn,
  index,
  remove,
  questionIndex,
}: {
  control: any
  errors: IFormError
  type: Type
  name: string
  nameEn: string
  index: number
  remove: (i: any) => void
  questionIndex: number
}) => {
  const { t } = useTranslation(['survey_form'])
  const inputTypes = ['input', 'textarea']

  return (
    <Row>
      {type === 'radiobutton' && <RadioIcon />}
      {type === 'checkbox' && <CheckboxIcon />}
      <InputWrapper>
        <InputStyled
          label={t('option')}
          control={control}
          errors={errors}
          name={name}
          rules={{ required: `${t('form:field_required')}` }}
          errorIndex={questionIndex}
          errorName={'name'}
          option={index}
          data-e2e={`survey-input-reply-name${questionIndex}${index}`}
        />
        <InputStyled
          label={t('option_en')}
          control={control}
          errors={errors}
          name={nameEn}
          rules={{ required: `${t('form:field_required')}` }}
          errorIndex={questionIndex}
          errorName={'nameEn'}
          option={index}
          data-e2e={`survey-input-reply-name-en${questionIndex}${index}`}
        />
      </InputWrapper>
      {!inputTypes.includes(type) && (
        <Button
          size="small"
          color="inherit"
          onClick={() => remove(index)}
          data-e2e="survey-remove-reply"
        >
          <FontAwesomeIcon icon={faTrash as IconProp} size="lg" />
        </Button>
      )}
    </Row>
  )
}

export default SurveyOption
const Row = styled.div`
  padding: 5px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 500px;
`
const Button = styled(IconButton)`
  margin: 16px 0 0;
  width: 30px;
  align-self: flex-start;
`
const RadioIcon = styled(RadioButtonUncheckedIcon)`
  margin-top: 24px;
  color: ${themeColors.gray};
  align-self: flex-start;
`
const CheckboxIcon = styled(CheckBoxOutlineBlankIcon)`
  margin-top: 24px;
  color: ${themeColors.gray};
  align-self: flex-start;
`
const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
