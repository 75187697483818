import { Button } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const EventSalesSystem = () => {
  const { t } = useTranslation(['events'])
  return (
    <Container>
      <Link
        href={'https://event-tickets.oliviacentre.com/admin/login'}
        target="_blank"
      >
        <ButtonStyled color="primary" variant="outlined" size="large">
          {t('sales_system')}
        </ButtonStyled>
      </Link>
    </Container>
  )
}

export default EventSalesSystem

const Link = styled.a`
  text-decoration: none;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const ButtonStyled = styled(Button)`
  width: 200px;
  margin-bottom: 16px;
`
