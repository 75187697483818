import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IDisableUserVars {
  login: string
  disable: boolean
}

export interface IDisableUserData {
  disableUser: IQueryBasicData
}

export const DISABLE_USER = gql`
  mutation disableUser($login: String!, $disable: Boolean!) {
    disableUser: DisableUser(login: $login, disable: $disable) {
      status
      code
      message
      error
    }
  }
`
