import React, { useState } from 'react'
import Input from '../Shared/Input'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { DialogActions } from '@mui/material'
import {
  CREATE_TENANT,
  ITenantCreateVars,
  ITenantCreateData,
} from '../../graphql/tenant/createTenant'
import {
  TENANT_DOMAIN_ASSIGN,
  ITenantDomainAssignData,
  ITenantDomainAssignVars,
} from '../../graphql/tenant/tenantDomainAssign'
import { ApolloError, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { BaseTextButton } from '../Shared/BaseButton'
import DomainsContainer from './DomainsContainer'
import ErrorContainer from '../Shared/ErrorContainer'

const AddTenantForm = ({
  handleClose,
  handleUpdateTenants,
}: {
  handleClose: () => void
  handleUpdateTenants: () => void
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [domains, setDomains] = useState<string[]>([])
  const { t } = useTranslation(['form', 'btn', 'tenant'])
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()

  const tenant = watch('company')
  const successNotify = (message: string) => toast.dark(message)

  const onSubmit = (data: any) => {
    tenantCreate({
      variables: {
        name: data.company,
      },
    })
  }

  const onTenantCreateCompleted = (data: ITenantCreateData) => {
    if (data.tenantCreate.status) {
      successNotify(`${t('tenant:add_tenant_success')}`)
      setErrorMessage('')
      if (domains.length) {
        domains.forEach((domain) => {
          tenantDomainAssign({
            variables: {
              tenant,
              domain,
            },
          })
        })
      } else {
        handleUpdateTenants()
        handleClose()
      }
    } else setErrorMessage(t(`code:${data.tenantCreate.code}`))
  }

  const onDomainAssignCompleted = (data: ITenantDomainAssignData) => {
    if (data.tenantDomainAssign.status) {
      handleUpdateTenants()
      handleClose()
    } else setErrorMessage(t(`code:${data.tenantDomainAssign.code}`))
  }

  const [tenantCreate] = useMutation<ITenantCreateData, ITenantCreateVars>(
    CREATE_TENANT,
    {
      onCompleted: onTenantCreateCompleted,
      onError: (error: ApolloError) => setErrorMessage(error.message),
    }
  )

  const [tenantDomainAssign] = useMutation<
    ITenantDomainAssignData,
    ITenantDomainAssignVars
  >(TENANT_DOMAIN_ASSIGN, {
    onCompleted: onDomainAssignCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  return (
    <>
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            label={t('company_name')}
            name="company"
            control={control}
            errors={errors}
            rules={{ required: `${t('field_required')}` }}
            autoCapitalize="none"
            data-e2e="domain-input"
          />
        </form>
        <DomainsContainer domains={domains} setDomains={setDomains} />
      </FormContainer>
      {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
      <CustomActions>
        <BaseTextButton onClick={handleClose}>{t('btn:cancel')}</BaseTextButton>
        <BaseTextButton onClick={handleSubmit(onSubmit)} data-e2e="submit">
          {t('btn:save')}
        </BaseTextButton>
      </CustomActions>
    </>
  )
}

export default AddTenantForm

const FormContainer = styled.div`
  padding: 25px;
  width: 377px;
`
const CustomActions = styled(DialogActions)`
  background-color: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1px 16px;
`
