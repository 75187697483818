import gql from 'graphql-tag'
import { ParkingPlaceStatus } from './queryParkingPlace'

export interface IChangeParkingPlaceStatusVars {
  ids: string[]
  newStatus: ParkingPlaceStatus
  comment: string
}

export const CHANGE_PARKING_PLACE_STATUS = gql`
  mutation changeParkingPlaceStatus(
    $ids: [ID!]
    $newStatus: ParkingPlaceStatus!
    $comment: String
  ) {
    updateParkingPlace(
      input: {
        filter: { id: $ids }
        set: { status: $newStatus, logs: { message: $comment } }
      }
    ) {
      numUids
    }
  }
`
