import { DatePicker as Picker } from '@mui/x-date-pickers/DatePicker'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'

const DateRangeFilter = ({
  rangeFrom,
  rangeTo,
  setRangeFrom,
  setRangeTo,
  labelFrom,
  labelTo,
}: {
  rangeFrom: Date | null
  setRangeFrom: (value: Date | null) => void
  rangeTo: Date | null
  setRangeTo: (value: Date | null) => void
  labelFrom?: string
  labelTo?: string
}) => {
  const { t } = useTranslation(['lockers'])

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Picker
          label={labelFrom ? labelFrom : t('from')}
          value={dayjs(rangeFrom)}
          onChange={(date: Dayjs | null) =>
            setRangeFrom(date?.toDate() || null)
          }
          format="DD.MM.YYYY"
        />
      </Grid>
      <Grid item>
        <Picker
          label={labelTo ? labelTo : t('to')}
          value={dayjs(rangeTo)}
          onChange={(date: Dayjs | null) => setRangeTo(date?.toDate() || null)}
          disabled={!rangeFrom}
          format="DD.MM.YYYY"
        />
      </Grid>
    </Grid>
  )
}

export default DateRangeFilter
