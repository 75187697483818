import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormError } from '../../types/FormTypes'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import Input from '../Shared/Input'
import { TextBody2, TextBody1Bold } from '../Styles/TextCustom'
import TenantSelect from '../Shared/TenantSelect'
import LeaseTermPickers from '../Lockers/LeaseTermPickers'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'

const RentLockerInputs: FC<{
  control: any
  errors: IFormError | any
}> = ({ control, errors }) => {
  const { t } = useTranslation(['lockers'])

  return (
    <>
      <DetailsWrapper>
        <TextBody1Bold>{t('lease_term')}:</TextBody1Bold>
        <LeaseTermPickers control={control} errors={errors} />
      </DetailsWrapper>
      <DetailsWrapper>
        <TextBody1Bold>{t('tenant')}:</TextBody1Bold>
        <TextBody2>{t('tenant_tip')}:</TextBody2>
        <SelectWrapper>
          <TenantSelect control={control} errors={errors} />
        </SelectWrapper>

        <InputStyled
          data-e2e="tenant-name-input"
          label={t('company')}
          name={'tenantName'}
          control={control}
          errors={errors}
        />
        <InputStyled
          data-e2e="email-input"
          label={'Email'}
          name={'tenantEmail'}
          rules={{ required: `${t('form:field_required')}` }}
          control={control}
          errors={errors}
        />
        <InputStyled
          data-e2e="comment-input"
          label={t('notes')}
          name={'comment'}
          control={control}
          errors={errors}
          multiline
          rows={4}
        />
      </DetailsWrapper>
    </>
  )
}

export default RentLockerInputs

const InputStyled = styled(Input)`
  max-width: 640px;
`
const SelectWrapper = styled(Grid)`
  padding: 8px 0 16px;
  max-width: 640px;
`
