import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import {
  APPEND_LOG,
  IAppendLogVars,
  IAppendLogData,
} from '../../graphql/shared/appendLog'
import ErrorContainer from '../Shared/ErrorContainer'
import { FormData } from './SubscriptionActions'
import { BaseTextButton } from '../Shared/BaseButton'

const AddLog = ({
  id,
  refetch,
  handleSubmit,
}: {
  id: string
  refetch: () => void
  handleSubmit: any
}) => {
  const { t } = useTranslation()
  const successNotify = (message: string) => toast.dark(message)
  const [error, setError] = useState('')

  const onCompleted = (data: IAppendLogData) => {
    const { status, error } = data.appendLog
    if (status) {
      successNotify(`${t('generic:success_message')}`)
      refetch()
    } else {
      error && setError(error)
    }
  }

  const [appendLog] = useMutation<IAppendLogData, IAppendLogVars>(APPEND_LOG, {
    onCompleted,
    fetchPolicy: 'no-cache',
  })

  const onSubmit = (data: FormData) => {
    const { comment } = data
    appendLog({
      variables: {
        objectId: id,
        message: comment,
      },
    })
  }

  return (
    <>
      <BaseTextButton onClick={handleSubmit(onSubmit)} data-e2e="submit-log">
        {t('btn:add_comment')}
      </BaseTextButton>

      {!!error && <ErrorContainer errorMessage={error} />}
    </>
  )
}

export default AddLog
