import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import styled from 'styled-components'
import {
  DELETE_ACCOUNT_REQUESTS_REPORT,
  IDeleteAccountRequestsReportData,
} from '../../graphql/userIssues/deleteAccountRequestsReport'
import { Typography } from '@mui/material'
import { getAccountStatusColor } from '../../utils/getStatusColor'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface Row {
  date: string
  dateCSV: string
  resident: string
  tenant: string
  accountStatus: string
}

const DeleteAccountRequestsList = () => {
  const { t, i18n } = useTranslation(['columns', 'admin_menu'])
  const lang = i18n.language

  const { data, loading } = useQuery<IDeleteAccountRequestsReportData>(
    DELETE_ACCOUNT_REQUESTS_REPORT,
    {
      fetchPolicy: 'no-cache',
    }
  )

  const invisibleColumns = {
    dateCSV: false,
  }

  const csvHeaders = [
    {
      label: t('creation_date'),
      key: 'dateCSV',
    },
    {
      label: t('resident'),
      key: 'resident',
    },
    {
      label: t('tenant'),
      key: 'tenant',
    },
    {
      label: t('account_status'),
      key: 'accountStatus',
    },
  ]

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        header: t('creation_date'),
        accessorKey: 'date',
        cell: (value) => dateIso2localeString(value.getValue(), lang),
        sortingFn: 'datetime',
      },
      {
        header: t('creation_date'),
        accessorKey: 'dateCSV',
      },
      {
        header: t('resident'),
        accessorKey: 'resident',
      },
      {
        header: t('tenant'),
        accessorKey: 'tenant',
      },
      {
        header: t('account_status'),
        accessorKey: 'accountStatus',
        cell: (value) => t(`account_status:${value.getValue()}`),
      },
    ],
    [t, lang]
  )

  const tableData: Row[] = useMemo(
    () =>
      data?.queryIssue.map(
        ({ createdAt, user: { tenant, disabled, anonymizeStatus } }) => ({
          date: createdAt,
          dateCSV: dateIso2ExcelFormat(createdAt),
          resident: !!tenant?.name ? t('bool:yes') : t('bool:no'),
          tenant: tenant?.name || '-',
          accountStatus: disabled
            ? 'disabled'
            : !!anonymizeStatus
            ? 'anonimized'
            : 'active',
        })
      ) || [],
    [data, t]
  )

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {t('admin_menu:delete_account_requests')}
      </TypographyStyled>
      <Table
        columns={columns}
        data={tableData}
        csvExport
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvHeaders={csvHeaders}
        getCellProps={({ column, row }) =>
          column.id === 'accountStatus'
            ? {
                style: {
                  color: getAccountStatusColor(row.original.accountStatus),
                  fontWeight: 600,
                },
              }
            : {}
        }
        sortByKey="date"
        sortDesc
      />
    </>
  )
}

export default DeleteAccountRequestsList

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
`
