import gql from 'graphql-tag'
import { IGetResponseJob } from './querySyncJobs'

export interface IGetResponseJobSubscriptionVars {
  id: string
}

export interface IGetResponseJobSubscriptionData {
  getGetResponseJob: IGetResponseJob
}

export const GETRESPONSE_JOB_SUBSCRIPTION = gql`
  subscription getGetResponseJob($id: ID!) {
    getGetResponseJob(id: $id) {
      id
      createdAt: created_at
      startedAt: started_at
      finishedAt: finished_at
      status
      recordsTotal: records_total
      recordsProcessed: records_processed
      recordsCreated: records_created
      recordsUpdated: records_updated
      recordsDeleted: records_deleted
    }
  }
`
