import { gql } from '@apollo/client'

export interface IGetTenantVars {
  name: string
}

export interface IGetTenantData {
  getTenant: {
    parkingPool: {
      id: string
    }
  }
}

export const GET_TENANT_PARKING_POOL = gql`
  query getTenant($name: String) {
    getTenant(name: $name) {
      parkingPool: parking_pool {
        id
      }
    }
  }
`
