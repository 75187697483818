import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { ApolloError, useMutation } from '@apollo/client'
import {
  CANCEL_USER_LOCKER_ORDER,
  ICancelUserLockerOrderVars,
  ICancelUserLockerOrderData,
} from '../../graphql/lockers/cancelUserLockerOrder'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import { BaseButton } from '../Shared/BaseButton'
import { themeColors } from '../../const/colors'
import styled from 'styled-components'
import ErrorContainer from '../Shared/ErrorContainer'

const CancelLockerReservation = ({
  closeDrawer,
  user,
  login,
  refetch,
}: {
  closeDrawer: () => void
  user: string
  login?: string
  refetch: () => void
}) => {
  const { t } = useTranslation(['lockers'])
  const successNotify = (message: string) => toast.dark(message)
  const [openModal, setOpenModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const onCompleted = (data: any) => {
    successNotify(`${t('generic:success_message')}`)
    refetch()
    closeDrawer()
  }

  const [cancelLockerReservation, { loading }] = useMutation<
    ICancelUserLockerOrderData,
    ICancelUserLockerOrderVars
  >(CANCEL_USER_LOCKER_ORDER, {
    onCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  if (!user || !login) {
    return null
  }
  const onSubmit = () => cancelLockerReservation({ variables: { login } })

  return (
    <Container>
      <BaseButton
        customColor={themeColors.negative}
        onClick={() => setOpenModal(true)}
        loading={loading}
      >
        {t('cancel_reservation')}
      </BaseButton>
      {!!errorMessage && <ErrorContainer errorMessage={errorMessage} />}
      <BaseModal
        confirmText={'btn:confirm'}
        cancelText={'btn:exit'}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleAccept={onSubmit}
      >
        <ModalContent>{`${t(
          'cancel_reservation_confirmation'
        )} ${user}?`}</ModalContent>
      </BaseModal>
    </Container>
  )
}

export default CancelLockerReservation

const Container = styled.div`
  padding: 16px 0;
`
