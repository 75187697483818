import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Grid, IconButton, Tooltip } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { themeColors } from '../../const/colors'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IBanner, BannerTargetType } from '../../graphql/banner/queryBanner'
import BannerVisibility from './BannerVisibility'
import DeleteBanner from './DeleteBanner'
import BannerPreview from './BannerPreview'
import { TextBody2Medium } from '../../components/Styles/TextCustom'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface Row {
  id: string
  name: string
  visible: boolean
  preview: boolean
  target: string
  url: string
  urlEn: string
  src: string
  srcEn: string
  residentOnly: boolean
  type: BannerTargetType
}

const BannerTable = ({
  banners,
  setEditBanner,
  refetch,
}: {
  banners?: IBanner[]
  setEditBanner: (v: Row) => void
  refetch: () => void
}) => {
  const { t } = useTranslation(['banner'])

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        header: t('name'),
        accessorKey: 'name',
      },
      {
        header: 'Id',
        accessorKey: 'id',
      },
      {
        header: t('target'),
        accessorKey: 'target',
      },
      {
        header: 'Status',
        accessorKey: 'visible',
        cell: (value) => (
          <BannerVisibility
            banners={banners}
            bannerId={value.row.original.id}
            refetch={refetch}
          />
        ),
        enableColumnFilter: false,
      },
      {
        header: t('preview'),
        accessorKey: 'preview',
        cell: (value) => (
          <BannerPreview
            bannerId={value.row.original.id}
            banners={banners || []}
            refetch={refetch}
          />
        ),
        enableColumnFilter: false,
      },
      {
        accessorKey: 'actions',
        header: '',
        cell: (value) => (
          <ActionsWrapper
            container
            direction="row"
            justifyContent="space-between"
          >
            <Tooltip title={`${t('btn:edit')}`}>
              <IconButton
                size="small"
                onClick={() => setEditBanner(value.row.original)}
                data-e2e="edit-banner"
              >
                <FontAwesomeIcon
                  icon={faEdit as IconProp}
                  size="1x"
                  color={themeColors.primary}
                />
              </IconButton>
            </Tooltip>
            <DeleteBanner
              refetch={refetch}
              bannerId={value.row.original.id}
              visible={value.row.original.visible}
            />
          </ActionsWrapper>
        ),
        enableColumnFilter: false,
      },
    ],
    [t, banners, refetch]
  )

  const tableData: Row[] = useMemo(() => {
    return (
      banners?.map(
        ({
          id,
          name,
          residentOnly,
          visible,
          preview,
          url,
          urlEn,
          src,
          srcEn,
          type,
        }) => ({
          id,
          name,
          target: residentOnly ? t('residents') : t('all'),
          visible,
          preview,
          url,
          urlEn,
          src,
          srcEn,
          type,
          residentOnly,
        })
      ) || []
    )
  }, [banners, t])

  return (
    <>
      <Table columns={columns} data={tableData} enableRowSelection>
        <HintWrapper>
          <TextBody2Medium color={themeColors.gray}>
            {t('preview_hint')}
          </TextBody2Medium>
        </HintWrapper>
      </Table>
    </>
  )
}

export default BannerTable

const ActionsWrapper = styled(Grid)`
  padding-right: 8px;
  width: 80px;
`
const HintWrapper = styled(Grid)`
  padding: 16px 0;
`
