import { useTranslation } from 'react-i18next'
import BasicSelect, { OptionType } from '../Shared/BasicSearchableSelect'
import { ReservationStatus } from '../../graphql/shared/sharedTypes'

const LockerReservationStatusSelect = ({
  control,
  errors,
  defaultValue,
}: {
  control: Object
  errors: Object
  defaultValue?: ReservationStatus
}) => {
  const { t } = useTranslation(['lockers'])

  const options: OptionType[] = [
    {
      value: ReservationStatus.rented,
      label: `${t('rented_a')}`,
    },

    {
      value: ReservationStatus.reserved,
      label: `${t('reserved_a')}`,
    },
  ]

  return (
    <div data-e2e="status-select">
      <BasicSelect
        options={options}
        control={control}
        errors={errors}
        name={'status'}
        rules={{ required: `${t('form:field_required')}` }}
        label={'Wybierz status'}
        defaultValue={defaultValue}
      />
    </div>
  )
}

export default LockerReservationStatusSelect
