import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  IAcceptance,
  GET_MY_ACCEPTANCES,
  IGetMyAcceptancesData,
} from '../../graphql/users/getMyAcceptances'
import {
  IGetUserVars,
  IGetUsersData,
  GET_USER_MOBILE_PHONE,
} from '../../graphql/users/getUserMobilePhone'
import {
  UPDATE_USER_MOBILE_PHONE,
  IUpdateUserMobilePhoneVars,
} from '../../graphql/users/updateUserMobilePhone'
import { useMutation, ApolloError, useQuery } from '@apollo/client'
import { BaseButton } from '../../components/Shared/BaseButton'
import { useForm, FieldErrors } from 'react-hook-form'
import { CheckboxText } from '../../components/Styles/TextCustom'
import MySolvyRequirementsForm from './MySolvyRequirementsForm'
import { themeColors } from '../../const/colors'
import { CenterContainer } from '../../components/Styles/CustomElementsStyled'
import {
  ACCEPTANCE_CHANGE,
  IAcceptanceChangeVars,
  IAcceptanceChangeData,
} from '../../graphql/users/acceptanceChange'
import FullScreenLoader from '../Shared/FullScreenLoader'
import ErrorContainer from '../Shared/ErrorContainer'

type FormData = {
  mobilePhone: string
  [k: string]: any
}

const MySolvyRequirements: React.FC<{
  login: string
  redirectToMySolvy: () => void
}> = ({ login, redirectToMySolvy }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [acceptances, setAcceptances] = useState<IAcceptance[]>([])
  const [phone, setPhone] = useState('')
  const { t } = useTranslation(['form'])

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  const { loading: getDataLoading } = useQuery<IGetMyAcceptancesData>(
    GET_MY_ACCEPTANCES,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data: IGetMyAcceptancesData) =>
        setAcceptances([
          ...data?.acceptances.acceptances,
          ...data?.acceptances2.acceptances,
        ]),
    }
  )
  const { loading: getPhoneLoading } = useQuery<IGetUsersData, IGetUserVars>(
    GET_USER_MOBILE_PHONE,
    {
      fetchPolicy: 'no-cache',
      variables: { login },
      onCompleted: (data: IGetUsersData) => setPhone(data.getUser.mobilePhone),
    }
  )

  const [updateMobilePhone, { loading: updatePhoneLoading }] =
    useMutation<IUpdateUserMobilePhoneVars>(UPDATE_USER_MOBILE_PHONE, {
      onCompleted: () => redirectToMySolvy(),
      onError: (error: ApolloError) => setErrorMessage(error.message),
    })
  const [changeAcceptances, { loading: acceptancesLoading }] = useMutation<
    IAcceptanceChangeData,
    IAcceptanceChangeVars
  >(ACCEPTANCE_CHANGE)

  const mySolvyConsent = acceptances.find(
    ({ regulation }) =>
      regulation.title === 'Regulamin korzystania z serwisu zgłoszeniowego'
  )?.decision
  const phoneConsent = acceptances.find(
    ({ regulation }) =>
      regulation.title === 'Przetwarzanie numeru telefonu kontaktowego'
  )?.decision

  const goToMySolvy = () => {
    if (mySolvyConsent && phoneConsent && phone) {
      redirectToMySolvy()
    }
  }

  const onSubmit = async (data: FormData) => {
    const { mobilePhone, ...checkboxes } = data
    const acceptancesArray = Object.keys(checkboxes)

    await acceptancesArray.forEach((item) =>
      changeAcceptances({ variables: { decision: 'yes', regulation: item } })
    )
    await updateMobilePhone({
      variables: {
        login,
        mobilePhone,
      },
    })
  }

  useEffect(() => {
    goToMySolvy()
  }, [mySolvyConsent, phoneConsent, phone])

  const onInvalidSubmit = (submitErrors: FieldErrors) => {
    !!Object.keys(submitErrors).length &&
      setErrorMessage(t('form:correct_form'))
  }

  if (
    acceptancesLoading ||
    updatePhoneLoading ||
    getDataLoading ||
    getPhoneLoading ||
    (mySolvyConsent && phoneConsent && phone)
  ) {
    return <FullScreenLoader />
  }

  return (
    <CenterContainer>
      <BlueContainer>
        <Logo>
          {'My'}&nbsp;
          <Color>Solvy</Color>
        </Logo>
        <MySolvyRequirementsForm control={control} errors={errors} />
        <CheckboxText color={themeColors.error}>{`* ${t(
          'required_fields'
        )}`}</CheckboxText>
        <BaseButton
          onClick={handleSubmit(onSubmit, onInvalidSubmit)}
          disabled={updatePhoneLoading}
        >
          {t('btn:go_to_mysolvy')}
        </BaseButton>
        {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
      </BlueContainer>
    </CenterContainer>
  )
}

export default MySolvyRequirements

const BlueContainer = styled.div`
  background-color: ${themeColors.lightBlue};
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  width: 566px;
`
const Color = styled.span`
  font-weight: 800;
  color: ${themeColors.secondary};
`
const Logo = styled.h3`
  font-size: 44px;
  margin: 0 0 30px;
  letter-spacing: 0.75px;
  font-weight: 400;
`
