import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BaseDrawer from '../Shared/BaseDrawer'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import AddParkingPass from './AddParkingPass'
import EditParkingPass from './EditParkingPass'
import { useQuery } from '@apollo/client'
import {
  GET_PARKING_PASSES,
  IParkingPassesData,
  VehicleType,
} from '../../graphql/parking/getParkingPasses'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { themeColors } from '../../const/colors'
import { formatPrice, formatToExcelPrice } from '../../utils/formatPrice'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'

export interface ParkingPassRow {
  parkingId: string
  parkingName: string
  durationDays: number
  name: string
  namePl: string
  nameEn: string
  productId: string
  price: string
  priceCSV: string
  active: boolean
  sellerName: string
  productRegulations: string
  minAdvanceDays: number
  maxAdvanceDays: number
  sort: number
  amanoProductNo: number
  amanoCurrency: string
  amanoSellCard: boolean
  minAdvanceWorkDays: number
  overflowReservationDays: number
  rawPrice: number
  vehicleType: VehicleType
}

const ParkingPassesTable: FC<{
  openedAddForm: boolean
  closeAddForm: () => void
}> = ({ openedAddForm, closeAddForm }) => {
  const { t, i18n } = useTranslation(['parking_subscriptions_products_list'])
  const lang = i18n.language
  const [openedDetails, setOpenedDetails] = useState<ParkingPassRow>()
  const [itemToDelete, setItemToDelete] = useState('')

  const invisibleColumns = {
    productId: false,
    parkingId: false,
    priceCSV: false,
    sellerName: false,
    productRegulations: false,
  }

  const csvHeaders = [
    {
      label: t('product_id'),
      key: 'productId',
    },
    {
      label: 'Parking',
      key: 'parkingName',
    },
    {
      label: t('parking_id'),
      key: 'parkingId',
    },
    {
      label: t('product_name'),
      key: 'namePl',
    },
    {
      label: `${t('product_price')} (PLN)`,
      key: 'priceCSV',
    },
    {
      label: t('active'),
      key: 'active',
    },
    {
      label: t('product_regulations'),
      key: 'productRegulations',
    },
  ]

  const { data, refetch, loading } = useQuery<IParkingPassesData>(
    GET_PARKING_PASSES,
    {
      fetchPolicy: 'no-cache',
    }
  )

  const handleDeleteMenuItem = (id: string) => {
    setItemToDelete('')
  }

  const columns: ColumnDef<ParkingPassRow, any>[] = useMemo(
    () => [
      {
        accessorKey: 'productId',
        header: t('product_id'),
      },
      {
        accessorKey: 'name',
        header: t('product_name'),
      },
      {
        accessorKey: 'parkingId',
        header: t('parking_id'),
      },
      {
        accessorKey: 'parkingName',
        header: t('parking_name'),
      },
      {
        accessorKey: 'durationDays',
        header: t('product_duration'),
      },
      {
        accessorKey: 'price',
        header: t('product_price'),
      },
      {
        accessorKey: 'priceCSV',
        header: t('product_price'),
      },
      {
        accessorKey: 'active',
        header: t('active'),
        cell: (value) => (value.getValue() ? t('bool:yes') : t('bool:no')),
        meta: {
          filterVariant: 'select',
          options: [
            { label: 'Tak', value: true },
            { label: 'Nie', value: false },
          ],
        },
      },
      {
        accessorKey: 'sellerName',
        header: t('seller_company_name'),
      },
      {
        accessorKey: 'productRegulations',
        header: t('product_regulations'),
      },
    ],
    [t]
  )

  const tableData: ParkingPassRow[] = useMemo(
    () =>
      data?.queryParkingPass.map(
        ({
          disabled,
          durationDays,
          id,
          nameEn,
          namePl,
          overflowReservationDays,
          minAdvanceDays,
          maxAdvanceDays,
          parking,
          price,
          regulation: { title, regulationId },
          sort,
          amanoProductNo,
          amanoCurrency,
          amanoSellCard,
          minAdvanceWorkDays,
          seller,
          vehicleType,
        }) => ({
          parkingId: parking.id,
          parkingName: parking.name,
          durationDays,
          name: lang === 'pl' ? namePl : nameEn,
          namePl,
          nameEn,
          productId: id,
          price: formatPrice(price),
          priceCSV: formatToExcelPrice(price),
          active: !disabled,
          sellerName: seller ? seller.name : parking.seller.name,
          productRegulations: title,
          overflowReservationDays,
          minAdvanceDays,
          maxAdvanceDays,
          rawPrice: price,
          regulationId,
          sort,
          amanoProductNo,
          amanoCurrency,
          amanoSellCard,
          minAdvanceWorkDays,
          vehicleType,
        })
      ) || [],
    [data, lang]
  )

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <Table
        columns={columns}
        data={tableData}
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvExport
        csvHeaders={csvHeaders}
        onRowClick={(rowData: ParkingPassRow) => {
          rowData && setOpenedDetails(rowData)
        }}
        getRowProps={(rowData: ParkingPassRow) => ({
          style: {
            background: !rowData.active && themeColors.lighterGray,
          },
        })}
        getCellProps={(cellInfo) =>
          cellInfo.column.id === 'active'
            ? cellInfo.row.original.active
              ? {
                  style: {
                    color: themeColors.success,
                    fontWeight: 600,
                  },
                }
              : {
                  style: {
                    color: themeColors.negative,
                    fontWeight: 600,
                  },
                }
            : {}
        }
      />
      <BaseDrawer open={!!openedDetails} variant={'temporary'}>
        <EditParkingPass
          closeDetails={() => setOpenedDetails(undefined)}
          data={openedDetails}
          updateList={refetch}
        />
      </BaseDrawer>
      <BaseDrawer open={openedAddForm} variant={'temporary'}>
        <AddParkingPass updateList={refetch} closeDetails={closeAddForm} />
      </BaseDrawer>
      <BaseModal
        confirmText={'btn:confirm'}
        open={!!itemToDelete}
        handleClose={() => setItemToDelete('')}
        handleAccept={() => handleDeleteMenuItem(itemToDelete)}
      >
        <ModalContent>{t('menu_item_form:delete_confirm_title')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default ParkingPassesTable
