import gql from 'graphql-tag'

export const UPDATE_MENU_ITEM = gql`
  mutation UpdateMenuItem(
    $id: [ID!]
    $removeAvailability: [AvailabilityRef]
    $visible: Boolean!
    $name: String!
    $nameEn: String
    $description: String
    $descriptionEn: String
    $amount: Int
    $categoryId: String
    $unit: String
    $price: Int
    $dietType: DietType
    $availability: [AvailabilityRef]
  ) {
    updateMenuItem(
      input: {
        filter: { id: $id }
        remove: { availability: $removeAvailability }
        set: {
          visible: $visible
          name: $name
          name_en: $nameEn
          description: $description
          description_en: $descriptionEn
          amount: $amount
          category: { id: $categoryId }
          unit: { name: $unit }
          price: $price
          diet_type: $dietType
          availability: $availability
        }
      }
    ) {
      menuItem {
        id
      }
      numUids
    }
  }
`
