import { gql } from '@apollo/client'
import { IFormRegulation } from '../../types/FormTypes'

export interface IGetMySolvyRegulationsData {
  regulations: IFormRegulation[]
  regulations2: IFormRegulation[]
}

export const GET_MYSOLVY_REGULATIONS_PL = gql`
  query getRegulations {
    regulations: queryRegulation(
      filter: { current: true, service: { eq: "mysolvy" } }
    ) {
      id
      required
      content
    }
    regulations2: queryRegulation(
      filter: {
        service: { eq: "olivia" }
        title: { eq: "Przetwarzanie numeru telefonu kontaktowego" }
      }
    ) {
      id
      required
      content
    }
  }
`
export const GET_MYSOLVY_REGULATIONS_EN = gql`
  query getRegulations {
    regulations: queryRegulation(
      filter: { current: true, service: { eq: "mysolvy" } }
    ) {
      id
      required
      content: content_en
    }
    regulations2: queryRegulation(
      filter: {
        service: { eq: "olivia" }
        title: { eq: "Przetwarzanie numeru telefonu kontaktowego" }
      }
    ) {
      id
      required
      content: content_en
    }
  }
`
