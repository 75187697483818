import gql from 'graphql-tag'
import { ID } from '../shared/IQueryData'

export interface IUpdateParkingPoolVariables {
  id: string
  maxActiveUserCount: number
  maxAdvanceDays: number
  maxDurationDays: number
  maxReservationCountPerUser: number
  placesCountContract: number
  notifyUserNearingReservation: boolean
  reservationNearingInHours?: number
  customEmailMessage?: string
  notifyOfficeManagersNewReservation: boolean
}

export interface IUpdateParkingPoolData {
  updateParkingPool: {
    parkingPool: {
      id: ID
    }
  }
}

export const UPDATE_PARKING_POOL = gql`
  mutation updateParkingPool(
    $id: [ID!]
    $maxActiveUserCount: Int
    $maxAdvanceDays: Int
    $maxDurationDays: Int
    $maxReservationCountPerUser: Int
    $placesCountContract: Int
    $notifyUserNearingReservation: Boolean
    $reservationNearingInHours: Int
    $customEmailMessage: String
    $notifyOfficeManagersNewReservation: Boolean
  ) {
    updateParkingPool(
      input: {
        filter: { id: $id }
        set: {
          max_active_user_count: $maxActiveUserCount
          max_advance_days: $maxAdvanceDays
          max_duration_days: $maxDurationDays
          max_reservation_count_per_user: $maxReservationCountPerUser
          places_count_contract: $placesCountContract
          notify_user_nearing_reservation: $notifyUserNearingReservation
          reservation_nearing_in_hours: $reservationNearingInHours
          custom_email_message: $customEmailMessage
          notify_office_managers_new_reservation: $notifyOfficeManagersNewReservation
        }
      }
    ) {
      numUids
    }
  }
`
