import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IDestroyHIDConfigVars {
  tenant: string
}
export interface IDestroyHIDConfigData {
  destroyHIDConfig: IQueryBasicData
}

export const DESTROY_HID_CONFIG = gql`
  mutation destroyHIDConfig($tenant: String!) {
    destroyHIDConfig(tenant: $tenant) {
      status
      error
      message
      code
    }
  }
`
