import { ApolloError, useMutation } from '@apollo/client'
import {
  IDeleteExtrasVars,
  DELETE_EXTRAS,
} from '../../graphql/extras/deleteExtras'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { themeColors } from '../../const/colors'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { toast } from 'react-toastify'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const DeleteExtras = ({
  id,
  refetch,
  visible,
}: {
  id: string
  refetch: () => void
  visible: boolean
}) => {
  const { t } = useTranslation(['banner'])
  const [openModal, setOpenModal] = useState(false)
  const successNotify = (message: string) => toast.dark(message)
  const errorNotify = (message: string) => toast.error(message)

  const onDeleteCompleted = () => {
    successNotify(t('generic:success_message'))
    refetch()
  }

  const [deleteExtras, { loading }] = useMutation<IDeleteExtrasVars>(
    DELETE_EXTRAS,
    {
      onCompleted: onDeleteCompleted,
      onError: (error: ApolloError) => errorNotify(error.message),
    }
  )
  const handleDeleteBanner = () => {
    deleteExtras({ variables: { id } })
    setOpenModal(false)
  }

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <IconButton
        size="small"
        disabled={visible}
        onClick={() => setOpenModal(true)}
        data-e2e="delete-row"
      >
        <FontAwesomeIcon
          icon={faTrashAlt as IconProp}
          size="1x"
          color={visible ? themeColors.lightGray : themeColors.error}
        />
      </IconButton>
      <BaseModal
        confirmText={'btn:confirm'}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleAccept={() => handleDeleteBanner()}
      >
        <ModalContent>{t('menu_item_form:delete_confirm_title')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default DeleteExtras
