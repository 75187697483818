import { DatePicker as Picker } from '@mui/x-date-pickers/DatePicker'
import { Controller } from 'react-hook-form'
import { IFormError } from '../../types/FormTypes'
import dayjs from 'dayjs'

const DatePickerController = ({
  label,
  disablePast = false,
  control,
  errors,
  name,
  defaultValue,
  rules,
  minDate,
}: {
  label: string
  disablePast?: boolean
  control: any
  errors: IFormError | any
  name: string
  defaultValue?: Date | null
  rules?: Object
  minDate?: Date
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue ? dayjs(defaultValue) : null}
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <Picker
          label={label}
          value={value}
          onChange={onChange}
          minDate={minDate ? dayjs(minDate) : undefined}
          disablePast={disablePast}
          format="DD.MM.YYYY"
          slotProps={{
            textField: {
              error: !!errors[name],
              helperText: !!errors[name]?.message && errors[name]?.message,
            },
          }}
        />
      )}
    />
  )
}

export default DatePickerController
