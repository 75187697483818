import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IFormError } from '../../types/FormTypes'
import styled from 'styled-components'
import { TextBody1 } from '../Styles/TextCustom'
import BasicSelect from '../Shared/BasicSearchableSelect'
import {
  GET_PARKING_LIST,
  IParkingListData,
} from '../../graphql/parking/getParkingList'
import { useQuery } from '@apollo/client'
import RegulationComponent from '../Shared/RegulationComponent'

const ParkingPassInputs: FC<{
  control: any
  errors: IFormError | any
  parkingPass?: any
  watch: any
}> = ({ control, errors, parkingPass, watch }) => {
  const { t } = useTranslation(['parking_subscription_form', 'form'])
  const { data: parking } = useQuery<IParkingListData>(GET_PARKING_LIST)

  return (
    <Container>
      <TextBody1>{'Parking:'}</TextBody1>
      {parking && (
        <div data-e2e={'parking-pass-select-parking'}>
          <BasicSelect
            options={parking.queryParking.map(({ name, id }) => ({
              value: id,
              label: name,
            }))}
            control={control}
            errors={errors}
            label={t('select_parking')}
            name={'parkingId'}
            rules={{ required: `${t('form:field_required')}` }}
            defaultValue={parkingPass?.parkingId}
          />
        </div>
      )}
      <TextBody1>{t('rules')}</TextBody1>
      <RegulationComponent
        regulationId={parkingPass?.regulationId}
        service={'parking'}
        control={control}
        errors={errors}
        watch={watch}
      />
    </Container>
  )
}

export default ParkingPassInputs

const Container = styled.div`
  max-width: 600px;
`
