import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Input from '../Shared/Input'
import { useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import DateTimePikerController from '../Shared/DateTimePickerController'
import { ParkingSubscriptionStatus } from '../../graphql/parking/queryParkingSubscriptions'
import CancelParkingSubscription from './CancelParkingSubscription'
import AddLog from './AddLog'
import { DetailsWrapper } from '../Styles/CustomElementsStyled'
import { checkRole } from '../../utils/checkRole'
import { PARKING_BILLING } from '../../const/permissions'
import { useAppSelector } from '../../redux/store'
import AmanoSync from './AmanoSync'

export type FormData = {
  date: Date
  comment: string
}

const SubscriptionActions = ({
  id,
  refetch,
  status,
  allowSync,
}: {
  id: string
  refetch: () => void
  status: ParkingSubscriptionStatus
  allowSync: boolean
}) => {
  const { t } = useTranslation(['sold_parking_items'])
  const { roles } = useAppSelector((state) => state.user)

  const allowAction =
    checkRole(PARKING_BILLING, roles) &&
    status !== ParkingSubscriptionStatus.cancelled

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  return (
    <DetailsWrapper>
      <Grid>
        {status === ParkingSubscriptionStatus.error && (
          <PickerWrapper>
            <DateTimePikerController
              label={t('sync_date_and_time')}
              name={'date'}
              control={control}
              errors={errors}
              disablePast={true}
            />
          </PickerWrapper>
        )}

        <InputStyled
          label={t('comment')}
          name={'comment'}
          control={control}
          errors={errors}
          rules={{ required: `${t('form:field_required')}` }}
          multiline
          rows={3}
          data-e2e="subscription-action-comment"
        />
      </Grid>
      <Row container spacing={2}>
        <Grid item xs={4}>
          <AddLog id={id} refetch={refetch} handleSubmit={handleSubmit} />
        </Grid>
        {allowAction && (
          <Grid item xs={4}>
            <AmanoSync
              id={id}
              refetch={refetch}
              handleSubmit={handleSubmit}
              allowSync={allowSync}
            />
          </Grid>
        )}
        {allowAction && (
          <Grid item xs={4}>
            <CancelParkingSubscription
              id={id}
              refetch={refetch}
              handleSubmit={handleSubmit}
            />
          </Grid>
        )}
      </Row>
    </DetailsWrapper>
  )
}

export default SubscriptionActions

const Row = styled(Grid)`
  padding: 16px 0;
  wrap: no-wrap;
`
const PickerWrapper = styled(Grid)`
  padding: 16px 0 0;
`
const InputStyled = styled(Input)`
  margin-top: 16px;
`
