import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useFieldArray } from 'react-hook-form'
import AddSurveyOption from './AddSurveyOption'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { themeColors } from '../../const/colors'
import { Button } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '@mui/material'
import { TextButton } from '../Styles/TextCustom'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { TextField } from '@mui/material'
import Select, { OptionType } from './SurveyQuestionSelect'
import Input from './CustomValidationInput'
import { styled as styledMaterial } from '@mui/material/styles'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const InputStyled = styledMaterial(Input)({
  width: 410,
  margin: '0 8px 16px',
  backgroundColor: themeColors.white,
  maxWidth: 500,
})

const IconButtonStyled = styled(IconButton)({
  margin: '16px 0 0',
  width: 30,
  alignSelf: 'flex-start',
})

const SurveyQuestion = ({
  control,
  errors,
  number,
  name,
  nameEn,
  nameSelect,
  questionIndex,
  questionRemove,
  watch,
  setValue,
}: {
  control: any
  errors: any
  number: number
  name: string
  nameEn: string
  nameSelect: string
  questionIndex: number
  questionRemove: (i: any) => void
  watch: any
  setValue: (name: string, value: any) => void
}) => {
  const { t } = useTranslation(['survey_form'])
  const questionTypes: OptionType[] = [
    { value: 'radiobutton', label: `${t('radiobutton')}` },
    { value: 'checkbox', label: `${t('checkbox')}` },
    { value: 'input', label: `${t('input')}` },
    { value: 'textarea', label: `${t('textarea')}` },
  ]

  const inputTypes = ['input', 'textarea']
  const { fields, append, remove } = useFieldArray({
    control,
    name: `questions.${questionIndex}.options`,
  })
  const questionType = watch(nameSelect) || 'radiobutton'
  const otherField = watch(`questions[${questionIndex}].other`)

  const handleAddOther = () =>
    setValue(`questions[${questionIndex}].other`, !otherField)

  const renderIcon = (type: string) =>
    type === 'radiobutton' ? <RadioIcon /> : <CheckboxIcon />

  const renderOptions = () => {
    return fields.map((field: any, index: any) => (
      <AddSurveyOption
        control={control}
        errors={errors}
        type={questionType}
        name={`questions[${questionIndex}].options[${index}].name`}
        nameEn={`questions[${questionIndex}].options[${index}].nameEn`}
        key={field.id}
        index={index}
        remove={remove}
        questionIndex={questionIndex}
      />
    ))
  }
  useEffect(() => {}, [otherField])

  return (
    <Container>
      <Row>
        <Dot>{number}</Dot>
        <InputWrapper>
          <InputStyled
            label={t('question_content')}
            rules={{ required: `${t('form:field_required')}` }}
            name={name}
            control={control}
            errors={errors}
            errorIndex={questionIndex}
            errorName={'name'}
            multiline
            data-e2e={`survey-input-question-name${number}`}
          />
          <InputStyled
            label={t('question_content_en')}
            control={control}
            errors={errors}
            name={nameEn}
            rules={{ required: `${t('form:field_required')}` }}
            errorIndex={questionIndex}
            errorName={'nameEn'}
            multiline
            data-e2e={`survey-input-question-name-en${number}`}
          />
        </InputWrapper>
        <IconButtonStyled
          size="small"
          color="inherit"
          onClick={() => questionRemove(questionIndex)}
          data-e2e="survey-remove-question"
        >
          <FontAwesomeIcon icon={faTrash as IconProp} size="lg" />
        </IconButtonStyled>
      </Row>
      <div data-e2e={`survey-question-type-select${number}`}>
        <Select
          options={questionTypes}
          control={control}
          errors={errors}
          label={t('question_type')}
          name={nameSelect}
          rules={{ required: `${t('form:field_required')}` }}
          errorIndex={questionIndex}
        />
      </div>
      {!inputTypes.includes(questionType) && renderOptions()}
      {inputTypes.includes(questionType) && (
        <TextareaStyled
          label={t('option')}
          name={questionType}
          defaultValue={'Twoja opowiedź'}
          variant="outlined"
          multiline
          fullWidth
          rows={questionType === 'textarea' ? 2 : 1}
          disabled
        />
      )}
      {otherField && (
        <OtherRow>
          {renderIcon(questionType)}
          <TextStyled color={themeColors.primary}>{t('other')}</TextStyled>
        </OtherRow>
      )}
      {!inputTypes.includes(questionType) && (
        <Row>
          <ButtonStyled
            color="primary"
            onClick={() => append({})}
            data-e2e="survey-add-reply"
          >
            <TextButton color={themeColors.primary}>
              {t('add_reply')}
            </TextButton>
          </ButtonStyled>
          <p>{'lub'}</p>
          <ButtonStyled
            color="primary"
            onClick={handleAddOther}
            data-e2e="survey-add-other-reply"
          >
            <TextButton color={themeColors.primary}>{t('other')}</TextButton>
          </ButtonStyled>
        </Row>
      )}
    </Container>
  )
}

export default SurveyQuestion
const Container = styled.div`
  padding: 24px 0 8px;
  border-bottom: 1px solid #bdbdbd;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Dot = styled.div`
  background-color: ${themeColors.primary};
  color: ${themeColors.white};
  min-width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  margin: 13px 0 16px 0;
  font-size: 19px;
  align-self: flex-start;
`

const ButtonStyled = styled(Button)`
  border-radius: 100px;
  padding: 0 16px;
`
const RadioIcon = styled(RadioButtonUncheckedIcon)`
  color: ${themeColors.gray};
`
const CheckboxIcon = styled(CheckBoxOutlineBlankIcon)`
  color: ${themeColors.gray};
`
const TextStyled = styled(TextButton)`
  margin: 0 16px;
  cursor: auto;
`
const OtherRow = styled(Row)`
  padding: 16px 0;
`
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const TextareaStyled = styled(TextField)`
  margin: 16px 0;
  background-color: ${themeColors.white};
  max-width: 500px;
`
