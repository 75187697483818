import gql from 'graphql-tag'
import { IPayment } from '../shared/sharedTypes'

export interface ISeller {
  name: string
  nip: string
  simplifiedInvoice: {
    enabled: boolean
  }
}

export interface ParkingProduct {
  id: string
  parkingPass?: {
    price: number
    name: string
    nameEn: string
    parking: {
      parkingName: string
      seller: ISeller
    }
    seller?: ISeller
  }
  price?: number
  parkingName?: string
  buyer?: {
    city: string
    buyerName: string
    country: string
    nip: string
    street: string
    zipcode: string
  }
  productSeller?: ISeller
  payment: IPayment
  carPlate: string
  carPlate2?: string
  invoiceNeeded: boolean
  validFrom: string
  validTo: string
  user: {
    login: string
    firstName: string
    lastName: string
    accessCardId: string
    tenant?: {
      tenantName: string
    }
  }
  duration?: string
}

export interface IQueryParkingPaymentData {
  queryParkingTicket: ParkingProduct[]
  queryOneTimeParkingTicket: ParkingProduct[]
}

export const QUERY_PARKING_PAYMENT = gql`
  query queryParkingTicket {
    queryParkingTicket @cascade(fields: ["parking_subscription"]) {
      id
      parking_subscription(filter: { type: { eq: Personal } }) {
        type
      }
      buyer {
        city
        buyerName: name
        country
        nip
        street
        zipcode
      }
      payment {
        paymentId: id
        createdAt: created_at
        status
        sandbox
        amount
      }
      carPlate: car_plate
      carPlate2: car_plate_2
      invoiceNeeded: invoice_needed
      parkingPass: parking_pass {
        price
        name
        parking {
          parkingName: name
        }
      }
      validFrom: valid_from
      validTo: valid_to
      user {
        login
        firstName: first_name
        lastName: last_name
        tenant {
          tenantName: name
        }
      }
    }
    queryOneTimeParkingTicket(order: { desc: created_at }) {
      id
      buyer {
        city
        buyerName: name
        country
        nip
        street
        zipcode
      }
      payment {
        paymentId: id
        createdAt: created_at
        status
        sandbox
        amount
      }
      duration
      carPlate: car_plate
      invoiceNeeded: invoice_needed
      parkingName: parking_name
      price
      validFrom: valid_from
      validTo: valid_to
      user {
        login
        firstName: first_name
        lastName: last_name
        tenant {
          tenantName: name
        }
      }
    }
  }
`
