import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'
export interface ITenantDomainAssignVars {
  tenant: string
  domain: string
}
export interface ITenantDomainAssignData {
  tenantDomainAssign: IQueryBasicData
}

export const TENANT_DOMAIN_ASSIGN = gql`
  mutation TenantDomainAssign($tenant: String!, $domain: String!) {
    tenantDomainAssign(tenant: $tenant, domain: $domain) {
      status
      code
      message
      error
    }
  }
`
