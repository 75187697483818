import React, { FC } from 'react'
import { useAppSelector } from '../../redux/store'
import { ListItemIcon } from '@mui/material'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import FAIcon from '../Shared/FAIcon'
import { ROUTES } from '../../const/routes'
import { useTranslation } from 'react-i18next'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useIsRouteSelected } from '../../hooks/useIsRouteSelected'
import {
  MainListItem,
  CustomListItemText,
  LinkStyled,
} from '../Styles/NavListStyle'

interface IOlivkaListItemProps {
  isMenuOpen?: boolean
}

const OlivkaListItem: FC<IOlivkaListItemProps> = ({ isMenuOpen }) => {
  const { t } = useTranslation(['admin_menu'])

  const { isAdmin } = useAppSelector((state) => state.user)
  const { isSelected } = useIsRouteSelected(ROUTES.OLIVKA, !!isMenuOpen)

  if (!isAdmin) {
    return null
  }

  return (
    <MainListItem selected={isSelected}>
      <LinkStyled to={ROUTES.OLIVKA}>
        <ListItemIcon>
          <FAIcon icon={faCode as IconProp} />
        </ListItemIcon>
        <CustomListItemText primary={t('blocking_olivka_products')} />
      </LinkStyled>
    </MainListItem>
  )
}

export default OlivkaListItem
