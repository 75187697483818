import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Chip, CircularProgress } from '@mui/material'
import { useQuery } from '@apollo/client'
import {
  FIND_EMAILS_WITH_DOMAIN,
  IQueryEmailsWithDomainData,
  IQueryEmailsWithDomainVars,
} from '../../graphql/tenant/findEmailsWithDomain'
import { useState } from 'react'

const DomainChip = ({
  domain,
  handleDeleteDomain,
}: {
  domain: string
  handleDeleteDomain: (v: string) => void
}) => {
  const { t } = useTranslation(['form', 'btn', 'tenant', 'generic'])
  const [usersNumberForDomain, setUsersNumberForDomain] = useState<number>()

  const onCompleted = (data: IQueryEmailsWithDomainData) =>
    setUsersNumberForDomain(data.queryEmail.length)

  const { loading } = useQuery<
    IQueryEmailsWithDomainData,
    IQueryEmailsWithDomainVars
  >(FIND_EMAILS_WITH_DOMAIN, {
    onCompleted,
    onError: (e) => console.log(e),
    fetchPolicy: 'no-cache',
    variables: { domain },
  })

  if (loading) {
    return <ChipLoader size={20} />
  }

  return (
    <CustomChip
      color="primary"
      variant="outlined"
      label={`${domain} (${usersNumberForDomain || 0} ${t(
        'admin_menu:users'
      )})`}
      onDelete={() => handleDeleteDomain(domain)}
      data-e2e="domain-chip"
    />
  )
}

export default DomainChip

const CustomChip = styled(Chip)`
  margin: 4px 8px;
`
const ChipLoader = styled(CircularProgress)`
  margin: 4px 20px;
`
