import { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { IQueryFeaturesData, QUERY_FEATURES } from '../graphql/users/features'
import { UserActionTypes } from '../redux/user/types/UserActionTypes'
import { useDispatch } from 'react-redux'
import { IUserState } from '../redux/user/types/IUserState'

export const useFeatures = (): {
  features?: string[]
} => {
  const dispatch = useDispatch()
  const setData = (userData: IUserState) =>
    dispatch({
      type: UserActionTypes.SET_USER_DATA,
      data: userData,
    })
  const [getFeatures, { data }] = useLazyQuery<IQueryFeaturesData>(
    QUERY_FEATURES,
    {
      fetchPolicy: 'no-cache',
      onCompleted: async (data: IQueryFeaturesData) => {
        const fetchedFeatures = await data?.enabledFeatures.map(
          (val) => val.name
        )
        await setData({
          features: fetchedFeatures,
        })
      },
    }
  )

  useEffect(() => {
    getFeatures()
  }, [])

  const features = data?.enabledFeatures.map((val) => val.name)

  return {
    features,
  }
}
