import BaseModal from '../Shared/BaseModal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { ClickAwayListener } from '@mui/material'
import { ModalContent } from '../Styles/CustomElementsStyled'
import { TextError, TextH6 } from '../Styles/TextCustom'
import { useForm } from 'react-hook-form'
import FullScreenLoader from '../Shared/FullScreenLoader'
import DrawerButtons from '../Shared/DrawerButtons'
import DrawerHeader from '../Shared/DrawerHeader'
import { useMutation, ApolloError } from '@apollo/client'
import { toast } from 'react-toastify'
import AvailabilitySwitch from '../Shared/AvailabilitySwitch'
import BannerInputs from '../Banner/BannerInputs'
import {
  ADD_BANNER,
  IAddBannerData,
  IAddBannerVars,
} from '../../graphql/banner/addBanner'

type FormData = {
  name: string
  url: string
  urlEn: string
  src: string
  srcEn: string
  mobileView: {
    label: string
    value: string
  }
}

const AddBanner = ({
  closeDetails,
  updateList,
}: {
  closeDetails: () => void
  updateList: () => void
}) => {
  const [openClickAwayModal, setOpenClickAwayModal] = useState(false)
  const [residentOnly, setResidentOnly] = useState(false)
  const [type, setType] = useState('external')
  const [error, setError] = useState('')
  const { t } = useTranslation(['banner'])
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()
  const successNotify = (message: string) => toast.dark(message)

  const handleCloseModal = (e: any) => {
    e.stopPropagation()
    setOpenClickAwayModal(false)
  }
  const handleClickAway = () => {}
  const onCompleted = () => {
    updateList()
    successNotify(t('success'))
    closeDetails()
  }
  const [addBanner, { loading }] = useMutation<IAddBannerData, IAddBannerVars>(
    ADD_BANNER,
    {
      onCompleted,
      onError: (error: ApolloError) => setError(error.message),
    }
  )

  const onSubmit = (data: FormData) => {
    const { name, url, urlEn, src, srcEn, mobileView } = data
    const variables = {
      name,
      src,
      srcEn: srcEn ? srcEn : src,
      residentOnly,
      visible: false,
    }
    switch (type) {
      case 'internal':
        return addBanner({
          variables: {
            ...variables,
            url: mobileView.value,
            urlEn: mobileView.value,
            type: 'internal',
          },
        })
      case 'external':
        return addBanner({
          variables: {
            ...variables,
            url,
            urlEn: urlEn ? urlEn : url,
            type: 'internal',
          },
        })
    }
  }

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <>
          <DrawerHeader title={t('add_banner')} handleClose={closeDetails} />
          <BannerInputs
            control={control}
            errors={errors}
            type={type}
            setType={setType}
          />
          <SwitchWrapper>
            <TextH6>{t('only_residents')}</TextH6>
            <AvailabilitySwitch
              checked={residentOnly}
              checkedText={`${t('residents')}`}
              uncheckedText={`${t('all')}`}
              onChange={() => setResidentOnly(!residentOnly)}
            />
          </SwitchWrapper>
          <DrawerButtons
            handleAccept={handleSubmit(onSubmit)}
            handleCancel={closeDetails}
          />
          {(!!Object.keys(errors).length || error) && (
            <TextError>{!error ? t('form:form_error') : error}</TextError>
          )}
        </>
      </ClickAwayListener>
      <BaseModal
        confirmText={'btn:exit'}
        open={openClickAwayModal}
        handleClose={(e) => handleCloseModal(e)}
        handleAccept={() => closeDetails()}
      >
        <ModalContent>{t('generic:exit_without_saving')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default AddBanner

const SwitchWrapper = styled.div`
  padding: 0 16px;
`
