import gql from 'graphql-tag'

export interface IUpdateExtrasSortVars {
  id: string
  sort: number
}

export interface IUpdateExtrasSortData {
  updateQuickPromo: {
    quickPromo: {
      sort: number
      title: string
    }
  }
}

export const UPDATE_EXTRAS_SORT = gql`
  mutation updateQuickPromo($id: [ID!], $sort: Int) {
    updateQuickPromo(input: { filter: { id: $id }, set: { sort: $sort } }) {
      quickPromo {
        id
        sort
        title
      }
    }
  }
`
