import React, { useMemo, useState, useCallback, FC } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Typography, IconButton, Tooltip, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import {
  EMAIL_SENT_NOT_VERIFIED,
  IqueryEmailVerificationRequestData,
  RESEND_VERIFICATION_CODE,
  IresendVerificationCodeVars,
  IresendVerificationCodeData,
  DELETE_VERIFICATION_CODE,
  IdeleteEmailVefiricationRequestVars,
  DELETE_VERIFICATION_CODES,
  IdeleteEmailVefiricationRequestsVars,
} from '../../graphql/users/queryEmailVerificationRequest'
import {
  dateIso2ExcelFormat,
  dateIso2localeString,
} from '../../utils/formatDate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { themeColors } from '../../const/colors'
import { BaseButton } from '../../components/Shared/BaseButton'
import BaseModal from '../../components/Shared/BaseModal'
import { toast } from 'react-toastify'
import {
  HeaderContainer,
  BadgeStyled,
} from '../../components/Styles/CustomElementsStyled'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Table from '../../components/Table'
import { ColumnDef } from '@tanstack/react-table'
interface Row {
  fullName: string
  login: string
  email: string
  createdAt: string
  createdAt_csv: string
}

const UnconfirmedEmailsList: FC<{ emailVerificationRequestCount?: number }> = ({
  emailVerificationRequestCount,
}) => {
  const { t, i18n } = useTranslation([
    'columns',
    'admin_menu',
    'email_verification_requests',
    'code',
    'btn',
  ])
  const [selectedRequests, setSelectedRequests] = useState<Row[]>()
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [confirmDeleteSelectedModal, setConfirmDeleteSelectedModal] =
    useState(false)
  const [rowToDelete, setRowToDelete] = useState<Row>()

  const successNotify = (message: string) => toast.dark(message)
  const errorNotify = (message: string) => toast.error(message)

  const resendEmail = (rowData: Row) => {
    resendVerificationCode({
      variables: { email: rowData.email },
    })
  }

  const resendSelected = () => {
    selectedRequests?.forEach((row) => {
      resendEmail(row)
    })
  }

  const onResendCompleted = (data: IresendVerificationCodeData) => {
    if (data?.resendVerificationCode.status) {
      successNotify(`${t('email_verification_requests:success')}`)
    } else errorNotify(`${t(`code:${data.resendVerificationCode.code}`)}`)
  }

  const handleSelectRows = useCallback((selectedRowsData: Row[]) => {
    setSelectedRequests(selectedRowsData)
  }, [])

  const handleSetRowToDelete = (row: Row) => {
    setRowToDelete(row)
    setConfirmDeleteModal(true)
  }
  const deleteRequest = () => {
    rowToDelete &&
      deleteEmailVerificationRequest({
        variables: {
          email: rowToDelete.email,
        },
      })
  }

  const deleteSelected = () => {
    const selectedEmails = selectedRequests?.map((item) => item.email)
    deleteEmailVerificationRequests({
      variables: {
        emails: selectedEmails,
      },
    })
  }
  const onDeleteCompleted = () => {
    refetch()
    setConfirmDeleteSelectedModal(false)
    setConfirmDeleteModal(false)
  }

  const { data, loading, refetch } =
    useQuery<IqueryEmailVerificationRequestData>(EMAIL_SENT_NOT_VERIFIED, {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    })

  const [resendVerificationCode] = useMutation<
    IresendVerificationCodeData,
    IresendVerificationCodeVars
  >(RESEND_VERIFICATION_CODE, {
    fetchPolicy: 'no-cache',
    onCompleted: onResendCompleted,
  })

  const [deleteEmailVerificationRequest] =
    useMutation<IdeleteEmailVefiricationRequestVars>(DELETE_VERIFICATION_CODE, {
      fetchPolicy: 'no-cache',
      onCompleted: onDeleteCompleted,
    })

  const [deleteEmailVerificationRequests] =
    useMutation<IdeleteEmailVefiricationRequestsVars>(
      DELETE_VERIFICATION_CODES,
      {
        fetchPolicy: 'no-cache',
        onCompleted: onDeleteCompleted,
      }
    )

  const invisibleColumns = {
    createdAtCSV: false,
  }

  const csvHeaders = [
    {
      label: t('full_name'),
      key: 'fullName',
    },
    {
      label: 'Login',
      key: 'login',
    },
    {
      label: 'Email',
      key: 'email',
    },
    {
      label: t('created_at'),
      key: 'createdAtCSV',
    },
  ]

  const columns: ColumnDef<Row, any>[] = useMemo(
    () => [
      {
        header: t('full_name'),
        accessorKey: 'fullName',
      },
      {
        header: t('login'),
        accessorKey: 'login',
      },
      {
        header: t('unconfirmed_email'),
        accessorKey: 'email',
      },
      {
        header: t('created_at'),
        accessorKey: 'createdAt',
        cell: (value) => dateIso2localeString(value.getValue(), i18n.language),
        enableColumnFilter: false,
      },
      {
        header: 'createdAt_csv',
        accessorKey: 'createdAtCSV',
      },
      {
        accessorKey: 'actions',
        header: '',
        cell: (value) => (
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            data-e2e="actions-cell"
          >
            <Tooltip title={`${t('email_verification_requests:send_again')}`}>
              <IconButton
                size="small"
                onClick={() => resendEmail(value.row.original)}
                data-e2e="resend-button"
              >
                <FontAwesomeIcon
                  icon={faPaperPlane as IconProp}
                  size="lg"
                  color={themeColors.primary}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={`${t('btn:delete')}`}>
              <IconButton
                size="small"
                onClick={() => handleSetRowToDelete(value.row.original)}
                data-e2e="delete-button"
              >
                <FontAwesomeIcon
                  icon={faTimesCircle as IconProp}
                  size="lg"
                  color={themeColors.error}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        ),
        enableColumnFilter: false,
      },
    ],
    [t, i18n]
  )

  const tableData: Row[] = useMemo(
    () =>
      data?.queryEmailVerificationRequest.map(
        ({ user, email, type, createdAt }) => ({
          login: user.login,
          fullName: `${user.firstName} ${user.lastName}`,
          email,
          createdAt,
          createdAt_csv: dateIso2ExcelFormat(createdAt),
          type,
        })
      ) || [],
    [data?.queryEmailVerificationRequest]
  )

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <HeaderContainer>
        <BadgeStyled badgeContent={emailVerificationRequestCount} color="error">
          <TypographyStyled variant="h6">
            {t('admin_menu:unconfirmed_emails')}
          </TypographyStyled>
        </BadgeStyled>
        <ButtonsWrapper>
          <BaseButton
            onClick={resendSelected}
            disabled={!selectedRequests?.length}
            data-e2e="resend-selected-button"
          >
            {t('btn:send_again')}
          </BaseButton>
          <DeleteButton
            onClick={() => setConfirmDeleteSelectedModal(true)}
            disabled={!selectedRequests?.length}
            data-e2e="delete-selected-button"
            customColor={themeColors.negative}
          >
            {t('btn:delete_selected')}
          </DeleteButton>
        </ButtonsWrapper>
      </HeaderContainer>
      <Table
        csvHeaders={csvHeaders}
        columnVisibility={invisibleColumns}
        enableRowSelection
        csvExport
        onSelectionChange={handleSelectRows}
        columns={columns}
        data={tableData}
        sortByKey="createdAt"
        sortDesc
      />
      <BaseModal
        title={t('email_verification_requests:delete_request')}
        confirmText={'btn:confirm'}
        open={confirmDeleteModal}
        handleClose={() => setConfirmDeleteModal(false)}
        handleAccept={() => deleteRequest()}
      />
      <BaseModal
        title={t('email_verification_requests:delete_selected_requests')}
        confirmText={'btn:confirm'}
        open={confirmDeleteSelectedModal}
        handleClose={() => setConfirmDeleteSelectedModal(false)}
        handleAccept={() => deleteSelected()}
      />
    </>
  )
}

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
`
const ButtonsWrapper = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
`
const DeleteButton = styled(BaseButton)`
  width: 200px;
  margin-left: 16px;
`

export default UnconfirmedEmailsList
