import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Grid, Alert } from '@mui/material'
import styled from 'styled-components'
import { useQuery, ApolloError } from '@apollo/client'
import {
  QUERY_PARKING_PLACE,
  IQueryParkingPlaceData,
  ParkingPlaceStatus,
  IPlace,
} from '../../graphql/parking/queryParkingPlace'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import PlacesTable from '../../components/ParkingPlaces/PlacesTable'
import PlaceFilters from '../../components/ParkingPlaces/PlaceFilters'
import { PlaceRow } from '../../components/ParkingPlaces/PlacesTable'
import { TextBody1Bold } from '../../components/Styles/TextCustom'
import { themeColors } from '../../const/colors'
import EditPlaces from '../../components/ParkingPlaces/EditPlaces'
import PlaceOccupancyInfo from '../../components/ParkingPlaces/PlaceOccupancyInfo'
import { useLocation } from 'react-router-dom'
import { sortParkingPlaces } from '../../utils/sortParkingPlaces'
import TooltipButton from '../../components/Shared/TooltipButton'
import ErrorContainer from '../../components/Shared/ErrorContainer'
import AssignPlaceToParkingPool from '../../components/ParkingPlaces/AssignPlaceToParkingPool'

interface stateType {
  prop: { parkingProp: string | undefined; parkingPool: boolean | undefined }
}

const ParkingPlacesList = () => {
  const { t } = useTranslation(['parking_places'])
  const location = useLocation()
  const state: stateType = location.state as stateType
  const [openedEditForm, setOpenedEditForm] = useState<PlaceRow[]>([])
  const [parkingFilter, setParkingFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState<ParkingPlaceStatus | ''>('')
  const [sharedFilter, setSharedFilter] = useState('')
  const [error, setError] = useState('')
  const [places, setPlaces] = useState<IPlace[]>([])
  const [parkingPool, setParkingPool] = useState(false)
  const [selectedPlaces, setSelectedPlaces] = useState<PlaceRow[]>([])

  const { data, loading, refetch } = useQuery<IQueryParkingPlaceData>(
    QUERY_PARKING_PLACE,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => setError(error.message),
    }
  )

  const handleAssignPlacesToParkingPool = () => {
    setParkingPool(true)
    setOpenedEditForm(selectedPlaces)
  }

  const closeForm = () => {
    setParkingPool(false)
    setOpenedEditForm([])
  }

  useEffect(() => {
    if (state?.prop?.parkingProp) {
      setParkingFilter(state.prop.parkingProp)
    }
    if (state?.prop?.parkingPool) {
      setStatusFilter(ParkingPlaceStatus.available)
    }
  }, [state])

  useEffect(() => {
    if (data) {
      let items = data.places
      if (statusFilter) {
        items = data.places.filter(({ status }) => status === statusFilter)
      }
      if (parkingFilter) {
        items = items.filter(({ parking }) => parking.name === parkingFilter)
      }
      if (sharedFilter) {
        sharedFilter === 'yes'
          ? (items = items.filter(({ shared }) => shared))
          : (items = items.filter(({ shared }) => !shared))
      }
      const sortedItems = sortParkingPlaces(items)
      setPlaces(sortedItems)
    }
  }, [statusFilter, data, sharedFilter, parkingFilter])

  if (!data || loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <Title variant="h6">{t('parking_place_statuses')}</Title>
      <PlaceOccupancyInfo data={data} parking={parkingFilter} />
      <PlacesTable places={places} setSelectedPlaces={setSelectedPlaces}>
        <>
          {!!state?.prop?.parkingPool && (
            <AlertStyled severity="error">
              {t('parking_pool:assign_places_hint')}
            </AlertStyled>
          )}
          <PlaceFilters
            parking={parkingFilter}
            setParking={setParkingFilter}
            status={statusFilter}
            setStatus={setStatusFilter}
            shared={sharedFilter}
            setShared={setSharedFilter}
            tenantFilter={false}
          />
          <RowContainer>
            {!!selectedPlaces.length && (
              <Text color={themeColors.primary}>{`${selectedPlaces.length} ${t(
                'generic:selected'
              )}`}</Text>
            )}

            <TooltipButton
              onClick={() => setOpenedEditForm(selectedPlaces)}
              disabled={!statusFilter || !selectedPlaces.length}
              hint={t('change_status_button_hint')}
              data-e2e="change-status"
            >
              {t('change_status')}
            </TooltipButton>
            <ButtonWrapper>
              <TooltipButton
                onClick={handleAssignPlacesToParkingPool}
                disabled={
                  !parkingFilter || !statusFilter || !selectedPlaces.length
                }
                hint={t('change_status_button_hint')}
                data-e2e="assign_places"
              >
                {t('assign_place_to_parking_pool')}
              </TooltipButton>
            </ButtonWrapper>
          </RowContainer>
        </>
      </PlacesTable>
      {!places.length && (
        <TextBody1Bold color={themeColors.primary}>
          {t('generic:no_results')}
        </TextBody1Bold>
      )}
      {!!error && <ErrorContainer errorMessage={error} />}
      <BaseDrawer open={!!openedEditForm.length} variant={'temporary'}>
        {!parkingPool && (
          <EditPlaces
            closeDetails={() => setOpenedEditForm([])}
            parking={parkingFilter}
            status={statusFilter}
            places={openedEditForm}
            refetch={refetch}
          />
        )}
        {!!parkingPool && (
          <AssignPlaceToParkingPool
            closeDetails={closeForm}
            parking={parkingFilter}
            places={openedEditForm}
            refetch={refetch}
          />
        )}
      </BaseDrawer>
    </>
  )
}

export default ParkingPlacesList

const Title = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
const RowContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
`
const Text = styled(TextBody1Bold)`
  margin-right: 16px;
`
const AlertStyled = styled(Alert)`
  margin: 16px 0;
`
const ButtonWrapper = styled(Grid)`
  padding: 0 16px;
`
