import React, { FC, useState } from 'react'
import { useAppSelector } from '../../redux/store'
import { List, Collapse, ListItemIcon, ListItemText } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import FAIcon from '../Shared/FAIcon'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../const/routes'
import { useTranslation } from 'react-i18next'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  MainListItem,
  ListItem,
  ListItemTextStyled,
  BadgeStyled,
  BadgeDot,
  LinkStyled,
} from '../Styles/NavListStyle'
import { checkRole } from '../../utils/checkRole'
import {
  USER_ROLES,
  ISSUE_ROLES,
  USER_LIST_ROLES,
} from '../../const/permissions'

interface IUsersListItemProps {
  tenantRequestCount?: number
  emailVerificationRequestCount?: number
  isMenuOpen?: boolean
}

const UsersListItem: FC<IUsersListItemProps> = ({
  tenantRequestCount,
  emailVerificationRequestCount,
  isMenuOpen,
}) => {
  const { t } = useTranslation(['admin_menu'])
  let { pathname } = useLocation()

  const [isUsersExpanded, setIsUsersExpanded] = useState(false)
  const { isAdmin, roles } = useAppSelector((state) => state.user)

  const isSelected = (route: string, isNestedListExpanded: boolean = true) =>
    !isMenuOpen || !isNestedListExpanded
      ? pathname.includes(route)
      : `/admin/${route}` === pathname

  if (!checkRole(USER_ROLES, roles)) {
    return null
  }

  return (
    <>
      <MainListItem
        onClick={() => setIsUsersExpanded(!isUsersExpanded)}
        data-e2e="navigation-users"
      >
        <ListItemIcon>
          <FAIcon icon={faUserCircle as IconProp} />
        </ListItemIcon>
        <BadgeDot variant="dot" invisible={!tenantRequestCount} color="error">
          <ListItemTextStyled primary={t('users')} />
        </BadgeDot>
        {isUsersExpanded ? <ExpandLess /> : <ExpandMore />}
      </MainListItem>
      <Collapse in={isMenuOpen && isUsersExpanded} timeout="auto">
        <List component="div">
          {checkRole(USER_LIST_ROLES, roles) && (
            <LinkStyled to={ROUTES.USER_LIST} data-e2e="nav-users-list">
              <ListItem
                selected={isSelected(ROUTES.USER_LIST, isUsersExpanded)}
                data-e2e="nav-user-list"
              >
                <ListItemText primary={t('users_list')} />
              </ListItem>
            </LinkStyled>
          )}

          {isAdmin && (
            <LinkStyled to={ROUTES.USER_ROLES_LIST}>
              <ListItem
                selected={isSelected(ROUTES.USER_ROLES_LIST, isUsersExpanded)}
                data-e2e="nav-user-roles-list"
              >
                <ListItemText primary={t('user_roles_list')} />
              </ListItem>
            </LinkStyled>
          )}
          {checkRole(ISSUE_ROLES, roles) && (
            <LinkStyled to={ROUTES.USERS_TENANT_ISSUES}>
              <ListItem
                selected={isSelected(
                  ROUTES.USERS_TENANT_ISSUES,
                  isUsersExpanded
                )}
                data-e2e="nav-users-requests"
              >
                <BadgeStyled badgeContent={tenantRequestCount} color="error">
                  <ListItemText primary={t('tenant_issues')} />
                </BadgeStyled>
              </ListItem>
            </LinkStyled>
          )}
          {isAdmin && (
            <>
              <LinkStyled to={ROUTES.UNCONFIRMED_EMAILS_LIST}>
                <ListItem
                  selected={isSelected(
                    ROUTES.UNCONFIRMED_EMAILS_LIST,
                    isUsersExpanded
                  )}
                  data-e2e="nav-unconfirmed-emails"
                >
                  <BadgeStyled
                    badgeContent={emailVerificationRequestCount}
                    color="error"
                  >
                    <ListItemText primary={t('unconfirmed_emails')} />
                  </BadgeStyled>
                </ListItem>
              </LinkStyled>

              <LinkStyled to={ROUTES.DELETE_ACCOUNT_REQUESTS_LIST}>
                <ListItem
                  selected={isSelected(
                    ROUTES.DELETE_ACCOUNT_REQUESTS_LIST,
                    isUsersExpanded
                  )}
                  data-e2e="nav-delete-account-requests"
                >
                  <ListItemText primary={t('delete_account_requests')} />
                </ListItem>
              </LinkStyled>
            </>
          )}
        </List>
      </Collapse>
    </>
  )
}

export default UsersListItem
