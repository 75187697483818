import gql from 'graphql-tag'
import { LockerStatus } from './queryLockers'
import { IQueryBasicData } from '../shared/IQueryData'

export interface IChangeLockerStatusVars {
  id: string
  oldStatus: LockerStatus
  newStatus: string
  comment?: string
}

export interface IChangeLockerStatusData {
  changeLockerStatus: IQueryBasicData
}

export const CHANGE_LOCKER_STATUS = gql`
  mutation ChangeLockerStatus(
    $id: ID!
    $oldStatus: LockerStatus!
    $newStatus: LockerStatus!
    $comment: String
  ) {
    changeLockerStatus(
      id: $id
      old_status: $oldStatus
      new_status: $newStatus
      comment: $comment
    ) {
      status
      code
      error
      message
    }
  }
`
