import { IQueryBasicData } from '../shared/IQueryData'
import { gql } from '@apollo/client'

export interface IEmailVars {
  email: string
}

export interface IResetPasswordData {
  sendPasswordReset: IQueryBasicData
}

export const RESEND_VERIFICATION_CODE = gql`
  mutation resendVerificationCode($email: String!) {
    resendVerificationCode(email: $email) {
      code
      error
      message
      status
    }
  }
`
