import gql from 'graphql-tag'
import { OneTimeParkingTicket } from './queryOneTimeParkingTicket'

export interface IGetOneTimeParkingTicketVars {
  id?: string
}

export interface IGetOneTimeParkingTicketData {
  getOneTimeParkingTicket: OneTimeParkingTicket
}

export const GET_ONE_TIME_PARKING_TICKET = gql`
  query getOneTimeParkingTicketLogs($id: ID!) {
    getOneTimeParkingTicket(id: $id) {
      id
      ticketNumber: ticket_number
      amanoTicketNo: amano_ticketno
      validFrom: valid_from
      validTo: valid_to
      createdAt: created_at
      carPlate: car_plate
      status
      price
      parkingName: parking_name
      payment {
        id
        paymentId: id
        sessionId: session_id
        createdAt: created_at
        status
        externalId: external_order_id
        logs {
          message
          createdAt: created_at
        }
      }
      user {
        login
        firstName: first_name
        lastName: last_name
        email {
          email
        }
        tenant {
          name
        }
      }
      logs {
        createdAt: created_at
        message
        parameters {
          name
          value
          type
        }
      }
    }
  }
`
