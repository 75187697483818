import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Tooltip, Grid } from '@mui/material'
import { themeColors } from '../../const/colors'
import { IconButton } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const MenuItemActionsCell = ({
  onClickDetails,
  onClickDelete,
}: {
  onClickDetails: () => void
  onClickDelete: () => void
}) => {
  const { t } = useTranslation(['gastro'])

  return (
    <ActionsWrapper container direction="row" justifyContent="space-between">
      <Tooltip
        title={`${t('btn:edit')}`}
        data-e2e="gastro-table-button-edit-dish"
      >
        <IconButton size="small" onClick={onClickDetails}>
          <FontAwesomeIcon
            icon={faEdit as IconProp}
            size="1x"
            color={themeColors.primary}
          />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={`${t('btn:delete')}`}
        data-e2e="gastro-table-button-remove-dish"
      >
        <IconButton size="small" onClick={onClickDelete}>
          <FontAwesomeIcon
            icon={faTrashAlt as IconProp}
            size="1x"
            color={themeColors.error}
          />
        </IconButton>
      </Tooltip>
    </ActionsWrapper>
  )
}

export default MenuItemActionsCell

const ActionsWrapper = styled(Grid)`
  padding-right: 8px;
  width: 80px;
`
