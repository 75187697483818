import React, { useState, FC } from 'react'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ResidentIssuesTable from '../../components/ResidentRequests/ResidentIssuesTable'
import {
  BadgeStyled,
  TabStyled,
  TabsStyled,
} from '../../components/Styles/CustomElementsStyled'

const ResidentIssuesList: FC<{
  tenantRequestCount?: number
  updateAggregatedCount: () => void
}> = ({ tenantRequestCount, updateAggregatedCount }) => {
  const [value, setValue] = useState(0)
  const { t } = useTranslation(['tabs', 'admin_menu'])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <BadgeStyled badgeContent={tenantRequestCount} color="error">
        <TypographyStyled variant="h6">
          {t('admin_menu:tenant_issues')}
        </TypographyStyled>
      </BadgeStyled>
      <TabsStyled value={value} onChange={handleChange} textColor="primary">
        <TabStyled label={t('pending')} />
        <TabStyled label={t('verified')} />
      </TabsStyled>
      <ResidentIssuesTable
        tab={value}
        updateAggregatedCount={updateAggregatedCount}
      />
    </>
  )
}

export default ResidentIssuesList
const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
`
