import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TextH6Bold } from '../../components/Styles/TextCustom'
import { themeColors } from '../../const/colors'
import { useQuery } from '@apollo/client'
import {
  IQueryOlivkaRegexpData,
  QUERY_OLIVKA_REGEXP,
  IRegexp,
} from '../../graphql/olivka/queryOlivkaRegexp'
import OffersBlockingInputs from '../../components/Olivka/OffersBlockingInputs'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'

const BlockingOlivkaProducts = () => {
  const { t } = useTranslation(['olivka'])
  const [offers, setOffers] = useState<IRegexp[]>([])
  const [restaurants, setRestaurants] = useState<IRegexp[]>([])
  const [promo, setPromo] = useState<IRegexp[]>([])
  const [loading, setLoading] = useState(true)

  const {
    refetch,
    loading: regexpLoading,
    data,
  } = useQuery<IQueryOlivkaRegexpData>(QUERY_OLIVKA_REGEXP, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (data) {
      const { offers, restaurants, promo } = data
      setOffers(offers)
      setRestaurants(restaurants)
      setPromo(promo)
      setLoading(false)
    }
  }, [data, refetch])

  if (loading || regexpLoading) {
    return <FullScreenLoader />
  }

  return (
    <Container>
      <BlueContainerStyled>
        <TextH6Bold>{t('blocking_olivka_offers')}</TextH6Bold>
        <OffersBlockingInputs
          items={offers}
          title={'offers'}
          refetch={refetch}
        />
        <OffersBlockingInputs
          items={restaurants}
          title={'restaurants'}
          refetch={refetch}
        />
        <OffersBlockingInputs items={promo} title={'promo'} refetch={refetch} />
      </BlueContainerStyled>
    </Container>
  )
}

export default BlockingOlivkaProducts

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const BlueContainerStyled = styled.div`
  width: 566px;
  background-color: ${themeColors.lightBlue};
  border-radius: 30px;
  padding: 10px 50px;
`
