import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { TextH6Bold } from '../Styles/TextCustom'
import { CenterContainer } from '../../components/Styles/CustomElementsStyled'
import { CircularProgress } from '@mui/material'
import { themeColors } from '../../const/colors'

const FullScreenLoader: FC = ({ children }: { children?: ReactNode }) => {
  return (
    <CenterContainer>
      {!!children && <LoaderText>{children}</LoaderText>}
      <CustomProgress size={50} />
    </CenterContainer>
  )
}

const LoaderText = styled(TextH6Bold)`
  padding-bottom: 40px;
`
const CustomProgress = styled(CircularProgress)`
  color: ${themeColors.primary};
  margin: 16px;
`

export default FullScreenLoader
