import gql from 'graphql-tag'

export interface IQueryPayuPayoutCompanyData {
  queryPayuPayoutCompany: {
    name: string
    description: string
  }[]
}

export const QUERY_PAYU_PAYOUT_COMPANY = gql`
  query queryPayuPayoutCompany {
    queryPayuPayoutCompany {
      name
      description
    }
  }
`
