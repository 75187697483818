import { useState } from 'react'
import styled from 'styled-components'
import { TextBody2Medium } from '../Styles/TextCustom'
import { useForm } from 'react-hook-form'
import { ApolloError, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { IssueType } from '../../graphql/userIssues/queryIssue'
import { IIssue } from '../../graphql/userIssues/getIssue'
import {
  UPDATE_ISSUE_TYPE,
  IUpdateIssueTypeData,
  IUpdateIssueTypeVars,
} from '../../graphql/userIssues/updateIssueType'
import BasicSelect from '../Shared/BaseSelect'
import { BaseButton } from '../Shared/BaseButton'
import { themeColors } from '../../const/colors'
import { Grid } from '@mui/material'
import {
  APPEND_LOG,
  IAppendLogVars,
  IAppendLogData,
} from '../../graphql/shared/appendLog'
import { useAppSelector } from '../../redux/store'
import ErrorContainer from '../Shared/ErrorContainer'

type FormData = {
  type: {
    value: IssueType
    label: string
  }
}

const ChangeIssueType = ({
  issue,
  refetch,
  updateList,
}: {
  issue: IIssue
  refetch: () => void
  updateList: () => void
}) => {
  const { t } = useTranslation(['user_message_details'])
  const [errorMessage, setErrorMessage] = useState('')
  const { login } = useAppSelector((state) => state.user)
  const { id, type } = issue
  const { control, handleSubmit } = useForm<FormData>()

  const options = [
    { label: t('application_problem'), value: IssueType.application_problem },
    {
      label: t('account_delete_request'),
      value: IssueType.account_delete_request,
    },
  ]

  const [appendLog] = useMutation<IAppendLogData, IAppendLogVars>(APPEND_LOG)

  const addMessageLogLine = (message: string, objectId: string) =>
    appendLog({
      variables: {
        objectId,
        message,
        parameters: 'value=issue.setup.type',
      },
    })

  const onCompleted = (data: IUpdateIssueTypeData) => {
    refetch()
    updateList()
    addMessageLogLine(
      `Issue type changed to ${data.updateIssue.issue[0].type} by ${login}`,
      id
    )
  }

  const [updateIssueType, { loading }] = useMutation<
    IUpdateIssueTypeData,
    IUpdateIssueTypeVars
  >(UPDATE_ISSUE_TYPE, {
    onCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  if (loading || !issue) {
    return <FullScreenLoader />
  }

  const onSubmit = (formData: FormData) =>
    updateIssueType({
      variables: {
        id,
        type: formData.type.value,
      },
    })

  return (
    <Container container>
      <TextBody2Medium>{t('change_issue_type')}</TextBody2Medium>
      <SelectWrapper item xs={9}>
        <BasicSelect
          options={options.map(({ label, value }) => ({
            value,
            label,
          }))}
          control={control}
          name={'type'}
          label={t('issue_type')}
          defaultValue={options.find((item) => item.value === type)}
          isClearable={false}
          isSearchable={false}
        />
      </SelectWrapper>
      <ButtonWrapper item xs={3}>
        <BaseButton onClick={handleSubmit(onSubmit)}>
          {t('btn:save')}
        </BaseButton>
      </ButtonWrapper>
      {errorMessage && <ErrorContainer errorMessage={errorMessage} />}
    </Container>
  )
}

export default ChangeIssueType

const Container = styled(Grid)`
  background-color: ${themeColors.white};
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 16px;
`
const SelectWrapper = styled(Grid)`
  padding: 8px 0;
`
const ButtonWrapper = styled(Grid)`
  padding: 8px;
`
