import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { Controller } from 'react-hook-form'
import { IFormError } from '../../types/FormTypes'
import dayjs from 'dayjs'

const TimePickerController = ({
  label,
  control,
  errors,
  name,
  defaultValue,
  rules,
  disabled,
}: {
  label: string
  control: any
  errors: IFormError | any
  name: string
  defaultValue?: Date | null
  rules?: Object
  disabled?: boolean
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue ? dayjs(defaultValue) : null}
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <TimePicker
          label={label}
          value={value}
          onChange={onChange}
          disabled={disabled}
          format="HH:mm"
          ampm={false}
          ampmInClock={false}
          timeSteps={{ hours: 1, minutes: 1 }}
          slotProps={{
            textField: {
              error: !!errors[name],
              helperText: !!errors[name]?.message && errors[name]?.message,
            },
          }}
        />
      )}
    />
  )
}

export default TimePickerController
