import gql from 'graphql-tag'
import { IQueryBasicData } from '../shared/IQueryData'
import { Roles } from '../shared/sharedTypes'

export interface ILoginVars {
  login: string
  password: string
  app?: string
  apiVersion: number
  service?: string
}

export interface ILoginData {
  login: IQueryBasicData & {
    accessToken: string
    refreshToken: string
    user: {
      roles: {
        name: Roles
      }[]
    }
  }
}

export const LOGIN_QUERY = gql`
  query login(
    $login: String!
    $password: String!
    $app: String!
    $apiVersion: Int!
    $service: String
  ) {
    login(
      login: $login
      password: $password
      app: $app
      api_version: $apiVersion
      service: $service
    ) {
      accessToken
      code
      error
      message
      refreshToken
      status
      user {
        roles {
          name
        }
      }
    }
  }
`
