import gql from 'graphql-tag'
import { ReservationStatus } from '../shared/sharedTypes'

export interface IBuyer {
  name: string
  nip: string
  street: string
  city: string
  zipcode: string
}

export interface IUpdateLockerGroupReservationVars {
  id: string
  status: ReservationStatus
  validFrom: string
  validTo: string
  tenant?: { id: string }
  tenantName: string
  tenantEmail: string
  price: number
  comment: string
  representative?: string
  buyer?: IBuyer
}

export const UPDATE_LOCKER_GROUP_RESERVATION = gql`
  mutation updateLockerGroupReservation(
    $id: [ID!]
    $status: ReservationStatus
    $validFrom: DateTime
    $validTo: DateTime
    $tenant: TenantRef
    $tenantName: String
    $tenantEmail: String
    $price: Int
    $comment: String
    $representative: String
    $buyer: CompanyInfoRef
  ) {
    updateLockerGroupReservation(
      input: {
        filter: { id: $id }
        set: {
          status: $status
          valid_from: $validFrom
          valid_to: $validTo
          tenant: $tenant
          tenant_name: $tenantName
          tenant_email: $tenantEmail
          price: $price
          comment: $comment
          buyer_represented_by: $representative
          buyer: $buyer
        }
      }
    ) {
      lockerGroupReservation {
        id
      }
    }
  }
`
