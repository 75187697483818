import gql from 'graphql-tag'

export interface IAddOlivkaRegexpVars {
  name: string
  value: string
}

export const ADD_OLIVKA_REGEXP = gql`
  mutation addOlivkaRegexp($name: String!, $value: String!) {
    addGlobalConfig(
      input: [{ name: $name, value: $value, type: "string", enabled: true }]
    ) {
      globalConfig {
        name
        value
      }
    }
  }
`
