import gql from 'graphql-tag'

export interface ISurvey {
  surveyId: string
  surveyName: string
  surveyNameEn: string
  active: boolean
  preview: boolean
  description: string
  descriptionEn: string
  createdAt: string
  answersAggregate: {
    quantityOfResponse: number
  }
  answers: {
    user: {
      login: string
    }
  }[]
}

export interface IQuerySurveyData {
  querySurvey: ISurvey[]
}

export const QUERY_SURVEY = gql`
  query querySurvey {
    querySurvey {
      surveyId: id
      surveyName: name
      surveyNameEn: name_en
      active
      preview
      description
      descriptionEn: description_en
      createdAt: created_at
      answersAggregate {
        quantityOfResponse: count
      }
      answers {
        user {
          login
        }
      }
    }
  }
`
