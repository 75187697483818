import React, { useState } from 'react'
import { TextH4Bold, TextInfo } from '../Styles/TextCustom'
import { Img } from '../Styles/CustomElementsStyled'
import image from '../../assets/images/email-sent.png'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { BaseTextButton } from '../Shared/BaseButton'
import { themeColors } from '../../const/colors'

const RemindPasswordEmailSent = ({
  data,
  resend,
  loading,
}: {
  data: string
  resend: () => void
  loading: boolean
}) => {
  const { t } = useTranslation(['remind_password_email_sent_screen'])

  return (
    <Container>
      <Header>{t('header')}</Header>
      <TextInfo>{data}</TextInfo>
      <Text>{t('info')}</Text>
      <Img src={image} alt="img" width="515" />
      <Text>{t('info2')}</Text>
      <BaseTextButton
        disabled={loading}
        onClick={resend}
        color={themeColors.primary}
      >
        {t('btn:send_link_again')}
      </BaseTextButton>
    </Container>
  )
}

export default RemindPasswordEmailSent
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
`
const Header = styled(TextH4Bold)`
  margin: 0;
`
const Text = styled(TextInfo)`
  margin: 0;
`
