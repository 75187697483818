import React, { useCallback, useEffect, useState } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import {
  UPDATE_SURVEY_STATUS,
  IUpdateSurveyStatusVars,
} from '../../graphql/survey/updateSurveyStatus'
import { ISurvey } from '../../graphql/survey/querySurvey'
import { Grid } from '@mui/material'
import AvailabilitySwitch from '../../components/Shared/AvailabilitySwitch'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { toast } from 'react-toastify'

const SurveyActivity = ({
  surveyId,
  surveys,
  refetch,
}: {
  surveyId: string
  surveys: ISurvey[]
  refetch: () => void
}) => {
  const [available, setAvailable] = useState<string[]>([])
  const errorNotify = (message: string) => toast.error(message)

  const [updateSurveyStatus, { loading: updateLoading }] =
    useMutation<IUpdateSurveyStatusVars>(UPDATE_SURVEY_STATUS, {
      onCompleted: () => refetch(),
      onError: (error: ApolloError) => errorNotify(error.message),
    })

  const handleAvailabilityChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, name } = event.target
      const variables = {
        id: name,
        active: checked,
      }

      if (checked && !available.length) {
        checked && setAvailable((items) => items.concat([name]))
        updateSurveyStatus({ variables })
      } else if (!checked && available.length) {
        setAvailable((items) => items.filter((item) => item !== name))
        updateSurveyStatus({ variables })
      }
    },
    [available, updateSurveyStatus]
  )

  useEffect(() => {
    if (!!surveys) {
      setAvailable(
        surveys
          .filter((item: any) => item.active)
          .map((item: any) => item.surveyId)
      )
    }
  }, [surveys])

  if (updateLoading) {
    return <FullScreenLoader />
  }

  return (
    <Grid>
      <AvailabilitySwitch
        checked={available.includes(surveyId)}
        name={surveyId}
        onChange={handleAvailabilityChange}
        checkedText={'active'}
        uncheckedText={'inactive'}
      />
    </Grid>
  )
}

export default SurveyActivity
